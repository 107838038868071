'use strict';

exports.init = function () {
	$('.home-page-hero').on('click', function() {
		var _url = $(this).data('url');
		if (_url) {
			window.location.href = _url;
		}
    }); 
};
