'use strict';

const breakpoints = require('./breakpoints');
const debounce = require('lodash/debounce');
var progressBar = require('./pages/checkout/progressBar');
let progressInitResult;

const miniCart = '.mini-cart-content';

const minicart = {
    init: () => {
        setBagIconInstance();
        
        $(window).on('scroll resize', debounce(()=>{
            setBagIconInstance();
        }, 200));

        $(`${miniCart} .mini-cart-close`).on('click', () => {
            minicart.close();
            handleMiniCartEngagementTracking('modal exit', null, progressInitResult);
        });

        $('.mini-cart-overlay').on('click', () => {
            minicart.close();
        });

        $(document).on('keydown', (e) => {
            if(e.key === 'Escape' && $('.mini-cart-content').is(':visible')){
                minicart.close();
            }
        });
    },
    /**
     * @function
     * @description Shows the given content in the mini cart
     * @param {String} A HTML string with the content which will be shown
     */
    show: function (html) {
        try {
            $(miniCart).html(html);

            if($('.product-quick-view').is(':visible')){
                $('.ui-dialog-titlebar-close').trigger('click');
            }
    
            $(miniCart).animate({
                right: 0
            }, {
                start: () => {
                    $(miniCart).css('display', 'flex');
                    $(':root').css('overflow', 'hidden');
    
                    if(!breakpoints.isMobile()){
                       $('.mini-cart-overlay').show(); 
                    }

                    progressInitResult = progressBar.init();
    
                    minicart.init();
    
                    const $miniCartName = $(`${miniCart} .mini-cart-name`);
                    const fullProductName = $miniCartName.text();
                    const maxChars = 70;
                    if(fullProductName.length > maxChars){
                        const shortenedProductName = fullProductName.substring(0, maxChars) + "...";
                        $miniCartName.text(shortenedProductName);
                    } 
                },
                duration: 300,
                done: () => {
                    const cartQuantity = $('.mini-cart-content .mc-quantity .qty').text();

                    $(miniCart).trigger("focus");
                    $('.mini-cart-alert').text('A product has been added to your cart! Continue shopping or checkout now.');
                    
                    const lateLoadingRecommender = setInterval(() => {
                        if($(`${miniCart} .mini-cart-recommender .product__image`).length > 0){
                            clearInterval(lateLoadingRecommender);
                            addMiniCartRecommenderEventListeners();
                        }
                    }, 100);

                    $('.sh-promo-bar-item__icon--bag .minicart-quantity').text(cartQuantity);
                }
            });
        } catch (error) {
            console.warn('minicart could not be shown');
        }
    },
    /**
     * @function
     * @description Update the given content in the minicart, but do not show
     * @param {String} A HTML string with the content which will be shown
     */
    update: function (html) {
        try {
            $(miniCart).html(html);
        } catch (error) {
            console.warn('minicart could not be updated');
        }
    },
    close: () => {
        $(miniCart).animate({
            right: "-390px"
        }, {
            duration: 300,
            done: () => {
                $(miniCart).css('display', 'none');
                $(':root').css('overflow', 'auto');
                $('.mini-cart-overlay').hide();
                $('.mini-cart-alert').text('Your mini cart is now closed, can\'t wait to see what you pick next!');
            }
        });
    }
};

function handleMiniCartEngagementTracking(action, page, progressInitResult){
    window.dataLayer.push({
        event: 'mini-cart-engagement',
        clickType: action,
        currentPage: page,
        freeShippingThresholdMet: progressInitResult.freeShippingThresholdMet
    });
}

function setBagIconInstance(){
    // Set correct instance for bag icon depending on which of our site headers is in use
    const bagIconSelector = '.sh-promo-bar-item__icon--bag';
    let bagIcon = '';

    if(breakpoints.isDesktop()){
        if($('body').hasClass('has-fixed-header')){
            const dockedSiteHeaderSelectors = '.has-fixed-header .site-header .sh-nav-right';
            bagIcon = `${dockedSiteHeaderSelectors} ${bagIconSelector}`
        }else{  
            const fullSiteHeaderSelectors = '.site-header .sh-banner-wrapper--middle .sh-middle-right-outer';
            bagIcon = `${fullSiteHeaderSelectors} ${bagIconSelector}`
        }
    }else{
        const mobileTabletSiteHeaderSelectors = '.site-header .sh-banner';
        bagIcon = `${mobileTabletSiteHeaderSelectors} ${bagIconSelector}`
    }

    $(bagIcon).off().on('click', () => {
        window.location.href = $('.js-mini-cart-link').data('link-url');
        window.dataLayer.push({
            'event': 'navigation-utility-cart-click'
        });
    });
}

function addMiniCartRecommenderEventListeners(){
    $(`${miniCart} .mini-cart-recommender .product__image, ${miniCart} .mini-cart-recommender .product__name`).on('click', (e) => {
        const position = Math.floor($(e.target).closest('.product-tile').data('position'));
        const sku = $(e.target).closest('.product-tile').data('itemid');
        const name = $(e.target).closest('.product-tile').find('.product__name .seo-heading').text();
        const clickLocation = e.target.tagName === 'IMG' ? 'image' : 'product name';

        window.dataLayer.push({
            event: 'mini-cart-recommender-engagement',
            recommenderPosition: position,
            productSku: sku,
            productName: name,
            clickType: clickLocation
        });
    })
}

module.exports = minicart;
