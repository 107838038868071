'use strict';

var util = require('../util');

function productView(pageData) {
	if (window.MARKETINGPIXELS.enablePinterestTracking != "true") {
        return;
    }

	var trackObj = {
        value: pageData.product.price,
        currency: window.CURRENCY_CODE,
        product_id: [pageData.product.id],
    };
    pintrk('track', 'pagevisit', trackObj);

    if (window.enableMarketingPixelDebug == "true") {
        console.log("pintrkDataObj: " + JSON.stringify(trackObj));
        console.log("Pinterest Product View");
    }
}

function addToBag(addToBagData) {
    if (window.MARKETINGPIXELS.enablePinterestTracking != "true") {
        return;
    }

	var trackObj = {
        value: addToBagData.product.price,
        order_quantity: !addToBagData.quantity ? 1 : addToBagData.quantity,
        currency: window.CURRENCY_CODE,
        product_id: [util.getVariationSKU(addToBagData.product.id)]
	};
    pintrk('track', 'addtocart', trackObj);

    if (window.enableMarketingPixelDebug == "true") {
        console.log("pintrkDataObj: " + JSON.stringify(trackObj));
        console.log("Pinterest Add To Cart");
    }
}

module.exports = {
	productView: productView,
	addToBag: addToBag
};
