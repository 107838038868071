'use strict';

var applePayPaymentRequest;

function initializeEvents() {
	refreshApplePayRequest();

	$('.apple-button').on('click', function() {
		var $this = $(this);
		$this.addClass('active');
		var isExpress = $this.hasClass('js-applepay-express');

		var session = new ApplePaySession(3, applePayPaymentRequest);
		session.begin();

		session.oncancel = function() {
			$('.apple-button').removeClass('active');
		};

		session.onvalidatemerchant = function(event) {
			$.ajax({
				url: Urls.applepayValidateMerchant,
				type: 'POST',
				data: {
					'validationURL': event.validationURL,
					'isExpress': (isExpress) ? "Y" : "N"
				}
			})
			.done(function(response) {
				if (response.success) {
					session.completeMerchantValidation(JSON.parse(response.merchantSession));
				}
			});
		};

		session.onshippingcontactselected = function(event) {
			$.ajax({
				url: Urls.applepayShippingContact,
				type: 'POST',
				data: event.shippingContact,
				dataType: 'json'
			})
			.done(function(response) {
				if (response.success) {
					session.completeShippingContactSelection(response.shippingContactUpdate);
				} else {
					var applePayErrors = [];
					var currentError;

					for (var i=0; i<response.errors.length; i++) {
						currentError = response.errors[i];
						applePayErrors.push(new ApplePayError(currentError.code, currentError.contactField, currentError.message));
					}

					var shippingContactUpdate = response.shippingContactUpdate;
					shippingContactUpdate.errors = applePayErrors;

					session.completeShippingContactSelection(shippingContactUpdate);
				}
			});
		};

		session.onshippingmethodselected = function(event) {
			$.ajax({
				url: Urls.applepayShippingMethod,
				type: 'POST',
				data: {
					shippingMethodID: event.shippingMethod.identifier
				}
			})
			.done(function(response) {
				session.completeShippingMethodSelection(response);
			});
		};

		session.onpaymentauthorized = function(event) {
			$.ajax({
				url: Urls.applepayCompletePayment,
				type: 'POST',
				data: {
					payment: JSON.stringify(event.payment)
				}
			})
			.done(function(response) {
				if (response.success) {
					session.completePayment({
						"status": 0
					});
				} else {
					var applePayErrors = [];
					var currentError;

					for (var i=0; i<response.errors.length; i++) {
						currentError = response.errors[i];
						applePayErrors.push(new ApplePayError(currentError.code, currentError.contactField, currentError.message));
					}

					session.completePayment({
						"status": 1,
						"errors": applePayErrors
					});
				}
				
				if (response.url !== '') {
					window.location = response.url;
				}
			});
		};
	});
}

function refreshApplePayRequest() {
	if (window.ApplePaySession && ApplePaySession.canMakePayments()) {

		// button for cart, radio input for billing
        $('.apple-button-wrapper, .panel-CYBERSOURCE_APPLEPAY').removeClass('available');

		$.ajax({
			url: Urls.applepayGetRequest,
			type: 'POST'
		})
		.done(function(response) {
			if (response.success) {
				applePayPaymentRequest = response.applePayPaymentRequest;

				$('.apple-button-wrapper, .panel-CYBERSOURCE_APPLEPAY').addClass('available');
			}
		});
	}
}

exports.init = function () {
	initializeEvents();
};

exports.refreshApplePayRequest = refreshApplePayRequest;
