'use strict';

var breakpoints = require('./breakpoints');

function bindEvents() {
	$('.js-hover-item').unbind('mouseenter');
    $('.js-hover-item').on('mouseenter', function (e) {
        $(this).addClass('is-open');
        if (!$(this).find('.js-hover-link').hasClass('is-tapped') && (breakpoints.isMobile() || breakpoints.isTablet())){
            e.preventDefault();
            $(this).addClass('is-open').find('.js-hover-link').addClass('is-tapped');
        }
    })
    .on('mouseleave', function () {
        $(this).removeClass('is-open').find('.js-hover-link').removeClass('is-tapped');
    });
}

function init() {
    bindEvents();
}

exports.init = init;
