'use strict';

const debounce = require('lodash/debounce');
const util = require('../../util');

let isVideoPlaying = false;

/**
 * Init video component
 * Exit early if no video is present
 */
function init() {
    const $video = $('.pdp-aplus__images__video');
    const video = $video[0];
    const $playBtn = $video.parent().find('.js-pdp-aplus-video-player-btn');

    if(video){
        manageVideoInViewport(isVideoPlaying, $video, video);
        $(window).on('scroll', debounce(() => manageVideoInViewport(isVideoPlaying, $video, video), 500));
        $video.off().on('click keypress', () => handleAndTrackVideoOnClick($video));
        $playBtn.off().on('click keypress', () => handleAndTrackVideoOnClick($video));
    }else{
        return;
    }
}

function manageVideoInViewport(isVideoPlaying, $video, video){
    if (isVideoPlaying && !util.elementInViewport(video, 100)) {
        pause($video);
    }
    if (!isVideoPlaying && util.elementInViewport(video, 100) && $video.hasClass('autoplay')) {
        play($video);
    }
    if(util.elementInViewport(video, 100) && window.innerWidth > 1080){
        trackVideoInView();
    }
}

function play($video){
    const video = $video[0];
    const $videoOverlay = $('.pdp-aplus__images__video__overlay');
    const $playBtn = $video.parent().find('.js-pdp-aplus-video-player-btn');
    const playPromise = video.play();

    if (playPromise !== undefined) {
        playPromise.then(_ => {
            // Flash controls to user before playing
            $videoOverlay.addClass('show-pause');

            setTimeout(()=>{
                $videoOverlay.removeClass('show-pause');
            }, 1000);

            $videoOverlay.addClass('is-playing');

            if($('.thumb-video-ctrl-icon').length > 0){
                $('.thumb-video-ctrl-icon').addClass('is-playing');
            }
        
            $playBtn.attr('aria-label', 'Pause video');
        
            isVideoPlaying = true;
        })
        .catch(error => {
            console.error(error)
        });
    }
}

function pause($video){
    const video = $video[0];
    const $videoOverlay = $('.pdp-aplus__images__video__overlay');
    const $playBtn = $video.parent().find('.js-pdp-aplus-video-player-btn');

    video.pause();

    $videoOverlay.removeClass('is-playing');

    if($('.thumb-video-ctrl-icon').length > 0){
        $('.thumb-video-ctrl-icon').removeClass('is-playing');
    }

    // Reset the video to beginning when out of viewport.
    video.currentTime = 0;

    $playBtn.attr('aria-label', 'Play video');

    isVideoPlaying = false;
}

function handleAndTrackVideoOnClick($video){
    const productName = getProductName();
    let action;

    if($video[0].paused){
        play($video);
        action = 'play';
    }else{
        pause($video);
        action = 'pause';
    } 

    window.dataLayer.push({ 
        'event': 'pdp-video-engagement', 
        'videoAction': action, 
        'productName': productName
    });
}

function trackVideoInView(){
    const productName = getProductName();

    window.dataLayer.push({ 
        'event': 'pdp-video-in-view', 
        'productName': productName
    });
}

function getProductName(){
    let productName = '';

    if ($('.pdp-aplus--family-match').length > 0) {
        // There is no quickbuy experience for FM PDP
        productName = $('.pdp-aplus__images__video').attr('data-product-name');
    } else {
        productName = JSON.parse($('.js-analytics-add-to-bag').first().text()).product.name;
    }

    return productName;
}

module.exports = {
    init: init,
    play: play,
    pause: pause,
    trackVideoInView: trackVideoInView,
    handleAndTrackVideoOnClick: handleAndTrackVideoOnClick
};
