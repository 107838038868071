'use strict';
const includes = require('lodash/includes');

var $form, $requiredInputs, validator;
 
var hasEmptyRequired = function () {
    // filter out only the visible fields
    var requiredValues = $requiredInputs.filter(':visible').map(function () {
        return $(this).val();
    });
    return includes(requiredValues, ''); 
};

var validateForm = function () {
    // only validate form when all required fields are filled to avoid
    // throwing errors on empty form
    if (!validator) {
        return;
    }
    if (!hasEmptyRequired()) {
        return validator.form();
    }
};

var validateEl = function () {
	// Check for emoticons in field and remove if any exist
    var _pattern;

    if ($(this).hasClass('password') || $(this).hasClass('currentpassword') || $(this).hasClass('newpassword')) {
        _pattern = /[^a-zA-Z0-9- !."'_@&,\/()\n;:$%#^*=+{}<>?`~|[\]]*/ig;
    } else if($(this).hasClass('address1') || $(this).hasClass('address2') || $(this).hasClass('city') || $(this).hasClass('postal')){
        _pattern = /[^#.0-9a-zA-Z\s/,-]*/ig;
    } else {
        _pattern = /[^a-zA-Z0-9- !."'_@&,+\/()\n;:$%]*/ig;
    };

	var $th = $(this);
    var fieldValue = $(this).val();

    if (typeof fieldValue !== 'string') {
        return;
    }

	$th.val(fieldValue.replace(_pattern, ""));

    if ($(this).val() != '') {
        // enable continue button on last required field that is valid
        // only validate single field
        return validator.element(this) && !hasEmptyRequired();
    }
};

var init = function (opts) {
    if (!opts.formSelector || !opts.continueSelector) {
        throw new Error('Missing form and continue action selectors.');
    }
    $form = $(opts.formSelector);
    validator = $form.validate();
    $requiredInputs = $form.find(":input.required");

    validateForm();
    // start listening
    $requiredInputs.on('change', validateEl);
    $form.find(":input.address2").on('change', validateEl);
};

exports.init = init;
exports.validateForm = validateForm;
exports.validateEl = validateEl;
