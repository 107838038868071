'use strict';

var ajax = require('../ajax'),
    button = require('../button'),
    compareWidget = require('../compare-widget'),
    dialog = require('../dialog'),
    progress = require('../progress'),
    flyoutfilter = require('../flyoutfilter'),
    hoverMenu = require('../hovermenu'),
    productgrid = require('../productgrid'),
    refinements = require('../searchrefinements'),
    util = require('../util'),
    quickview = require('../quickview'),
    validator = require('../validator'),
    turnto = require('../turnto');

var filterBarCheckInProgress = false;
var filterBarCheckWaitlist = false;
var stickyMenuScrollPos = 0;
var stickyMenuCheckCounter = 0;
/**
 * Scrolls window to the top of the product grid
 * Only scrolls if the refinements bar is outside of the viewport.
 */
function scrollToRefinementsBar () {
    var $refinements = $('.plp-refinements');
    if ($refinements.length > 0){
        const elemInViewport = $refinements[0].getBoundingClientRect().y > $('.site-header').height();

        if (!elemInViewport) {
            util.scrollToElement($refinements);
        }
    }
}

// Called when scrolling to section of page where no data exists.  Is not called on initial load of page.
var infiniteScroll = function() {

    $(".js-loadmore").remove(); // Always remove button after first intialization of infiniteScroll.

    // getting the hidden div, which is the placeholder for the next page
    var $loadingPlaceHolder = $('.infinite-scroll-placeholder[data-loading-state="unloaded"]');
    // get url hidden in DOM
    var gridUrl = $loadingPlaceHolder.attr('data-grid-url');

    if ($loadingPlaceHolder.length === 1 && util.elementInViewport($loadingPlaceHolder.get(0), 100)) {

        // named wrapper function, which can either be called, if cache is hit, or ajax repsonse is received
        var fillEndlessScrollChunk = function (html) {
            $loadingPlaceHolder.removeClass('infinite-scroll-loading');
            $loadingPlaceHolder.attr('data-loading-state', 'loaded');

            if($(".subcat-results-grid").length > 0) {

                // This cleans up the load more div placeholders in the grids as they load in.
                $(".category-results__loadmore").remove();

                $(".subcat-results-grid").append(html).find(".js-loadmore").remove();

            } else {

                // This cleans up the load more div placeholders in the grids as they load in.
                $(".js-loadmore-container").remove();

                $('.search-result-items').append(html).find(".js-loadmore").remove();
            }

            // Update for infinite-loading-placeholder in grid for future loads
            $(".js-loadmore-container, .category-results__loadmore").css({ "grid-column" : "span " + (window.innerWidth >= 1081 ? "4" : "2") });

            // Update newly loaded tiles with TurnTo teaser ratings
            turnto.renderTileStars();

            $(window).trigger('search.infinitescrollload');
        };

        $.ajax({
            type: 'GET',
            dataType: 'html',
            url: gridUrl,
            beforeSend: function( xhr ) {
                $loadingPlaceHolder.attr('data-loading-state', 'loading');
                $loadingPlaceHolder.addClass('infinite-scroll-loading');
            }
        }).done(function (response) {
            // put response into cache
            try {
                sessionStorage['scroll-cache_' + gridUrl] = response;
            } catch (e) {
                // nothing to catch in case of out of memory of session storage
                // it will fall back to load via ajax
            }

            // update UI
            fillEndlessScrollChunk(response);
            updateSpecialtyGridInfiniteScrollPlaceHolders();
            $(document).trigger('ga.producttilesajax');

        }).fail(function(jqXHR, textStatus, errorThrown) {
            $loadingPlaceHolder.removeClass('infinite-scroll-loading');
            $loadingPlaceHolder.attr('data-loading-state', 'loaded');
        });

    }
};

var toggleRefinements = function() {
    $('.js-refinements').toggleClass('is-open');
    adjustRefinementMenu();
    setRefinementPosition(); // this has to be here because if you put it in the ajax done, the hero won't be loaded so this won't calculate correctly.
};

var setRefinementPosition = function() {
    // if mobile refinement is open, then calculate the top of the refinement bar so it can be absolutely positioned below the
    // filter box. The position depends on whether or not there is a hero at the top of the page.
    // The full-grid-banner class may or may not be used anymore.

    if($(window).innerWidth() <= 767) {
        var $RT = $(".search-result-options .js-refinement-toggle");
        // check if the shop-by theme carousel exists
        var themesliderHeight = 0;
        if($(".js-themeslider").length > 0) {
            themesliderHeight = $(".js-themeslider").innerHeight() + $(".js-refinement-toggle").outerHeight();
        }
        if ($RT.length) {
            var $heroBanner;
            if ($(".full-grid-banner").length) {
                $heroBanner = $(".full-grid-banner");
            } else {
                $heroBanner = $(".cat-landing-slotbanner");
            }
            var _bannerHeight = $heroBanner.height() + 2;
            $('.product-grid-refinements').css({'top' : $RT.position().top + $RT.innerHeight() + _bannerHeight + themesliderHeight });
        }
    }
}

/**
 * Open Private Order Share Modal
 * Includes share codes that can be sent
 * to a friend to access private order
 * @param {Event} e event object
 */
var openPrivateOrderShare = function(e) {
    e.preventDefault();
    var $this = $(this);

    var options = {};
    options.url = util.ajaxUrl($this.attr('href'));
    options.options = {
        width: 572
    };
    dialog.open(options);
    gtmAnalytics.submitPrivateOrderShare('success');
};

/**
 * Open Private Order Newsletter Subscribe Modal
 * @param {Event} e event object
 */
var openPrivateOrderEmail =  function(e) {
    e.preventDefault();
    var $this = $(this);
    var url = $this.parents('.js-private-order-subscribe-tile').data('url');

    var options = {};
    options.url = util.ajaxUrl(url);
    options.callback = privateOrderEmailOpened;
    options.options = {
        width: 572,
        dialogClass: 'po-email-dialog'
    };
    dialog.open(options);
}

/**
 * Callback fired in openPrivateOrderEmail()
 * Sets up form submission handler and validation
 */
var privateOrderEmailOpened = function() {
    var $modal = $('.ui-dialog');

    var $form = $modal.find('form');
    validator.initForm($form);

    $form.off('.po');
    $form.on('submit.po', privateOrderEmailSubmit);
}

/**
 * AJAX Post submit form
 * Calls privateOrderEmailCallback()
 * @param {Event} e form submission event
 */
var privateOrderEmailSubmit = function(e) {
    e.preventDefault();
    $('.po-modal__error').text('');

    var $form = $(e.target);
    if (!$form.valid()) { return; }

    var options = {};
    options.url = util.ajaxUrl($form.attr('action'));
    options.data = $form.serialize();
    options.callback = privateOrderEmailCallback;
    options.$form = $form;
    ajax.getJson(options);

    progress.show($form.find('.button'));
}

/**
 * Handle form submission in privateOrderEmailSubmit()
 * Show success/error messages in the modal
 * @param {Object} response JSON object
 */
var privateOrderEmailCallback = function(response) {
    if (response.error) {
        $('.po-modal__error').text(Resources.NEWSLETTER_ERROR);
        button.updateBtnError(this.$form.find('.button'));
        gtmAnalytics.submitPrivateOrderEmailSignup('failure');
    } else if (response.status === 'preexisting') {
        $('.po-modal__error').text(Resources.NEWSLETTER_EXISTS_SHORT);
        button.updateBtnError(this.$form.find('.button'));
        gtmAnalytics.submitPrivateOrderEmailSignup('failure');
    } else {
        button.updateBtnSuccess(this.$form.find('.button'));
        gtmAnalytics.submitPrivateOrderEmailSignup('success');
    }
}

function getDataAttrValue(e, dataName) {
    var $obj = $(e.target).closest('[data-' + dataName + ']');
    if ($obj.length < 1) { $obj = $(e.target).find('[data-' + dataName + ']'); }
    return ($obj.length < 1) ? "" : $($obj[0]).data(dataName);
}

var initializeEvents = function() {
    var $main = $('#main');

    $(window).on('scroll',  function() {
        infiniteScroll();
    });

    $main.on("click", ".quickbuy-btn", function (e) {
        e.preventDefault();

        var $link = $(this).closest(".product__image").find('.thumb-link');
        if($link.length <= 0) {
            $link = $(this);
        }

        quickview.quickview.show({
            url : $link.attr("href"),
            source : "quickview",
            analyticsuuid : getDataAttrValue(e, 'analyticsuuid')
        });
    });

    $main.on('click', '.product-tile a', function () {
        var tile = $(this).closest('.product-tile');
        var wrapper = tile.parents('more-to-love-recommendations');
        var product_list_name = wrapper.find('h3').first().text().replace(/\n/g, '');

        $(document).trigger('ga.producttile.click', [tile, product_list_name]);
    });

    $main.on('click', '.pgr-size-chart-link', function(e) {
        // prevent other event handlers from stopping this link
        // by stopping the propagation of the event, navigate to the
        // size chart page
        e.stopImmediatePropagation();
        return;
    });

    // handle toggle refinements menu
    $main.on('click', '.js-refinement-toggle, .js-refinement-overlay', function (e) {
        e.preventDefault();

        toggleRefinements();
    });

    // handle events for updating grid
    $main.on('click', '.refinements a, .pagination a, .breadcrumb-refinement-value a, .plp-refinements a', function (e) {
        // don't intercept for category and folder refinements, unselectable, or part of a content slot in the refinement section
        var $this = $(this);
        let clearAllButton = $('.plp-refinements--clear').is($this);
        const filterMenuOpen = checkFilterMenuOpen();

        if($this.closest(".plp-refinements__sortby").length > 0) {
            return;
        }

        if ($this.parents('.category-refinement').length > 0 || $this.parents('.folder-refinement').length > 0 || $this.parent().hasClass('unselectable') || $this.parents('.pgr-custom-marketing').length > 0 || $this.hasClass('pgr-size-chart-link')) {
            return;
        }

        // PLP A+ don't intercept rules
        if ($this.hasClass('size-chart')) {
            return;
        }

        var _href = $this.attr("href");
        if($(".pt_categorylanding").length > 0) {
            return;
        } else if($(this).parent().hasClass("selected")) {
            if($(this).closest(".js-refinements").find("li.selected").length == 1) {
                _href = $(".pgr-clear-all").attr("href");
            }
        }

        e.preventDefault();

        var listingOptions = {
            url: _href,
            callback: function() {
                if ($('.plp-container').length > 0) {
                    $('.plp-container').animate({ opacity: 1.0 }, 'fast');
                }
                scrollToRefinementsBar();
                if(clearAllButton && filterMenuOpen){
                    $('.plp-refinements--button.filter').trigger('click');
                }
            },
            el: $this
        }

        if ($this.closest('.plp-refinements--button.filter').length > 0) {
            e.stopPropagation();
            listingOptions.updateType = 'refinements';
        }
        // clear session storage when filtering by refinement as result set changes.
        clearSessionStorage();

        productgrid.updateProductListing(listingOptions);
    });

    // handle sorting change
    $main.on('click', '.js-read-more', function (e) {
        e.preventDefault();
        $(this).remove();
        $('.js-seo-more-text').removeClass('is-hidden');
    });

    $main.on('click', '.refinements h4.pgr-title, .plp-refinements h4.pgr-title', function(e) {
        e.preventDefault();

        var $obj = $(this);
        $obj.next().stop();

        if($obj.hasClass("active") == false) {
            $obj.next().animate({ height: 'auto' },{ queue: false, duration: 'fast', complete : function() {
                $obj.addClass('active');
                $obj.find(".expand-btn").addClass("is-active");
            } });
        } else {
            $obj.next().animate({ },{ queue: false, duration: 'fast', complete : function() {
                $obj.removeClass('active');
                $obj.find(".expand-btn").removeClass("is-active");
            } });
        }
    });

    // Click Main Collection Landing Page - Shop All Collection
    $main.on('click', '.collection-landing-category__shopalldesktop a, .collection-landing-category__shopallmobiletablet a, .collection-landing-subcategory__shopallwrapper',function(e) {
        e.preventDefault();
        var _url = $(this).attr("href");
        var _primaryCollectionName = $(this).parents(".collection-landing-tiles").data("primarycollectionname");
        var _collectionName = $(this).parents(".collection-landing-category").data("collectionname");
        window.dataLayer.push({
            'event': 'primary-collection-shop-all-click',
            'primaryCollectionName': _primaryCollectionName,
            "collectionName": _collectionName,
            'clickType': 'text' //ex. text, image
        });
        window.location.href = _url;
    });

    // Click Main Collection Landing Page - Collection Group Image
    $main.on('click', '.collection-landing-subcategory-wrapper', function(e) {
        e.preventDefault();

        var url = $(this).data('url');
        var primaryCollectionName = $(this).parents('.collection-landing-tiles').data('primarycollectionname');
        var collectionName = $(this).parents('.collection-landing-category').data('collectionname');
        var groupName = $(this).find('.collection-landing-subcategory__title').text().replace(/[\n\r]/g, ''); // remove carriage return

        window.dataLayer.push({
            'event': 'primary-collection-group-click',
            'primaryCollectionName': primaryCollectionName,
            'collectionName': collectionName,
            'groupName': groupName,
            'clickType': 'text' //ex. text, image
        });

        // Look to see if this is a product tile and retrieve the inner product url
        // to use for redirection below.
        if(!url) {
            var $productlink = $(this).find('.thumb-link');
            if($productlink) {
                url = $productlink.attr('href');
            }
        }

        // Only redirect if url exists
        if(url) {
            window.location.href = url;
        }

    });

    // Click Collection Page - Group Image
    $main.on('click', '.collection-subcategory-wrapper',function(e) {
        e.preventDefault();
        var _url = $(this).attr("href");
        var _primaryCollectionName = $(this).parents(".collection-body").data("primarycollectionname");
        var _collectionName = $(this).parents(".collection-body").data("collectionname");
        var _groupName = $(this).find(".collection-subcategory__title").text().replace(/[\n\r]/g, ''); // remove carriage return

        window.dataLayer.push({
            'event': 'collection-group-click',
            'primaryCollectionName': _primaryCollectionName,
            "collectionName": _collectionName,
            "groupName": _groupName,
            'clickType': 'image' //ex. text, image
        });
        window.location.href = _url;
    });

    // Private Order
    $main.on('click', '.js-po-share', openPrivateOrderShare);
    $main.on('click', '.js-private-order-subscribe-tile button', openPrivateOrderEmail);

    $(document).on('lazybeforeunveil', function() {
        var $tiles = $('.search-result-items .product-tile');
        if ($tiles.length === 0) { return; }
    });

    /*** Close mobile refinements nav and revert changes * made since this nav was opened.*
    To do this, grab saved state, apply to undo link,* and trigger a click to trigger
    search.updateProductListing()  */

    $main.on('click', '.plp-refinements--button.filter', function () {
        $(window).off('resize.filterMenuScroll');	
        if (!$(this).hasClass('expand')) {
            let filterType = 'static';
            if($('.stickyFilter').length > 0){
                filterType = 'sticky';
            }
            dataLayer.push({
                "event" : "product-list-filter-open",
                "refinementType" : "filter",
                "filterType" : filterType
            });
        }
        if ($(this).hasClass('expand')) {
            stickyMenuScrollPos = $('.plp-refinements__bar')[0].getBoundingClientRect().y - $('.plp-refinements__bar--type')[0].getBoundingClientRect().y;
        }
        $(this).toggleClass('expand');
        $('.plp-container, .plp-refinements__bar').toggleClass('expand');
        if ($(this).hasClass('expand')) {
            if($('.stickyFilterEnabled').length > 0){
                if($('.closeFilterMenu').length > 0){
                    $('.plp-refinements__bar').removeClass('closeFilterMenu');
                    $('.plp-container').removeClass('closeFilterMenu');
                    $('.plp-refinements__bar').addClass('hidden');
                }
                $('.plp-refinements__bar').removeClass('hidden');
                $('.plp-refinements__bar').addClass('openFilterMenu');
                $('.plp-container').addClass('openFilterMenu');
                stickyMenuCheckCounter = 0;
                stickyFilterMenuCheck();
                setTimeout(function() { 
                    $('.plp-refinements__bar').removeClass('openFilterMenu');
                    $('.plp-container').removeClass('openFilterMenu');
                    stickyFilterMenuCheck();
                    adjustStickyMenuPosition();
                }, 1000); 
                adjustStickyMenuPosition();
            }
            if(window.innerWidth < 1081){
                $('body').css('width', '100vw');
                $('html').css('overflow', 'hidden');
            }
            let filterMenuOrigScreenWidth = window.innerWidth;
            $(window).on('resize.filterMenuScroll', function () {
                if (filterMenuOrigScreenWidth < 1081 && window.innerWidth > 1080){
                    $('html').css('overflow', '');
                    filterMenuOrigScreenWidth = window.innerWidth;
                    $('body').css('width', '');
                    $('.plp-refinements--button').find('.filterText').text($('.plp-refinements--button').hasClass('expand') ? 'Hide Filter' : 'Show Filter')
                } else if(filterMenuOrigScreenWidth > 1080 && window.innerWidth < 1081){
                    $('body').css('width', '100vw');
                    filterMenuOrigScreenWidth = window.innerWidth;
                    $('html').css('overflow', 'hidden');
                }
            });
            $(this).find('.filterText').text('Hide Filter');
            if(window.innerWidth > 1080 && $('.stickyFilterEnabled').length > 0){
                stickyMenuCheckCounter = 0;
                stickyFilterMenuCheck();
                setTimeout(function() { 
                    $('.plp-refinements__bar').animate({
                        scrollTop: stickyMenuScrollPos
                    }, 0);
                }, 100);
            }
        } else {
            if($('.stickyFilterEnabled').length > 0){
                $('.plp-refinements__bar').addClass('closeFilterMenu');
                $('.plp-container').addClass('closeFilterMenu');
                if($('.openFilterMenu').length > 0){
                    $('.plp-refinements__bar').removeClass('openFilterMenu');
                    $('.plp-container').removeClass('openFilterMenu');
                }

                setTimeout(function() { 
                    $('.plp-refinements__bar').removeClass('closeFilterMenu');
                    $('.plp-container').removeClass('closeFilterMenu');
                    $('.plp-refinements__bar').addClass('hidden');
                    $('.plp-refinements__bar').removeClass('stickyFilterMenu');

                }, 1000); 
                adjustStickyMenuPosition();
            }
            $(this).find('.filterText').text('Show Filter');
            $('body').css('width', '');
        }

        if($(this).hasClass('expand') && $(".plp-refinements__header.refinements").data("clearurl") && $('.plp-refinements--clear').length == 0) {
            $(this).parent().append('<a href="'+$(".plp-refinements__header.refinements").data("clearurl")+'" class="plp-refinements--clear" aria-label="Clear All Refinements">Clear All</a>');
        }

        var aria_expanded = $(this).attr('aria-expanded');
        if (aria_expanded === 'true') {
            $(this).attr('aria-expanded', 'false');
        } else {
            $(this).attr('aria-expanded', 'true');
        }
    });

    $main.on('click', '.plp-refinements__header--button, .plp-refinements__bar--footer .clear-all, .plp-refinements__bar--footer .show-results', function() {
        if(window.innerWidth < 1081){
            $('html').css('overflow', '');
        }
        productgrid.unlockBodyScroll();
        $('body').css('width', '');
    });

    $main.on('click', '.plp-refinements--button.mobile, .plp-refinements__header--button.mobile', function (e) {
        $('.plp-refinements__bar').toggleClass('expand');
        $('.plp-refinements--button.filter.expand').toggleClass('expand');

        if($('.plp-refinements--button.filter.expand').length > 0 && $(".plp-refinements__header.refinements").data("clearurl")) {
            $('.plp-refinements--button.filter.expand').append('<strong><a href="'+$(".plp-refinements__header.refinements").data("clearurl")+'" class="plp-refinements--clear" aria-label="Clear All Refinements">Clear All</a></strong>');
        } else {
            $('.plp-refinements--button.filter').find('.plp-refinements--clear').remove();
        }
    });

    $main.on('click', '.plp-refinements--button.sort', function (e) {
        if (!$(this).hasClass('expand')) {
            let filterType = 'static';
            if($('.stickyFilter').length > 0){
                filterType = 'sticky';
            }
            dataLayer.push({
                "event" : "product-list-filter-open",
                "refinementType" : "sort",
                "filterType" : filterType
            });
        }
        var $sortBy = $('.plp-refinements__sortby');
        $sortBy.toggleClass('expand');

        if($(this).hasClass('hamburger-spring').length == 0) {
            $(this).html($sortBy.find('li.selected').data("sortid"));
        }
    });

    $main.on('click', '.show-results', function (e) {
        e.preventDefault();
        $('.plp-refinements__bar').toggleClass('expand');
    });

    $main.on('click', '.plp-refinements__bar--toggle', function (e) {
        $(this).children().toggleClass('is-active');
        $(this).siblings('.plp-refinements__selection--toggle').toggle();
        $(this).siblings('.plp-refinements__selection').slideToggle('fast').toggleClass('is-active');
        var aria_expanded = $(this).attr('aria-expanded');
        if (aria_expanded === 'true') {
            $(this).attr('aria-expanded', 'false');
        } else {
            $(this).attr('aria-expanded', 'true');
        }
    });

    $main.on('click', '.plp-refinements__selection--toggle', function (e) {
        var $refToggle = $(this);
        var refItems = $refToggle.parent('.size, .sizes').length > 0 ? 6 : 10;
        $refToggle.prev('.plp-refinements__selection').find(':nth-child('+refItems+')').nextAll().slideToggle('fast');
        $refToggle.children('.show, .hide').toggle();
        if ($refToggle.find('.hide:hidden').length != 0) {
            var scrollTop = $refToggle.parent().offset().top - $('.site-header').height();
            $('html, body').animate({
                scrollTop: scrollTop
            }, 1000);
        }
        if($('.stickyFilterEnabled').length > 0){
            stickyFilterBarCheck();
        }
    });

    $main.on('click', '.plp-refinements__selection--expand-values', function (e) {
        var $expandLink = $(this);
        $expandLink.siblings('.plp-refinements__selection').find('.hideable').toggleClass('hidden');
        $expandLink.toggleClass('expanded');
    });

    $main.on('click', '.plp-refinements--button.sort', function (e) {
         $(this).toggleClass('expand');
         $(".select-options").toggleClass('expand');
     });


    $main.on('click', '.select-options li a', function (e) {
        // Dont dupliacte call if sorting option is already selected
        if($(this).parent().hasClass('selected') === false) {
            e.preventDefault();

            // Maintain the expand/collapse state of the refinements bar
            var collapseRefineBar = $('.plp-refinements__bar.expand').length === 0;
            const filterSections = checkOpenFilterMenuSections();
            const filterMenuOpen = checkFilterMenuOpen();
            productgrid.updateProductListing({
                url: $(this).attr('href'),
                params: { collapseRefineBar: collapseRefineBar },
                callback: function() {
                    if ($(".plp-container").length > 0) {
                        $(".plp-container").animate({ opacity: 1.0}, "fast");
                        scrollToRefinementsBar();
                    }
                    if($('.stickyFilterEnabled').length > 0 && filterMenuOpen){
                        openFilterMenuSections(filterSections);

                        if($('.refinement-num').length > 0){
                            setTimeout(function() { 
                                $('.plp-refinements__bar').animate({
                                    scrollTop: stickyMenuScrollPos
                                }, 0);
                            }, 100);
                        } else if (window.innerWidth > 1080){
                            $('.plp-refinements--button.filter').trigger('click');
                        }
                    }
                    
                    if($('.plp-refinements--button').hasClass('expand')){
                        $('.plp-refinements--button').find('.filterText').text('Hide Filter');
                    }
                },
                el: $(this)
            });
        } else {
            e.preventDefault();
        }
    });

    $main.on('click keypress', '.plp-refinements__bar a', function (e) {
        if ((e.type == 'keypress') && (e.keyCode != 32)) {
            return;
        }
        // PLP A+ don't intercept rules
        var $this = $(this);
        var href = $this.attr('href');
        if ($this.hasClass('size-chart') || $this.hasClass('js-category-link')) {
            return;
        }
        if (href == '#') {
            return false;
        }
        e.preventDefault();
        e.stopPropagation();

        var refinementSelectionID;
        const selectedFilterRefID = $(e.target).closest('.plp-refinements__selection--link').attr('data-refinement-id');
        const filterSections = checkOpenFilterMenuSections();
        const filterMenuOpen = checkFilterMenuOpen();
        if ($(window).width() <= 1080) {
            refinementSelectionID = $(e.target).attr('data-refinement-id') ? $(e.target).attr('data-refinement-id') : $(e.target).closest('a.plp-refinements__selection--link').attr('data-refinement-id');
        }

        productgrid.updateProductListing({
            url: href,
            updateType: 'refinements',
            refinement: refinementSelectionID,
            callback: function() {
                if ($('.plp-container').length > 0) {
                    $('.plp-container').animate({ opacity: 1.0 }, 'fast');
                }
                scrollToRefinementsBar();

                if($('.plp-refinements--button.filter.expand').length > 0 && $(".plp-refinements__header.refinements").data("clearurl") && $('.plp-refinements--clear').length == 0) {
                    $('.plp-refinements--button.filter.expand').find('.filterText').text('Hide Filter');
                    $('.plp-refinements--button.filter.expand').parent().append('<a href="'+$(".plp-refinements__header.refinements").data("clearurl")+'" class="plp-refinements--clear" aria-label="Clear All Refinements">Clear All</a>');
                } else {
                    $('.plp-refinements--button.filter').find('.plp-refinements--clear').remove();
                }
                for(var i = 0; i < $('.promotedFilter').length; i++){
                    if(window.innerWidth < 1081){
                        $('body').css('width', '100vw');
                    }
                    const promotedFilter = $('.promotedFilter')[i];
                    const refinementID = $(promotedFilter).attr('data-refinement-id');
                    const filterElement = $(".plp-refinements__bar").find(`[data-refinement-id='${refinementID}']`);
                    const promotedFilterElement = $(".promotedFilterContainer").find(`[data-refinement-id='${refinementID}']`);
                    if(filterElement[0]){
                        const href = filterElement[0].href;
                        if(filterElement.hasClass('selected')){
                            promotedFilterElement.addClass('selected');
                            if($('.promotedTextFilter').length > 0){
                                promotedFilterElement.append('<span class="filters-close-icon"></span>')
                            }
                        }
                        promotedFilterElement[0].href = href;
                    } else{
                        promotedFilterElement.addClass('hidden');
                    }
                }
                if($('.stickyFilterEnabled').length > 0){
                    if(filterMenuOpen){
                        openFilterMenuSections(filterSections);
                        stickyFilterBarCheck();
                        stickyMenuCheckCounter = 0;
                        stickyFilterMenuCheck();
                        if(selectedFilterRefID){
                            setTimeout(function() { 
                                    const selectedFilterElem = $(".plp-refinements__bar").find(`[data-refinement-id='${selectedFilterRefID}']`);
                                    stickyMenuScrollPos = selectedFilterElem[0].getBoundingClientRect().y - $('.plp-refinements__bar--type')[0].getBoundingClientRect().y-150;
                                    $('.plp-refinements__bar').animate({
                                        scrollTop: stickyMenuScrollPos
                                    }, 0);
                            }, 100);       
                        }            
                    }
                    adjustStickyMenuPosition();
                }
            },
            el: $(this)
        });
    });

    // when a sorting option gets clicked, check if the clear all filter button exists, and if it dissapears after, then add it again
    $main.on('click', '.plp-refinements__sortby li a', function (e) {
        var filterClearAll = $('.plp-refinements--clear');
        // if the clear all button exists when the sorting option gets clicked then proceed
        if (filterClearAll.length > 0) {
            var counter = 0;
            var filterInterval = setInterval(function(){
                counter++;
                var filterClearAll2 = $('.plp-refinements--clear');
                if (filterClearAll2.length == 0) {
                    // if the clear all button no longer exists then add it again and clear the interval
                    if ($(".plp-refinements__header.refinements").data("clearurl")) {    
                        $('.plp-refinements--button.filter').parent().append('<a href="'+$(".plp-refinements__header.refinements").data("clearurl")+'" class="plp-refinements--clear" aria-label="Clear All Refinements">Clear All</a>');
                    }
                    clearInterval(filterInterval);
                } else if (counter > 3000){
                    // if unexpected behavior happens and this interval never clears then clear it after 5 minutes
                    clearInterval(filterInterval);
                }
            }, 100);
        }
    });

    if ($(".plp-refinements__header.refinements").data("clearurl")) {    
        $('.plp-refinements--button.filter').parent().append('<a href="'+$(".plp-refinements__header.refinements").data("clearurl")+'" class="plp-refinements--clear" aria-label="Clear All Refinements">Clear All</a>');
    }
    if($('.stickyFilterEnabled').length > 0){
        $('.plp-refinements__bar').addClass('filterMenuTransition');
        $('.plp-container').addClass('filterMenuTransition');
        $(window).on('scroll', function () {
            stickyFilterBarCheck();
        });
        stickyFilterBarCheck();
    }
};

function checkOpenFilterMenuSections(){
    let filterSections = {};
    if ($('.stickyFilterEnabled').length > 0 && $('.plp-refinements__bar').length > 0 && $('.plp-refinements__bar').hasClass('expand')) {
        for(var i = 0; i < $('.plp-refinements__bar--type button').length; i++){
            const filterMenuExpanded = $($('.plp-refinements__bar--type button')[i]).attr('aria-expanded');
            if(filterMenuExpanded == 'true'){
                const filterMenuID = $('.plp-refinements__bar--type button')[i].id;
                filterSections[filterMenuID] = filterMenuExpanded;
            }
        }
    }
    return filterSections;
}

function checkFilterMenuOpen(){
    let filterMenuOpen = false;
    if ($('.stickyFilterEnabled').length > 0 && $('.plp-refinements__bar').length > 0 && $('.plp-refinements__bar').hasClass('expand')) {
        stickyMenuScrollPos = $('.plp-refinements__bar')[0].getBoundingClientRect().y - $('.plp-refinements__bar--type')[0].getBoundingClientRect().y;
        filterMenuOpen = true;
    }
    return filterMenuOpen;
}

function openFilterMenuSections(filterSections){
    for(var i = 0; i < $('.plp-refinements__bar--type button').length; i++){
        const $elem = $($('.plp-refinements__bar--type button')[i]);
        const elemExpandedVal = $elem.attr('aria-expanded')
        const elemID = $('.plp-refinements__bar--type button')[i].id;
        for (var key in filterSections) {
            if( key == elemID && elemExpandedVal == 'false'){
                $elem.children().toggleClass('is-active');
                $elem.siblings('.plp-refinements__selection--toggle').toggle();
                $elem.siblings('.plp-refinements__selection').slideToggle('fast').toggleClass('is-active');
                $elem.attr('aria-expanded', 'true');
            }
        }
    }
}

function stickyFilterBarCheck(){
    if($('.plp-refinements__bar').length > 0 && $('.plp-refinements__bar').hasClass('expand') && window.innerWidth < 1081){
        return;
    }
    if(filterBarCheckInProgress == false && $('.plp-refinements').length > 0 && $('.plp-container').length > 0){
        filterBarCheckInProgress = true;
        if($('.plp-refinements')[0].getBoundingClientRect().y < $('.site-header').height() && $('.stickyFilter').length == 0){
            addStickyFilterBar();
        } else if(($('.plp-container')[0].getBoundingClientRect().y > $('.plp-refinements')[0].getBoundingClientRect().bottom) && $('.stickyFilterBar').length > 0){
            removeStickyFilterBar();
        }
        setTimeout(function() {         
            filterBarCheckInProgress = false;
        }, 200);
    } else if(filterBarCheckWaitlist == false){
        filterBarCheckWaitlist = true;
        setTimeout(function() {         
            filterBarCheckWaitlist = false;
            stickyFilterBarCheck();
        }, 200);
    }
    stickyMenuCheckCounter = 0;
    stickyFilterMenuCheck();
 }

 function addStickyFilterBar(){
    $(window).off('resize.stickyFilterBar');	
    $('.plp-refinements__bar').addClass('stickyFilterBar');
    $('.plp-container').addClass('stickyFilterBar');
    $('.plp-refinements').addClass('stickyFilter');
    $('.stickyFilter').css('top', $('.site-header').height());
    $('.plp-refinements__sortby').css('margin-top', '20px');
    if(window.innerWidth < 1081){
        $('.stickyFilter').addClass('stickyFilterColorIn');
        setTimeout(function() { 
            $('.stickyFilter').removeClass('stickyFilterColorIn');
        }, 1500); 
    }
    adjustStickyBarPosition();
    $(window).on('resize.stickyFilterBar', function () {
        stickyFilterBarCheck();
        adjustStickyBarPosition();
        adjustStickyMenuPosition();
    });
 }

 function removeStickyFilterBar(){
    $(window).off('resize.stickyFilterBar');	
    $('.plp-refinements').removeClass('stickyFilter');
    $('.plp-refinements__sortby').css('margin-top', '')
    $('.plp-refinements__bar').removeClass('stickyFilterBar');
    $('.plp-container').removeClass('stickyFilterBar');
    adjustStickyBarPosition();
    if(window.innerWidth < 1081){
        $('.plp-refinements').addClass('stickyFilterColorOut');
        setTimeout(function() { 
            $('.plp-refinements').removeClass('stickyFilterColorOut');
        }, 1500); 
    }
    $('.plp-refinements').css({'padding-left': '', 'padding-right': '', 'top': ''});
 }

 function stickyFilterMenuCheck(){
    if(window.innerWidth > 1080){
        let menuHeight = window.innerHeight - ($('.has-fixed-header .site-header').height() + $('.plp-refinements')[0].clientHeight);
        let plpBottom = window.innerHeight - ($('.plp')[0].getBoundingClientRect().bottom + 40);
        if($('.openFilterMenu').length > 0 && (plpBottom + 500) > 0){
            // if the plp is towards the end then make the menu smaller and call the function again
            // after one second when the open menu animation is done, to get the accurate height.
            menuHeight = menuHeight - plpBottom - 500;
            setTimeout(function() { 
                stickyFilterMenuCheck();
            }, 1000); 
        } else if(plpBottom > 0){
            menuHeight = menuHeight - plpBottom;
            if(stickyMenuCheckCounter < 60){
                stickyMenuCheckCounter++;
                setTimeout(function() { 
                    stickyFilterMenuCheck();
                }, 1000); 
            }
        }
        if(menuHeight < 50){
            menuHeight = 0;
            $('.plp-refinements__bar').css('display', 'none');
        } else {
            $('.plp-refinements__bar').css('display', '');
        }

        $('.plp-refinements__bar').css('height', menuHeight);
    }

    if($('.stickyFilter').length > 0 && window.innerWidth > 1080 && $('.stickyFilterMenu').length === 0){
        addStickyFilterMenu();
    } else if($('.stickyFilterMenu').length > 0 && $('.stickyFilter').length === 0){
        removeStickyFilterMenu();
    }
    
 }

 function addStickyFilterMenu(){
    if(window.innerWidth > 1080){
        $('.plp-refinements__bar').addClass('stickyFilterMenu');
    }
    adjustStickyMenuPosition();
 }

 function removeStickyFilterMenu(){
    $('.plp-refinements__bar').removeClass('stickyFilterMenu');
    adjustStickyMenuPosition();
 }

 function adjustStickyMenuPosition(){
    if($('.plp-refinements__bar').hasClass('expand')){
        $('.plp-container').addClass('expand');
    } else {
        $('.plp-container').removeClass('expand');
    }
    if($('.stickyFilterMenu').length > 0 && window.innerWidth > 1080){
        if(window.innerWidth > 1440){
            let leftPosition = (window.innerWidth - 1440)/2 + 20;
            $('.stickyFilterMenu').css('left', leftPosition);
        } else {
            $('.stickyFilterMenu').css('left', '20px');
        }
    } else if(window.innerWidth < 1081){
        $('.plp-refinements__bar').removeClass('stickyFilterMenu');
        $('.plp-refinements__bar').css('left', '0');
        $('.plp-refinements__bar').css('z-index', '10010');
        $('.plp-refinements__bar').css('height', '');
    } else {
        $('.plp-refinements__bar').css('z-index', '');
        if($('.plp-refinements__bar').hasClass('expand')){
            $('.plp-refinements__bar').css('left', '20px');
        }
    }
 }

 function adjustStickyBarPosition(){
    if($('.has-fixed-header .site-header').length > 0){
        $('.stickyFilter').css('top', $('.has-fixed-header .site-header').height());
        if($('.stickyFilter').length > 0 && window.innerWidth > 1440){
            let stickyPadding = (($('.site-header').width() - 1388)/2);
            $('.stickyFilter').css('padding-left', stickyPadding);
            $('.stickyFilter').css('padding-right', stickyPadding);
        } else if(window.innerWidth > 767){
            $('.plp-refinements').css('padding-left', '26px');
            $('.plp-refinements').css('padding-right', '26px');
        } else {
            $('.plp-refinements').css('padding-left', '12px');
            $('.plp-refinements').css('padding-right', '12px');
        }
    } else {
        setTimeout(function() { 
            adjustStickyBarPosition();
        }, 500); 
    }
}

var ReloadGridState = function() {

    var $gridSection = $(".search-result-content").find('.infinite-scroll-placeholder[data-loading-state="unloaded"]');

    var fillEndlessScrollChunk = function (html) {
        $gridSection.removeClass('infinite-scroll-loading');
        progress.hide($gridSection);
        $gridSection.attr('data-loading-state', 'loaded');

        if($(".subcat-results-grid").length > 0) {
            $(".subcat-results-grid").append(html);
        } else {
            $('.search-result-items').append(html);
        }

        // This cleans up the load more div placeholders in the grids as they load in.
        if($(".plp").length > 0) {
            $(".category-results.search-result-items").find(".category-results__loadmore:not(:last)").remove();
            $(".category-results__loadmore:last .infinite-scroll-placeholder[data-loading-state='loaded']").parent().remove();
        }

        // Update for infinite-loading-placeholder in grid for future loads
        $(".js-loadmore-container").css({ "grid-column" : "span " + (window.innerWidth >= 1081 ? "4" : "2") });
    };

    if($gridSection.length == 1) {
        var _gridSectionURL = $gridSection.attr('data-grid-url');

        if(_gridSectionURL && sessionStorage['scroll-cache_' + _gridSectionURL]) {

            $gridSection.attr('data-loading-state', 'loading');
            $gridSection.addClass('infinite-scroll-loading');

            progress.show($gridSection);

            fillEndlessScrollChunk(sessionStorage['scroll-cache_' + _gridSectionURL]);

            // Run reload grid state to pickup any other sections stored in sessionStorage
            ReloadGridState();
            turnto.renderTileStars();
        }
    }
};

var clearSessionStorage = function() {

    var _keysToRemove = new Array();
    var _sessionStorageLen = sessionStorage.length;

    for (var __i=0; __i < _sessionStorageLen; __i++){
        var __key = sessionStorage.key(__i);
        var __keyArr = __key.split("_");

        if(__keyArr[0] == "scroll-cache") {
            _keysToRemove.push(__key);
        }
    }

    // Remove any keys that were found that should be removed based on current url.
    _keysToRemove.forEach(function(key, index) {
        sessionStorage.removeItem(key);
    });

};

var updateSpecialtyGridInfiniteScrollPlaceHolders = function() {
    var _currentPageArr = location.href.split("?");
    if(_currentPageArr.length == 2) {
        $('.infinite-scroll-placeholder[data-loading-state="unloaded"]').each( function() {
            var _updatedUrl = $(this).data("grid-url") + "&" + _currentPageArr[1];
            $(this).data("grid-url", _updatedUrl);
        });
    }
};

var updateSortBy = function() {

    if($(".plp-container").length > 0) {
        var _sortid = $('.plp-refinements__sortby').find('li.selected').data("sortid");
        if(_sortid) {
            $(".plp-refinements--button.sort").first().text($('.plp-refinements__sortby').find('li.selected').data("sortid"));
        }
    }

};

var adjustRefinementMenu = function() {

    var $rfmenu = $(".js-refinement-menu");

    setRefinementPosition();
    if($(window).innerWidth() > 767) {

        $rfmenu.find("h4.pgr-title").each(function() {
            if($(this).data("active") == true) {
                $(this).addClass("active");
                $(this).find(".pgr-expand-btn").addClass("is-active");
            }
        });

        $(".refinements, .plp-refinements").removeClass("is-open").css({ top : 0 });
        $(".js-product-grid").css("margin-top", "20px");
    }

    // calculate filter tray height and apply styles to the tray and grid respectively
    $(".js-refinement-toggle").on('click', function() {
        var filterTrayHeight = $(".js-refinement-menu").innerHeight();
        if($(".js-refinements").hasClass("is-open")) {
            $(".js-refinement-toggle").removeClass("active");
            $("#secondary.js-refinements").css('max-height', 'none');
            $(".js-product-grid").css('margin-top', 'initial');
        } else {
            $(".js-refinement-toggle").addClass("active");
            $("#secondary.js-refinements").css('max-height', filterTrayHeight);
            $(".js-product-grid").css('margin-top', filterTrayHeight);
        }
    });

};

exports.init = function () {
    
    // Calculate cateogry search result list elements
    var containerSum = 0;
    var $ulContainer =  $('.category-search-results');

    if ($ulContainer.length > 0) {
        $ulContainer.find('ul li').each(function() {
            containerSum += $(this).outerWidth() + 13;
        });

        // Set calculated width on parent element containing
        // the list, to insure overflow works properly
        $ulContainer.find('ul').css('width', containerSum);
    }

    updateSortBy();

    if($('.plp-refinements--button.filter.expand').length > 0 && $(".plp-refinements__header.refinements").data("clearurl")) {
        $('.plp-refinements--button.filter.expand').append('<strong><a href="'+$(".plp-refinements__header.refinements").data("clearurl")+'" class="plp-refinements--clear" aria-label="Clear All Refinements">Clear All</a></strong>');
    } else {
        $('.plp-refinements--button.filter.expand').find('.plp-refinements--clear').remove();
    }

    if($(".pt_categorylanding").length > 0 || $(".js-refinement-menu li.selected").length == 0) {
        $(".pgr-clear-all").hide();
    }

    // Check sessionStorage and remove entries not on the current url, ignore querystring.
    // Example: scroll-cache_http://dev07-web-hannaandersson.demandware.net/s/hannaandersson/women-clothing-tops/?sz=12&start=12&format=page-element
    var _currentUrl = location.href;
    var _currentUrlArr = _currentUrl.split("?");
    if(_currentUrlArr[0] != sessionStorage["CurrentURL"]) {
        clearSessionStorage();
    }

    // Save current URL into SessionStorage as that URL may differ from grid section URLS being
    // loaded in grid sections for certain category pages.
    sessionStorage["CurrentURL"] = _currentUrlArr[0];

    // If someone clicked back from the PDP page after coming from a grid tile
    // Reload any tiles from sessionStorage with same url so we can scroll back to that tile
    // and keep them at their current place.

    ReloadGridState();

      // compare checked
    compareWidget.init();

    initializeEvents();

    gtmAnalytics.trackInterruptorImpressions($('.search-result-items'));
    $(document).on('click', '.interruptor-tile', function() {
        gtmAnalytics.trackInterruptorClicks(this);
    });

    // If Specialty grid page, add refinements to sub category load placeholders
    if($(".search-result-items").data("grid") == "subcatgrid") {
        updateSpecialtyGridInfiniteScrollPlaceHolders();
    }

    // This will allow the back button to be clicked and do an ajax request
    // for the previous refinement urls.
    var _firstLoad = true;
    var _collectionPage = $(".collection-page").length > 0;
    $(window).on({
        scroll : function() {
            if($(".plp").length == 0) {
                infiniteScroll();
            }
        },
        load : function() {
            _firstLoad = false;
        },
        popstate :function(e) {
            if ((!_firstLoad) && (!_collectionPage)) {
                var collapseRefineBar = $('.plp-refinements__bar.expand').length === 0;

                productgrid.updateProductListing({
                    url: location.href,
                    params: { collapseRefineBar: collapseRefineBar },
                    callback: function() {
                        $('html,body').scrollTop(0);
                        if($(".plp-container").length > 0) {
                            $(".plp-container").animate({ opacity: 1.0}, "fast");
                        }
                    }
                });
            }
        }
    });

    // Initialize Menu
    hoverMenu.init();

    // Initialize flyout filters
    flyoutfilter.init();

    // Listen for clicks on marketing tiles within
    // the product grid. Interrupt the action and
    // load the data-url
    $('.search-result-items').on('click', '.product-grid-content-link', function(e) {
        var _url = $(this).data('url');
        if (_url) {
            e.preventDefault();
            e.stopImmediatePropagation();
            window.location.href = _url;
        }
    });

    refinements.hideEmptyCategoryRefinements();
    refinements.updateShowMoreInRefinements();

    setTimeout(function() {
        if($(".plp-container").length > 0) {
            $(".plp-container").animate({ opacity: 1.0}, "fast");
        }
    }, 300);

    $(document).trigger('ga.producttiles');
};
