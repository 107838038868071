'use strict';
var ajax = require('../ajax'),
	util = require('../util');

function init() {
    var orderId = $('.order-confirmation-details') ? $('.order-confirmation-details').data('order-no'): '';
    var url = util.appendParamsToUrl(window.Urls.orderExport, {
        orderId: orderId
    });

    ajax.getJson({
        url: url,
        callback: function (response) {
            if (response && response.success) {
                populateDataLayer(response.userInfo);
            }
        }
    });
}

function populateDataLayer(userInfo) {
    if (!window.dataLayer || !userInfo) {
        return;
    }

    var customerData = {
        userDetails : {
            userID : userInfo.customerNo,
            customerId : userInfo.orderExportCustomerId ? userInfo.orderExportCustomerId : userInfo.customerExternalNo,
            customerEmail : userInfo.customerEmail,
            customerEmailHash : userInfo.customerEmailHash,
            customerEmailHash2 : userInfo.customerEmailHash2,
            customerFirstName : userInfo.firstName,
            customerLastName : userInfo.lastName,
            customerStatus : userInfo.orderExportCustomerStatus ? userInfo.orderExportCustomerStatus : 'Existing'
        }
    };

    dataLayer.push({ userDetails: null });  // Clear the previous userDetails object.
    window.dataLayer.push(customerData);
    window.dataLayer.push({ 'event': 'axcustomerinfo'});
}

exports.init = init;