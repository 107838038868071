'use strict';

var homePageHeroSwiper = undefined;

function initializeHomePageHeroSwiper() {
    homePageHeroSwiper = new Swiper('.heroCarousel.swiper', {
        autoplay: {
            delay: 4000,
        },
        keyboard: {
            enabled: true,
            onlyInViewport: true
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
        },
        rewind: true,
        pagination: {
            el: ".swiper-pagination",
            clickable: true
        },
        a11y: {
            containerMessage: 'Home page hero slider',
            slideRole: 'link'
        },
        on: {
            init: attachAccessibility,
            resize: attachAccessibility
        },
    });

    // GTM Click tracking
    $('.heroCarousel .swiper-button-prev, heroCarousel .swiper-button-next').off('click.gatracking').on('click.gatracking', function(e){
    	try {
    		gtmAnalytics.submitPromotionClick($(e.target).closest('[data-contentid]'), $(e.target));
    	} catch (err) { console.log("gtmAnalytics error: " + err.message); }
    });
}

function attachAccessibility() {
    // Creates slider play/pause option
    function carouselControls(e) {
        var $target = $(e.target);
        var $heroCarousel = $target.closest('.heroCarousel');
        var $sliderControls = $heroCarousel.find(".swiper-pagination-bullet");
        var accessibilityMsg = '';

        if ($heroCarousel.attr('data-click-state') == 1) {
            $heroCarousel.attr('data-click-state', 0).addClass('play');
            homePageHeroSwiper.autoplay.stop();
            accessibilityMsg = 'play carousel';
        } else {
            $heroCarousel.attr('data-click-state', 1).removeClass('play');
            homePageHeroSwiper.autoplay.start();
            accessibilityMsg = 'pause carousel';
        }
        $sliderControls.each(function() {
            var slideControler = $(this);
            var accessibilityLabel = slideControler.attr('data-accessibility-label') ? slideControler.attr('data-accessibility-label') + ' and ' + accessibilityMsg : accessibilityMsg;
            slideControler.attr('aria-label', accessibilityLabel);
        });
    }

    // Add proper event behavior for play/pause
    $(".accessible-slider-control").off('keydown').on('keydown', function(e) {
        if ((e.keyCode == 32) || (e.keyCode == 13)) {
            e.preventDefault();
            carouselControls(e);
        }
    });

    // Slider bullet navigation accessibility
    $(".heroCarousel .swiper-pagination-bullet").each(function() {
        $(this).attr({
            'data-slider-control': true,
            'data-clicked': 'slide bullet'
        })
    });

    $(".heroCarousel .swiper-pagination-bullet").off('click').on('click', carouselControls);

    $('.heroCarousel').on('keydown keyup', function(e){
        if (e.keyCode==9) {
            homePageHeroSwiper.slideNext();
        }
    });
}

module.exports.init = initializeHomePageHeroSwiper;
