var breakpoints = require('./breakpoints');

// Shop By Attribute Swiper
var shopBySwiper = undefined;
var videoPlaying = false;
var videoPlayingIndex = '';

var enableSwiper = function(){
    // Shop By Attribute Swiper Instance
    var slidesPerGroup = (Math.round($(window).width() / $('.swiper-slide').outerWidth(true)));
    if(!breakpoints.isDesktop() && $('.swiper.js-shop-by-attr-carousel').length > 0) {
        shopBySwiper = new Swiper('.swiper.js-shop-by-attr-carousel', {
            slidesPerView: 'auto',
            slidesPerGroup: slidesPerGroup,
            keyboard: {
                enabled: true,
                onlyInViewport: true         
            },
            pagination: {
                el: ".swiper-pagination",
                clickable: true
            },
            a11y: {
                containerMessage: 'Shop by size slider',
                slideRole: 'link'
            }
        });
        
        addSwiperCustomizations();

    } else if (breakpoints.isDesktop() && shopBySwiper != undefined){
        shopBySwiper.destroy();
        shopBySwiper = undefined;
    }
    
    var $vQlSwipers = $('.vQL .swiper');
    $vQlSwipers.each(function () {
        var $this = $(this);
        var swiperId = $this.attr('id');
        var title = $this.parent().find('.vQL__headline').text().trim();
        new Swiper('.swiper-' + swiperId, {
            spaceBetween: 20,
            breakpoints: getVQLSwiperBreakpoint($this),
            centerInsufficientSlides: true,
            a11y:  {
                containerMessage: title,
                enabled: true,
                slideLabelMessage:  'item {{index}} / {{slidesLength}}',
                slideRole: 'option'
            },
            keyboard: {
                enabled: true,
                onlyInViewport: false
            },
            navigation: {
                prevEl: '.swiper-button-prev',
                nextEl: '.swiper-button-next'
            },
            on: {
                reachEnd: customRewind
            }
        });
    });

    var $2upSwipers = $('.tiles.2up.swiper');
    $2upSwipers.each(function () {
        var $this = $(this);
        var swiperId = $this.attr('data-swiper-id');
        new Swiper('.swiper-' + swiperId, {
            breakpoints: {
                200: {
                    slidesPerView: 1,
                    spaceBetween: 0
                },
                768: {
                    slidesPerView: 2,
                    spaceBetween: 20
                }
            },
            centerInsufficientSlides: true,
            a11y: true,
            keyboard: {
                enabled: true,
                onlyInViewport: false
            },
            navigation: {
                prevEl: '.swiper-button-prev',
                nextEl: '.swiper-button-next'
            },
            on: {
                reachEnd: customRewind
            }
        });
    });

    var $3upSwipers = $('.tiles.3up.swiper');
    $3upSwipers.each(function () {
        var $this = $(this);
        var swiperId = $this.attr('data-swiper-id');
        new Swiper('.swiper-' + swiperId, {
            breakpoints: {
                200: {
                    slidesPerView: 1.5,
                    spaceBetween: 0
                },
                768: {
                    slidesPerView: 2.5,
                    spaceBetween: 10
                },
                1080: {
                    slidesPerView: 3,
                    spaceBetween: 20
                }
            },
            centerInsufficientSlides: true,
            a11y: true,
            keyboard: {
                enabled: true,
                onlyInViewport: false
            },
            navigation: {
                prevEl: '.swiper-button-prev',
                nextEl: '.swiper-button-next'
            },
            on: {
                reachEnd: customRewind
            }
        });
    });

    var $recommenderSwipers = $('.more-to-love-recommendations .swiper');
    $recommenderSwipers.each(function () {
        var $this = $(this);
        var recommendationTitle = $this.parents('.more-to-love-recommendations').find('h3').first().text().replace(/\n/g, '');
        var swiperId = $this.attr('id');
        new Swiper('.swiper-' + swiperId, {
            spaceBetween: 15,
            breakpoints: {
                200: {
                    slidesPerView: 2.5
                },
                768: {
                    slidesPerView: 3
                },
                1080: {
                    slidesPerView: 5
                }
            },
            a11y: true,
            keyboard: {
                enabled: true,
                onlyInViewport: false
            },
            navigation: {
                prevEl: '.swiper-button-prev',
                nextEl: '.swiper-button-next'
            },
            on: {
                reachEnd: customRewind,
                slideNextTransitionStart: function() {
                    $(document).trigger('ga.recommenderslider', ['forward', recommendationTitle]);
                },
                slidePrevTransitionStart: function() {
                    $(document).trigger('ga.recommenderslider', ['backward', recommendationTitle]);
                }
            }
        });
    });

    new Swiper('.collection-landing-category-thumbnails.swiper', {
        keyboard: {
            enabled: true,
            onlyInViewport: true
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
        },
        slidesPerView: 4,
        slidesPerGroup: 2,
        preventClicks: true,
        preventClicksPropagation: true,
        a11y: false,
        on: {
            click: navigateCollectionLanding
        }
    });

    var $collectionLandingTilesSwipers = $('.collection-landing-group-tiles.swiper');
    $collectionLandingTilesSwipers.each(function () {
        var swiperId = $(this).attr('id');
        new Swiper('.swiper-' + swiperId, {
            slidesPerView: 'auto',
            keyboard: {
                enabled: true,
                onlyInViewport: true
            },
            navigation: {
                nextEl: '.swiper-button-next-' + swiperId,
                prevEl: '.swiper-button-prev-' + swiperId
            },
            a11y: {
                containerMessage: 'Collection landing category tiles slider',
                slideRole: 'link'
            },
            slideClass: 'collection-landing-subcategory-wrapper',
            slidesPerGroup: 2,
            spaceBetween: 20
        });
    });

    var $UGCSwiper = $('.ambassadorUGC.swiper');
    $UGCSwiper.each(function () {
        var $this = $(this);
        var swiperId = $this.attr('id');
        new Swiper('.swiper-' + swiperId, {
            breakpoints: {
                200: {
                    slidesPerView: 1,
                    spaceBetween: 0
                },
                768: {
                    slidesPerView: 4,
                    spaceBetween: 20
                }
            },
            centerInsufficientSlides: true,
            a11y: true,
            keyboard: {
                enabled: true,
                onlyInViewport: false
            },
            navigation: {
                prevEl: '.swiper-button-prev',
                nextEl: '.swiper-button-next'
            }
        });
    });

    var $promotedFilters = $('.promotedFilterContainer.swiper');
    $promotedFilters.each(function () {
        var $this = $(this);
        var swiperId = $this.attr('id');
        var mobileView = false;
        new Swiper('.swiper-' + swiperId, {
            breakpoints: {
                200: {
                    slidesPerView: 'auto',
                    centerInsufficientSlides: false
                },
                768: {
                    slidesPerView: 'auto',
                    centerInsufficientSlides: true
                }
            },
            a11y: true,
            keyboard: {
                enabled: true,
                onlyInViewport: false
            },
            watchSlidesProgress: true,
            on: {
                activeIndexChange: () => {
                    window.dataLayer.push({
                        'event': 'clp-swipe'
                    });
                    if($('.filterVideo').length > 0 && mobileView){
                        setTimeout(function() { playFilterVideo(0)}, 1000); 
                    }
                },
                afterInit: () => {
                    $('.promotedFilterContainer .swiper-wrapper').css('place-content', 'unset');
                    $('.promotedFilter').css('width', 'auto !important');
                    if($('.filterVideo').length > 0){
                        if(window.innerWidth<1081){
                            mobileView = true;
                            playFilterVideo(0);
                        } else {
                            $('.mediaFilterWrapper').on('mouseover', function(){
                                let videoContainer = this.querySelector('.videoFilterWrapper');
                                let imageContainer = this.querySelector('.ImageFilterWrapper');
                                $(imageContainer).addClass('hidden');
                                $(videoContainer).removeClass('hidden');
                                let videoElem = videoContainer.querySelector('.filterVideo');
                                videoElem.play();
                                $(videoElem)[0].onended = function(){
                                    videoElem.play();
                                }
                            });
                            $('.mediaFilterWrapper').on('mouseleave', function(){
                                let videoContainer = this.querySelector('.videoFilterWrapper');
                                let videoElem = videoContainer.querySelector('.filterVideo');
                                videoElem.pause();
                                videoElem.currentTime = 0;
                                $(videoContainer).addClass('hidden');
                                $(this.querySelector('.ImageFilterWrapper')).removeClass('hidden');
                            });
                        }
                    }
                }
            }
        });
    });
};

function playFilterVideo(filterIndex){
    if(videoPlaying == true){
        $('.filterVideo')[videoPlayingIndex].pause();
        $($('.videoFilterWrapper')[videoPlayingIndex]).addClass('hidden');
        $($('.ImageFilterWrapper')[videoPlayingIndex]).removeClass('hidden')
    }
    if(filterIndex < $('.filterVideo').length){
        const el = $('.promotedVisualFilter')[filterIndex];
        if($(el).hasClass('hidden')){
            filterIndex++;
            playFilterVideo(filterIndex);
        }
        const rect = el.getBoundingClientRect();

        if ( rect.top > $('.sh-banner')[0].offsetHeight && rect.left > 0 && rect.right < window.innerWidth && rect.bottom < window.innerHeight) { 
            $($('.ImageFilterWrapper')[filterIndex]).addClass('hidden')
            $($('.videoFilterWrapper')[filterIndex]).removeClass('hidden');
            $('.filterVideo')[filterIndex].play();
            videoPlaying = true;
            videoPlayingIndex = filterIndex;
            $('.filterVideo')[filterIndex].onended = function(){
                $($('.videoFilterWrapper')[filterIndex]).addClass('hidden');
                $($('.ImageFilterWrapper')[filterIndex]).removeClass('hidden')
                videoPlaying = false;
                filterIndex++;
                playFilterVideo(filterIndex);
            }
        } else if(rect.left < 0){
            filterIndex++;
            playFilterVideo(filterIndex);
        }
    }
}

function customRewind(){
    var $thisVQLSwiper = $(this)[0].$el[0].swiper;
    setTimeout(function(){
        $thisVQLSwiper.slideTo(0, 1000);
    }, 3000);   
}

function navigateCollectionLanding(swiper, event) {
    // Click Main Collection Landing Page - Carousel Thumbnails
    var $targetSlide = $(event.srcElement).closest('.collection-landing-category-thumbnails__slide');
    if ($targetSlide.length === 0 || (swiper.touches.currentX - swiper.touches.startX !== 0)) {
        return;
    }

    var _position = $targetSlide.data('position')
    var _primaryCollectionName = $targetSlide.parents(".collection-landing-hero-wrapper").data("primarycollectionname");
    var _collectionName = $targetSlide.find(".collection-landing-category-thumbnails__title").text();
    $targetSlide.parents(".collection-landing-category-thumbnails").find(".collection-landing-category-thumbnails__title").removeClass("selected");
    $targetSlide.find(".collection-landing-category-thumbnails__title").addClass("selected");

    $('html, body').animate({
        scrollTop: $("#" + _position).offset().top - 90
    }, 500); // account for fixed header height

    window.dataLayer.push({
        'event': 'primary-collection-carousel-click',
        'primaryCollectionName': _primaryCollectionName,
        "collectionName": _collectionName,
        'clickType': 'image' //ex. text, image
    });
}

function getVQLSwiperBreakpoint($vqlswiper) {
    var data = $vqlswiper.data('swiper-options') || {};
    var swiperBreakpoints =  {};
    if (!data.items || data.items.length == 0) {
        return {};
    }

    for (var i = 0; i < data.items.length; i++) {
        var breakpoint =  data.items[i][0];
        var numofSlides = {slidesPerView: data.items[i][1]};
        swiperBreakpoints[breakpoint] = numofSlides;  
    }

    return swiperBreakpoints;
}

function addSwiperCustomizations(){
    // START - Shop by Attribute specific code
    var $swiper = $(shopBySwiper.el);
    var $shopBySlides = $swiper.find('.swiper-slide');
    var $shopByPagination = $swiper.find('.swiper-pagination-bullet');
    var slideTotal = $shopBySlides.length;

    $shopBySlides.each(function(slideIndex){
        var $slide = $(this);
        var slideAriaLabel = $slide.attr('data-accessibility-label');

        $slide.attr('aria-label', slideAriaLabel + ' link ' + slideIndex + ' of ' + slideTotal);
    });

    $shopByPagination.removeAttr('aria-label tabindex role');

    $('.js-skip-swiper').on('keydown', function(e){
        if (($(this).is(':focus')) && (e.keyCode == 32 || e.keyCode == 13)) {
            e.preventDefault();
            shopBySwiper.slideTo(slideTotal);
            setTimeout(function(){
                $shopBySlides.get(slideTotal - 1).trigger('focus');
            }, 200);
        }
    });

    $('.shop-by-attr-tabs').on('click', function(e){
        e.preventDefault();
        var $clickTarget = $(e.target);
        var $carouselIndex = $clickTarget.attr('data-carousel-index');
        
        $(this).find('.attr-tab').removeClass('active');
        $clickTarget.addClass('active');
    
        shopBySwiper.slideTo($carouselIndex);
        setTimeout(function(){
            $shopBySlides.get($carouselIndex).focus();
        }, 200);
    });
    // END - Shop by Attribute specific code
}

function init(){
    enableSwiper();
    return;
}

module.exports = {
    init: init
}