'use strict';

// set dataLayer to empty array if it's not on the window yet
window.dataLayer = window.dataLayer || [];

function openDrawer() {
    return window.dataLayer.push({
        'event': 'promo-drawer-open'
    })
}

function promoCardClick(promoName) {
    return window.dataLayer.push({
        'event': 'promo-drawer-click',
        'promoName': promoName
    })
}

function applyCode(promoName) {
    return window.dataLayer.push({
        'event': 'promo-drawer-apply',
        'promoName': promoName
    })
}

function getCardPromoName($el) {
    var $card = $el.closest('.promo-drawer__card');
    return $card.data('promoName');
}

function promoPPBttnClick(actionType) {
	window.dataLayer.push({
        'event': 'promo-ribbon',
        'actionType': actionType
    });
}

module.exports = {
    openDrawer: openDrawer,
    promoCardClick: promoCardClick,
    applyCode: applyCode,
    getCardPromoName: getCardPromoName,
    promoPPBttnClick: promoPPBttnClick
};