'use strict'

function init() {
    $('.js-ma-group').on('click', function () {
        $(this).find('.js-expand-toggle').toggleClass('is-active');
        $('.ma-group-list').toggleClass('expanded');
    });

    $('.ma-group-list li a').each(function () {
        var $this = $(this);
        // if the current path is like this link, make it active

        var loc = window.location;
        var pathName = loc.pathname;
        var newPathname = loc.href.substring(0, loc.href.length - ((loc.pathname + loc.search + loc.hash).length - pathName.length));

        if ($this.attr('href') === newPathname) {
            $this.addClass('is-active');
        }

    });
}

module.exports.init = init;
