'use strict';

const video = require('./video');
const analytics = require('./analytics');
const debounce = require('lodash/debounce');
const util = require('../../util');
var pdpLiteDOM;
function init() {
    // for PDP Lite, the cutoff is 768 and we switch between the mobile DOM and tablet/desktop DOM when we change between the two
    if($('.pdpLite').length > 0){
        if ($(window).innerWidth() > 768) {
            if(pdpLiteDOM && pdpLiteDOM.hasClass('hide-mobile')){
                $('.pdpLite').prepend(pdpLiteDOM);
            }
            pdpLiteDOM = $('.pdpLiteTop.hide-tablet').detach();
            initTabletMobileImages();
        } else {
            if(pdpLiteDOM && pdpLiteDOM.hasClass('hide-tablet')){
                $('.pdpLite').prepend(pdpLiteDOM);
            }
            pdpLiteDOM = $('.pdpLiteTop.hide-mobile').detach();
            initTabletMobileImages();
        }
    } else if ($(window).innerWidth() > 1080) {
        const $gif = $('.pdp-aplus__images__gif');

            updateClasses();

            $('.pdp-aplus__images .swiper-button-prev').hide();
            $('.pdp-aplus__images .swiper-button-next').hide();
            $('.pdp-aplus__images .swiper-pagination').hide();
            $('.pdp-aplus__images__thumb').on('click', function() {
                const $fullsizeImageID = $(this).attr('data-scroll-to');

                if($('.swiper-zoom-container.desktop.zoomed').length > 0){
                    let $zoomIconTrigger = $('.swiper-zoom-container.desktop.zoomed').parent().find('.js-pdp-aplus-zoom');
                    let $zoomImageContainer = $('.swiper-zoom-container.desktop.zoomed');
                    handleDesktopZoom($zoomIconTrigger, $zoomImageContainer, 'unset');
                }

                $('html,body').animate({
                    scrollTop: $($fullsizeImageID).offset().top - 100}
                ,'slow');
            });

            $('.js-pdp-aplus-zoom.desktop, .swiper-zoom-container.desktop').off().on('click', (e) => manageDesktopZoomElements(e));

        $(window).on('resize', debounce(() => {
            if (window.innerWidth <= 1080 && $('.pdp-aplus__images__mains__wrapper').length >= 1) {
                const $zoomIconTrigger = $('.js-pdp-aplus-zoom');
                const $zoomImageContainer = $('.swiper-zoom-container');
        
                updateClasses();
        
                if($zoomImageContainer.hasClass('zoomed')){
                    handleDesktopZoom($zoomIconTrigger, $zoomImageContainer, 'unset');
                }
        
                initTabletMobileImages();
                video.init();
            }
        }, 200));

        $(window).on('scroll', debounce(() => {
            if($gif.length > 0 && util.elementInViewport($gif[0], 100) && window.innerWidth > 1080){
                trackGifInView();
            }
        }, 500));
    } else {
        updateClasses();
        initTabletMobileImages();
    }

    video.init();
}

function updateClasses(){
    if($(window).innerWidth() > 1080){
        $('.js-pdp-aplus-zoom').addClass('desktop');
        $('.swiper-zoom-container').addClass('desktop');
    }else{
        $('.js-pdp-aplus-zoom').removeClass('desktop');
        $('.swiper-zoom-container').removeClass('desktop');
    }
}

function manageDesktopZoomElements(e){
    if($(window).innerWidth() > 1080){
        const $this = $(e.currentTarget);
        const rect = $this[0].getBoundingClientRect();
        const coX = e.clientX - rect.left;
        const coY = e.clientY - rect.top;
        let $zoomIconTrigger;
        let $zoomImageContainer;
        let coord;

        if($this.hasClass('swiper-zoom-container')){
            // Image click
            $zoomIconTrigger = $this.parent().find('.js-pdp-aplus-zoom');
            $zoomImageContainer = $this;
            coord = -(coX*2) + "px " + -(coY*2) + "px";
        }else{
            // Zoom icon click
            $zoomIconTrigger = $this;
            $zoomImageContainer = $this.parent().parent().find('.swiper-zoom-container');
            coord = 'center';
        }

        handleDesktopZoom($zoomIconTrigger, $zoomImageContainer, coord);
    }
}

function handleDesktopZoom($zoomIconTrigger, $zoomImageContainer, coord){
    if($zoomIconTrigger.hasClass('js-zoomed-in')){
        $zoomIconTrigger.removeClass('js-zoomed-in');
        $zoomIconTrigger.attr('aria-label', "Image is not zoomed in");

        $zoomImageContainer.removeClass('zoomed');
        $zoomImageContainer.css('background-image', 'none');
        $zoomImageContainer.css('background-size', 'unset');
        $zoomImageContainer.css('background-position', 'center');
        $zoomImageContainer.css('background-repeat', 'no-repeat');
        $zoomImageContainer.css('width', 'auto');
        $zoomImageContainer.css('height', 'auto');

        $zoomImageContainer.find('picture').show();
    }else{
        const $zoomImage = $zoomImageContainer.find('img').attr('data-hiRes-src');

        $zoomIconTrigger.addClass('js-zoomed-in');
        $zoomIconTrigger.attr('aria-label', "Image is zoomed in");

        $zoomImageContainer.addClass('zoomed');
        $zoomImageContainer.css('background-image', `url('${$zoomImage}')`);
        $zoomImageContainer.css('background-size', `${$zoomImageContainer.width() * 3}px ${$zoomImage.height * 3}`);
        $zoomImageContainer.css('background-position', coord);
        $zoomImageContainer.css('background-repeat', 'no-repeat');
        $zoomImageContainer.css('width', `${$zoomImageContainer.width()}px`);
        $zoomImageContainer.css('height', `${$zoomImageContainer.height()}px`);

        $zoomImageContainer.find('picture').hide();
    }
}

// This function accounts for tablet/Mobile on full PDPs and  all the images on PDP Lite
function initTabletMobileImages() {
    const originalScreenWidth = $(window).innerWidth();
    const swiperContainerSelectors = '.pdp-aplus__images__mains__wrapper.swiper';
    let slidesPerView = $('.pdp-aplus__images__mains > *').length > 1 ? 1.2 : 1;

    $('.pdp-aplus__images__mains__wrapper').addClass('swiper');
    $('.pdp-aplus__images__mains').addClass('swiper-wrapper');
    $('.pdp-aplus__images__main').addClass('swiper-slide');

    $('.pdp-aplus__images .swiper-pagination').show();

    if($('.pdpLite').length > 0){
        slidesPerView = 1;
    }

    if($(swiperContainerSelectors).hasClass('test-ui') && !$('.pdp-aplus__top').hasClass('is-giftcard')){
        $('.pdp-aplus__images .swiper-button-prev').hide();
        $('.pdp-aplus__images .swiper-button-next').hide();
        const swiper = new Swiper(swiperContainerSelectors, {
            keyboard: {
                enabled: true,
                onlyInViewport: true
            },
            a11y: {
                prevSlideMessage: 'Previous slide',
                nextSlideMessage: 'Next slide',
            },
            pagination: {
                el: ".swiper-pagination",
                clickable: true
            },
            slidesPerView: slidesPerView,
            spaceBetween: 10,
            on: {
                resize: () => {
                    if($('.pdpLite').length > 0){
                        const mobileToTablet = originalScreenWidth < 768 && $(window).innerWidth() > 768;
                        const TabletToMobile = originalScreenWidth > 768 && $(window).innerWidth() < 768;
                        if (mobileToTablet || TabletToMobile) {
                            imagesSwiperResize(swiper);
                        }
                    } else if ($(window).innerWidth() > 1080) {
                        imagesSwiperResize(swiper);
                    }
                },
                activeIndexChange: () => {
                    const $gif = $(swiper.slides[swiper.activeIndex]).find('.pdp-aplus__images__gif');

                    if($gif.length > 0){
                        trackGifInView();
                    }

                    handleSwiperVideoOnSlideChange(swiper, '.pdp-aplus__images__video');
                },
                sliderFirstMove: () => {
                    pdpCarouselRedesignTestTracking('swipe', 'variant');
                },
                click: (...args)=>{
                    const e = args[1].type === 'touchend' ? args[1].changedTouches[0] : args[1];
                    const hasImage = $(swiper.slides[swiper.activeIndex]).find('.swiper-zoom-target').length > 0;
                    const slider = args[0];

                    if (!slider) {
                        return;
                    }

                    const clickedSlide = slider.clickedSlide;

                    if (!clickedSlide) {
                        return;
                    }

                    const classList = clickedSlide.classList;

                    if (!classList) {
                        return;
                    }

                    const isActiveSlide = classList.contains('swiper-slide-active');

                    if (hasImage && isActiveSlide) { 
                        const isImageClick = $(args[1].target)[0].tagName === "IMG" ? true : false;
                        handleSwiperZoomOnClick(e, swiper, isImageClick);
                    }
                }
            }
        });

        if(slidesPerView === 1){
            $(`${swiperContainerSelectors} .swiper-slide`).addClass('single-image');
        }

        $('.pdp-aplus__images .swiper-pagination-bullet').on('click', () => pdpCarouselRedesignTestTracking('pagination tap', 'variant'));
    }else{
        $('.pdp-aplus__images .swiper-button-prev').show();
        $('.pdp-aplus__images .swiper-button-next').show();

        const swiper = new Swiper(swiperContainerSelectors, {
            keyboard: {
                enabled: true,
                onlyInViewport: true
            },
            touchMoveStopPropogation: true,
            a11y: {
                prevSlideMessage: 'Previous slide',
                nextSlideMessage: 'Next slide',
            },
            pagination: {
                el: ".swiper-pagination",
                type: "fraction",
            },
            slidesPerView: 1,
            spaceBetween: 0,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            on: {
                resize: () => {
                    if ($(window).innerWidth() > 1080) {
                        imagesSwiperResize(swiper);
                    }
                },
                activeIndexChange: () => {
                    const $gif = $(swiper.slides[swiper.activeIndex]).find('.pdp-aplus__images__gif');

                    if($gif.length > 0){
                        trackGifInView();
                    }

                    handleSwiperVideoOnSlideChange(swiper, '.pdp-aplus__images__video');
                },
                sliderFirstMove: () => {
                    pdpCarouselRedesignTestTracking('swipe', 'control');
                },
                click: (...args)=>{
                        const e = args[1].type === 'touchend' ? args[1].changedTouches[0] : args[1];
                        const hasImage = $(swiper.slides[swiper.activeIndex]).find('.swiper-zoom-target').length > 0;

                        if(hasImage){
                            const isImageClick = $(args[1].target)[0].tagName === "IMG" ? true : false;
                            handleSwiperZoomOnClick(e, swiper, isImageClick);
                        }
                }
            }
        });

        $('.pdp-aplus__images .swiper-button-prev, .pdp-aplus__images .swiper-button-next').on('click', () => pdpCarouselRedesignTestTracking('arrow click', 'control'));
    }

}

function imagesSwiperResize(swiper){
    swiper.zoom.out();
    
    swiper.destroy();

    $('.pdp-aplus__images__mains__wrapper').removeClass('swiper');
    $('.pdp-aplus__images__mains').removeClass('swiper-wrapper');
    $('.pdp-aplus__images__main').removeClass('swiper-slide');
    $(".pdp-aplus-zoom__btn").removeClass('js-zoomed-in');
    $(".pdp-aplus-zoom__btn").attr('aria-label', "Image is not zoomed in");

    init();
}

function initQuickViewImages() {
    // No images show on tablet/mobile quickview 
    if ($(window).innerWidth() <= 1080) {
        return;
    }

    const swiper = new Swiper('.pdp-aplus__images-qv__mains__wrapper.swiper', {
        keyboard: {
            enabled: true,
            onlyInViewport: true
        },
        a11y: {
            prevSlideMessage: 'Previous slide',
            nextSlideMessage: 'Next slide',
        },
        pagination: {
            el: ".swiper-pagination",
            type: "fraction",
        },
        slideToClickedSlide: true,
        slidesPerView: 1,
        spaceBetween: 0,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        on: {
            activeIndexChange: () => {
                const $target = $('.js-image-zoom-' + swiper.activeIndex); 
                const $gif = $(swiper.slides[swiper.activeIndex]).find('.pdp-aplus__images__gif');

                handleSwiperVideoOnSlideChange(swiper, '.pdp-aplus__images-qv__video');

                if($target.length > 0){
                    analytics.reportAlternateImageViewed('carousel', $target);
                }

                if($gif.length > 0){
                    trackGifInView();
                }
            },
            click: (...args)=>{
                const e = args[1];
                const hasImage = $(swiper.slides[swiper.activeIndex]).find('.swiper-zoom-target').length > 0;
                const $video = $(swiper.slides[swiper.activeIndex]).find('.pdp-aplus__images-qv__video');

                if(hasImage){
                    const isImageClick = $(args[1].target)[0].tagName === "IMG" ? true : false;
                    handleSwiperZoomOnClick(e, swiper, isImageClick);
                }

                if($video.length > 0){
                    video.handleAndTrackVideoOnClick($video);
                }
            }
        }
    });

    $('.pdp-aplus__images-qv__thumb').on('click', (e) => {
        var $this = $(e.currentTarget);

        if(!$this.hasClass('active')){
            const $previouslyActiveThumb = $('.pdp-aplus__images-qv__thumb.active');
            const $previouslyActiveSlide = $('.swiper-slide-active');
            const $currentlyActiveSlide = $($this.attr('data-scroll-to'));
            const index = $this.data('index');

            $previouslyActiveThumb.removeClass('active');
            $previouslyActiveSlide.removeClass('swiper-slide-active');

            // Unlock swiper
            swiper.allowSlidePrev = swiper.allowSlideNext = true;
            swiper.slideTo(index);
            $this.addClass('active');
            $currentlyActiveSlide.addClass('swiper-slide-active');
        }else{
            return;
        }
    });
}

function handleSwiperZoomOnClick(e, swiper, isImageClick){
    const $activeSlide = $(swiper.slides[swiper.activeIndex]);
    const target = $activeSlide.hasClass('pdp-aplus__images-qv__main') ? 'img' : 'picture';
    const $zoomImageContainer = $activeSlide.find(".swiper-zoom-container");
    const $zoomIconTrigger = $activeSlide.find(".pdp-aplus-zoom__btn");

    if($activeSlide.hasClass("swiper-slide-zoomed")){
        swiper.zoom.out();

        $zoomIconTrigger.removeClass('js-zoomed-in');
        $zoomIconTrigger.attr('aria-label', "Image is not zoomed in");

        analytics.reportZoomClick($zoomImageContainer.find('img'), 'zoom out');

        // Unlock swiper
        swiper.allowSlidePrev = swiper.allowSlideNext = true;
    }else{
        const rect = $(swiper.el)[0].getBoundingClientRect();
        const coX = e.clientX - rect.left;
        const coY = e.clientY - rect.top;
        let coord;

        if(isImageClick){
            coord = coX + "px " + coY + "px";
        }else{
            coord = 'center';
        }

        swiper.zoom.in();

        $zoomIconTrigger.addClass('js-zoomed-in');
        $zoomIconTrigger.attr('aria-label', "Image is zoomed in");

        analytics.reportZoomClick($zoomImageContainer.find('img'), 'zoom in');

        $activeSlide.find(target).css("transform-origin", coord);

        // Lock swiper
        swiper.allowSlidePrev = swiper.allowSlideNext = false;
    }
}

function handleSwiperVideoOnSlideChange(swiper, videoSelector){
    let $video = $(swiper.slides[swiper.activeIndex]).find(videoSelector);
    const isVideo = $video.length > 0;

    if(isVideo){
        const $activeSlide = $(swiper.slides[swiper.activeIndex]);
        
        if(!$activeSlide.find('.pdp-aplus__images__video__overlay').hasClass('is-playing') && $video.hasClass('autoplay')){
            video.play($video);
        }
        video.trackVideoInView();
    }else{
        const $previousSlide = $(swiper.slides[swiper.previousIndex]);
        $video = $previousSlide.find(videoSelector);

        if($video.length > 0 && $previousSlide.find('.pdp-aplus__images__video__overlay').hasClass('is-playing')){
            video.pause($video);
        }
    }
}

function trackGifInView(){
    const productName = JSON.parse($('.js-analytics-add-to-bag').first().text()).product.name;
    const productSku = JSON.parse($('.js-analytics-add-to-bag').first().text()).product.id;

    window.dataLayer.push({ 
        'event': 'pdp-gif-in-view', 
        'productName': productName,
        'productSku': productSku
    });
}

function pdpCarouselRedesignTestTracking(carouselInteraction, testVariant){
    window.dataLayer = window.dataLayer || [];

    window.dataLayer.push({
        event: 'view-alternate-image',
        carouselInteraction: carouselInteraction,
        testVariant: testVariant
    });
}

module.exports = {
    init: init,
    initQuickView: initQuickViewImages
};
