'use strict';

var util = require('../../util');
var herochatTracking = require('../../chat/herochatTracking');
var pinterestTracking = require('../../trackingData/pinterestTracking');
var tiktokTracking = require('../../trackingData/tiktokTracking');
var facebookTracking = require('../../trackingData/facebookTracking');

var $pdpMain;
var pageData;
var ns = '.pdpapa';

function setPdpMain(isQuickBuy) {
	$pdpMain = (isQuickBuy) ? $('#pdpQv') : $('#pdpMain');
}

/**
 * @public
 * Setup analytics on page load
 */
function init() {
    setPdpMain(false);
    updateData();

    // Report Detail Impression on page load
    reportDetailImpression();
    reportSetDetailImpression();

    // Setup Variant Refinement History
    $pdpMain.data('analyticsRefinementHistory', []);

    initializeEvents();
}

/**
 * @public
 * Setup analytics on quick view load
 */
function initQuickView() {
	setPdpMain(true);
    updateData();

    // Report Detail Impression on qv load
    reportDetailImpression();
    reportSetDetailImpression();

    // Setup Variant Refinement History
    $pdpMain.data('analyticsRefinementHistory', []);

    initializeEvents();
}

function initializeEvents() {
    $pdpMain.off(ns);

    // Event Reporting
    $pdpMain.on('click' + ns, '[data-analytics-promo]', reportPromoClick);
    $pdpMain.on('click' + ns, '.pdp-aplus__images__thumb', function() { reportAlternateImageViewed('alt click', $(this)); });
    $pdpMain.on('click' + ns, '.js-swatchanchor', reportVariantSwatchClick);
    $pdpMain.on('click' + ns, '.pdo-size-message a', reportSizeChartLinkClick);
    $pdpMain.on('click' + ns, '.js-expander', reportExpanderClick);
    $pdpMain.on('click' + ns, '.content-module', reportContentModuleClick);
    $pdpMain.on('click' + ns, '.pdp-contact-row a.chat', reportChatLinkClick);

    // Family Matching
    $pdpMain.on('fms.selectorload' + ns, reportFamilyMatchingSetProductImpression);

    // TurnTo Related Event Reporting
    $pdpMain.on('click' + ns, '.pdp-aplus .TTteaser__write-review, .pdp-aplus .tt-c-reviews-summary__write-review, .pdp-aplus .tt-c-toolbar__write-review ', reportWriteReviewClick);
    $pdpMain.on('click' + ns, '.pdp-aplus .TTteaser__rating', function() { reportRatingClick('star rating'); });
    $pdpMain.on('click' + ns, '.pdp-aplus .TTteaser__read-reviews', function() { reportRatingClick('number of reviews'); });
    $pdpMain.on('click' + ns, '.pdp-aplus .TTteaser__read-qa', function() { reportTurnToOtherClick('q&a-link'); });
    $pdpMain.on('click' + ns, '.pdp-aplus__reviews .reviews .toggle', function() { reportTurnToOtherClick('review-expander'); });
}

/**
 * @public
 * Update page data using '.js-analytics-add-to-bag' data
 */
function updateData() {
    try {
        var $analytics = $('.js-analytics-add-to-bag');
        pageData = JSON.parse($analytics.first().text());
        return pageData;
    } catch(e) {
        console.warn('Analytics data could not be parsed');
    }
}

/**
 * @public
 * Add page data analytics parameters to a URL
 */
function addPageDataAnalyticsParams(url) {
	try {
		if (!pageData) {
			setPdpMain(util.isQuickviewOpen());
		    updateData();
		}
		if (pageData && pageData.extraData.hasOwnProperty('clickData')) {
			var clickData = pageData.extraData.clickData;
			url = util.appendParamToURL(url, 'clicksrc', clickData.location);
			url = util.appendParamToURL(url, 'clicklt', clickData.linkType);
		}
	} catch(e) {}
	return url;
}

/**
 * Report a detail impression on PDP
 * page load or Quick View load
 */
function reportDetailImpression() {
    if (!pageData) { return; }

    var $tierOneMsg = $pdpMain.find('.tier-one-msg');
    pageData.product.tierOneMsg = ($tierOneMsg.length) ? $tierOneMsg.first().text() : "";
    var data = {
        event: 'product-detail-view',
        productSku: pageData.product.id,
        productName: pageData.product.name,
        actionLocation: (pageData.extraData.hasOwnProperty('location')) ? pageData.extraData.location : '',
        badging: pageData.extraData.badging,
        specialtyShop: 'false',
        ecommerce: {
            currencyCode: window.CURRENCY_CODE,
            detail: {
            	actionField: {},
            	products: [pageData.product]
            }
        }
    };

    window.dataLayer.push(data);
    
    herochatTracking.productView(pageData);
    pinterestTracking.productView(pageData);
    tiktokTracking.productView(pageData);
    facebookTracking.productView(pageData);
}

/**
 * Report a detail impression on Product Set PDP
 * page load or Quick View load
 */
function reportSetDetailImpression() {
    var $setData = $('.js-analytics-product-set');
    var setData;

    if (!$setData.length) { return; }
    try {
        setData = JSON.parse($setData.first().text());
    } catch(e) {
        console.warn('Analytics set data could not be parsed');
    }

    var data = {
        event: 'collection-product-detail-view',
        ecommerce: {
            currencyCode: window.CURRENCY_CODE,
            detail: {
                actionField: {
                    list: setData.setName
                },
                products: setData.products
            }
        }
    };

    window.dataLayer.push(data);
}

/**
 * Report a detail impression on Family Matching PDP
 * when a "Selector" component is loaded showing Product data
 */
function reportFamilyMatchingSetProductImpression() {
    var $setData = $('.js-analytics-family-matching');
    var $productData = $('.js-analytics-family-matching-set-item');
    var setData;
    var productData;

    if (!$setData.length || !$productData.length) { return; }
    try {
        setData = JSON.parse($setData.first().text());
        productData = JSON.parse($productData.first().text());
    } catch(e) {
        console.warn('Analytics set data could not be parsed');
    }

    var data = {
        event: 'collection-product-detail-view',
        ecommerce: {
            currencyCode: window.CURRENCY_CODE,
            detail: {
                actionField: {
                    list: setData.setName
                }
            }
        }
    };

    if (setData && setData.collectionName &&
        productData && productData.addToCart && productData.addToCart.product) {
        productData.addToCart.product.dimension93 = setData.collectionName;
        data.ecommerce.detail.products = [ productData.addToCart.product ];
        window.dataLayer.push(data);
    }
}

function getFamilyMatchingListName() {
	var $setData = $('.js-analytics-family-matching');
	var setData = ($setData.length > 0) ? JSON.parse($setData.first().text()) : {};
	return ('collectionName' in setData) ? setData.collectionName : ('setName' in setData) ? setData.setName : "";
}

/**
 * Report a family member (builder) button
 * was clicked on Family Matching PDP 
 */
function reportFamilyMatchingBuilderBtnClick(memberType) {
    var data = {
        event: 'collection-fm-button-click',
        listName: getFamilyMatchingListName(),
        familyMember: memberType
    };
    window.dataLayer.push(data);
}

/**
 * Report the Matching Family Builder instructions link
 * was clicked on Family Matching PDP 
 */
function reportFamilyMatchingBuilderInstructionsClick() {
    var data = {
        event: 'collection-instructions-click',
        listName: getFamilyMatchingListName()
    };
    window.dataLayer.push(data);
}

/**
 * Report Promo Clicks
 * These promos have JSON data stored in
 * a "data-analytics-promo" attribute
 * Calls GTM function to push promos to dataLayer
 */
function reportPromoClick() {
    var $promo = $(this);
    var data = $promo.data('analytics-promo');

    if (data) {
        gtmAnalytics.pdpPromoClick(data);
    }
}

/**
 * Report Alternate Image Viewed
 */
function reportAlternateImageViewed(actionDesc, $target) {
    if (!pageData) { return; }
    var imageName = $target.find('img').attr('src')
    var interactionType = (actionDesc == "alt click") ? "side bar image click" : "carousel click or swipe";
    if (imageName.length > 0) {
        imageName = getImageName(imageName);
    }
    var data = {
        event: 'view-alternate-image',
        productSku: pageData.product.id,
        productName: pageData.product.name,
        productImage: imageName,
        imageInteraction: interactionType
    };

    window.dataLayer.push(data);
}

/**
 * @public
 * Report Zoom icon click
 * @param {jQuery} $img jQuery object
 * @param {String} action 'zoom in' or 'zoom out'
 */
function reportZoomClick($img, action) {
    if (!pageData) { return; }

    var imageName = $img.attr('src');
    if (imageName.length) {
        imageName = getImageName(imageName);
    }

    var data = {
        event: 'image-interaction',
        productSku: pageData.product.id,
        productName: pageData.product.name,
        imageInteraction: action,
        productImage: imageName
    };

    window.dataLayer.push(data);
}

function reportVariantSwatchClick() {
    if (!pageData) { return; }
    var $swatch = $(this);
    var swatchAnalytics = $swatch.data('analytics');
    var swatchAts = $swatch.data('ats') || '';
    var refinementCategory = '', refinementSelected = '';
    try {
    	refinementCategory = (swatchAnalytics.refinementCategory || '').toLowerCase();
    	refinementSelected = swatchAnalytics.refinementSelected || '';
    } catch(e) {
    	console.warn('refinement category/selection not set; error: ' + e);
    }
    var noSize = "no size selected";
    var sizeSelected = pageData.product.dimension15.trim();
    var atsProperty = "offerATS", atsClearProperty = "skuATS";
    if (refinementCategory == 'color') {
    	if (sizeSelected != noSize) {
    		var sizesATSs = $swatch.data('sizes-ats') || {};
    		var sizeAts = (sizesATSs.hasOwnProperty(sizeSelected)) ? sizesATSs[sizeSelected] : 0;
    		if (sizeAts > 0) {
    			swatchAts = sizeAts; atsProperty = "skuATS"; atsClearProperty = "offerATS";
    		} else {
    			sizeSelected = noSize;
    		}
    	}
    }
    if (refinementCategory == 'size') {
    	sizeSelected = refinementSelected.split("|")[0].trim();
    	atsProperty = "skuATS"; atsClearProperty = "offerATS";
    }
    var data = {
        event: 'product-detail-refinement',
        productName: pageData.product.name,
        sizeSelected: sizeSelected,
        actionLocation: util.isQuickviewOpen() ? 'quickbuy' : 'detail',
        badging: pageData.extraData.badging
    };
    $.extend(data, swatchAnalytics);
    data[atsProperty] = swatchAts;
    data[atsClearProperty] = '';

    if (!data.hasOwnProperty('variationGroup')) {
    	// add in variation group if found
    	var $productIDs = $('.js-product-ids');
    	if ($productIDs.length && $productIDs.data('cvg').length) {
    		data.variationGroup = $productIDs.data('cvg');
    	}
    }

    // add in historical data for when the page/product was loaded
    if ($pdpMain.data('analyticsRefinementHistory').length) {
        data.listRefinementsHistory = $pdpMain.data('analyticsRefinementHistory').join(', ');
        data.listRefinementNumber = $pdpMain.data('analyticsRefinementHistory').length + 1;
    } else {
        data.listRefinementsHistory = '';
        data.listRefinementNumber = 1;
    }

    // push selected attribute to refinements history
    var newData =  $pdpMain.data('analyticsRefinementHistory');
    newData.push(data.refinementSelected);
    $pdpMain.data('analyticsRefinementHistory', newData);

    window.dataLayer.push(data);
}

function reportSizeChartLinkClick() {
    if (!pageData) { return; }

    var data = {
        event: 'size-guide-modal',
        productSku: pageData.product.id,
        productName: pageData.product.name,
        clickType: 'text',
        actionLocation: util.isQuickviewOpen() ? 'quickbuy' : 'detail'
    };

    window.dataLayer.push(data);
}

function reportExpanderClick() {
    var $expander = $(this);
    var $tab = $expander.find('.toggle');
    var isExpanded = $tab.hasClass('expanded');
    var clickType = isExpanded ? 'expand' : 'collapse';
    var tabName = $tab.text().replace(/[\n\t]/g, '');

    if (!tabName) {
        tabName = 'other';

        if ($expander.hasClass('details')) {
            tabName = 'details and care';
        }

        if ($expander.hasClass('reviews')) {
            tabName = 'reviews';
        }
    }

    if (!pageData) { 
        window.dataLayer.push({
            event: 'expand-product-info',
            productInfoTab: tabName,
            clickType: clickType,
            tabName: tabName
        });
        return;
    }

    var data = {
        event: 'expand-product-info',
        productSku: pageData.product.id,
        productName: pageData.product.name,
        productInfoTab: tabName,
        clickType: clickType,
        tabName: tabName
    };

    window.dataLayer.push(data);
}

function reportContentModuleClick() {
    if (!pageData) { return; }

    var moduleID = $(this).data('id') || '';

    var data = {
        event: 'content-module',
        productSku: pageData.product.id,
        productName: pageData.product.name,
        contentModule: moduleID
    };

    window.dataLayer.push(data);
}

function reportChatLinkClick() {
    if (!pageData) { return; }

    var data = {
        event: 'contact-us-live-chat',
        actionLocation: 'PDP'
    };

    window.dataLayer.push(data);
}

function reportWriteReviewClick() {
    if (!pageData) { return; }

    var data = {
        event: 'submit-review',
        productSku: pageData.product.id,
        productName: pageData.product.name
    };

    window.dataLayer.push(data);
}

function reportRatingClick(type) {
    if (!pageData) { return; }

    var data = {
        event: 'rating-click',
        productSku: pageData.product.id,
        productName: pageData.product.name,
        ratingClickType: type
    };

    window.dataLayer.push(data);
}

function reportTurnToOtherClick(whatWasClicked) {
	if (!pageData) { return; }
    var data = {
        event: whatWasClicked + '-click',
        productSku: pageData.product.id,
        productName: pageData.product.name,
    };
    window.dataLayer.push(data);
}

/**
 * Get just the image name from a URL
 * @param {String} imgPath with folders and params
 * @returns {String} image name with folder path and query params removed
 */
function getImageName(imgPath) {
    return imgPath.split('/').pop().split('?').shift();
}

module.exports = {
    init: init,
    initQuickView: initQuickView,
    updateData: updateData,
    addPageDataAnalyticsParams: addPageDataAnalyticsParams,
    reportAlternateImageViewed: reportAlternateImageViewed,
    reportZoomClick: reportZoomClick,
    reportFamilyMatchingBuilderBtnClick: reportFamilyMatchingBuilderBtnClick,
    reportFamilyMatchingBuilderInstructionsClick: reportFamilyMatchingBuilderInstructionsClick
};
