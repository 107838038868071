'use strict';

var updateSummary = require('./pages/checkout/updateSummary'),
    ajax = require('./ajax'),
    address = require('./pages/checkout/address'),
    progress = require('./progress'),
    util = require('./util'),
    applepay = require('./applepay'),
    dialog = require('./dialog'),
    floatingLabels = require('./floatingLabels'),
    formPrepare = require('./pages/checkout/formPrepare'),
    internationalForms = require('./internationalFields'),
    breakpoints = require('./breakpoints');

var $creditCardForm;

function initGCAddressFields() {
    var $billingAddressObj = $('.cc-billing-address-on-load');
    if($billingAddressObj.length < 1 || $billingAddressObj.hasClass('events-initialized')) {
        return;
    }
    util.initCheckoutAddressFieldGroups($('form.cob-billing'));

    formPrepare.init({
        formSelector: 'form[id$="billing"]',
        continueSelector: '[name$="billing_save"]'
    });
    floatingLabels.init();
    address.init();
    bindSameAsShippingEvents();
    internationalForms.init();

    var $cityStateZip = $('form.cob-billing').find('.cc-billing-address-on-load .share.city-state-zip'),
        $city = $('form.cob-billing').find('.cc-billing-address-on-load .form-row.co-city');
    if (breakpoints.isTablet() || breakpoints.isDesktop()) {
        $city.prependTo($cityStateZip);
        $cityStateZip.removeClass('share-2').addClass('share-3');
    } else {
        $city.insertBefore($cityStateZip);
        $cityStateZip.removeClass('share-3').addClass('share-2');
    }

    $('.cc-billing-address-on-load').addClass('events-initialized');
}

function toggleCreditCardForm() {
    if ($('.js-gc-balance').data('hideCreditCardForm')){
        $('#billing-promo-code-gift-card .same-as-shipping-address input').trigger('click');
        if ($('.js-gc-balance').data('hideSameAsShipping')) {
    		$('#billing-promo-code-gift-card .same-as-shipping-address').hide();
    	}
        $creditCardForm = $('.js-cc-inner').clone();
        $('.js-cc-inner').remove();
        if ($('.gift-cert-used').length == 0) {
            $('.js-cc-form').append('<div class="gift-cert-used form-indent">' + Resources.GIFT_CERT_NO_METHOD + '</div>');
            initGCAddressFields();
        }
        $('button[name="dwfrm_billing_save"]').attr('disabled', false);
    } else {
        $('.js-cc-form').find('.gift-cert-used').remove();
        $('.js-cc-form').append($creditCardForm);
    }
}

function updateGiftCardForm() {
    progress.show($('.js-gc-wrapper'));
    var options = {
        url : Urls.giftCardUpdate,
        callback: function(response) {
            $('.js-gc-wrapper').html(response);
            initGCAddressFields();
            toggleCreditCardForm();
            resetGiftHelpModal();

            /* global pca */
            // Reinits Loqate
            if (typeof(pca) != 'undefined' && pca) { 
                pca.load();
            }
        }
    }
    ajax.load(options);
}

function removeGiftCard($link) {
    progress.show($link.parent());
    var options = {
        url : $link.attr('href'),
        callback: function() {
            updateSummary(5);
            updateGiftCardForm();
            applepay.refreshApplePayRequest();
            $('.payment-box-cybersource-applepay.was-payment-fourth').addClass('payment-fourth').removeClass('was-payment-fourth');
            $('.panel-AFTERPAY_PBI').removeClass('hide');
            window.location.href = Urls.billing;
        }
    }
    ajax.load(options);
}

function applyGiftCard($btn) {
    var $giftCertCode = $('.js-gc-number');
    var $giftCertPIN = $('.js-gc-pin');
    var gcCheck = gcEntryCheck($giftCertCode, $giftCertPIN);
    if (!gcCheck.entriesOk) {
    	return;
    }
    if (gcCheck.errMsg == "" && $('.js-gc-applied').length >= SitePreferences.MAX_GIFT_CERT_NUMBER) {
   		gcCheck.errMsg = Resources.GIFT_CERT_TOO_MANY;
    }
    if (gcCheck.errMsg != "") {
    	$('.js-gc-balance').removeClass('error').addClass('error').html(gcCheck.errMsg).show();
        return;
    }
    var _gcNo = $giftCertCode.val();
    var _gcPin = $giftCertPIN.val();

    progress.show($btn);

    var url = util.appendParamsToUrl(Urls.processGiftCard, { giftCardNo: _gcNo, giftCardPin: _gcPin, format: 'ajax' });

    $.getJSON(url, function (data) {
        if (data && data.success) {
        	 updateSummary(5);
             updateGiftCardForm();
             applepay.refreshApplePayRequest();
             $('.payment-box-cybersource-applepay.payment-fourth').removeClass('payment-fourth').addClass('was-payment-fourth');
             $('.panel-AFTERPAY_PBI').addClass('hide');
             if (data.afterpayreset) {
                $('.panel-AFTERPAY_PBI').siblings('.afterpay-submit-error').remove();
                $('form.checkout-billing').find('.billing-error.no-scroll').remove();
             }
        } else {
            showTempErrMsg(data.message, '.js-gc-balance', 5000);
        }

        progress.hide();

        $(document).trigger('ga.applygiftcard', [_gcNo, Boolean(data && data.success)]);
    }).fail(function() {
		progress.hide();
	});
}

function showTempErrMsg(errMsg, target, time) {
	var $errorContainer = $(target);
	$errorContainer.removeClass('success').removeClass('error').addClass('error').html(errMsg).show();
    
    if (time != null) {
        setTimeout(function() { $errorContainer.fadeOut(); }, time);
    }
}

function gcEntryCheck($gcNumber, $gcPIN) {
	var gcNumber = $gcNumber.val();
	var gcPIN = $gcPIN.val();
	var errMsg = "";
	if (gcNumber.length === 0) {
		errMsg = Resources.GIFT_CERT_MISSING;
	} else if (SitePreferences.GIFT_CERT_VALIDATE_PIN && gcPIN.length === 0) {
		errMsg = Resources.GIFT_CERT_PIN_MISSING;
	}
	var entriesOk = (gcNumberCheck($gcNumber) && gcPINCheck($gcPIN));
	return { "errMsg": errMsg, "entriesOk": entriesOk };
}

function gcNumberCheck($gcNumber) {
	var gcNumberLen = $gcNumber.val().length;
	return (gcNumberLen == 0 || gcNumberLen == 16 || gcNumberLen == 19);
}
function gcPINCheck($gcPIN) {
	var gcPINLen = $gcPIN.val().length;
	return (gcPINLen == 0 || gcPINLen == 4 || gcPINLen == 6);
}

function setGiftCardErrMsg($target, type) {
	if (type == 'number') {
		if (!gcNumberCheck($target)) {
			$target.addClass('error');
			$target.closest('.js-gc-wrapper').addClass('js-gc-num-error');
		}
	} else if (type == 'pin') {
		if (!gcPINCheck($target)) {
			$target.addClass('error');
			$target.closest('.js-gc-wrapper').addClass('js-gc-pin-error');
		}
	}
}

function removeGiftCardErrMsg($target, type) {
    if (type == 'number') {
        $target.removeClass('error');
        $target.closest('.js-gc-wrapper').removeClass('js-gc-num-error');
    } else if (type == 'pin') {
        $target.removeClass('error');
        $target.closest('.js-gc-wrapper').removeClass('js-gc-pin-error');
    }   
}

function validateGiftCardFormation(event) {
    var $element = $(event.target);
    var _pattern = /\D/g;

    $element.val($element.val().replace(_pattern, ""));
}

function checkBalance() {
    var $giftCertCode = $('.js-gc-number');
    var $giftCertPIN = $('.js-gc-pin');
    var gcCheck = gcEntryCheck($giftCertCode, $giftCertPIN);
    if (!gcCheck.entriesOk) {
    	return;
    }
    if (gcCheck.errMsg != "") {
    	showTempErrMsg(gcCheck.errMsg, '.js-gc-balance', 5000);
    	return;
    }

    progress.show($(".js-gc-wrapper"));

    var url = util.appendParamsToUrl(Urls.checkGiftCardBalance, {'giftCardNumber': $giftCertCode.val(), 'giftCardPIN': $giftCertPIN.val(), 'format': 'JSON'});
    $.getJSON(url, function (data) {
        if (!data || !data.response.gotTheBalance) {
        	showTempErrMsg(SitePreferences.GIFT_CERT_VALIDATE_PIN ? Resources.GIFT_CERT_OR_PIN_INVALID : Resources.GIFT_CERT_INVALID, '.js-gc-balance', 5000);
        } else {
        	$('.js-gc-balance').html(Resources.GIFT_CERT_BALANCE + ' $' + data.response.balanceAmount).removeClass('error').addClass('success').show();
        }
        progress.hide();
    }).fail(function() {
        progress.hide();
    });
}

function initGiftcardHelpModal() {
    var $form = $('form.cob-billing'),
        $giftcardHelp = $form.find('.cob-giftcard-help-modal'),
        $giftcardFormRow = $form.find('.form-row.cob-giftcard');

    $giftcardHelp.insertAfter($giftcardFormRow.find('.field-wrapper'));

    $form.on('click', '.cob-giftcard-help-link', function(e) {
        e.preventDefault();

        var modalWidth, modalHeight, positionMy, positionAt;

        if ((util.isMobile() && window.screen.width < 768) || breakpoints.isMobile()) {
            modalWidth =  $('form.cob-billing').find('.cob-gcnumber-wrapper').innerWidth();
            modalHeight = 'auto';
            positionMy = 'right+10 top+33';
            positionAt = 'right';
        }
        
        if ((util.isMobile() && window.screen.width > 768) || breakpoints.isTablet()) {
            modalWidth = 375;
            modalHeight = 'auto';
            positionMy = 'right+20 top+33';
            positionAt = 'right';
        }

        if(breakpoints.isDesktop()){
            modalWidth = '375';
            modalHeight = 'auto';
            positionMy = 'right+33 top+33';
            positionAt = 'right';
        }

        var settings = {
            url: $(this).data('modal-content'),
            options: {
                dialogClass: 'checkout-modal giftcard-help',
                modal: true,
                draggable: false,
                width: modalWidth,
                height: modalHeight,
                open: function() {
                    $('.ui-widget-overlay').css('opacity','0');
                    $('.ui-dialog-titlebar-close').trigger('blur');
                },
                position: {
                    my: positionMy,
                    at: positionAt,
                    of: '.form-field.giftCertCode',
                    collision: "fit none"
                }
            }
        };
        dialog.open(settings);

        $(window).on('resize', function() {
            dialog.close();
        });

        return false;
    });
}

function resetGiftHelpModal() {
    $(document).ajaxComplete(function() {
        initGiftcardHelpModal();
    });
}

function clearBillingAddressForm() {
    var billingFields = $('.js-billing-form').find('input, select');
    billingFields.each(function() { // reset form
        $(this).val('').removeClass('valid').removeClass('error');
        $(this).removeAttr('aria-describedby').removeAttr('aria-invalid');
        $('.js-billing-form').find('span.error').remove();
        $('label[for$="_states_state"]').addClass('no-float');
    });
    $('select.country').val('US').trigger('change').removeClass('valid');
    if ($('.input-select.state').val() == '') {
        $('label[for$="_states_state"]').addClass('no-float');
    }
}

function bindSameAsShippingEvents() {
    var $savedAddressSelect = $('.js-saved-addresses');
    $('#billing-promo-code-gift-card .input-checkbox.sameAsShippingAddress').on('click', function() {
        if($(this).hasClass('animating')) {
            return;
        }
        if ($(this).hasClass('address-expanded')) {
            clearBillingAddressForm();
        }
        $(this).toggleClass('address-expanded');
        $(this).addClass('animating');
        setTimeout(function() {
            // Clear form if Same as Shipping checkbox is unchecked before submitting
            clearBillingAddressForm();
            $('.js-billing-form').slideToggle('fast', function() {
                if (!$('.sameAsShippingAddress').hasClass('address-expanded') && $('.sameAsShippingAddress').is(':checked')) {
                    $.get(Urls.getShippingAddress, function(data) {
                        $('input[name$="_firstName"').val(data.address.firstName);
                        $('input[name$="_lastName"').val(data.address.lastName);
                        $('input[name$="_address1"').val(data.address.address1);
                        $('input[name$="_address2"').val(data.address.address2);
                        $('input[name$="_country"').val(data.address.countryCode);
                        $('input[name$="_city"').val(data.address.city);
                        $('.state.required').val(data.address.stateCode);
                        $('label[for$="_states_state"]').removeClass('no-float');
                        $('input[name$="_postal"').val(data.address.postalCode);
                        $('input[name$="_phone"').val(data.address.phone);
                    });
                } else {
                    $('form.cob-billing').on('change', '.input-select.country', function() {
                        if ($(this).val() !== 'US' && $(this).val() !== 'CA' &&  $(this).val() !== 'AU') {
                            $('.form-row.co-states').find('.arrow-down').addClass('hide');
                            if ($('.form-field.state').val() == '') {
                                $('.input-select.state').removeClass('valid');
                                $('form.cob-billing').find('label[for$="_states_state"]').addClass('no-float');
                            }
                        } else {
                            $('.form-row.co-states').find('.arrow-down').removeClass('hide');
                            if ($('.input-select.state').val() == '') {
                                $('.input-select.state').removeClass('valid');
                                $('form.cob-billing').find('label[for$="_states_state"]').addClass('no-float');
                            }
                        }
                    });

                    // Handle saved address interaction if logged in
                    if ($savedAddressSelect.length > 0 && $savedAddressSelect.is(':visible')) {
                        $('form.cob-billing').on('change', '.js-saved-addresses', function() {
                            if ($(this).val() == '') {
                                clearBillingAddressForm();
                            }
                            if ($('.state.required').val().length > 0) {
                                $('label[for$="_states_state"]').removeClass('no-float');
                            } else {
                                $('label[for$="_states_state"]').addClass('no-float');
                            }
                        });
                    }

                    $('.state.required').on('change', function() {
                        if ($(this).val() !== '') {
                            $('label[for$="_states_state"]').removeClass('no-float');
                        } else {
                            $('label[for$="_states_state"]').addClass('no-float');
                        }
                    });
                }
            });
            $('.input-checkbox.sameAsShippingAddress').removeClass('animating');
        }, 20);
    });
}

function bindGiftCardEvents() {
    $('body')
    .on('keypress', '.js-gc-number, .js-gc-pin', function (e) {
        if (e.keyCode == 13) {
            e.preventDefault();
            checkBalance();
        }
    })
    .on('click', '.js-gc-balance-btn', function (e) {
        e.preventDefault();
        checkBalance();
    })
    .on('click', '.js-gc-apply', function (e) {
        e.preventDefault();
        applyGiftCard($(this));
    })
    .on('click', '.js-gc-remove', function (e) {
        e.preventDefault();
        removeGiftCard($(this));
    })
    .on('change', '.cob-payment-fieldset .input-radio', function () {
        if (this.value === 'AFTERPAY_PBI') {
            $('.giftcard-fieldset').addClass('hide');
        } else {
            $('.giftcard-fieldset').removeClass('hide');
        }
    })
    .on('input', '.js-gc-number, .js-gc-pin', function (e) {
        validateGiftCardFormation(e);
    })
    .on('focus', '.js-gc-number', function(e) {
        removeGiftCardErrMsg($(e.target), 'number');
    })
    .on('focus', '.js-gc-pin', function(e) {
        removeGiftCardErrMsg($(e.target), 'pin');
    })
    .on('blur', '.js-gc-number', function(e){
        setGiftCardErrMsg($(e.target), 'number');
    })
    .on('blur', '.js-gc-pin', function(e){
        setGiftCardErrMsg($(e.target), 'pin');
    })
    .on('focus', 'input', function(e) {
        if ($('.js-gc-balance').hasClass('error')) {
            $('.js-gc-balance').removeClass('error').empty();
        }
    });
}

/**
 * @function
 * @description binds check gift card button to giftcard check balance
 */
exports.init = function () {
    bindGiftCardEvents();
    toggleCreditCardForm();
    initGiftcardHelpModal();
};

exports.updateGCForm = updateGiftCardForm;
