function init(){
    if($('.plp-video').length > 0){
        const $video = $(".plp-video");
        const $wrapper = $(".plp-video-wrapper");

        $(window).on("load", () => {
            const hero = $video.length > 0 ? "video" : "image";

            if($('.plp').length > 0){
                window.dataLayer.push({
                    event: "plp-hero",
                    heroType: hero
                });
            }
        });

        playVideo($video, $wrapper);
        addVideoEventListeners($video, $wrapper);
    }
}

async function playVideo($video, $wrapper) {
    try {
        await $video[0].play();

        $wrapper.addClass("playing");
        $wrapper.removeClass("show-play");

        // Flash controls to user before playing
        $wrapper.addClass('show-pause');
        setTimeout(()=>{
            $wrapper.removeClass('show-pause');
        }, 1000);
    } catch (err) {
        $wrapper.removeClass("playing");
    }
}

function addVideoEventListeners($video, $wrapper) {
    let amountOfPlays = 0;
    const desiredAmountOfLoops = $wrapper.data('content-loops');

    $video.on("ended", () => {
        amountOfPlays++;

        if(amountOfPlays < desiredAmountOfLoops){
            playVideo($video, $wrapper);
        }else{
            handlePausedVideoUI($wrapper);
        }
    });

    $wrapper.on("click", () => {
        let action = "";

        if ($video[0].paused) {
            playVideo($video, $wrapper);
            action = "play";
        } else {
            $video[0].pause();
            handlePausedVideoUI($wrapper);
            action = "pause";
        }
        
        if($('.plp').length > 0){
            window.dataLayer.push({
                event: "plp-video-engagement",
                videoAction: action
            });
        }
    });
}

function handlePausedVideoUI($wrapper){
    $wrapper.removeClass("playing");
    $wrapper.addClass("show-play");
}

module.exports = {
    init: init
}