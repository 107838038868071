'use strict';

var dialog = require('./dialog'),
    findIndex = require('lodash/findIndex'),
    product = require('./pages/product'),
    progress = require('./progress'),
    util = require('./util'),
    breakpoints = require('./breakpoints');


// Borderfree Requirement
var bfxprice = require('./bfxprice');

var makeUrl = function (url, source, productListID) {
    if (source) {
        url = util.appendParamToURL(url, 'source', source);
    }
    if (productListID) {
        url = util.appendParamToURL(url, 'productlistid', productListID);
    }
    return url;
};

var removeParam = function (url) {
    if (url.indexOf('?') !== -1) {
        return url.substring(0, url.indexOf('?'));
    } else {
        return url;
    }
};

var quickview = {
    init: function () {
        if (!this.exists()) {
            this.$container = $('<div/>').attr('id', 'QuickViewDialog').appendTo(document.body);
        }
        this.productLinks = $('#search-result-items .thumb-link').map(function (index, thumbLink) {
            return $(thumbLink).attr('href');
        });
    },

    setup: function (qvUrl) {
        product.initQuickview();

        this.productLinkIndex = findIndex(this.productLinks, function (url) {
            return removeParam(url) === removeParam(qvUrl);
        });
    },

    /**
     * @param {Number} step - How many products away from current product to navigate to. Negative number means navigate backward
     */
    navigateQuickview: function (step) {
        // default step to 0
        this.productLinkIndex += (step ? step : 0);
        var url = makeUrl(this.productLinks[this.productLinkIndex], 'quickview');
        dialog.replace({
            url: url,
            callback: this.setup.bind(this, url)
        });
    },

    /**
     * @description show quick view dialog
     * @param {Object} options
     * @param {String} options.url - url of the product details
     * @param {String} options.source - source of the dialog to be appended to URL
     * @param {String} options.productlistid - to be appended to URL
     * @param {Boolean} options.isGiftCard - gift card will use non PDP A+ modal dimensions
     * @param {Function} options.callback - callback once the dialog is opened
     */
    show: function (options) {
        var _url;
        var source;
        if (!this.exists()) {
            this.init();
        }
        if (options.source == 'cart'){
            source = 'product-quick-view is-cart';
        } else {
            source = 'product-quick-view';
        }

        _url = makeUrl(options.url, options.source, options.productlistid);
        var analyticsuuid = (options.analyticsuuid != null) ? options.analyticsuuid : "";
        $('#QuickViewDialog').attr('data-analyticsuuid', analyticsuuid);

        var dialogDefaultOptions = {
            width: (options.width || 660),
            title: Resources.QUICK_VIEW_POPUP,
            dialogClass: source,
            autoOpen: true,
            modal: true,
            overlay: {
                opacity: 0.5,
                background: 'black'
            },
            resizable: false,
            draggable: false,
            closeText: ''
        };

        var customOptions = {};
        var minHeight = breakpoints.isDesktop() ? 400 : 200;

        customOptions = {
            dialogClass: (source + ' pdp-aplus-dialog'),
            width: 990,
            minHeight: minHeight,
        };

        var dialogOptions = $.extend(dialogDefaultOptions, customOptions);

        $('#QuickViewDialog').html('');
        var _this = this;

        $.ajax({
            url : _url,
            method : "get",
            beforeSend : function() {
                $("#QuickViewDialog").dialog(dialogOptions);
                progress.show($('#QuickViewDialog'));
            },
            success: function(data) {
                $('#QuickViewDialog').html(data);
                _this.setup(_url);

                $("#QuickViewDialog").parent().position({
                    my: "center",
                    at: "center",
                    of: window,
                    using: function (pos, ext) {
                        $(this).animate({ top: pos.top < 0 ? 10 : pos.top }, 600);
                    }
                });

                if (options.callback) {
                    options.callback(null);
                }

                // Borderfree Adjustment
                bfxprice.init();
                if (SessionAttributes && SessionAttributes.BFX_INTERNATIONAL) {
                    $('.js-pdp-aplus-add-wishlist').addClass('intl-hide');
                    $('.pdp-aplus-addcart__form').addClass('full-width');
                }

                $(window).trigger('quickview.load');
            }
        });

    },
    exists: function () {
        return this.$container && (this.$container.length > 0);
    }
};

module.exports.quickview = quickview;
