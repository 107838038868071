'use strict';

var hoverMenu = require('./hovermenu'),
compareWidget = require('./compare-widget'),
progress = require('./progress'),
refinements = require('./searchrefinements'),
util = require('./util'),
turnto = require('./turnto'),
recommenders = require('./pages/product/recommendations'),
swiper = require('./swiperSlider'),
plpVideo = require('./plpVideo');
/**
* This module is used to update the product grid and perform
* various product grid related functions. Its functions were split
* out from search.js so other modules could have access to the same
* grid updating functions
*/

function updateProductListing(options) {
	options = options || {};

	var url = options.url;
	var params = options.params || {};
	var callback = options.callback;
	var updateType = options.updateType;

	params.format = params.format || 'ajax';

	var clickedFilter = options.el;
	var newRefinementNum = $('.refinement-num').attr('data-value');
	if (clickedFilter != undefined && ((clickedFilter.hasClass('selected') && newRefinementNum > 3) || (!clickedFilter.hasClass('selected') && (newRefinementNum >= 2))) && url.indexOf('crawl') == -1) {
		if (url.indexOf('?') > -1){
			url += '&crawl=no'
		}else{
			url += '?crawl=no'
		}
	}

	$.ajax({
		type: 'GET',
		dataType: 'html',
		url: util.appendParamsToUrl(url, params),
		beforeSend: function() {
			progress.show($("#main"));
		}
	}).done(function(response) {
		var $response = $(response);
		var filteredProductsResponse = $response.filter(".plp");

		progress.hide($("#main"));

		if (updateType === 'refinements') {
			$response.find('.plp-refinements--button.filter, .plp-refinements__bar, .plp-container').addClass('expand');
		}
	
		$('.plp')
			.empty()
			.append(filteredProductsResponse);


		if($(".swiper").length > 0){
			swiper.init();
		}
		
		if($('.plp-video').length > 0){
			plpVideo.init();
		}

		handleProductListingUpdated();

		// Update new sort by PLP dropdown if exists
		if($(".plp-container").length > 0) {
			var _sortid = $('.plp-refinements__sortby').find('li.selected').data("sortid");
			if(_sortid) {
				$(".plp-refinements--button.sort").first().text($('.plp-refinements__sortby').find('li.selected').data("sortid"));
			}
		}

		if($(".js-refinement-menu li.selected").length == 0) {
			$(".pgr-clear-all").hide();
		}

		if ((newRefinementNum >= 2)) {
			$('.plp-refinements__selection--link').each(function() {
				var $this = $(this);

				if (($this.hasClass('selected') && (newRefinementNum > 4)) || (!$this.hasClass('selected') && (newRefinementNum >= 2))) {
					var _href = $this.attr("href");
					if (_href.indexOf("?") > -1) {
						$this.attr("href", _href + '&crawl=no');
					} else {
						$this.attr("href", _href + '?crawl=no');
					}

					$this.attr("rel", "nofollow");
				}
			});
			if (newRefinementNum > 2 && $("meta[name='robots']").length < 1) {
				$("head").append('<meta name="robots" content="noindex, nofollow">');
			} else if (newRefinementNum < 3) {
				$("meta[name='robots']").remove();
			}
		} else {
			if ($("meta[name='robots']").length > -1) {
				$("meta[name='robots']").remove();
			}
		}

		compareWidget.init();
		hoverMenu.init();
		refinements.hideEmptyCategoryRefinements();
		refinements.updateShowMoreInRefinements();
		recommenders.init();
		turnto.renderTileStars();

		$(document).trigger('ga.producttilesajax');

		if (location.href != url) {
			history.pushState({ 'previous_url' : location.href }, '', url);
		}

		// invoke callback
		if (callback && typeof callback === 'function') {
			callback();
		}
	}).fail(function() {
		progress.hide($('#main'));
	});
}

function updateProductGridFromFlyout(url, callback) {
	$.ajax({
		type: 'GET',
		dataType: 'html',
		url: util.appendParamToURL(url, 'format', 'ajax'),
		beforeSend: function( xhr ) {
			progress.show($(".js-product-grid"));
		}
	}).done(function (response) {

		$(".js-product-grid").empty().html(response);

		compareWidget.init();
		hoverMenu.init()

		if(location.href != url) {
			history.pushState({ 'previous_url' : location.href }, '', url);
		}

		// invoke callback
		if (callback && typeof callback === 'function') {
			callback();
		}

	}).fail(function(jqXHR, textStatus, errorThrown) {
		progress.hide($('.js-productgrid'));
	});
}

/**
 * Handle Product Listing update after the markup is
 * added to the page.
 *
 * Update the body scroll lock with new markup
 */
function handleProductListingUpdated() {
	if ($('.plp-refinements__bar').hasClass('expand')) {
		lockBodyScroll('.plp-refinements__bar');
	} else if ($('.plp-refinements__sortby').hasClass('expand')) {
		lockBodyScroll('.plp-refinements__sortby');
	} else {
		unlockBodyScroll();
		$(window).off('resize', handleProductListingUpdated);
	}
}

/**
 * Lock the screen from scrolling, but permit the selected
 * element to scroll naturally.
 * @param {String} selector jQuery selector for item to permit scrolling
 */
function lockBodyScroll(selector) {
	$(window).off('resize', handleProductListingUpdated);
	if(window.innerWidth >= 1081) {
		$(window).on('resize', handleProductListingUpdated);
		return; 
	}
	util.lockBodyScroll($(selector));
	if(!($('.plp-refinements__bar').is($(selector)) && $('.stickyFilterEnabled').length > 0)){
		$('body').css('position', 'fixed');
	}
}

/**
 * Clear all body scroll locks
 * @param {String} selector jQuery selector for item to permit scrolling
 */
function unlockBodyScroll() {
	util.unlockBodyScroll();
	$('body').css('position', '');
}

module.exports = {
	updateProductListing: updateProductListing,
	lockBodyScroll: lockBodyScroll,
	unlockBodyScroll: unlockBodyScroll,
	updateProductGridFromFlyout: updateProductGridFromFlyout
};
