'use strict';

var imagesLoaded = require('imagesloaded');
var turnto = require('../../turnto');
var quickview = require('../../quickview')
// Borderfree Requirement
var bfxprice = require('../../bfxprice');

// Variable definitions
var observer = new MutationObserver(mutationCallback);
var $einstein;

// Helper Functions
function getDataAttrValue(e, dataName) {
    var $obj = $(e.target).closest('[data-' + dataName + ']');
    if ($obj.length < 1) { 
        $obj = $(e.target).find('[data-' + dataName + ']'); 
    }
    return ($obj.length < 1) ? "" : $($obj[0]).data(dataName);
}

function mutationCallback(mutations, observer) {
    var $this = $(mutations[0].target)
    var $recommenderCarousel = $this.find('.more-to-love-recommendations__tiles');
    if ($recommenderCarousel) {
        imagesLoaded($recommenderCarousel).on('always', function () {
            initializeRecommendationSwipers($recommenderCarousel);
        });
    }
    var title = $this.find('.more-to-love-recommendations h3').first().text().replace(/\n/g, '');
    turnto.renderTileStars();
    // Borderfree Adjustment
    bfxprice.init();
    $(document).trigger('ga.viewproductslot', [null, title]);
}

function initializeRecommendationSwipers($carousel) {
    // Make sure to only initialize if there are more than 5 products
    new Swiper($carousel[0], {
        init: true,
        keyboard: {
            enabled: true,
            onlyInViewport: true
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
        },
        slidesPerView: 2.5,
        watchSlidesProgress: true,
        slidesPerGroup: 1,
        spaceBetween: 15,
        breakpointsBase: 'container',
        breakpoints: {
            // when window width is >= 600px
            600: {
                slidesPerView: 3
            },
            // when window width is >= 768px
            768: {
                slidesPerView: 4
            },
                // when window width is >= 1280px
            1280: {
                slidesPerView: 5
            }
        },
        on: {
            reachEnd: rewindSwiper,
            slideChange : updateStatus,
            slideNextTransitionStart: function() {
                var recommendationTitle = $carousel.parents('.more-to-love-recommendations').find('h3').first().text().replace(/\n/g, '');
                $(document).trigger('ga.recommenderslider', ['forward', recommendationTitle]);
            },
            slidePrevTransitionStart: function() {
                var recommendationTitle = $carousel.parents('.more-to-love-recommendations').find('h3').first().text().replace(/\n/g, '');
                $(document).trigger('ga.recommenderslider', ['backward', recommendationTitle]);
            }
        }
    });
}
/**
 * Init on DOM load
 * Setup Mutation Observer and event handlers
 */
 function initialize() {
    $('body').on("click", ".quickbuy-btn", function (e) {
        e.preventDefault();

        var $link = $(this).closest(".product__image").find('.thumb-link');
        if($link.length <= 0) {
            $link = $(this);
        }

        quickview.quickview.show({
            url : $link.attr("href"),
            source : "quickview",
            analyticsuuid : getDataAttrValue(e, 'analyticsuuid')
        });
    });

    $einstein = $('[id^=cq_recomm_slot]');
    initObserver($einstein);
}
/**
 * Setup a Mutation Observer for Einstein recommendations
 * If standard recommendations are used, initialize immediately
 */
function initObserver($einstein) {
    if ($('.more-to-love-recommendations').length > 0 || $einstein.length > 0)  {
        if ($einstein.length > 0) {
            // Create an observer for CQ Recommendations being injected into
            // slot containers with id starting with cq_recomm_slot
            $einstein.each(function(i, slot) {
                observer.observe(slot, {
                    subtree: true,
                    childList: true,
                    attributes: true
                });
            });
        } else {
            var $productCarousel = $this.find('.more-to-love-recommendations__tiles');
            initializeRecommendationSwipers($productCarousel);
            turnto.renderTileStars();
        }
    }
}

function rewindSwiper(){
    var $thisRecommendationsSwiper = $(this)[0].$el[0].swiper;
    setTimeout(function(){
        $thisRecommendationsSwiper.slideTo(0, 1000);
    }, 3000);   
}

function updateStatus(){
    var swiper = $(this)[0].$el[0].swiper;
    var text= 'Now showing slides ' + (swiper.activeIndex+1) + ' through ' + (swiper.activeIndex+swiper.params.slidesPerView);
    $('.more-to-love-recommendations .swiper').find('.swiper-notification').text(text);
}

module.exports = {
    init: initialize
};
