/**
 *    (c) 2009-2014 Demandware Inc.
 *    Subject to standard usage terms and conditions
 *    For all details and documentation:
 *    https://bitbucket.com/demandware/sitegenesis
 */

 'use strict';

 var countries = require('./countries'),
     dialog = require('./dialog'),
     quantity = require('./quantity'),
     topnav = require('./topnav'),
     minicart = require('./minicart'),
     page = require('./page'),
     newvisitor = require('./newvisitor'),
     promoDrawer = require('./promodrawer/promo-drawer'),
     searchsuggest = require('./searchsuggest'),
     tooltip = require('./tooltip'),
     util = require('./util'),
     validator = require('./validator'),
     floatingLabels = require('./floatingLabels'),
     giftcards = require('./giftcards'),
     contactus = require('./contactus'),
     overlay = require('./overlay'),
     progress = require('./progress'),
     breakpoints = require('./breakpoints'),
     tabMenu = require('./tab-menu'),
     newsletter = require('./newsletter'),
     backToTop = require('./backtotop'),
     videoEmbed = require('./vimeoEmbed'),
     videoPlayer = require('./vimeoPlayer'),
     videoPlayerSimplified = require('./vimeoPlayerSimplified'),
     plpVideo = require('./plpVideo'),
     recommendations = require('./pages/product/recommendations'),
     chat = require('./chat/chat'),
     stylitics = require('./styliticsv3'),
     turnto = require('./turnto'),
     privatesale = require('./privatesale'),
     twoUpSplit = require('./two-up-split'),
     // Borderfree Requirements
     bfxprice = require('./bfxprice'),
     signInNudge = require('./signinnudge'),
     swiperSlider = require('./swiperSlider'),
     personalShopperTestimonials = require('./personalShopperTestimonials'),
     countDownClock = require('./countDownClock'),
     loyaltyScript= require('./pages/loyaltyScript'),
     heroSwiper = require('./heroSwiper'),
     fixStickyNav = require('./stickyNav').fixStickyNav;
 
 // if jQuery has not been loaded, load from google cdn
 if (!window.jQuery) {
     var s = document.createElement('script');
     s.setAttribute('src', 'https://ajax.googleapis.com/ajax/libs/jquery/3.6.0/jquery.min.js');
     document.getElementsByTagName('head')[0].appendChild(s);
 }
 
 require('./jquery-ext')();
 require('./cookieprivacy')();
 
 var debounce = require('lodash/debounce');
 
 function initializeEvents() {
     var controlKeys = ['8', '13', '46', '45', '36', '35', '38', '37', '40', '39'];
 
     var $allParentLevel1 = $('.li-mega-1').not('.li-mega-1--logo');
     $allParentLevel1.each(function(){
         $(this).find('.view-toggle').addClass('last-link');
     });
 
     $('body')
         .on('click', '.ui-widget-overlay', function() {
             var $topDialog, highestIndex = 0;
 
             $('.ui-dialog:visible').each(function() {
                 var $this = $(this);
                 var currentIndex = parseInt($this.css('zIndex'), 10);
                 if (currentIndex > highestIndex) {
                     highestIndex = currentIndex;
                     $topDialog = $this;
                 }
             });
 
             $topDialog.find('.ui-icon-closethick').click();
         })
         .on('keydown', 'textarea[data-character-limit]', function (e) {
             var text = $(this).val(),
                 charsLimit = $(this).data('character-limit'),
                 charsUsed = text.length;
 
             if ((charsUsed >= charsLimit) && (controlKeys.indexOf(e.which.toString()) < 0)) {
                 e.preventDefault();
             }
         })
         .on('change keyup mouseup', 'textarea[data-character-limit]', function () {
             var text = $(this).val(),
                 charsLimit = $(this).data('character-limit'),
                 charsUsed = text.length,
                 charsRemain = charsLimit - charsUsed,
                 charsRemainHTML;
 
             if ($(this).hasClass('giftMessage')) {
                 charsRemainHTML = charsUsed;
             } else {
                 charsRemainHTML = charsRemain;
             }
 
             if (charsRemain < 0) {
                 $(this).val(text.slice(0, charsRemain));
                 charsRemain = 0;
             }
 
             $(this).next('div.char-count').find('.char-remain-count').html(charsRemainHTML);
         });
         
     // IE11 fire change by forcing a blur
     $('select').on('change',function(){$(this).trigger('blur')});
 
     if (navigator.appVersion.indexOf("MSIE 10") !== -1) {
         $('.arrow-down').on('click', function (e) {
 
             var element = $(e.currentTarget).parent().find('select'),
                 selectClone = element.clone();
 
             selectClone.val($(e.currentTarget).parent().find('select').val());
             selectClone.attr('id', 'expand-select');
             selectClone.removeAttr('aria-required');
             selectClone.removeClass('required');
             selectClone.removeClass('error');
             $(e.currentTarget).parent().find('select').before(selectClone);
             selectClone[0].size = selectClone.find('option').length >= 15 ? 15 : selectClone.find('option').length;
 
             $('body').on('click', '*', function (event) {
                 if ($(event.target).parents('.select-wrapper').length === 0) {
                     if ($('#expand-select')) {
                         $('#expand-select').remove();
                         $('body').off('click');
                     }
                 }
             });
 
             if (element.find('option').length > 1) {
                 $('#expand-select').on('change', function () {
                     var value = $(this).val();
                     $(e.currentTarget).parents('.form-row.error').removeClass('error');
                     $(e.currentTarget).parent().find('select').val(value);
                     $(e.currentTarget).parent().find('select').trigger('focusout');
                     if ($('#expand-select')) {
                         $('#expand-select').remove();
                         $('body').off('click');
                     }
                 });
             } else {
                 $('#expand-select').on('mousedown', function () {
                     $('#expand-select').remove();
                     $('body').off('click');
                 });
             }
         });
     }

    // Track Wunderkind event data on submit

     $('.bx-button').on('click', function(){
        window.dataLayer.push({
            'event': 'newsletter-sign-up',
            'campaignTag': 'sms-footer',
            'formInput' : "sms",
            'formType' : "subscribe-sms"
        });
     });

     // Track loyalty checkout forgot password CTA
    $('.pt-loyalty-checkout #co-password-reset').on('click', function(){
        var $parentEl = $(this).parents(':last');
        var continueButtonText = $parentEl.find('.pt-loyalty-checkout .checkout-button').text();
        continueButtonText = continueButtonText.replace('&', 'and');

        window.dataLayer.push({
            'event': 'checkout-forgot-password',
            'accountStep': 'sign in completion',
            'clickText': continueButtonText
        });
            
    });

     if($('.loyalty-signup-step1-page').length > 0){
        $('.progress-bar').on('click', '.step-2', function(e) {
            e.preventDefault();
            $('.loyalty-sign-up-button button').click();
        });
     }
     

     /**
      * Initialize Pixlee event listenter if
      * div element exists on the page
      */

     function receiveMessage(event) {
        window.dataLayer = window.dataLayer || [];

        // Parse event data and store in message variable
        if (event.data) {
            try {
                var message = JSON.parse(event.data);
            } catch (error) {
                return;
            }
        } else {
            return;
        }

        /**
         * Listen for Pixlee event type and retrieve correct
         * product data to pass into the dataLayer object
         */
        var isPDP = $('#pdpMain').length > 0;
        if (message.name && message.name === 'pixlee:open:lightbox' && isPDP) {
            $('html').addClass('noScroll');
            var $pixleeProductInfo = JSON.parse($('.swatchanchor.selected .js-swatchanchor').attr('data-analytics'));
            $pixleeProductID = $pixleeProductInfo.variationGroup;

            var $productName = JSON.parse($('.js-analytics-add-to-bag').first().text());
            $productName = $productName.product.name;
            window.dataLayer.push({
                'event': 'pixlee-gallery-click',
                'productSku': $pixleeProductID,
                'productName': $productName
            });
        } else if (message.name && message.name === 'pixlee:open:lightbox'){
            $('html').addClass('noScroll');
            window.dataLayer.push({
                'event': 'pixlee-gallery-click-landing'
            });
        } else if (message.name && message.name === 'pixlee:hide:lightbox'){
            $('html').removeClass('noScroll');
        }
        
        /**
         * Listen for Pixlee event type and retrieve product
         * data based on URL provided by the event listener
         */
        if (message.name && message.name === 'pixlee:cta:clicked') {
            // Get product id from event listener data
            var $pixleeProductURL = message.data.ctaUrl;
            $pixleeProductURL = /[^/]*$/.exec($pixleeProductURL)[0];
            var $pixleeProductID = $pixleeProductURL.split('.')[0];
            var data = $pixleeProductID;

            $.ajax({
                type: 'GET',
                url: Urls.getPixleeProductDetails,
                data: data,
                success: function (response) {
                    window.dataLayer.push({
                        'event': 'pixlee-product-click',
                        'productSku': response.productSku,
                        'productName': response.porductName
                    });
                }
            });
        }
    }

     // Intialize listener only if Pixlee is on the page
    if ($('#pixlee_container').length) {
        window.addEventListener("message", receiveMessage, false);
	}

     /**
      * initialize search suggestions, pending the value of the site preference(enhancedSearchSuggestions)
      * this will either init the legacy(false) or the beta versions(true) of the the search suggest feature.
      * */
     var $searchContainer = $('.js-header-search');
     searchsuggest.init($searchContainer, Resources.SIMPLE_SEARCH);
 
     // add show/hide navigation elements
     $('.secondary-navigation .toggle').on('click', function () {
         $(this).toggleClass('expanded').next('ul').toggle();
     });
 
     // a11y keyboard navigation for Video Player
     $(".js-video-trigger").on("focus", function() {
         $(this).on('keydown', function (e) {
             var key = e.which;
             if (key == 13) { // Enter key
                 $(this).trigger('click');
             }
         })
     });
 
     // a11y keyboard navigation for Search Bar - Open Search
     $(".js-search-trigger").on("focus", function() {
         $(this).on('keydown', function (e) {
             var key = e.which;
             if (key == 13 || key == 32 || key == 40) { // Enter Key, Space Bar, or Down Arrow
                 e.preventDefault();
                 openSearch();
                 return false;
             }
         })
     });
     // a11y keyboard navigation for Search Bar - Close Search
     $(".js-search-close").on("focus", function() {
         $(this).on('keydown', function (e) {
             var key = e.which;
             if (key == 13 || key == 32 ) { // Enter Key or Space Bar
                 e.preventDefault();
                 closeSearch();
                 return false;
             }
         })
     });
     $(".js-header-search").on("focusin", function() {
        $(this).on('keydown', function (e) {
            if (e.key === 'Escape') { // Esc Key
                e.preventDefault();
                closeSearch();
                return false;
            }
        })
     });

     function openSearch() {
         // if menu bar open, then close it first
         if ($('.js-menu-toggle').hasClass('is-active')) {
             $('.js-menu-toggle').removeClass('is-active');
             closeNav();
         }

         $('.sh-banner-right').addClass('hidden')
 
         var $headerSearch = $('.js-header-search');
         $headerSearch.addClass('is-active');
         $headerSearch.attr('aria-expanded', 'true');
         $headerSearch.find('#q').attr('aria-expanded', 'true');
         overlay.show('js-search-overlay');
 
         util.lockBodyScroll($headerSearch);
 
         $('.js-header-search-bar').trigger('blur').trigger('focus');
     }
 
     function closeSearch() {
         overlay.hide();
         $('.js-site-overlay').removeClass('js-search-overlay');
         if ($('.sh-banner-right').hasClass('hidden')) {
            $('.sh-banner-right').removeClass('hidden');
        }
         var $headerSearch = $('.js-header-search');
 
         $headerSearch.removeClass('is-active');
         $headerSearch.attr('aria-expanded', 'false');
         $headerSearch.find('#q').attr('aria-expanded', 'false');
         util.unlockBodyScroll($headerSearch);
         
         if (breakpoints.isDesktop()) {
             $('.sh-banner-wrapper--middle .js-search-trigger').trigger('focus');
         } else {
             $('.sh-promo-bar-menu .js-search-trigger').trigger('focus');
         }
     }
 
     // Open search bar
     $('.js-search-trigger').on('click', function () {
         openSearch();
         window.dataLayer.push({
            'event': 'header-search-click'
        });
     });
     // Close Search bar
     $('body').on('click', '.js-search-overlay, .js-search-close', function (e) {
         e.preventDefault();
         closeSearch();
     });
     
     // add generic toggle functionality
     $('.toggle').on('click keydown', function (e) {
        if((e.type == "keydown" && e.key == "Enter") || e.type == "click"){
            $(this).toggleClass('expanded').next('.toggle-content').toggle();
            $(this).trigger('focus');
        }
     });

     //------------------------------------------------------
     // TEST CASE B
     $('.li-mega-3.view-more, .li-mega-3.view-less').on('click', function() {
 
            var $this = $(this);
         if ($(window).innerWidth() <= 1080) {
             var headerHeight = $('.site-header').outerHeight();
             var navScrolledPos = $("#navigation").scrollTop();
             // if view more was clicked on mobile/tablet (e.g. view-less was hidden) then get position of view more link and scroll to it.
             if ($this.parent('ul').find('.li-mega-3.view-less').hasClass('hide-link')) {
                 // new pos = view more link position minus the height of header + the position of the scrolled window.
                 var thisTop = $this.offset().top;
                 var viewMorePos = thisTop - headerHeight + navScrolledPos ;
                 $("#navigation").animate({scrollTop : viewMorePos},'slow');
             } else {
                 // new pos = position of containing subcategory - header height + the position of the scrolled window.
                 var liTop = $this.parents(".li-mega-2").offset().top;
                 var viewLessPos = liTop - headerHeight + navScrolledPos ;
                 $("#navigation").animate({scrollTop : viewLessPos},'slow');
             }
         } else {
             $this.siblings('.view-more,.view-less').find('a').trigger('focus');
         }
         $this.parent('ul').find('.li-mega-3.expandable').toggleClass('hide-link').children('a').attr('data-arrownav',function(){
             if ($(this).parent().hasClass('hide-link')) {
                 return '0-0';
             } else {
                 return $(this).data('arrowval');
             }
         });
         $this.parent('ul').find('.li-mega-3.view-more').toggleClass('hide-link');
         $this.parent('ul').find('.li-mega-3.view-less').toggleClass('hide-link');
     });
     // close the window that is currently open due to hover on the li-mega-1.  This requires setting it to display none, then putting it back.
     $('.mega-drop-down .mega-nav-close-button').on('click',function() {
         var _buttonClicked = $(this);
         setTimeout(function() {
             _buttonClicked.parents('.ul-mega-1 .li-mega-1 .mega-drop-down').css("display", "none");
             _buttonClicked.parents('.ul-mega-1 .li-mega-1').removeClass('js-focus-active').trigger('blur');
         },10); // remove menu
         setTimeout(function() {_buttonClicked.parents('.ul-mega-1 .li-mega-1 .mega-drop-down').css("display", "")}, 250); // energize for next time
     });

     //close mega menu on esc while focused on elements inside menu
     $(".mega-drop-down").on("focusin", function() {
        $(this).on('keydown', function (e) {
            if (e.key === 'Escape')  { // Esc Key
                e.preventDefault();
                var _buttonClicked = $('.mega-drop-down .mega-nav-close-button');
                setTimeout(function() {
                    _buttonClicked.parents('.ul-mega-1 .li-mega-1 .mega-drop-down').css("display", "none");
                    _buttonClicked.parents('.ul-mega-1 .li-mega-1').removeClass('js-focus-active').trigger('blur');
                },10); // remove menu
                setTimeout(function() {_buttonClicked.parents('.ul-mega-1 .li-mega-1 .mega-drop-down').css("display", "")}, 250); // energize for next time
            }
        })
     });
     $(".exp-dropdown-content button").on("click", function(e) {
         e.preventDefault();
         e.stopImmediatePropagation();
 
         var _url = $(this).data('url');
         if (_url) {
             window.location.href = _url;
         }
     });
 
     // content assets throughout
     $('body').on(
         'click',
         '.category-page-hero, .content-block, .js-link',
         function(e) {
             var _url = $(this).data('url');
             if (_url) {
                 window.location.href = _url;
             }
     });
 
     $(".expandable-dropdown").attr({
         'aria-expanded':'false'
     });
 
     $(".expandable-btn").on("click", function(e) {
         e.stopPropagation(); // This prevents clicks from propagating through the button to the background image link.
         if(!$(this).parent().hasClass("selected")) {
             $(this).parent().attr('aria-expanded', 'true');
             $(this).parent().addClass("selected");
             $(this).next(".exp-dropdown-content").slideDown();
         } else {
             $(this).parent().attr('aria-expanded', 'false');
             $(this).parent().removeClass("selected");
             $(this).next(".exp-dropdown-content").hide();
         }
     });
 
     // subscribe email box
     var $subscribeEmail = $('.subscribe-email');
     if ($subscribeEmail.length > 0) {
         $subscribeEmail.on('focus', function () {
             var val = $(this.val());
             if (val.length > 0 && val !== Resources.SUBSCRIBE_EMAIL_DEFAULT) {
                 return; // do not animate when contains non-default value
             }
 
             $(this).animate({ color: '#999999' }, 500, 'linear', function () {
                 $(this).val('').css('color', '#333333');
             });
         }).on('blur', function () {
             var val = $(this.val()).trim();
             if (val.length > 0) {
                 return; // do not animate when contains value
             }
             $(this).val(Resources.SUBSCRIBE_EMAIL_DEFAULT)
                 .css('color', '#999999')
                 .animate({ color: '#333333' }, 500, 'linear');
         });
     }
 
     // Generic open modal function
     $('.js-modal-link-trigger').on('click', function (e) {
         e.preventDefault();
         dialog.open({
             url: $(e.target).attr('data-modal-target'),
             options: {
                 open: function() {
                     $('.js-modal-link-close').on('click', function() {
                         dialog.close();
                     });
                 }
             }
         });
     });
 
     // Generic open modal function for forms
     $('.js-form-modal-trigger').on('click', function (e) {
         e.preventDefault();
         dialog.open({
             url: $(e.target).attr('data-modal-target'),
             options: {
                 dialogClass: 'contact-us-modal-container',
                 open: function() {
                     $('.js-form-modal-close').on('click keydown', function(e) {
                         if (e.type === 'click' || e.keyCode === 32 || e.keyCode === 13) {
                             dialog.close();
                         }
                     });
                 }
             },
             callback: function () {
                 util.limitCharacters();
                 validator.init();
             }
         });
     });


     $('.js-personal-shopper-trigger').on('click', function (e) {
        e.preventDefault();
        dialog.open({
            url: $(e.target).attr('data-modal-target'),
            options: {
                open: function() {
                    $('.js-modal-link-close').on('click', function() {
                        dialog.close(); 
                    });
                    $('.js-personal-shopper-close').on('click', function() {
                        dialog.close(); 
                    });
                }
            }
        });
    });
 
     // eGift email change open modal function
     $('.js-egift-modal-trigger').on('click', function (e) {
         e.preventDefault();
         dialog.open({
             url: $(e.target).attr('data-modal-target'),
             options: {
                 dialogClass: 'egiftcard-email-change-modal',
                 open: function() {
                     validator.init();
                     
                     var emailValue;
                     var $modalContainer = $('.egiftcard-email-change-modal');
                     var $emailForm = $('#egiftEmailChangeForm');
                     var $successMessage = $('#recipientEmailAddressUpdated');
 
                     $modalContainer
                         .off('click').on('click', '#egiftRecipientChangeButton', function(e){
                             e.preventDefault();
 
                             if ($emailForm.valid()) {
                                 var $this = $(this);
                                 var data = $emailForm.serialize();
                                 // add form action to data
                                 data += '&' + $this.attr('name') + '=';
                                 // make sure the server knows this is an ajax request
                                 if (data.indexOf('ajax') === -1) {
                                     data += '&format=ajax';
                                 }
                                 progress.show($this);
                                 emailValue = $('#recipientEmailAddress').val();
 
                                 $.ajax({
                                     type: 'POST',
                                     url: $emailForm.attr('action'),
                                     data: data,
                                     success: function (response) {
                                         if (typeof response === 'object') {
                                             if (!response.success && response.error === 'CSRF Token Mismatch') {
                                                 page.redirect(Urls.csrffailed);
                                             } else {
                                                 $successMessage.removeClass('hidden');
                                                 $('.egift-modal-messaging').addClass('hidden');
                                                 $('.checkout-steps-egift .contact .details').html(emailValue);
                                             }
                                         } else {
                                             window.location.reload();
                                         }
                                         progress.hide();
                                     },
                                     failure: function () {
                                         progress.hide();
                                         dialog.$container.html('<h1>' + Resources.SERVER_ERROR + '</h1>');
                                     }
                                 });
                             }
 
                         });
 
                     // Prevents form submitting to improve safari user experience
                     $emailForm.on('submit', function(e){
                         e.preventDefault();
                     });
                         
                     $('.js-modal-link-close').on('click', function() {
                         dialog.close();
                     });
                 }
             }
         });
     });
 
     // Mobile Nav Open
     $('.js-menu-toggle').on('click', function (e) {
         // Slide Right (open)
         if (!$(this).hasClass('is-active')) {
             $('.hamburger').addClass('is-active');
             overlay.show('js-nav-overlay nav-overlay');
             $('body').addClass('has-menu-active');
             $('.js-site-nav').addClass('is-active').attr('aria-expanded', 'true');
             $(this).addClass('is-active');
             $('body').css('width','100%');
             $('body').ontouchmove = function (e) {
                   e.preventDefault();
             }
         // Close
         } else {
             $(this).removeClass('is-active');
             $('.hamburger').removeClass('is-active');
             closeNav();
         }
     });
     // Close window if click outside left nav in main section.
     $('#main, .site-overlay').on('click', function () {
         if ($('body').hasClass('has-menu-active')) {
             $('.js-menu-toggle').removeClass('close-btn').removeClass('is-active');
             closeNav();
         }
     });

     function toggleAttributeValue(index, attr) {
        return attr === 'true' ? 'false' : 'true';
     }
     // Tablet and Mobile, click Any Top Level Category (e.g. BABY, TODDLER, GIRLS)
     $('.a-mega-1').on('click', function(e) {
         if ($(window).innerWidth() < 1081) {
             if ($(this).parent('.li-mega-1').find('.div-mega-1-text-container').hasClass("no-menu")) {
                 // follow link that was clicked
             } else {
                 e.preventDefault();
                 $(this).parents('.ul-mega-1').toggleClass('active');
                 $(this).parents('.ul-mega-1').attr('aria-expanded',$(this).attr('aria-expanded')==='true'?'false':'true');
                 $(this).parent('.li-mega-1').find('.mega-drop-down').toggleClass('active');
                 $(this).parent('.li-mega-1').find('.mega-drop-down').attr('aria-expanded', toggleAttributeValue);
                 $(this).parent('.li-mega-1').find('.div-mega-1-text-container').toggleClass('active');
                 $(this).parent('.li-mega-1').find('.div-mega-1-text-container').attr('aria-expanded', toggleAttributeValue);
                 $(this).parent('.li-mega-1').toggleClass('active');
                 $(this).parent('.li-mega-1').attr('aria-expanded',toggleAttributeValue);
                 $('#navigation').animate({ scrollTop: 0}, 300);
             }
         }
     });
     // Tablet and Mobile, click Any Second Level Category (e.g. BABY BOY, BABY GIRL, ACCESSORIES)
     $('.div-mega-3').on('click', function(e) {
         if ($(window).innerWidth() < 1081) {
             // if section not yet expanded then bring section to top
             if (!$(this).parent('.li-mega-2').hasClass('active')) {
                $("#navigation").animate({scrollTop : $(this).position().top + $(this).parent().height()},'slow');
            }
             $(this).parent('.li-mega-2').find('.ul-mega-3').toggleClass('active');
             $(this).parent('.li-mega-2').find('.div-mega-3').toggleClass('active');
             $(this).parent('.li-mega-2').toggleClass('active');
             $(this).parent('.li-mega-2').find('.ul-mega-3').attr('aria-expanded',toggleAttributeValue);
             $(this).parent('.li-mega-2').find('.div-mega-3').attr('aria-expanded',toggleAttributeValue);
             $(this).parent('.li-mega-2').attr('aria-expanded',toggleAttributeValue);
         }
     });
 
     // Tablet and Mobile, click Any Shop By Size Category
     $('.div-shop-by-size').on('click', function(e) {
         if ($(window).innerWidth() < 1081) {
             // if section not yet expanded then bring section to top
             if (!$(this).hasClass('active')) {
                 var navScrolledPos = $("#navigation").scrollTop();
                 var subCatPos = $(this).offset().top - $('.site-header').outerHeight() + navScrolledPos;
                 $("#navigation").animate({scrollTop : subCatPos},'slow');
             }
             $(this).parent('.mega-2-right').find('.ul-sizes').toggleClass('active');
             $(this).parent('.mega-2-right').toggleClass('active');
             $(this).toggleClass('active');
         }
     });
 
     // Desktop tabbing within the mega nav, keep the nav open by
     // applying the 'js-focus-active' class to the top level li
     $('.mega-drop-down a:not(".view-toggle")').on('focus', function(e) {
         var $parentLevel1 = $(this).parents('.li-mega-1');
         $parentLevel1.addClass('js-focus-active');
         $allParentLevel1.not($parentLevel1).removeClass('js-focus-active');
     });
 
     // Creating nav control keys array which includes the space key
     var navControlKeys = controlKeys.concat(['32']);
 
     // remove focus of top nav element when its children are not in focus
     $allParentLevel1.on('focus', function (e) {
         $allParentLevel1.not($(this)).removeClass('js-focus-active');
     }).on('blur', function(e) {
         var $megaChildActive = $(document.activeElement).parents('.mega-drop-down');
         if ($megaChildActive && $megaChildActive.length) {
         } else {
             $allParentLevel1.removeClass('js-focus-active');
         }
     }).on('keydown', function(e) {
         if ((navControlKeys.indexOf(e.keyCode.toString()) > -1) && ($(this).is(':focus'))) {
             e.preventDefault();
             // toggle the mega menu open/close with space key for keyboard navigation
             if (e.keyCode == 32 || e.keyCode == 13) {
                 $(this).toggleClass('js-focus-active');
                 $(this).attr('aria-expanded') === 'false' ? $(this).attr('aria-expanded', 'true') : $(this).attr('aria-expanded', 'false');
             } else if (e.keyCode == 40) {
                 //focus on first submenu item
                 if ($('.mega-drop-down a:focus').length == 0) {
                     $(this).find('.mega-2__shop-all a').first().trigger('focus');
                 }
             } else if (e.keyCode == 13) {
                 // sends user to page if they hit enter on keyboard
                 var listItemLink = $(this).find('.a-mega-1').attr('href');
                 window.location.href = listItemLink;;
             }
         }
     });
 
     // ADA Skip Navigation Link
     $('.ada-skip-navigation').on('keydown', function(e) {
         if (navControlKeys.indexOf(e.keyCode.toString()) > -1 && $(this).is(':focus')) {
             if (e.keyCode == 32 || e.keyCode == 13) {
                 e.preventDefault();
                 var $mainContent = $('#main');
                 util.scrollBrowser($mainContent.offset().top - $('div.site-header').outerHeight());
                 $mainContent.find(':focusable').first().trigger('focus');
             }
         }
     });

     // close category nav if user presses escape
     $(document).on('keydown', function(e) {
         if (e.key === 'Escape') {
             $allParentLevel1.removeClass('js-focus-active');
         }
     });

     // create a listener for header nav
     $(".mega-drop-down").on('keydown', function(e) {
         if (e.keyCode == 37 || e.keyCode == 38 || e.keyCode == 39 || e.keyCode == 40) {
             arrowNavigation(e.keyCode, e, $(this));
         }
     });
 
     // Borderfree load events
     $(window).bind('bfx-contextChooser-loadEnd', function() {

        // Set country selector display values
         var curCountry = $('#bfx-cc-flag-link img').attr('alt');
         var aria = $('bfx-cc-flag-link').attr('aria-label');
         // Hide international elements as necessary
         $('.flag-wrapper').removeClass('pre-load-hide');
         if(curCountry !== 'United States') {
             $('.bfx-intl-hide').hide();
             $('.footer-contact-row .contactMainContainer').addClass('bfx-intl-center');
             $('.header-country-selector-wrapper').show();
             // Mark session as bfx international if not already
             if(SessionAttributes && !SessionAttributes.BFX_INTERNATIONAL) {
                 var endpointURL = Urls.attachBfxToSession + '?country=ES';
                 $.get(endpointURL, function(data) {return;});
             }
         } else {
             $('.bfx-us-hide').hide();
         }
         var countryModified = curCountry;
         countryModified = countryModified.replace(/ /g, '_').replace(/,/g, '').toLowerCase();
         var imageUrl = Urls.staticPath + 'images/flags/' + countryModified + '.png';
         $('.bfx-country-selector').attr('tabindex', 0);
         $('.bfx-country-selector .flag-image').attr('src', imageUrl).attr('alt', curCountry).attr('aria-label', aria);
         $('.bfx-country-selector .country-name').html(curCountry);
 
         $('.bfx-cc-text').html(window.Resources.BFX_MODAL_HEADER);
         $('#bfx-cc-countries-label').html(window.Resources.BFX_MODAL_COUNTRY);
         $('#bfx-cc-currencies-label').html(window.Resources.BFX_MODAL_CURRENCY);
         $('.bfx-cc-countries').after('<div class="arrow-down countries"></div>');
         $('.bfx-cc-currencies').after('<div class="arrow-down currencies"></div>');
         $('#bfx-cc-btn').prop('value', window.Resources.BFX_MODAL_SAVE);
         $('#bfx-cc-form').after('<div class="bfx-cc-cancel" tabindex="0">' + window.Resources.BFX_MODAL_CANCEL + '</div>');
         $('.bfx-cc-logo-wrapper').attr('tabindex', 0);
 
         var lastFocus;

         window.dataLayer.push({
            'event': 'sessionCountry',
            'country': $('#bfx-cc-flag-link img').attr('alt')
        });
 
         function bfxModalOpen() {
             $('#bfx-cc-flag-link img').trigger('click');
 
             // Cache last focused item before modal open
             lastFocus = document.activeElement;
 
             var $modal = $('.bfx-cc-content');
 
             // Creates array of all focuasable elements within modal
             var $focusableElements = $modal.children().filter(function() {
                 return typeof this.focus === 'function';
             });

             var firstFocusableElement = $focusableElements[0];
             var lastFocusableElement = $focusableElements[$focusableElements.length - 1];
 
             setTimeout(function() {
                firstFocusableElement.focus();
             }, 1000);
 
             // Traps keyboard focus within modal for ADA Compliance
             $modal.on('keydown', function(e) {
                 if (e.key === 'Tab' || e.keyCode === 9) {
                     if (e.shiftKey && (document.activeElement === firstFocusableElement)) {
                         lastFocusableElement.focus();
                         e.preventDefault();
                     } else if (!e.shiftKey && (document.activeElement === lastFocusableElement)) {
                         firstFocusableElement.focus();
                         e.preventDefault();
                     }
                 } else if (e.keyCode == 27) {
                     bfxModalClose();
                 }
             });
         }
 
         function bfxModalClose() {
             $('#bfx-cc-wrapper-expanded').hide();
             // Re-enable scroll
             $('body').css('overflow', 'auto');
             $('body').css('height', 'auto');
             document.removeEventListener('touchmove', preventSafariMobileScroll);
 
             // Return focus to last focused dom element on modal close
             lastFocus.focus();
         }
 
         $('.bfx-country-selector, .flag-wrapper, .flag-image').on('click keydown', function(e) {
             if (e.type === 'click' || e.keyCode === 32 || e.keyCode === 13) {
                 bfxModalOpen();
             }
         });

         $('.scp-order-summary-container, #secondary.co-summary').on('click keydown touchstart', '.need-help-country-selector', function(e) {
            if (e.type === 'click' || e.keyCode === 32 || e.keyCode === 13) {
                bfxModalOpen();
             }
         });
 
         $('.bfx-cc-logo-wrapper, .bfx-cc-cancel').on('click keydown', function(e) {
             if (e.type === 'click' || e.keyCode === 32 || e.keyCode === 13) {
                 bfxModalClose();
             }
         });

         $('#dwfrm_singleshipping_shippingAddress_addressFields_country').on('change', function() {
            if(SitePreferences.BFX_UNSUPPORTED_COUNTRIES.indexOf($(this).val()) === -1) {
                $('.input-select.state').val('').removeClass('valid');
                bfxModalOpen();
                $(this).val('US').trigger('change');
            }
        });
     });
 
     $(window).bind('bfx-welcomeMat-loadEnd', function() {
         var curCountry = $('#bfx-cc-flag-link img').attr('alt');

         if (curCountry) {
            curCountry = curCountry.replace(/ /g, '_').toLowerCase();
            var imageUrl = Urls.staticPath + 'images/flags/' + curCountry + '.png';
            $('#bfx-wm-flag').attr('src', imageUrl);
         }
         
         $('#bfx-wm-info').html('<a href="' + Urls.internationalOrders + '">Click here</a> for more information about shipping internationally.')
     });
 
     $(window).on('bfx-contextChooser-submit', util.attachBfxToSession);
     
     function trackSelCountryClick(location) {
      	var data = {
  			event: 'country-selector-click',
  			location: location,
  			anchorText: 'Country Selector'
  		};
  		window.dataLayer.push(data);
      }
      $('.mobile-menu-country-selector-wrapper').on('click', function(e) {
     	 trackSelCountryClick('utility');
      });
      $('.footer-country-selector-wrapper').on('click', function(e) {
     	 trackSelCountryClick('footer');
      });
 
     // a11y meganav keyboard controls
     function arrowNavigation(arrowKey, e, $el) {
         e.preventDefault();
         var $focusEl = $("a:focus, button:focus");
         var navLocation = $focusEl.data("arrownav").split("-");
         var navX = parseInt(navLocation[0], 10);
         var navY = parseInt(navLocation[1], 10);
 
         if (arrowKey == 40 && ($focusEl.parent().is(':last-child') || $focusEl.hasClass('last-link'))) {
             if ($el.find('.ul-sizes').length) {
                 $el.find('.ul-sizes a:first').focus();
             } else {
                 $el.find(".mega-nav-close-button").focus();
                 e.stopPropagation();
             }
         }
 
         if (arrowKey == 39 && $el.find(".a-size:last").is(":focus")) {
             $el.find(".mega-nav-close-button").focus();
         }
 
         if (arrowKey == 38 && ($(".a-size").is(":focus") || $el.find(".mega-nav-close-button").is(":focus"))) {
             $el.find('.li-mega-2:not(".hide-desktop") .li-mega-3:not(".hide-link") .a-mega-3:last').focus();
         }
 
         // up and down arrow
         if (arrowKey == 38 || arrowKey == 40) {
             var rowTarget = navY + 1;
             if (arrowKey == 38) {
                 rowTarget = navY - 1;
             }
             var currentCol = navX;
             var $target = $el.find("[data-arrownav='" + currentCol + "-" + rowTarget + "']");
             if ($target.length > 0) {
                 $target.focus();
                 return;
             }
         }
 
         // left and right arrow
         if (arrowKey == 37 || arrowKey == 39) {
             var colTarget = navX + 1;
             if (arrowKey == 37) {
                 colTarget = navX - 1;
             }
             var rowTarget = navY;
             var $nextCol = $el.find("[data-arrownav*='" + colTarget + "-']");
             if ($nextCol.length > 0) {
                 var $target = $el.find("[data-arrownav='" + colTarget + "-" + rowTarget + "']");
                 if ($target.length > 0) {
                     $target.focus();
                     return;
                 } else {
                     rowTarget = $nextCol.not("[data-arrownav$='-99']").length - 1;
                     $target = $el.find("[data-arrownav*='" + colTarget + "-" + rowTarget + "']");
                     $target.focus();
                     return;
                 }
             }
         }
     }
 
     // Close Nav on Desktop Breakpoint
     $(window).on('resize', debounce(function () {
         if (breakpoints.isDesktop()) {
            if(!$('.js-site-overlay').hasClass('loyaltyUpgradeModalOverlay')){
                closeNav();
            }
             // Footer Nav ADA enhancements 
             $('.fmn-main-nav .fmn-content')
                 .prop('hidden', false)
                 .find('a')
                 .attr('tabindex', '0');
         } else {
             $('.fmn-main-nav .js-expand-item')
                 .not('.is-active')
                 .find('.fmn-content')
                 .prop('hidden', true)
                 .find('a')
                 .attr('tabindex', '-1');
         }
     }, 300));
 
     function closeNav() {
         // enable body scrolling on iOS
         $('body').css('position','initial');
         $('body').ontouchmove = function (e) {
               return true;
         }
         $('.js-expand-toggle, .js-expand-item').removeClass('is-active');
         $('.js-site-nav').removeClass('is-active');
         $('.js-site-nav').attr('aria-expanded', 'false');
         $('body').removeClass('has-menu-active');
         $('.hamburger').removeClass('is-active');
         overlay.hide();
     }
     // Sets footer nav ada enhancements on page load
     if (!breakpoints.isDesktop()) {
         $('.fmn-main-nav .fmn-content')
             .prop('hidden', true)
             .find('a')
             .attr('tabindex', '-1');
     }
     // Accordion
     $('body').on('click', '.js-expand-toggle a', function (e) {
        e.stopPropagation();
     });
     $('body').on('click', '.js-expand-toggle', function (e) {
         e.preventDefault();
         var $parentLi = $(e.target).closest('.js-expand-item');
         var $inactiveLi = $parentLi.siblings('.js-expand-item');
 
         $inactiveLi
             .removeClass('is-active')
             .find('.js-expand-toggle, .js-expand-item')
             .attr('aria-expanded', 'false');
         $inactiveLi.find('.js-expand-toggle').removeClass('is-active');
         $inactiveLi.find('.fmn-content').prop('hidden', true);
         $inactiveLi.find('.fmn-content a').attr('tabindex', '-1');
         
         $parentLi.toggleClass('is-active');
         $(e.target)
            .toggleClass('is-active')
            .attr('aria-expanded', function(index, attr) {
                return attr == 'false' ? 'true' : 'false';
            });
         // Special handling of revinement accordion overlay
         if ($('.js-refinement-overlay').is('*')) {
             $('.js-refinement-overlay').css({ top: $('.js-refinement-menu').outerHeight() });
         }
         
         // Special handling of sitemap accordion
         var $parentList = $parentLi.closest('.sm-item');
         var $inactiveList = $parentList.siblings('.sm-item');
        
         $inactiveList
             .find('.js-expand-toggle, .js-expand-item')
             .removeClass('is-active')
             .attr('aria-expanded', 'false');

         // Special ADA hanlding for footer nav accordions
         var $accordionContent = $parentLi.find('.fmn-content');
         if ($accordionContent.length > 0 && !breakpoints.isDesktop()) {
             $inactiveLi.find('.fmn-content a').attr('tabindex', '-1');
 
             $accordionContent.prop('hidden') ? $accordionContent.prop('hidden', false).find('a').attr('tabindex', 0) : $accordionContent.prop('hidden', true).find('a').attr('tabindex', '-1');
         }
     });

     $('.user-account').on('click', function (e) {
         e.preventDefault();
         $(this).parent('.user-info').toggleClass('active');
     });
 
     $(document).on('click', '.afterpay-link a', function (e) {
         e.preventDefault();

         dialog.open({
             url: $(this).attr('href'),
             options: {
                 dialogClass: 'afterpay-modal',
                 open: function() {
                     $('.ui-widget-overlay').css('opacity','.4');
                     $('.ui-dialog-titlebar-close').focus();
                 }
             }
         });
     });

     // Sticky Header
     fixStickyNav();

     $(window).on('scroll', fixStickyNav);
 
     /*
      * Moving tabs to global, so that user can use them in a content slot
      */
     tabMenu();
 
     // enable optimized virtual keyboards for input fields in checkout/registry
     if ($('.pt_checkout').length > 0 || $('.pt_gift-registry').length > 0 || $('.ma-editaddress-form').length > 0) {
         util.changePostalFieldTypes("[name$='_postal']", ".address-box", null, null);
     }
 
     $('body').on('change', '.input-select.country', function() {
         // if the selected country is not US, change the input type from number (default) to text to support alphanumeric postal codes
         util.changePostalFieldTypes("[name$='_postal']", ".address-box", $(this), null);
     });
 
     $("#LinkCheckrOverlay").next("iframe").attr("title", "DoubleClick Tracking");
 
     // Smooth Scroll For Jump Links
     $( "body" ).on( "click", '.js-jump-link', function(e) {
         e.preventDefault();
         var selector = $(this).attr('href');
         var $target = $(selector).offset().top;
         var headerHeight = $('div.site-header').outerHeight();
         util.scrollBrowser($target - headerHeight - 25);
     });

     //  Handle password show/hide functionality
     util.showHidePassword();

     //  Dynamically positions the mobile/tablet nav and overlay
     $('.js-menu-toggle').on('click', () => {
        const $siteHeaderHeight = $('.site-header').outerHeight();

        if(window.scrollY === 0){
            // Full site header
            const regex = /[a-zA-Z]/g;
            const $siteNavPadding = $('.sh-banner-right').css('padding').replace(regex, '').split(' ');
            const $siteHeaderHeightMinusSearchBar = $siteHeaderHeight - $('.sh-middle-right-inner').outerHeight() - ($siteNavPadding[0] * 2);
            
            $('.site-nav.is-active, .js-nav-overlay').css("top", $siteHeaderHeightMinusSearchBar);
        }else{
            // Collapsed site header
            $('.site-nav.is-active, .js-nav-overlay').css("top", $siteHeaderHeight);
        }
     });

    $('.ambassador').on('click', '.primary-hero .is-button', function(e){ 
        var data;
        if((this) == $('.ambassador .is-button')[0]){
            data = {
                'event': 'ambassador-apply-now',
                'clickLocation': 'top hero'
            };
        } else if((this) == $('.ambassador .is-button')[1]){
            data = {
                'event': 'ambassador-apply-now',
                'clickLocation': 'middle hero'
            };
        }
        if(data) {
            window.dataLayer.push(data);
        }
    });

    if($('.pdpLite').length > 0){
        const fullPdpUrl = util.removeParamFromURL(document.location.href, 'pdplite');
        $( ".redirect-full-pdp" ).append('<a href=' + fullPdpUrl + ' class="full-product-url"> See Full Product Details </a>');
        $('body').on('click', '.redirect-full-pdp', function() {
            dataLayer.push({ 
                "event" : "pdp-link-click",
                "clickName" : "see full product details"
            });
        }); 
        const pdpLiteRecommenderLoading = setInterval(() => {
            if($('.pdpLiteRecommenderWrapper').length > 0){
                clearInterval(pdpLiteRecommenderLoading);
                $('.pdp-aplus__bottom').css('height', 'fit-content');
            }
        }, 100);
    }

    if($('.plp').length > 0){
        let promoBannerExist = "false"
        if($('.text-banner').length > 0){
            promoBannerExist = "true"
        }
        dataLayer.push({
            "event" : "promoBanner",
            "promoBanner" : promoBannerExist
        });
    }

}
 /**
  * @private
  * @function
  * @description Adds class ('js') to html for css targeting and loads js specific styles.
  */
 function initializeDom() {
     // add class to html for css targeting
     $('html').addClass('js');
     if (SitePreferences.LISTING_INFINITE_SCROLL) {
         $('html').addClass('infinite-scroll');
     }
     // load js specific styles
     util.limitCharacters();
     util.limitCharactersGiftMessage();
 
     // Updates data-arrownav attributes for mega nav flyout content assets. This attribute is used for
     // custom keyboard navigation events bound in initializeEvents.
     setArrowNavDataAttr('.mega-nav-banner__link', '.mega-drop-down__col', '.mega-drop-down');

     function setArrowNavDataAttr(selector, parentSelector, containerSelector) {
         var $target = $(selector);
 
         $target.each(function() {
             var $this = $(this);
             var $container = $this.closest(containerSelector);
             var $parents = $container.find(parentSelector);
             var dataArrowNav = $this.attr('data-arrownav');
 
             if (dataArrowNav) {
                 $this.attr('data-arrownav', dataArrowNav.replace('[]', $parents.length - 1));
             }
         });
     }
 
     // Facebook iframe ADA
     function waitForFbNode(params) {
 
         var fbLoaded = false;
 
         new MutationObserver(function(mutations) {
 
             for (var i = 0; i < mutations.length; i++) {
                 for (var j = 0; j < mutations[i].addedNodes.length; j++) {
                     if (mutations[i].addedNodes[j].id == "fb-root") {
                         fbLoaded = true;
                     }
                 }
             }
 
             if (fbLoaded) {
                 var el = $("#fb-root");
                 if (el) {
                     params.done(el);
                 }
             }
 
         }).observe(params.parent, {
             subtree: true,
             childList: true
         });
     }
 
     waitForFbNode({
         parent: document.body,
         done: function() {
             $("#fb-root iframe").removeAttr("style scrolling frameborder");
         }
     });
 }

 var pages = {
     account: require('./pages/account'),
     cart: require('./pages/cart'),
     checkout: require('./pages/checkout'),
     compare: require('./pages/compare'),
     orderconfirmation: require('./pages/orderconfirmation'),
     product: require('./pages/product'),
     search: require('./pages/search'),
     storefront: require('./pages/storefront'),
     wishlist: require('./pages/wishlist'),
     sitemap: require('./pages/sitemap')
 };
 
 var app = {
     init: function () {
         if (document.cookie.length === 0) {
             $('<div/>').addClass('browser-compatibility-alert').append($('<p/>').addClass('browser-error').html(Resources.COOKIES_DISABLED)).appendTo('#browser-check');
         }
         initializeDom();
         initializeEvents();

         // init specific global components
         countries.init();
         tooltip.init();
         quantity.init();
         topnav.init();
         minicart.init();
         validator.init();
         floatingLabels.init();
         signInNudge.init();
         giftcards.init();
         contactus.init();
         newvisitor.init();
         promoDrawer.init();
         newsletter.init();
         backToTop.init();
         heroSwiper.init();
         twoUpSplit.init();
         videoEmbed.init();
         videoPlayer.init();
         plpVideo.init();
         videoPlayerSimplified.init();
         chat.init(window.chatConfigs || {}, window.pageContext || {});
         swiperSlider.init();
         personalShopperTestimonials.init();
         countDownClock.init();
         //for recommendations slider to be used across the site
         recommendations.init();

         if (typeof StyliticsClassicWidget !== 'undefined') {
            stylitics.init();
         }
         
         privatesale.init();
         turnto.init();
         loyaltyScript.init();
         // Borderfree Requirements
         bfxprice.init();
 
         // Paypal
         window.sgDialog = require('./dialog');
         window.sgUtil = require('./util');
 
         // execute page specific initializations
         $.extend(page, window.pageContext);
         var ns = page.ns;
         if (ns && pages[ns] && pages[ns].init) {
             pages[ns].init();
         }
     }
 };
 
 function preventSafariMobileScroll(e) {
     e.preventDefault();
 }
 
 // general extension functions
 (function () {
     String.format = function () {
         var s = arguments[0];
         var i, len = arguments.length - 1;
         for (i = 0; i < len; i++) {
             var reg = new RegExp('\\{' + i + '\\}', 'gm');
             s = s.replace(reg, arguments[i + 1]);
         }
         return s;
     };
 })();
 
 // Rebind events/widgets here as needed after ajax calls complete
 $(document).ajaxComplete(function() {
     tooltip.init();
 });
 
 // initialize app
 $(document).ready(function () {
     app.init();
 });