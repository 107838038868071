'use strict';

var qv = require('../quickview');
var util = require('../util');

function init() {

    function addAnalyticsParams(url) {
        url = util.appendParamToURL(url, 'clicksrc', 'stylitics');
        url = util.appendParamToURL(url, 'clicklt', 'image');
        return url;
    }

    function swatchClicked(variationID) {
        stV3Instance.refresh({
            api: {
                item_number: variationID
            }
        });
    }

    //Check for PDP and init V3 widget
    var pdpMatching = $('#pdpMain .pdp-aplus--family-match');
    var pageType = window.pageContext.ns;

    if (pdpMatching.length == 0 && pageType == 'product' && $('#stylitics-widget-container').length > 0) {
        var stV3ProductID = $('#stylitics-widget-container').attr('data-sty-products');
        var stV3Instance = new StyliticsClassicWidget('hannaandersson', 'stylitics-widget-container', {
            api: {
                item_number: stV3ProductID,
                min: 3, 
                max: 3,
            },
            display: {
                disableMnM: true,
            }
        });

        stV3Instance.on("mount","bundles", function (data) { 
            // Display only if 3 tiles are available
            if (data.bundles && data.bundles.length >= 3 && !$('.is-giftcard').length) {
                $('#stylitics-sto-pdp').removeClass('stylitics-sto--hidden');
            } else {
                $('#stylitics-sto-pdp').addClass('stylitics-sto--hidden');
            }
        });

        stV3Instance.override("click", "item", function (item) {
            qv.quickview.show({
                url: addAnalyticsParams(util.appendParamToURL(window.Urls.productDetail, 'pid', item.item.remote_id)),
                source: 'quickview'
            });
            $(document).trigger('ga.shopthelook', [item.item.remote_id, item.item.name]);
        });

        stV3Instance.start();

        $(document).on('click', '.pdp-aplus-bucketed-color', function(){
                var colorData = JSON.parse($(this).find('a').attr('data-analytics'));
                var variationID = colorData.variationGroup;
                swatchClicked(variationID);
        });
    }
    
}

module.exports = { init: init };
