'use strict';

var page = require('../page'),
    login = require('../login'),
    quickview = require('../quickview'),
    accountNav = require('./accountnav'),
    util = require('../util');

exports.init = function () {
    $('#editAddress').on('change', function () {
        page.redirect(util.appendParamToURL(Urls.wishlistAddress, 'AddressID', $(this).val()));
    });

    //add js logic to remove the , from the qty feild to pass regex expression on client side
    $('.option-quantity-desired input').on('focusout', function () {
        $(this).val($(this).val().replace(',', ''));
    });

    login.init();
    accountNav.init();

    $('.js-gc-button').on('click', function (e) {
        e.preventDefault();
        quickview.show({
            url: $(this).attr('href'),
            source: 'quickview',
            callback : function() {
            	// FadeIn product content once all product logic and events have loaded.
        		$(".product-col-2").fadeIn(500);
        	}
        });
    });

    $('.mawlr-option-remove-mobile').on('click', function () {
        $('.mawlr-item-dashboard').find('.mawlr-delete').trigger('click');
    });

};
