module.exports = {
    init: function () {
        var wishListItemsJSON = localStorage.getItem('wishListItems');

        if (wishListItemsJSON) {
            var wishListItems = JSON.parse(wishListItemsJSON);
            var $addToWishlist = $('.js-pdp-aplus-add-wishlist');

            if (wishListItems.includes($addToWishlist.data('product-id'))) { 
                $('.wishlist-pdp-btn').addClass('is-active');
            }
        }
    }
}