'use strict';

var util = require('../util');

function productView(pageData) {
	if (window.MARKETINGPIXELS.enableFacebookTracking != "true") {
        return;
    }

	var trackObj = {
        content_ids: [pageData.product.id],
        content_type: pageData.product.dimension15 == '' || pageData.product.dimension15 == 'no size selected' ? 'product_group': 'product',
        value: pageData.product.price,
        currency: window.CURRENCY_CODE,
        
    };
    fbq('track', 'ViewContent', trackObj);

    if (window.enableMarketingPixelDebug == "true") {
        console.log("fbqDataObj: " + JSON.stringify(trackObj));
        console.log("Facebook Product View");
    }
}

function addToBag(addToBagData) {
    if (window.MARKETINGPIXELS.enableFacebookTracking != "true") {
        return;
    }

    var quantity = !addToBagData.quantity ? 1 : addToBagData.quantity;
    var value = addToBagData.product.price * quantity;
	var trackObj = {
        content_ids: [util.getVariationSKU(addToBagData.product.id)],
        content_type: "product",
        value: value.toFixed(2).toString(),
        currency: window.CURRENCY_CODE,
        external_id: window.MARKETINGPIXELS.facebookCustomerId
	};
    fbq('track', 'AddToCart', trackObj);

    if (window.enableMarketingPixelDebug == "true") {
        console.log("fbqDataObj: " + JSON.stringify(trackObj));
        console.log("Facebook Add To Cart");
    }
}

module.exports = {
	productView: productView,
	addToBag: addToBag
};
