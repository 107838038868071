var init = () => {
    // Create Swiper instance
    var $personalShopperCustomerTestimonialsSwipers = $('.personal-shopper-testimonials .swiper');
    $personalShopperCustomerTestimonialsSwipers.each(() => {
        new Swiper('.swiper', {
            spaceBetween: 15,
            breakpoints: {
                200: {
                    slidesPerView: 1
                },
                768: {
                    slidesPerView: 3
                }
            },
            a11y: true,
            keyboard: {
                enabled: true,
                onlyInViewport: false
            },
            navigation: {
                prevEl: '.swiper-button-prev',
                nextEl: '.swiper-button-next'
            },
            on: {
                navigationNext: pushNavClickEventToDataLayer,
                navigationPrev: pushNavClickEventToDataLayer
            }
        });
    });

    // Add .read-more-trigger to slides with lengthy text
    const $slideBodyElements = $('.personal-shopper-testimonials .swiper-slide .body');
    if($slideBodyElements.length > 0){
        $(window).on('resize load', () => {
            $slideBodyElements.each((i , slideBody) => {
                if($(slideBody).prop('clientHeight') < $(slideBody).prop('scrollHeight') && $(slideBody).parent().children('.read-more-trigger').length < 1){
                    buildAndAppendTrigger('read more', 'read-more-trigger', openReadMoreModal, $(slideBody).parent());
                }
            });
        });
    }

    function buildAndAppendTrigger(text, classes, eventCallback, $parent){
        const trigger = document.createElement('a');

        trigger.href = "javascript:void(0)";

        trigger.innerText = text;

        trigger.classList.add(classes);

        trigger.addEventListener('click', (e) => eventCallback(e));

        $parent.append($(trigger));
    }

    function openReadMoreModal(e){
        const content = $(e.target).parent().html();

        $('.read-more-modal-content').dialog({
            autoOpen: false,
            draggable: false,
            modal: true,
            resizable: false,
            width: 777,
            close: () =>  $('html').css('overflow', 'visible'), //Enable scrolling when the modal is closed
            closeText: '',
            classes: {
                "ui-dialog": "read-more-modal"
            }
        });

        $('#read-more-modal-content').html(content);

        $('#read-more-modal-content').dialog("open");

        buildAndAppendTrigger('close', 'bottom-close-trigger', closeReadMoreModal, $('#read-more-modal-content'));

        // Disable scrolling when the modal is opened
        $('html').css('overflow', 'hidden');

        window.dataLayer.push({
            'event': 'read-more-click'
        });
    }
}

function closeReadMoreModal(e){
    $('#read-more-modal-content').dialog("close");
}

function pushNavClickEventToDataLayer(){
    window.dataLayer.push({
        'event': 'carousel-click'
    });
}

module.exports = {
    init: init
}