'use strict';

/**
 * Update 'Show More' links in PLP A+ Refinement Bar
 */
function updateShowMoreInRefinements() {

	$('.plp-refinements__selection.pgr-group').each(function(){
		var $refList = $(this);
		var refItems = $refList.children().length;
		var refLimit = 10;
		if (refItems > refLimit) {
			var refMore = refItems - refLimit;
			$refList.find(':nth-child('+refLimit+')').nextAll().hide();
			$('<button class="plp-refinements__selection--toggle"><span class="show">Show <span class="count">'+refMore+'</span> More</span><span class="hide" style="display:none">Show Less</span></button>').insertAfter($refList);
		}
	});

	// hide filter refinements that aren't expanded on page load
	$('.plp-refinements__selection:not(.is-active)').hide();

	// ensure selected refinement values are visible
	$('.plp-refinements__selection--expand-values').each(function () {
		var $expandLink = $(this);
		var $refinementDropdown = $expandLink.siblings('.plp-refinements__selection');
		var $values = $refinementDropdown.find('.hideable');
		if ($values.find('.selected').length && $values.find('.hidden').length) {
			$values.toggleClass('hidden');
			$expandLink.toggleClass('expanded');
			if (!$refinementDropdown.hasClass('is-active')) {
				$refinementDropdown.show().toggleClass('is-active');
			}
		}
	});

	// Adjust vertical align if size does not have a (cm)
	$('.plp-refinements__selection.size .rf-size-box').each( function() {
		if($(this).find('.rf-size-cm').length == 0) {
			$(this).css({ 'line-height' : '35px' });
		}
	});
}

/**
 * If Category refinement list is empty, remove the section from displaying.
 */
function hideEmptyCategoryRefinements () {
    if ($('.plp-container').length > 0) {
        var $catref = $('#category-level-1');
        if ($catref && $catref.children().length == 0) {
            $catref.closest('.category-refinement').remove();
        }
    }
}

module.exports = {
    updateShowMoreInRefinements: updateShowMoreInRefinements,
    hideEmptyCategoryRefinements: hideEmptyCategoryRefinements
};
