'use strict';

var util = require('./util');

var $signInNudge = null;
var signInNudgeTriggered = false;
var STORAGE_KEY = 'haSignInNudgeShown';

function setNudgeShownInSession () {
    try {
        sessionStorage.setItem(STORAGE_KEY, 1);
    } catch (e) {
        console.error(e);
    }
}

function nudgeHasShownInSession () {
    return parseInt(sessionStorage.getItem(STORAGE_KEY));
}

function getSignInModal () {
    if (!signInNudgeTriggered || !$signInNudge) {
        return;
    }

    var $nudgeData = $signInNudge.data();

    var nudgeCategory = $signInNudge.closest('.plp-sign-in-nudge').length > 0 ?
        $signInNudge.closest('.plp-sign-in-nudge').data('cgid') :
        '';

    util.launchSignInModal(util.appendParamsToUrl(Urls.loginModal, {
        cid: $nudgeData.cid,
        cgid: nudgeCategory
    }));

    setNudgeShownInSession();
}

function isTriggered () {
    return signInNudgeTriggered;
}

function init () {
    if (nudgeHasShownInSession()) {
        return;
    }

    if (window.SessionAttributes && window.SessionAttributes.AUTHENTICATED) {
        return;
    }

    var params = util.getQueryStringParams(window.location.href);
    if (!parseInt(params.haSignInNudge)) {
        return;
    }

    $signInNudge = $('.js-sign-in-nudge');

    if ($signInNudge.length > 0 && $('#GatedPageDialog').length === 0) {
        signInNudgeTriggered = true;
        getSignInModal();
    }
    return;
}

module.exports = {
    isTriggered: isTriggered,
    getSignInModal: getSignInModal,
    init: init
};
