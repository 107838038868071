'use strict';

var progress = require('./progress');

/**
 * @function
 * @description to control adding and subtracting from the quantity input field
 */
exports.init = function () {

    $('body').on('click', '.js-plus', function () {
        var parent = $(this).parents('.js-quantity');
        var target = $(this).siblings('.form-qty-input-wrapper').children('.form-qty-input');
        var value = +target.val() + 1;
        $('.form-qty-input', parent).val(value).trigger('change');
        
        if($(this).closest(".scp-cart-row").length > 0) // Only run if clicked from cart.
        {
	        var _form = $(this).closest("form");
			if(_form) {
			    $("<input name='"+$("button[name$='_updateCart']").attr("name")+"' type='hidden'/>").appendTo(_form);
			    _form.trigger('submit');
			}
        }
        
    });

    $('body').on('click', '.js-minus', function () {
        var parent = $(this).parents('.js-quantity');
        var target = $(this).siblings('.form-qty-input-wrapper').children('.form-qty-input');
        var value = +target.val() - 1;
        if (target.val() > 1) {
            $('.form-qty-input', parent).val(value).trigger('change');
        }
        
        if($(this).closest(".scp-cart-row").length > 0) // Only run if clicked from cart.
        {
	        var _form = $(this).closest("form");
			if(_form) {
			    $("<input name='"+$("button[name$='_updateCart']").attr("name")+"' type='hidden'/>").appendTo(_form);
			    _form.trigger('submit');
			}
        }
        
    });

    // Updated cart quantity select functionality
    $('body').on('change', 'select[name="quantitySelect"], select[name="quantitySelectDesktop"]', function () {
        var parent = $(this).parents('.js-quantity');
        var target = $(this).parents('.js-quantity').find('.form-qty-input');
        var value = $(this).val();

        $(target, parent).val(value).trigger('change');

        if($(this).closest(".scp-cart-row").length > 0) // Only run if clicked from cart.
        {
	        var _form = $(this).closest("form");
			if(_form) {
			    $("<input name='"+$("button[name$='_updateCart']").attr("name")+"' type='hidden'/>").appendTo(_form);
                _form.trigger('submit');
                progress.show();
			}
        }
    });
};