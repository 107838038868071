'use strict';

function init() {
    var $backToTop = $('.js-back-to-top');
    $backToTop.on('click', function () {
        $('html, body').animate({
            scrollTop: 0
        });
    });
    $(window).on('scroll', function () {
        if ($(window).scrollTop() == 0) {
            $backToTop.removeClass('is-visible');
        } else {
            $backToTop.addClass('is-visible');
        }
    });
}

module.exports.init = init;
