'use strict';

var productgrid = require('./productgrid');

var $doc = $(document);

function init() {
    // open/close flyouts
    $('.js-flyout-filter').off('.ff').on('click.ff', toggleFlyout);
    $doc.on('click.ff', dismissFlyouts);
    $('.js-flyout-filter-close').off('.ff').on('click.ff', closeFlyout);

    // filtering
    $('.js-flyout-filter-content').on('click.ff', '.js-flyout-filter-link', handleFilter);

    // clearing active filters
    $doc.on('click.ff', '.js-flyout-filter-active-refinement', handleFilter);
}

/**
 * Open/Close a flyout based on a click event on the flyout-trigger
 * Only one flyout can be open an a time, so when one is opened,
 * all others are closed.
 * @param {Event} e event object
 */
function toggleFlyout(e) {
    e.preventDefault();
    var $trigger = $(this);
    var $flyout = $('#' + $trigger.data('flyout'));

    if ($flyout.hasClass('active')) {
        $trigger.removeClass('active');
        $flyout.removeClass('active').triggerHandler('flyoutclosed', $flyout.attr('id'));
    } else {
        $trigger.addClass('active');
        $flyout.addClass('active').triggerHandler('flyoutopened', $flyout.attr('id'));

        // close other flyouts
        // filter out event target and 
        // filter out event targets parent flyout (if nested in a flyout)
        var $otherFlyouts = $('.js-flyout-filter-content').not($flyout).not($trigger.parents('.js-flyout-filter-content'));
        $otherFlyouts.each(function(){
          var $this = $(this);
          if ($this.hasClass('active')) {
            var $trigger = $('.js-flyout-filter[data-flyout="' + $this.attr('id') + '"]');
            $trigger.removeClass('active');
            $this.removeClass('active').triggerHandler('flyoutclosed', $this.attr('id'));
          }  
        });
    }
}

/**
 * Dismiss all flyouts when a click event is registered that doesn't hit on 
 * a flyout trigger or its flyout content
 * @param {Event} e event object
 */
function dismissFlyouts(e) {
    if (!$(e.target).closest('.js-flyout-filter-content').length && !$(e.target).closest('.js-flyout-filter').length) {
        $('.js-flyout-filter-content').each(function(){
            var $this = $(this);
            if ($this.hasClass('active')){
                var $trigger = $('.js-flyout-filter[data-flyout="' + $this.attr('id') + '"]');
                $trigger.removeClass('active');
                $this.removeClass('active').triggerHandler('flyoutclosed', $this.attr('id'));
            }
        });
    }
}

/**
 * Close the currently opened flyout
 * This function is useful to bind to an element inside the flyout
 */
function closeFlyout(e) {
    var $this = $(e.target);
    var $flyout = $this.closest('.js-flyout-filter-content');
    var $trigger = $('.js-flyout-filter[data-flyout="' + $flyout.attr('id') + '"]');

    if ($flyout.hasClass('active')) {
        $trigger.removeClass('active');
        $flyout.removeClass('active').triggerHandler('flyoutclosed', $flyout.attr('id'));
    }
}

/**
 * Handle a filter flyout click event
 * Works for refining and relaxing(clearing) a refinement value
 * @param {Event} e click event on filter link
 */
function handleFilter(e) {
    e.preventDefault();
    var $link = $(this);
  
	$link.toggleClass('is-secondary').toggleClass('is-black');
	
	productgrid.updateProductGridFromFlyout($link.attr('href'), handleFilterCallback);	
}

/**
 * Callback fired from handleFilter()
 * Executes after product grid has updated
 * - update flyout filter links with new values from VM
 * - update flyout filter links classes to reflect the active status
 */
function handleFilterCallback() {
    var vm = $('.js-flyout-refinement-vm');
    
    try {
        vm = JSON.parse(vm.text());
        var $refinement = $('#' + vm.refinement.id);

        //update links for each refinement value
        vm.refinement.values.forEach(function(valueObj) {
            var $refLink = $refinement.find('#' + vm.refinement.id + '-' + valueObj.id);
            $refLink.attr('href', valueObj.url);
            if (valueObj.active) {
                $refLink.removeClass('is-secondary').addClass('is-black');
            } else {
                $refLink.removeClass('is-black').addClass('is-secondary');
            }
        });
    } catch (e) {
        console.warn(e);
    }
}

module.exports = {
    init: init
};
