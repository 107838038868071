const familyMatchingUtil = require("./familyMatchingUtil");

function safeDataLayerPush(data) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(data);
}

function getProductSetName() {
    return $('.pdp-aplus__titleblock__name').text().trim();
}

function familyProductDetailRefinementTab($element) {
    safeDataLayerPush({
        event: 'family-product-detail-refinement-tab',
        familyMatchStep: '1',
        productSetName: getProductSetName(),
        refinementCategory: $element.text().trim(),
        productSet: familyMatchingUtil.getCurrentProductSetFromPath()
    });
}

function familyProductDetailRefinementBadgeClick() {
    $('.swatch .trigger').not('.active').on('click', function() {
        const $this = $(this);
        const productSet = familyMatchingUtil.getCurrentProductSetFromPath();
        const productSetName = getProductSetName();
        const refinementCategory = $('.fm-pdp-all-tabs .tab.active').text().trim();
        const $swatchImage = $this.find('img').first();
        let refinementSelected = $swatchImage.length > 0
            ? $swatchImage.attr('alt')
            : '';

        safeDataLayerPush({
            event: 'family-product-detail-refinement-badge',
            familyMatchStep: '1',
            productSetName,
            refinementCategory,
            refinementSelected,
            productSet
        });
    });
}

function getSelectedRefinement($element) {
    const $children = $element.children();
    const refinementSelected = $children.length
        ? $children.first().text().trim()
        : $element.text().trim;

    return refinementSelected;
}

function familyProductDetailRefinement($element) {
    const productSet = familyMatchingUtil.getCurrentProductSetFromPath();
    const productSetName = getProductSetName();
    const refinementSelected = getSelectedRefinement($element);
        
    safeDataLayerPush({
        event: 'family-product-detail-refinement',
        familyMatchStep: '2',
        productSetName,
        refinementCategory: 'family member',
        refinementSelected,
        productSet
    });
}

function getProductContainer($element) {
    return $element.closest('.fm-pdp-product-tile');
}

function getProductName($element) {
    const $productContainer = getProductContainer($element);
    const $productTitle = $productContainer.find('.title');
    const productName = $productTitle.length > 0
        ? $productTitle.first().text().trim()
        : '';
    
    return productName;
}

function familyMatchProductDetails() {
    $(document).on('click', '.see-additional-details', function() {
        const $this = $(this);
        const productName = getProductName($this);
        
        safeDataLayerPush({
            event: 'family-match-product-details',
            productName,
            familyMatchStep: '3'
        });
    });
}

function getProductId($element) {
    const $productContainer = getProductContainer($element);
    const $selectedSize = $productContainer.find('.size.active');
    const productId = $selectedSize.length > 0
        ? $selectedSize.first().attr('id')
        : '';
    
    return productId;
}

function familyMatchDeselectItem($element) {
    safeDataLayerPush({
        event: 'family-match-deselect-item',
        productName: getProductName($element),
        productId: getProductId($element),
        familyMatchStep: '3',
    });
}

function getVariationGroup($element) {
    const container = getProductContainer($element);
    
    return container.length > 0
        ? container.data('product')
        : '';
}

function productDetailRefinement($element) {
    const sizeSelected = getSelectedRefinement($element);

    safeDataLayerPush({
        event: 'product-detail-refinement',
        productName: getProductName($element),
        sizeSelected,
        actionLocation: 'family-match',
        refinementCategory: 'Size',
        refinementSelected: sizeSelected,
        variationGroup: getVariationGroup($element),
        listRefinementHistory: '',
        listRefinementNumber: 1,
    })
}

function familyMatchAddMember() {
    safeDataLayerPush({
        event: 'family-add-member',
        familyMatchStep: '4',
    })
}

function init() {
    familyProductDetailRefinementBadgeClick();
    familyMatchProductDetails();
}

module.exports = {
    init,
    familyMatchAddMember,
    familyMatchDeselectItem,
    familyProductDetailRefinement,
    familyProductDetailRefinementTab,
    productDetailRefinement,
};
