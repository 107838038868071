'use strict';

var applepay = require('../../applepay'),
    breakpoints = require('../../breakpoints'),
    dialog = require('../../dialog'),
    page = require('../../page'),
    progress = require('../../progress'),
    updateSummary = require('./updateSummary'),
    giftcards = require('../../giftcards'),
    util = require('../../util');

// Returns status message for a given coupon code element.
function getStatusMessageFromPromo($el) {
    var couponStatus = $el.data('couponStatus');
    var couponCode = $el.data('couponCode');

    var msg = '';

    if (SessionAttributes.BFX_INTERNATIONAL) {
        msg = Resources['NO_APPLICABLE_PROMOTION_INTL'];
    } else if (couponStatus && Resources[couponStatus]) {
        msg = Resources[couponStatus].replace('{0}', couponCode);
    } else {
        msg = Resources['NO_APPLICABLE_PROMOTION'];
    }

    return msg;
}

function handleNotAppliedPromoLink() {
    $('.cob-coupons-applied').on('click', '.coupon-not-applied', function(e) {
        e.preventDefault();

        var $el = $(this);
        var msg = getStatusMessageFromPromo($el);

        renderAppliedCouponModal(msg, $el, true, 27);
    });
}

// Constructs display HTML of applied promo code for JSON response
function renderAppliedCouponHTML(couponStatus, couponCode, isNotApplied) {
    var _isNotApplied = isNotApplied || false;

    if (!couponStatus) {
        return false;
    } else {
        var appliedPromosCount = $('.coupon-remove-link').length;
        var appliedCouponHTML  = '<div class="cob-coupons-applied">';
            appliedCouponHTML += '<div class="cob-coupon coupon-code">';
            if (_isNotApplied) {
                appliedCouponHTML += '<a href="" class="coupon-not-applied" aria-label="' + couponCode + '">';
            }
            appliedCouponHTML += couponStatus;
            if (_isNotApplied) {
                appliedCouponHTML += '</a>';
            }
            appliedCouponHTML += '</div>';
            appliedCouponHTML += '<div class="cob-coupon coupon-remove">';
            appliedCouponHTML += '<a title="' + Resources['REMOVE'] + ' ' + couponCode + '" id="' + couponCode + '" href="' +  Urls.removeCouponCode + '?couponCode=' + couponCode + '&format=ajax" class="coupon-remove-link" aria-label="' + couponCode + '">';
            appliedCouponHTML += '<button class="button-link" value="' + Resources['REMOVE'] + '" name="dwfrm_cart_coupons_i' + appliedPromosCount + '_deleteCoupon">' + Resources['REMOVE'] + '</button>';
            appliedCouponHTML += '</a>';
            appliedCouponHTML += '</div>';
            appliedCouponHTML += '</div>';
        return appliedCouponHTML;
    }
}

// Constructs applied promo code error message dialog modal display
function renderAppliedCouponModal(couponCodeStatus, addedStatusEl, isNotApplied, top) {
    var modalWidth, modalheight, positionMy, positionAt, positionOf, isIE11, _isNotApplied, _top;

    isIE11 = util.detectIEEdge() && util.detectIEEdge() == '11';

    _isNotApplied = isNotApplied || false;
    _top = top || '';

    if ((util.isMobile() && window.screen.innerWidth < 768) || breakpoints.isMobile()) {
        modalWidth = _isNotApplied ? 280 : $('.promo-content-container').innerWidth();
        modalheight = _isNotApplied ? 125 : 80;
        positionMy = _top != "" ? "left top+"+_top : ((addedStatusEl && addedStatusEl != '') ? "left top+10" : "left top+30");
        positionAt = (addedStatusEl && addedStatusEl != '') ? "left-20" : "left";
        positionOf = (addedStatusEl && addedStatusEl != '') ? addedStatusEl : ".promo-content-container";
    } else if ((util.isMobile() && window.screen.innerWidth > 767) || breakpoints.isTablet()) {
        modalWidth = _isNotApplied ? 300 : $('form.cob-billing').find('.form-row.cob-coupon-code').innerWidth();
        modalheight = 125;
        positionMy = _top != "" ? "left top+"+_top : ((addedStatusEl && addedStatusEl != '') ? "left top+10" : "left top+30");
        positionAt = "left";
        positionOf = (addedStatusEl && addedStatusEl != '') ? addedStatusEl : ".form-row.cob-coupon-code";
    } else if (!util.isMobile() || breakpoints.isDesktop()) {
        modalWidth = _isNotApplied ? 300 : 180;
        modalheight = 125;
        positionMy = _top != "" ? "left top+"+_top : ((addedStatusEl && addedStatusEl != '') ? "left top+10" : (isIE11 ? "left top+15" : "left top+30"));
        positionAt = "left";
        positionOf = (addedStatusEl && addedStatusEl != '') ? addedStatusEl : ".form-row.cob-coupon-code";
    }

    var settings = {
        html: couponCodeStatus,
        options: {
            dialogClass: _isNotApplied ? 'checkout-modal applied-coupon-msg not-applied' : 'checkout-modal applied-coupon-msg',
            modal: true,
            draggable: false,
            width: modalWidth,
            height: modalheight,
            open: function() {
                $('.ui-widget-overlay').css('opacity','0');
                $('.ui-dialog-titlebar-close').trigger('blur');
            },
            close: function() {
                $('.form-field.couponCode').val('');
            },
            position: {
                my: positionMy,
                at: positionAt,
                of: positionOf,
                collision: "fit none",
            }
        }
    };
    dialog.open(settings);
}

// Observes Narvar DOM changes and repositions promo code message if layout changes.
function startNarvarObserver(target, callback) {
    var observer = new MutationObserver(callback);

    observer.observe(target, { childList: true, subtree: true });
}

// Responsible for displaying promo code messages for codes entered via the promo drawer.
function handlePromoDrawerCouponMsg() {
    var $promoDrawerApplications = $('.js-promo-drawer-application');

    if ($promoDrawerApplications.length > 0) {
        $('#promo-code-toggle').trigger('click');

        if ($promoDrawerApplications.length === 1) {
            var $el = $promoDrawerApplications.find('.coupon-not-applied');
            var msg = getStatusMessageFromPromo($el);

            var $narvarDynamicContent = $('.narvareddcalc');

            if ($narvarDynamicContent.length > 0 & $narvarDynamicContent.children().length === 0) {
                startNarvarObserver($narvarDynamicContent[0], renderAppliedCouponModal.bind(this, msg, $el, true, 27));
            }

            renderAppliedCouponModal(msg, $el, true, 27);
        }
    }
}

exports.init = function (checkoutStep, refreshPage) {
    var _checkoutStep = 1,
        _refreshPage = refreshPage || false,
        $addCoupon = $('#add-coupon'),
        $couponCode = $('input[name$="_couponCode"]');

    if (checkoutStep) {
        _checkoutStep = checkoutStep;
    }

    $addCoupon.on('click', function (e) {
        $('.loyalty-combine-error').remove();
        e.preventDefault();

        var _code = $couponCode.val();
        var isCartPage = ($('.cart-promo-code').length > 0) ? 'y' : 'n';
        var url = util.appendParamsToUrl(Urls.addCoupon, { couponCode: _code, format: 'ajax', cartPage: isCartPage });

        if (_code.length === 0) {
            $('.js-coupon').find(".js-coupon-error").remove();
            $('.form-field.couponCode').after('<span class="js-coupon-error error redemption">' + Resources.COUPON_CODE_MISSING + '</span>');
            setTimeout(function() { $('.js-coupon-error').fadeOut(); }, 5000);
            return;
        }

        if (!refreshPage) {
            progress.show();
        }

        $.getJSON(url, function (data) {
            $('.js-coupon').find(".js-coupon-error").remove();

            try
            {
                var _success = false;
                var couponStatus;
                if (data && data.success && data.status == "APPLIED" && data.applyWithLoyalty) {
                    updateSummary(_checkoutStep);
                    applepay.refreshApplePayRequest();

                    if(data.couponCodeStatus == "APPLIED" || ($(".redemption.new-customer-code").length == 0 && data.couponCodeStatus == "NEW_CUSTOMER_COUPON_CODE")) {
                        if (data.numOfItemsApplied && data.numOfItemsApplied > 1) {
                            couponStatus = Resources[(data.couponCodeStatus == 'APPLIED' ? 'COUPON_SUCCESSFULLY_APPLIED_MANY' : data.couponCodeStatus)].replace('{0}', _code).replace('{1}', data.numOfItemsApplied);
                        } else {
                            couponStatus = Resources[(data.couponCodeStatus == 'APPLIED' ? 'COUPON_SUCCESSFULLY_APPLIED' : data.couponCodeStatus)].replace('{0}', _code);
                        }
                        $('.promo-code-msg').before(renderAppliedCouponHTML(couponStatus, _code));
                        $('.form-field.couponCode').val('');

                        if (_refreshPage) {
                            progress.show();
                            page.refreshUrl = util.appendParamsToUrl(window.location.href, { appliedpromocode: _code });
                            page.refresh();
                            return;
                        }
                    }
                    $couponCode.val("");
                    giftcards.updateGCForm();
                    _success = true;
                } else if(data && data.success == false || (data.applyWithLoyalty == false)) {
                    if (data.applyWithLoyalty == false) {
                        $('.cob-coupon-code input').val('');
                        var billingPage = $('.billing');
                        var errorIcon = $('.voucher-wrapper').attr('data-error-icon');
                        var promoFieldContainer = $('.loyaltyMessaging');

                        if ($(billingPage).length > 0) {
                            var $voucherCombineWrapper = $('#primary');
                        } else {
                            var $voucherCombineWrapper = $('.scp-brand-voice');
                        }
                    
                        $(promoFieldContainer).html(Resources.VOUCHER_PROMO_COMBINE_ERROR);
                        $($voucherCombineWrapper).prepend('<div class="loyalty-combine-error"><div class="error-message">' + '<img src="' + errorIcon + '">' + Resources.VOUCHER_COMBINE_ERROR + '</div></div>');

                        $('html, body').animate({
                            scrollTop: $("#main").offset().top
                        }, 500);
                    } else {
                        couponStatus = Resources[(data.couponCodeStatus != 'APPLIED' ? 'COUPON_NOT_APPLIED' : data.couponCodeStatus)].replace('{0}', _code);
                        if (data.couponCodeStatus !== 'COUPON_CODE_UNKNOWN' && data.couponCodeStatus !== 'COUPON_CODE_ALREADY_IN_BASKET') {
                            var newEl = $('.promo-code-msg').before(renderAppliedCouponHTML(couponStatus, _code, true));
                            handleNotAppliedPromoLink();
                        }
                        renderAppliedCouponModal(data.message, newEl, true);
                    }
                } else {
                    renderAppliedCouponModal(data.message);
                }

                gtmAnalytics.submitApplyPromo(_code, _success);

                progress.hide();
            } catch(e) {
                progress.hide();
            }
        }).fail(function() {
            progress.hide();
        });
    });

    // Reveal "Apply" button
    $('input.couponCode').on('focusin', function() {
        $('.cob-coupon-btn#add-coupon').addClass('active');
    });

    // Trigger promo drawer
    if ($('.cart-view-promo-codes').is(':visible') && $('.cart-view-promo-codes').length > 0) {
        $('.view-promo-codes-link').on('click', function(e) {
            e.preventDefault();

            /**
             * Push data layer event for tracking
             */
            const subTotalText = $('.order-subtotal .scp-order-total').first().text() || '';
            const subTotalAmount = parseFloat(subTotalText.replace(/[^\d.]/g, ''));

            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: 'cart-engagement',
                clickText: 'view all promotions',
                subTotalAmount: Number.isNaN(subTotalAmount) ? '' : subTotalAmount.toFixed(2),
            });

            window.scrollTo(0, 0);
            setTimeout(function(){ $('.promo-drawer__toggle').trigger('click'); }, 100);
        });
    }

    // Remove promo code
    $('.promo-content-container').on('click', '.coupon-remove-link', function(e) {
        e.preventDefault();
        var thisPromoRow = $(this).closest('.cob-coupons-applied');

        progress.show();

        // handle removing coupons that couldn't be added to the basket via the Promo Drawer
        if (thisPromoRow.hasClass('js-promo-drawer-application')) {
            thisPromoRow.remove();
            progress.hide();

            return;
        }

        $.get($(this).attr('href'), function(data) {
            if (data && data.promoRemoved) {
                thisPromoRow.remove();
                if (_refreshPage) {
                    page.refresh();
                } else {
                    updateSummary(_checkoutStep);
                    giftcards.updateGCForm()
                    progress.hide();
                }
            } else {
                progress.hide();
            }
        });
    });

    handleNotAppliedPromoLink();
    handlePromoDrawerCouponMsg();
}
