'use strict';
const util = require('../../../util');

const $button = $('.js-family-matching-button');
let currentBundle = {
    items: new Map(),
    totalFullPrice: 0,
    totalSalePrice: 0
};

function updateButtonState(newState) {
    let atcButtonText = '';

    switch (newState) {
        case 'select-item':
            atcButtonText = Resources.FM_SELECT_ITEM;

            $button.text(atcButtonText);

            break;
        case 'add-to-cart':
            const totalItems = currentBundle.items.size;
            const isOnSale = currentBundle.totalFullPrice > currentBundle.totalSalePrice;

            atcButtonText = `Add ${totalItems} ${totalItems > 1 ? 'items' : 'item'} to bag`;

            $button.text(atcButtonText);

            $button.append(` <span class="total-full-price ${isOnSale ? 'on-sale' : ''}">$${(currentBundle.totalFullPrice).toFixed(2)}</span>`);

            if(isOnSale){
                $button.append(` <span class="total-sale-price">$${(currentBundle.totalSalePrice).toFixed(2)}</span>`);
            }

            break;
        default: //reset
            atcButtonText = Resources.FM_SELECT_DEPARTMENT;
            $button.text(atcButtonText);
    }      

    $button.attr('data-button-state', (newState ? newState : 'default'));
}

function updateBundle(action, $currentProductTile) {
    const productID = $currentProductTile ? $currentProductTile.find('.size.active').attr('data-afbis-sku') : undefined;

    switch (action) {
        case 'add':            
            const productFullPrice = parseFloat($currentProductTile.find('.full-price span').text());
            const productSalePrice = $currentProductTile.find('.full-price').hasClass('on-sale') ? parseFloat($currentProductTile.find('.sale-price span').text()) : productFullPrice;
        
            currentBundle.items.set(productID, {
                id: productID, 
                qty: 1,
                fullPrice: productFullPrice,
                salePrice: productSalePrice
            });

            updateBundleCost();
            break;
        case 'delete':
            delete currentBundle.items.delete(productID);

            updateBundleCost();

            if (bundleHasItems()) {
                updateButtonState('add-to-cart');
            } else {
                updateButtonState('select-item');
                $('.fm-pseudo-atc-container').hide();
            }
            break;
        default: //reset
            currentBundle = {
                items: new Map(),
                totalFullPrice: 0,
                totalSalePrice: 0
            }
    }
}

function updateBundleCost() {
    currentBundle.totalFullPrice = 0;
    currentBundle.totalSalePrice = 0;

    for ( const value of currentBundle.items.values()) {
        currentBundle.totalFullPrice += value.fullPrice;
        currentBundle.totalSalePrice += value.salePrice;
    }
}

function getCurrentProductSetFromPath() {
    const pathname = window.location.pathname || '';
    const pathPieces = pathname.split('/');
    const lastPiece = pathPieces.slice(-1)[0] || '';
    
    const filePieces = lastPiece.split('.');
    
    return filePieces[0] || '';
}

function bundleHasItems() {
    return currentBundle.items.size > 0;
}

function getCurrentBundle() {
    return currentBundle
}

function showLoadingGraphic() {
    $('.js-family-matching').addClass('loading');
}

function hideLoadingGraphic() {
    $('.js-family-matching').removeClass('loading');
}

function resetFamilyMatchPDP(){
    updateButtonState();
    updateBundle();
    $('.js-department-button').removeClass('active');
    $('.js-family-matching-button').removeClass('disabled');
    $('.product-results').hide();
    $('.recommended-products').hide();
    $('.fm-pdp-step.four').hide();
    $('.fm-pseudo-atc-container').hide();

    $('.choose-member-msg').show();
    util.scrollToElement($('.pdp-aplus__titleblock__name'));
}

function resetAfterNewSwatchSelection(){
    $('.product-results').hide();
    $('.recommended-products').hide();
    $('.fm-pdp-step.four').hide();
    $('.fm-pseudo-atc-container').hide();
    $('.exact-matches-msg').hide();

    $('.choose-member-msg').show();
}

module.exports = {
    updateButtonState: updateButtonState,
    updateBundle: updateBundle,
    bundleHasItems: bundleHasItems,
    getCurrentBundle: getCurrentBundle,
    getCurrentProductSetFromPath: getCurrentProductSetFromPath,
    showLoadingGraphic: showLoadingGraphic,
    hideLoadingGraphic: hideLoadingGraphic,
    resetFamilyMatchPDP: resetFamilyMatchPDP,
    resetAfterNewSwatchSelection: resetAfterNewSwatchSelection
}
