'use strict';

var progress = require('./progress');
var breakpoints = require('./breakpoints');
/**
 * Update a button with its success message,
 * revert back to the original state after a brief delay
 * only if resetButton is passed as a parameter
 * Requires button have a .button-text-success child element
 * @param {jQuery}  $button Button to update
 * @param {Boolean} resetButton true to reset button (default is false)
 * @param {Number} resetTime time to wait before resetting the button (default 5s)
 * @param {Boolean} leaveDisabled if true leave the button disabled after the operation is finished
 */
function updateBtnSuccess($button, resetButton, resetTime, leaveDisabled) {
    // show success message, hide standard message
    $button.addClass('success').removeClass('warning');
    $button.find('.button-text').hide();
    $button.find('.button-text-success').show();
    $button.removeClass('is-disabled');

    if (leaveDisabled) {
        $button.prop('disabled', true);
    }
    progress.hide();

    // revert button back to normal after a delay
    if (resetButton) {
        setTimeout(function() {
            $button.removeClass('success');
            $button.find('.button-text').show();
            $button.find('.button-text-success').hide();
            if( $('.pdo-price-sales.hide').length > 0){
                $('.pdo-price-sales').removeClass('hide');
            }
            if( $('.pdo-price-standard.hide').length > 0){
                $('.pdo-price-standard').removeClass('hide');
            }
        }, resetTime || 5000);
    }
}

/**
 * Update a button with its warning message
 * Requires button have a .button-text-warning child element
 * @param {jQuery}  $button Button to update
 */
function updateBtnWarning($button) {
    // show success message, hide standard message
    $button.addClass('warning').removeClass('success');
    $button.find('.button-text').hide();
    var $btnWarning = $button.find('.button-text-warning');
    var $btnMobileWarning = $button.find('.button-text-warning-mobile');
    if (!breakpoints.isDesktop() && $btnMobileWarning.length > 0) {
        $btnWarning = $btnMobileWarning;
    }
    $btnWarning.show();
    $button.attr('title', $btnWarning.text());
    $button.attr('aria-label', $btnWarning.text());
    progress.hide();
}

/**
 * Update a button when an error occurs.
 * This function just resets the button state if it was disabled
 * due to ajax/validation. It does not provide error messaging
 * @param {jQuery} $button
 */
function updateBtnError($button) {
    $button.removeClass('is-disabled');
    progress.hide();
}

/**
 * Reset a button to its initial state without succes/warning
 * @param {jQuery} $button
 */
function resetBtn($button) {
    $button.removeClass('success warning');
    $button.find('.button-text').show();
    $button.find('.button-text-success').hide();
    $button.find('.button-text-warning').hide();
}

module.exports = {
    updateBtnSuccess: updateBtnSuccess,
    updateBtnWarning: updateBtnWarning,
    updateBtnError: updateBtnError,
    resetBtn: resetBtn
};
