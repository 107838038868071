'use strict';

var validateNewsletter = require('./validateNewsletter');

var $newsForm;
var showSMS;

function getSubscribeType($btn) {
	var _type;
    if ($btn.hasClass('js-newsletter-modal-submit')) {
    	_type = 'subscribe-popup';
    } else if($btn.hasClass('mailing')) {
    	_type = 'subscribe-mailing';
    } else if($btn.hasClass('privateordersignup')) {
    	_type = 'subscribe-privateorder';
    } else if($btn.hasClass('js-subscribe-sms')) {
    	_type = 'subscribe-sms';
    } else {
    	_type = 'subscribe-footer';
    }
    return _type;
}

function showMessaging($btn, status, includeSMS) {

	var _type = getSubscribeType($btn);
	var $form = $btn.closest('.js-emarsys-newsletter-container');
	var $newUserIntro = $('.news-square-content .news-intro');
	var $newUserExists = $('.news-square-content .news-exists');
	var $newUserSMS = $('.news-square-content .news-sms'); 
	var $newUserSubscribeError = $('.news-square-content .news-error');
	var $newUserSubscribeSuccess = $('.news-square-content .news-success');
	

	// error
    if (status == "error"){
    	if (_type == 'subscribe-popup') {
			$newUserSMS.addClass('hidden');
			$newUserIntro.addClass('hidden');
			$newUserSubscribeError.removeClass('hidden');
        } else {
	        $newsForm.html("<div class='hpns-subscribe-result'>" + Resources.NEWSLETTER_ERROR + "</div>");			
		}
    	
	// pre existing
    } else if (status == "preexisting") { 
    	if (_type == 'subscribe-popup') {
			$newUserSMS.addClass('hidden');
			$newUserIntro.addClass('hidden');
			$newUserExists.removeClass('hidden');
		} else if (_type == 'subscribe-mailing') {
    		$('.mailing-subscribe .news-intro').addClass('hidden');
    		$('.mailing-subscribe .news-exists').removeClass('hidden');
		} else if (_type == 'subscribe-footer' || _type == 'subscribe-sms') {
    		$form.find('.news-intro').addClass('hidden');
    		$form.find('.news-exists').removeClass('hidden');
		}
    	
    // success
    } else if (status == "ok") {
    	if (_type == 'subscribe-popup') {
			if (includeSMS == 'true') {
				$newUserIntro.addClass('hidden js-email-completed');
				$newUserSMS.removeClass('hidden');
				$newUserSMS.find(':focusable').first().focus();
				gtmAnalytics.submitModalImpression({
					event: "newsletter-sign-up-impression", 
					formType: "subscribe-popup-sms",
				});

				if (window.MARKETINGPIXELS.enableFacebookTracking == "true") {
					fbq('track', 'CompleteRegistration');
				}
			} else {
				$newUserIntro.addClass('hidden');
				$newUserSMS.addClass('hidden');
				$newUserSubscribeSuccess.removeClass('hidden');
				
				if (window.MARKETINGPIXELS.enableFacebookTracking == "true") {
					fbq('track', 'CompleteRegistration');
				}
			}
    	} else if (_type == 'subscribe-mailing') {
    		$('.mailing-subscribe .news-intro').addClass('hidden');
    		$('.mailing-subscribe .news-success').removeClass('hidden');

			if (window.MARKETINGPIXELS.enableFacebookTracking == "true") {
				fbq('track', 'CompleteRegistration');
			}
		} else if (_type == 'subscribe-footer' || _type == 'subscribe-sms') {
			$form.find('.news-intro').addClass('hidden');
    		$form.find('.news-success').removeClass('hidden');

			if (window.MARKETINGPIXELS.enableFacebookTracking == "true") {
				fbq('track', 'CompleteRegistration');
			}
		}
    }
}

function newsletterSubscribe($currentForm) {
	var $emailAddress = $currentForm.find('.js-email');
	var $phoneNumber = $currentForm.find('.js-sms-phone');
	var $tokenValue = $currentForm.find('.tokenValue');

    // if missing email address or invalid address then do not attempt to continue
    if ($emailAddress.val() == "" && $phoneNumber.val() == "") {
    	return false;
    }
	
	var $btn = $currentForm.find('[name=newsletterAction]');
	var subscribeType = getSubscribeType($btn);
    var originTag = $btn.attr('data-origin') ? $btn.attr('data-origin') : subscribeType;
    var campaignTag = $btn.attr('data-campaigntag') ? $btn.attr('data-campaigntag') : '';
	var successUrl = $btn.attr('data-successurl') || '';
	var notifyType = $btn.attr('data-notifytype') || '';
	var email = $emailAddress.length > 0 ? $emailAddress.val() : '';
	var tokenValue = $tokenValue.val() ? $tokenValue.val() : '';
	var encryptedEmail = window.localStorage.getItem('ha-encrypted-email') != null ? window.localStorage.getItem('ha-encrypted-email') : '';
	var phone = $phoneNumber.val() ? '1' + $phoneNumber.val().replace(/\D/g, "") : "";
	var inputType = '';
	if (email != '') {
		inputType = 'email';
	} else if (phone != '') {
		inputType = 'sms';
	}

	gtmAnalytics.submitNewsletterSignUpClick({ origin: originTag, inputType: inputType, campaigntag: campaignTag });

	$currentForm.find('.ajaxLoadingHolder').addClass('ajax-loading');
	$currentForm.find('.ajaxLoadingHolder').html('');

	// Call the emarsys service to save contact
	if (email != "" || phone != "") {
		$.ajax({
			type: 'POST',
			url: EmarsysUrls.footerSubscription,
			data: {
				emailAddress: email,
				phoneNumber: phone,
				encryptedEmail: encryptedEmail,
				formatajax: true,
				subscribeType: subscribeType,
				originTag: originTag,
				campaignTag: campaignTag,
				notifyType: notifyType,
				csrf_token: tokenValue
			},
			success: function (response) {
				if (response && response.success) {
					// For signups with a success url defined - redirect user to success page instead of updating current page
					// regardless of current account status.
					if (successUrl) {
						setTimeout(function() {
							window.location.href = successUrl;
							}, 2000);
						return;
					}
					switch (response.accountStatus) {
						case 'accountexists':
							if ((subscribeType != "subscribe-footer" && subscribeType != "subscribe-popup") || ((subscribeType == "subscribe-popup") && response.accountType != "EMAIL"))  {
								showMessaging($btn, 'preexisting');
								break;
							}
						case 'accountcreated':
						case 'submitted':
							if(subscribeType == "subscribe-popup" && phone == '') {
								showMessaging($btn, 'ok', showSMS);
							} else {
								showMessaging($btn, 'ok');
							}
							break;
						default:
							showMessaging($btn, 'error');
							break;	
					}
					if (subscribeType == "subscribe-popup" && (showSMS == 'true') && response.encryptedEmail) {
						window.localStorage.setItem('ha-encrypted-email', response.encryptedEmail);
					}
				} else {
					showMessaging($btn, 'error');
				}
			},
			error: function() {
				showMessaging($btn, 'error');
			}
		});
	} else {
		window.location.href = EmarsysUrls.emarsysSignup;
	}
}

function bindEvents() {
	var validationObject = {
		'isValidEmail': false,
		'isValidSMS': false,
		'isValidForSubmit': false
	};

    $newsForm.on('submit', function (e) {
        e.preventDefault();
		var $formData = $(this);

		if (validateNewsletter.validateSubmit($formData, validationObject)) {
			newsletterSubscribe($formData);
		}
	});
	$('.js-newsletter-modal-form')
		.on('blur', '.js-newsletter-email', function(e){
			validateNewsletter.isValidEmail(e.target, validationObject, true);

			if (e.target.value < 1) {
				$(e.target).parent('.js-form-input-container').removeClass('active');
			}
		})
		.on('blur', '.js-newsletter-phone', function(e){
			validateNewsletter.isValidSMS(e.target, validationObject, true);

			if (e.target.value < 1) {
				$(e.target).parent('.js-form-input-container').removeClass('active');
			}
		})
		.on('focus', '.js-newsletter-email, .js-newsletter-phone', function(e){
			validateNewsletter.clearErrors(e.target);

			$(e.target).parent('.js-form-input-container').addClass('active');
		});

	$('#account_subscribe').on('click', function(event){
		event.preventDefault();
		$('.email-signup-wrapper').slideDown(800);
	});
	$('.newsletterSubscribeForm .js-sms-phone').on('input', function(e){
		validateNewsletter.validatePhoneNumberFormat(e.target);
	});

	// intial modal opening event
	$(document).on('newVisitorModal:open', function(){
		$('.js-newsletter-email').parent('.js-form-input-container').addClass('active');
	});
}

function init(isNewVisitorCallout) {

	// modal popup or mobile tray depending on screen width
    if (isNewVisitorCallout){
		var $modal = $('.news-content-modal');
        $newsForm = $modal.find('.js-newsletter-modal-form');
		showSMS = $modal.find('.js-newsletter-container').attr('data-newsletter-includesms');
    // footer
    } else {
        $newsForm = $('.newsletterSubscribeForm');
    }
	
    bindEvents();
}

exports.init = init;