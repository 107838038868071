'use strict';

// Add bfx price tags to "Quick Buy" modal, "Product Detail" page and PLP tiles
function addBfxMasterPricingClass() {
    $('.pdp-aplus__master-pricing').children().addClass('bfx-price');
}

exports.init = function init () {
    var items = [];

    var pageBody = document.body;
    pageBody.classList.add("bfx-price-container");

    // ------ Add BFX Price Container Tags ------ //

    addBfxClassToId('bfx-price-container', 'product-content');

    addBfxClassToId('bfx-disable-element-container', 'product-content');

    addBfxClassToId('bfx-price-container', 'mini-cart');

    addBfxClassToId('bfx-price-container', 'pdpMain');

    addBfxClassToId('bfx-price-container', 'pdpQv');

    addBfxClass('bfx-price-container', '#wrapper, pt_product-details');

    addBfxClass('bfx-price-container', '#wrapper, pt_product-search-result');

    // Disable Domestic Cart (Checkout Buttons)

    addBfxClass('bfx-disable-element-container', '.product-add-to-cart');

    addBfxClassToId('bfx-disable-element', 'add-to-cart');

    // Add bfx price tags to Search Suggestions Dropdown

    addBfxClass('bfx-price-container', '.product-details');

    addBfxClass('bfx-price-product', '.product-details');

    // ------ Add BFX Price Tags ------ //

    var productPrice = document.getElementsByClassName('product-price');
    if (productPrice.length > 0) {
        items = Array.prototype.concat.apply(items, productPrice);
    }

    // Add bfx price tags to "Product Tiles"
    var pdoPriceSalesTags = document.getElementsByClassName('pdo-price-sales');
    if (pdoPriceSalesTags.length > 0) {
        items = Array.prototype.concat.apply(items, pdoPriceSalesTags);
    }

    var pdoPriceStandardTags = document.getElementsByClassName('pdo-price-standard');
    if (pdoPriceStandardTags.length > 0) {
        items = Array.prototype.concat.apply(items, pdoPriceStandardTags);
    }	
    
    var priceSalesTags = document.getElementsByClassName('price-sales');
    if (priceSalesTags.length > 0) {
        items = Array.prototype.concat.apply(items, priceSalesTags);
    }

    addBfxPriceClassAnd('bfx-original-price', '.price-standard');
    
    addBfxMasterPricingClass();
    $(window).on('search.infinitescrollload', addBfxMasterPricingClass);

    var bucketTags = document.querySelectorAll('.pdp-aplus-bucket__label, .pdp-aplus-bucket__label > span');
    if (bucketTags.length > 0) {
        items = Array.prototype.concat.apply(items, bucketTags);
    }

    // Add bfx-price tags to "Mini Cart"
    var savingsTags = document.getElementsByClassName('scp-savings-message');
    if (savingsTags.length > 0) {
        items = Array.prototype.concat.apply(items, savingsTags);
    }

    var cpoBasePrice = document.getElementsByClassName('cpo-base-price');
    if (cpoBasePrice.length > 0) {
        items = Array.prototype.concat.apply(items, cpoBasePrice);
    }

    var miniCartPrice = document.getElementsByClassName('mini-cart-price');
    if (miniCartPrice.length > 0) {
        items = Array.prototype.concat.apply(items, miniCartPrice);
    }

    addBfxPriceClassAnd('bfx-total-subtotal', '.mini-cart-subtotals > .value');

    // Add bfx-price tags to "Mini Line Items"
    var miniCartProduct = document.getElementsByClassName('mini-cart-product');
    if (miniCartProduct.length > 0) {
        items = Array.prototype.concat.apply(items, miniCartProduct);
    }

    // Add bfx-price tags to "Cart"
    var scpPriceStandard = document.getElementsByClassName('scp-price-standard');
    if (scpPriceStandard.length > 0) {
        items = Array.prototype.concat.apply(items, scpPriceStandard);
    }

    var scpPriceSales = document.getElementsByClassName('scp-price-sales');
    if (scpPriceSales.length > 0) {
        items = Array.prototype.concat.apply(items, scpPriceSales);
    }

    var scpPriceTotal = document.getElementsByClassName('scp-price-total');
    if (scpPriceTotal.length > 0) {
        items = Array.prototype.concat.apply(items, scpPriceTotal);
    }

    var scpOrderTotal = document.getElementsByClassName('scp-order-total');
    if (scpOrderTotal.length > 0) {
        items = Array.prototype.concat.apply(items, scpOrderTotal);
    }


    for (var i = 0; i < items.length; i++) {
        if (!items[i].classList.contains('bfx-price')) {
            items[i].classList.add('bfx-price');
        }
    }

    // ------ Add BFX Price Tags to Cart for Envoy Checkout ------ //

    addBfxClass('bfx-product-image', '.scp-item-image > img');

    addBfxClass('bfx-product-name', '.scp-name-link');

    addBfxClass('bfx-sku', '.sku > .value');

    addBfxClass('bfx-product-inventory', '.is-in-stock');

    addBfxClass('bfx-product-qty', '.form-qty-input');

    addBfxClass('bfx-product', '.scp-cart-row');

    addBfxPriceClassAnd('bfx-list-price', '.scp-price-promotion > .pli-unit-price');

    addBfxClassToId('bfx-coupon-form-input', 'dwfrm_cart_couponCode');


    // Disable Elements   
    addBfxClassToId('bfx-remove-element', 'stylitics-sto-pdp');

    addBfxClass('bfx-remove-element', '.scp-savings-message');

    $('.mobile-price-display .bfx-product-subtotal').removeClass('bfx-product-subtotal').addClass('bfx-list-price');
};

function addBfxClassToId(classToAdd, target) {
    var element = document.getElementById(target);
    if (element !== null && !element.classList.contains(classToAdd)) {
        element.classList.add(classToAdd);
    }
}

function addBfxClass(classToAdd, target) {
    var element = document.querySelectorAll(target);
    if (element.length > 0) {
        for (var i=0; i < element.length; i++) {
            if (!element[i].classList.contains(classToAdd)) {
                element[i].classList.add(classToAdd);
            }
        }
    }
}

function addBfxPriceClassAnd(classToAdd, target) {
    var element = document.querySelectorAll(target);
    if (element.length > 0) {
        for (var i=0; i < element.length; i++) {
            if (!element[i].classList.contains('bfx-price')) {
                element[i].classList.add('bfx-price');
            }
            if (!element[i].classList.contains(classToAdd)) {
                element[i].classList.add(classToAdd);
            }
        }
    }
}