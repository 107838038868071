'use strict';

const fmUtil = require('./familyMatchingUtil');
const familyMatchTracking = require('./familyMatchingTracking');
const util = require('../../../util');

function init() {
    $('.fm-pdp-product-tile .size').on('click', handleProductSelect);
    $('.fm-pdp-product-tile .custom-checkbox').on('click keydown', handleCustomCheckboxClick);
    $('.fm-pdp-product-tile .title').on('click', (e) => {
        const $this = $(e.target);

        if ($this.hasClass('active')) {
            handleProductDeselect($this);
        }
    });
}

function handleCustomCheckboxClick() {
    const $this = $(this);
    const $currentProductTile = $this.closest('.fm-pdp-product-tile');

    if ($this.hasClass('active')) {
        handleProductDeselect($this);
    } else {
        updateDynamicSizingMessage($this, $currentProductTile, 'error');
        util.scrollToElement($currentProductTile.find('.image-link-container .product-tile-image'));
    }
}

function handleProductDeselect($element) {
    const $this = $element;
    familyMatchTracking.familyMatchDeselectItem($this);
    const $currentProductTile = $this.closest('.fm-pdp-product-tile');

    fmUtil.updateBundle('delete', $currentProductTile);

    updateCheckbox($currentProductTile, 'reset');

    updateDynamicSizingMessage($this, $currentProductTile);

    $currentProductTile.find('.title').removeClass('active');
    $currentProductTile.find('.size').removeClass('active');
}

function handleProductSelect() {
    const $this = $(this);
    const $currentProductTile = $this.closest('.fm-pdp-product-tile');

    if (!$this.hasClass('js-pdp-bis-btn')) {
        if ($currentProductTile.find('.size.active').length > 0) {
            fmUtil.updateBundle('delete', $currentProductTile);
        }

        $currentProductTile.find('.size').removeClass('active');

        addActiveProductStyling($this, $currentProductTile);

        fmUtil.updateBundle('add', $currentProductTile);
        familyMatchTracking.productDetailRefinement($this);

        fmUtil.updateButtonState('add-to-cart');

        if ($('.fm-pseudo-atc-container').is(':hidden')) {
            $('.fm-pseudo-atc-container').show();
        }
    }
}

function updateCheckbox($currentProductTile, state) {
    const $currentCheckbox = $currentProductTile.find('.custom-checkbox');

    if (state === 'reset') {
        $currentCheckbox.removeClass('active');
        $currentCheckbox.attr('aria-checked', 'false');
    } else {
        $currentCheckbox.addClass('active');
        $currentCheckbox.attr('aria-checked', 'true');
    }
}

function updateDynamicSizingMessage($element, $currentProductTile, newState) {
    const $dynamicMessage = $currentProductTile.find('.sizing-details .dynamic-size');
    let dynamicSizingText = '';

    switch (newState) {
        case 'error':
            dynamicSizingText = $element.attr('data-error-msg');

            $dynamicMessage.addClass('error');
            $dynamicMessage.text(dynamicSizingText);
            break;
        case 'size-selected':
            const currentButtonTopText = $element.find('.top-text').text();
            const currentButtonBottomText = $element.find('.bottom-text').text();

            if (currentButtonBottomText) {
                dynamicSizingText = `${currentButtonTopText} | ${currentButtonBottomText}`;
            } else {
                dynamicSizingText = currentButtonTopText;
            }

            $dynamicMessage.removeClass('error');
            $dynamicMessage.text(dynamicSizingText);
            break;
        default: //reset
            $currentProductTile.find('.sizing-details .dynamic-size').empty();
    }
}

function addActiveProductStyling($sizeButton, $productTile) {
    $sizeButton.addClass('active');
    $productTile.find('.title').addClass('active');

    updateCheckbox($productTile);

    updateDynamicSizingMessage($sizeButton, $productTile, 'size-selected');
}

module.exports = {
    init: init,
    addActiveProductStyling: addActiveProductStyling
}
