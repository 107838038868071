'use strict';

function doHeroChatTracking() {
	return (window.herochatFlags && window.herochatFlags.tracking && (typeof hero !== 'undefined'));
}

function productView(pageData) {
	if (!doHeroChatTracking()) { return; }
	
	var $firstMainImage = $('#pdp-aplus__images__main-1 img, #pdp-aplus__images-qv__main-1 img');
	var imgUrl = ($firstMainImage.length > 0) ? $firstMainImage.attr("src") : "";
	var trackObj = {
		type: "ecommerce:detail",
		products: [{
			id: pageData.product.id,
			name: pageData.product.name,
			image: imgUrl,
			price: isNaN(pageData.product.price) ? null : parseFloat(pageData.product.price),
			currency: window.CURRENCY_CODE
		}]
	};
	hero("track", trackObj);
}

function basketAdd() {
	//not recommended for initial launch
}

function basketRemove() {
	//not recommended for initial launch
}

function categoryView() {
	//not recommended for initial launch
}

module.exports = {
	productView: productView,
	basketAdd: basketAdd,
	basketRemove: basketRemove,
	categoryView: categoryView
};
