'use strict';

var assign = require('lodash/assign');

var ajax = require('../ajax');
var detailsDialog = require('./promo-drawer-dialog');
var pdAnalyitics = require('./promo-drawer-analytics');

var selectors = {
    BANNER: '.promo-drawer__banner',
    CARD: '.promo-drawer__card',
    CARD_DESC: '.promo-drawer__card-desc',
    CARD_IMG: '.promo-drawer__card-image',
    COLLAPSE_CONTENT: '.promo-drawer__collapse',
    COLLAPSE_SCROLLER: '.promo-drawer__card-scroller',
    MESSAGE: '.promo-drawer__message',
    PROMO_DRAWER: '.promo-drawer',
    PROMO_DRAWER_ID: '#promo-drawer',
    OVERLAY: '.js-expander-banner-overlay',
    TOGGLE: '.promo-drawer__toggle',
    VIEW_ALL: '.promo-drawer__messages-view-all'
};

var constants = {
    MSG_SCROLLER_ID: 'promo-drawer-messages-scroller',
    AUTO_EXPAND_KEY: 'promoDrawerAuto'
};

var state = {
    isCollapsing: false,
    playing: false,
    autoStart: 'not-started'
};

var $promoDrawer = $(selectors.PROMO_DRAWER_ID);
var $collapseContent = $promoDrawer.find(selectors.COLLAPSE_CONTENT);
var $collapseScroller = $promoDrawer.find(selectors.COLLAPSE_SCROLLER);
var $promoCards = $(selectors.CARD);
var $drawerToggle = $promoDrawer.find(selectors.TOGGLE);
var promoDrawerSwiper = undefined;
var promoDrawerContentSwiper = undefined;
var promoDrawerTransitionSpeed = window.SitePreferences && window.SitePreferences.PROMO_DRAWER_AUTO_SPEED ? window.SitePreferences.PROMO_DRAWER_AUTO_SPEED : 4000;

// Module State / DOM update functions
function updateState(newState) {
    state = assign({}, state, newState);
    return;
}

function updateDOM(isCollapsed) {
    $promoDrawer.attr('data-collapsed', isCollapsed.toString());
    $drawerToggle.attr('aria-expanded', isCollapsed ? 'false' : 'true');
    toggleViewAllText();
    promoDrawerOverlay();
}

// Module Helpers
function toggleViewAllText() {
    var $viewAll = $promoDrawer.find(selectors.VIEW_ALL + ' ' + selectors.MESSAGE);

    //cache the text values
    var oldText = $viewAll.html();
    var newText = $viewAll.attr('data-alt-text');

    //swap the values
    $viewAll.html(newText);
    $viewAll.attr('data-alt-text', oldText);
}

function promoDrawerOverlay() {
    var $overlay = $(selectors.OVERLAY);

    if ($overlay.length > 0) {
        $overlay.off('click');
        $('.sh-banner').css('z-index','');
        $overlay.remove();
    } else {
        $overlay = $('<div></div>');
        $overlay.addClass('js-expander-banner-overlay');
        $overlay.on('click', handleCollapse);
        $('.sh-banner').after($overlay);
        $('.sh-banner').css('z-index','1002');
    }
}

function applyPromoCB() {
    var $this = $(this);

    var promoName = pdAnalyitics.getCardPromoName($this);
    pdAnalyitics.applyCode(promoName);

    // If called from cart page, refresh to automatically apply promo code
    if (window.pageContext && window.pageContext.type === 'Cart') {
        window.location = Urls.cartShow;
        return;
    }

    var promoCard = $this.closest(selectors.CARD);
    var promoCardDesc = promoCard.find(selectors.CARD_DESC);
    var promoCardImg = promoCard.find(selectors.CARD_IMG);

    // Replace button with Applied messaging
    $this.remove();
    promoCardDesc.text(window.Resources.PD_APPLY_CHECKOUT);

    // Create overlay on card image
    promoCardImg.append(createCardImgOverlay());

    return;
}

function createCardImgOverlay() {
    var $overlay = $('<div></div>');
    $overlay.addClass('promo-drawer__card-image-overlay');
    $overlay.append('<span>' + window.Resources.PD_APPLY_OVERLAY + '</span>');
    return $overlay;
}

function removeApplyOverlay() {
    return $('.promo-drawer__card-image-overlay').remove();
}

function applyPromo() {
    var $this = $(this);
    var couponCode = $this.data('couponCode');
    $this.addClass('is-disabled');

    ajax.post({
        url: window.Urls.stageCoupon,
        data: {
            coupon: couponCode
        },
        callback: applyPromoCB.bind(this)
    })
}

// ADA helper functions
function tabController(action) {
    var tabIndex = action === 'off' ? '-1' : '0';
    $collapseContent.find('a, button').attr('tabIndex', tabIndex);
    return;
}

// Drawer Functionality
function drawerCollapse($target, callback) {
    $collapseScroller.attr('tabindex', '-1');
    $promoCards.attr('tabindex', '-1');
    
    var sectionHeight = $target[0].scrollHeight;

    // temp disable css transitions
    var cachedTransition = $target.css('transition');
    $target.css('transition', '');
    promoDrawerSwiper.autoplay.start();

    // To create the slide up effect via css, explicity set height to current height in px.
    requestAnimationFrame(function() {
        $target.css('height', sectionHeight + 'px');
        $target.css('transition', cachedTransition)

        // On next frame, set height to 0px to trigger transition effect
        requestAnimationFrame(function() {
            $target.css('height', 0 + 'px');
            updateDOM(true);
            callback();
        });
    });
}

function drawerExpand($target, callback) {
    $collapseScroller.attr('tabindex', '0');
    $promoCards.attr('tabindex', '0');

    // get the height of the element's inner content, regardless of its actual size
    var sectionHeight = $target[0].scrollHeight;

    // $target should be hidden by default with height of 0.
    // have the element transition to the height of its inner content
    $target.css('height', sectionHeight + 'px');

    updateDOM(false);
    promoDrawerSwiper.autoplay.stop();

    if($promoDrawer.data('numPromos') > 4 && !$collapseContent.hasClass('swiper') && !promoDrawerContentSwiper) {
        initPromoContentSwiper();
    }

    $target.on('transitionend', function() {
        $target.off('transitionend');
        $target.css('height', 'auto');
        callback();
    });
}

function handleCollapse(e) {
    // return if drawer is currently collapsing
    if (state.isCollapsing) {
        return;
    } else {
        updateState({ isCollapsing: true });
    }

    var isCollapsed = $promoDrawer.attr('data-collapsed');

    if (isCollapsed === 'true') {
        tabController('on');
        // if it is an actual click, not synthetic e object from autostart
        if (e.type) {
            pdAnalyitics.openDrawer();
        }
        drawerExpand($collapseContent, function() {
            updateState({ isCollapsing: false });
        });
    } else {
        drawerCollapse($collapseContent, function() {
            removeApplyOverlay();
            updateState({ isCollapsing: false });
            tabController('off');
        });
    }
}

function addKeyboardListeners($target){
    var $firstFocusableElement = $target.find($collapseScroller);
	var $lastFocusableElement = $target.find('.js-close-promo-drawer');

	$firstFocusableElement.focus();

    $target.on('keydown', function(e){
        if (e.key === 'Tab' || e.keyCode === 9) {
            if (e.shiftKey && (document.activeElement === $firstFocusableElement.get(0))) {
                e.preventDefault();
                $lastFocusableElement.focus();
            } else if (!e.shiftKey && (document.activeElement === $lastFocusableElement.get(0))) {
                e.preventDefault();
                $firstFocusableElement.focus();
            }
        } else if ((e.keyCode == 27) && ($target.attr('data-collapsed') != 'true')) {
            handleCollapse(e);
        }
    })
}

function initPromosSwiper(){
    promoDrawerSwiper = new Swiper('#' + constants.MSG_SCROLLER_ID, {
        autoplay: {
            delay: promoDrawerTransitionSpeed,
        },
        direction: 'vertical',
        loop: true,
        allowTouchMove: false,
        centeredSlides: true
    });
}

function initPromoContentSwiper(){
    $promoCards.addClass('swiper-slide');
    $collapseScroller.addClass('swiper-wrapper');
    $collapseContent.addClass('swiper');
    promoDrawerContentSwiper = new Swiper(selectors.COLLAPSE_CONTENT, {});
}

// Module inititialization
function init() {
    // exit early if promo drawer isn't on page, or is hidden
    if ($promoDrawer.length === 0 || $promoDrawer.css('display') === 'none') {
        $('#navigation').addClass('no-promo');
        return;
    }

    // Set Event Handlers
    $promoDrawer.on('click', '.promo-drawer__banner, .promo-drawer__messages, .promo-drawer__toggle, .js-close-promo-drawer', handleCollapse);
    $promoDrawer.on('click', '.js-pd-apply-code', applyPromo);
    $promoDrawer.on('click', '.js-promo-details-link', detailsDialog.open);

    // Analytics Event Handlers
    $promoDrawer.find(selectors.CARD).on('click', 'a', function() {
        var promoName = pdAnalyitics.getCardPromoName($(this));
        pdAnalyitics.promoCardClick(promoName);
    });
    
    var $viewAllPromosLink = $('.view-promo-codes-link');
    
    if ($viewAllPromosLink.length > 0) {
        $viewAllPromosLink.removeClass('hide');
    }

    tabController('off');
    initPromosSwiper();
    addKeyboardListeners($promoDrawer);
    $('.promo-drawer__messages').removeClass('hide-tablet');
}

module.exports = {
    init: init
}
