'use strict';

var ajax = require('../../ajax'),
    formPrepare = require('./formPrepare'),
    internationalForms = require('../../internationalFields'),
    progress = require('../../progress'),
    masking = require('../../masking-input'),
    util = require('../../util'),
    promoCode = require('./promoCode'),
    loyaltyRewards = require('./loyaltyRewards'),
    applepay = require('../../applepay');

var $savedAddressSelect = $('.js-saved-addresses');

/**
 * @function
 * @description Fills the Credit Card form with the passed data-parameter and clears the former cvn input
 * @param {Object} data The Credit Card data (holder, type, masked number, expiration month/year)
 */
function setCCFields(data) {
    var $creditCard = $('[data-method="CREDIT_CARD"]');
    $creditCard.find('input[name$="creditCard_owner"]').val(data.holder).trigger('change');
    $creditCard.find('select[name$="_type"]').val(data.type).trigger('change');
    $creditCard.find('input[name*="_creditCard_number"]').val(data.maskedNumber).trigger('change');
    $creditCard.find('[name$="_month"]').val(data.expirationMonth).trigger('change');
    $creditCard.find('[name$="_year"]').val(data.expirationYear).trigger('change');
    $creditCard.find('input[name$="_cvn"]').val('').trigger('change');
}

/**
 * @function
 * @description Updates the credit card form with the attributes of a given card
 * @param {String} cardID the credit card ID of a given card
 */
function populateCreditCardForm(cardID) {
    // load card details
    var url = util.appendParamToURL(Urls.billingSelectCC, 'creditCardUUID', cardID);
    ajax.getJson({
        url: url,
        callback: function (data) {
            if (!data) {
                window.alert(Resources.CC_LOAD_ERROR);
                return false;
            }
            setCCFields(data);
        }
    });
}

/**
 * @function
 * @description Changes the payment method form depending on the passed paymentMethodID
 * @param {String} paymentMethodID the ID of the payment method, to which the payment method form should be changed to
 */
function updatePaymentMethod(paymentMethodID) {
    var $paymentMethods = $('.payment-method');
    $paymentMethods.removeClass('payment-method-expanded');

    var $selectedPaymentMethod = $paymentMethods.filter('[data-method="' + paymentMethodID + '"]');
    if ($selectedPaymentMethod.length === 0) {
        $selectedPaymentMethod = $('[data-method="Custom"]');
    }
    $selectedPaymentMethod.addClass('payment-method-expanded');

    // some payment methods provide their own continue button so we hide the
    // main continue button
    var $continueButtton = $('.cob-continue');
    $continueButtton.removeClass('hidden');
    if ($selectedPaymentMethod.data('hide-continue')) {
        $continueButtton.addClass('hidden');
    }

    formPrepare.validateForm();
    if($('.cob-cc-number .error-message').length > 0) {
        $('.cob-cc-number span.error').remove();
        $('.cob-cc-number').addClass('extended-bot-margin');
        $('.cob-expiry-cvn-group .form-row').addClass('extended-bot-margin');
    }

    var paypalEmail = $('.paypal-email').text();
    var placeOrderBtn = $('button[name="dwfrm_billing_save"]');
    if(paymentMethodID == "PayPal" ) {
        if (paypalEmail.length > 0) {
            placeOrderBtn.show();
            placeOrderBtn.attr('disabled', false);
        }
        else {
            placeOrderBtn.hide();
        }
        if ( window.paymentElementInstance) {
            window.paymentElementInstance.collapse();
        }
    }
}

function bindEvents() {
    var $selectPaymentMethod = $('.payment-method-options');
    var $addCoupon = $('#add-coupon');
    var $couponCode = $('input[name$="_couponCode"]');

    $selectPaymentMethod.on('click', 'input[type="radio"]', function () {
        updatePaymentMethod($(this).val());
    });

    if($('.step-row').length < 4) {
        $('.paypal-already-handled-account .co-checkbox input').trigger('click');
        $('.co-submit-btn-container').show();
    }

    // select credit card from list
    $('#creditCardList').on('change', function () {
        var cardUUID = $(this).val();
        if (!cardUUID) { return; }
        populateCreditCardForm(cardUUID);

        // remove server side error
        $('.required.error').removeClass('error');
        $('.error-message').remove();
    });

    // Checkout Sign In / Forgot Password Modals
    $('.co-guest-signin-link').on('click', function(e) {
        e.preventDefault();

        util.launchSignInModal(this.href);
    });

    // trigger events on enter
    $couponCode
        .on('keydown', function (e) {
            if (e.which === 13) {
                e.preventDefault();
                $addCoupon.trigger('click');
            }
        })
        .on('input', function () {
            var couponValue = $couponCode.val();
            $couponCode.val(couponValue.toUpperCase());
        })

    $('.cpo-place-order button[type="Submit"], .cob-continue button[name$="billing_save"]').on('click', function (e) {
    	if($(this).closest("form").valid()) {
        	$(this).addClass("is-disabled");
        	progress.show($(this));
    	}
    });

    loyaltyRewards.init();

    $('.input-checkbox.sameAsShippingAddress').on('click', function() {
        if($(this).hasClass('animating')) {
            return;
        }
        if ($(this).hasClass('address-expanded')) {
            clearBillingAddressForm();
        }
        $(this).toggleClass('address-expanded');
        $(this).addClass('animating');
        setTimeout(function() {
            // Clear form if Same as Shipping checkbox is unchecked before submitting
            clearBillingAddressForm();
            $('.cc-shipping-address').slideToggle('fast');
            $('.js-billing-form').slideToggle('fast', function() {
                if (!$('.sameAsShippingAddress').hasClass('address-expanded') && $('.sameAsShippingAddress').is(':checked')) {
                    $.get(Urls.getShippingAddress, function(data) {
                        $('input[name$="_addressFields_firstName"').val(data.address.firstName);
                        $('input[name$="_addressFields_lastName"').val(data.address.lastName);
                        $('input[name$="_addressFields_address1"').val(data.address.address1);
                        $('input[name$="_addressFields_address2"').val(data.address.address2);
                        $('input[name$="_addressFields_country"').val(data.address.countryCode);
                        $('input[name$="_addressFields_city"').val(data.address.city);
                        $('.state.required').val(data.address.stateCode);
                        $('label[for$="_states_state"]').removeClass('no-float');
                        $('input[name$="_addressFields_postal"').val(data.address.postalCode);
                        $('input[name$="_addressFields_phone"').val(data.address.phone);
                    });
                } else {
                    $('form.cob-billing').on('change co.countryupdate', '.input-select.country', function() {
                        if ($(this).val() !== 'US' && $(this).val() !== 'CA' &&  $(this).val() !== 'AU') {
                            $('.form-row.co-states').find('.arrow-down').addClass('hide');
                            if ($('.form-field.state').val() == '') {
                                $('.input-select.state').removeClass('valid');
                                $('form.cob-billing').find('label[for$="_states_state"]').addClass('no-float');
                            }
                        } else {
                            $('.form-row.co-states').find('.arrow-down').removeClass('hide');
                            if ($('.input-select.state').val() == '') {
                                $('.input-select.state').removeClass('valid');
                                $('form.cob-billing').find('label[for$="_states_state"]').addClass('no-float');
                            }
                        }
                    });

                    // Handle saved address interaction if logged in
                    if ($savedAddressSelect.length > 0) {
                        $('form.cob-billing').on('change', '.js-saved-addresses', function() {
                            if ($(this).val() == '') {
                                clearBillingAddressForm();
                            }
                            if ($('.state.required').val().length > 0) {
                                $('label[for$="_states_state"]').removeClass('no-float');
                            } else {
                                $('label[for$="_states_state"]').addClass('no-float');
                            }
                        });
                    }

                    $('.state.required').on('change', function() {
                        if ($(this).val() !== '') {
                            $('label[for$="_states_state"]').removeClass('no-float');
                        } else {
                            $('label[for$="_states_state"]').addClass('no-float');
                        }
                    });

                    /* global pca */
                    if (typeof(pca) != 'undefined' && pca) {
                        pca.load();
                    }
                }
            });
            $('.input-checkbox.sameAsShippingAddress').removeClass('animating');
        }, 20);
    });

    if($('.checkout-steps-egift').length > 0) {
        $('.input-checkbox.sameAsShippingAddress').trigger('click');
    }

    // Scroll to server-side billing error if displayed (credit card field errors, etc)
    $(window).on('load', function() {
        var errorContainer = $('.cob-error-msg-container').not('.no-scroll');
        if (errorContainer.length > 0) {
            var headerHeight = $('.site-header').outerHeight();
            var firstErrorTop = errorContainer.eq(0).offset().top;
            var scrollTo = (firstErrorTop - headerHeight);
            $('html, body').animate({
                scrollTop: scrollTo
            }, 1000);
        }

        if (!$('.input-checkbox.sameAsShippingAddress').is(':checked')) {
            $('.input-checkbox.sameAsShippingAddress').addClass('address-expanded');
            $('.js-billing-form').slideDown('fast');
            $('.cc-shipping-address').slideUp('fast');
        }
    });

    $('.add-to-address-book input').on('change', function() {
        var $checkbox = $(this);
        if($checkbox.is(':checked')) {
            $checkbox.val(true);
        } else {
            $checkbox.val(false);
        }
    });

    // Enable payment submit button when CC fields are filled out or when GC covers full order
    var ccFields = $('.panel-CREDIT_CARD').find('input'),
        placeOrderBtn = $('.co-submit-btn-container button[name="dwfrm_billing_save"]');

    $('.payment-method-options').find('.input-radio[name$="_selectedPaymentMethodID"]').on('change', function() {
        if($(this).attr('id') == 'is-PayPal') {
            placeOrderBtn.attr('disabled', true);
            placeOrderBtn.hide();
        }
    });

    if(ccFields.length == 0) {
        placeOrderBtn.attr('disabled', false);
    } else {
        ccFields.on('input', function() {
            if ($('.form-field.ccnumber').val().length > 0 && $('.form-field.creditcardexpiry').val().length > 0 && $('.form-field.cvn').val().length > 0) {
                // Using bool (condition for if) to set a bool instead of placeOrderBtn.attr('disabled', <condition>)
                placeOrderBtn.attr('disabled', false);
            } else {
                placeOrderBtn.attr('disabled', true);
            }
        });

        // If Place Order button is enabled from filling in CC fields but user then switches to alternate payment:
        $('.payment-method-options').find('.input-radio[name$="_selectedPaymentMethodID"]').on('change', function() {
            if ($(this).attr('id') !== 'is-CREDIT_CARD') {
                placeOrderBtn.attr('disabled', false);
                if($(this).attr('id') == 'is-PayPal') {
                    placeOrderBtn.attr('disabled', true);
                    placeOrderBtn.hide();
                } else if ($(this).attr('id') == 'is-CYBERSOURCE_APPLEPAY') {
                    placeOrderBtn.hide();
                }
            } else {
                placeOrderBtn.show();
                if ($('.form-field.ccnumber').val().length > 0 && $('.form-field.creditcardexpiry').val().length > 0 && $('.form-field.cvn').val().length > 0) {
                    placeOrderBtn.attr('disabled', false);
                } else {
                    placeOrderBtn.attr('disabled', true);
                }
            }
        });

        // Enable submit button if non CC payment method selected by default
        var $paymentMethodRadio = $('.payment-method-options').find('.input-radio[name$="_selectedPaymentMethodID"][checked="checked"]');
        if ($paymentMethodRadio.attr('id') !== 'is-CREDIT_CARD') {
            placeOrderBtn.attr('disabled', false);
            if($paymentMethodRadio.attr('id') == 'is-PayPal' && $('.step-row').length < 4) {
                placeOrderBtn.hide();
            }
        }
    }

    $('.form-field.ccnumber').on('keyup input', function(e) {
        var value = $(this).val();

        var $cardnumber = $(this);

        if (!$cardnumber.hasClass('error') && !$cardnumber.hasClass('valid')) {
            $cardnumber.addClass('valid');
        }

        if(value.indexOf("*") == -1) {
            if (value.length > 0) {
                var cardType = getCardType(value);

                if(cardType) {
                    var cardClass = cardType.class.toLowerCase();

                    // Choose Card type before returning a success.
                    var $cardtype = $(this).parents('.cob-payment-method').find('select[name$=\'creditCard_type\']');
                    if($cardtype.length > 0) {
                        $cardtype.val(cardType.name);
                        $cardnumber.replaceClass('card-icon-*', 'card-icon-' + cardClass);

                        if(e.keyCode === 9 || e.keyCode === 16 || e.keyCode === 39 || e.keyCode === 37 || e.keyCode === 40 || e.keyCode === 38) {
                            //do nothing
                        } else if(cardType.class === 'amex' && value.replace(/\s/g, '').length >= 15) {
                            $('.form-field.creditcardexpiry').focus();
                        } else if(value.replace(/\s/g, '').length >= 16){
                            $('.form-field.creditcardexpiry').focus();
                        }
                    }

                    handleCCMaskingPattern(cardClass);
                }
            } else {
                $cardnumber.replaceClass('card-icon-*', '');
            }
        }
    });

    $('.form-field.creditcardexpiry').on('input', function(e) {
        if(e.keyCode === 9 || e.keyCode === 16 || e.keyCode === 39 || e.keyCode === 37 || e.keyCode === 40 || e.keyCode === 38) {
            return;
        }
        var value = $(this).val();

        if(value.replace(/\s|\//g, '').length >= 4) {
            $('.form-field.cvn').focus();
        }
    });
    const getStripePaymentType = function(paymentMethod) {
        switch(paymentMethod) {
            case 'card':
                return 'CREDIT_CARD'
            case 'afterpay_clearpay':
                return 'AFTERPAY_PBI'
            case 'apple_pay':
                return 'Apple Pay'
            default:
                return null;
        }
    };
    $('body').one('click', '.js-billing-submit', function() {
        var gtmOption = getStripePaymentType(window.selectedStripeOption) || $("[name='dwfrm_billing_paymentMethods_selectedPaymentMethodID']:checked").val();
        var eventOptions = {};

        if (gtmOption && gtmOption.toLowerCase().indexOf('paypal') > -1) {
            if ($('.js-gtm-paypal-return').val() === 'true' && $('.js-gtm-paypal-from-cart').val() === 'true') {
                gtmOption = 'paypal express';
            } else {
                gtmOption = 'paypal';
            }
        }

        if ($('.js-gtm-coupon-applied').length > 0 && $('.js-gtm-coupon-applied').val() !== '') {
            eventOptions.promoCodeSubmitted = $('.js-gtm-coupon-applied').val();
        }

        eventOptions.orderShippingState = $('.js-gtm-shipping-state').val();
        eventOptions.orderShippingMethod = $('.js-gtm-shipping-method').val();
        eventOptions.orderContainsGift = $('.js-gtm-gift-order').val() === 'true';
        eventOptions.clickLocation = 'billing';

        gtmAnalytics.submitCheckoutStep(4, gtmOption, eventOptions);
        if (window.siteGaData && siteGaData.checkout) {
            siteGaData.checkout.billing = gtmOption;
        }
        $(document).trigger('ga.checkoutstep', 4);
    });
}

function handleCCMaskingPattern(ccType) {
	if (!ccType) {
		return;
	} else {
		if(ccType === 'amex') {
			$('.form-field.ccnumber').attr('pattern', '\\d{4} \\d{6} \\d{5}')
				.data('data-placeholder', 'XXXX XXXXXX XXXXX')
				.attr('data-placeholder', 'XXXX XXXXXX XXXXX');
		} else {
			$('.form-field.ccnumber').attr('pattern', '\\d{4} \\d{4} \\d{4} \\d{4}')
				.data('data-placeholder', 'XXXX XXXX XXXX XXXX')
				.attr('data-placeholder', 'XXXX XXXX XXXX XXXX');
		}
	}
}

function getCardType(ccNo) {

	var __indexOf = [].indexOf || function(item) { for (var i = 0, l = this.length; i < l; i++) { if (i in this && this[i] === item) return i; } return -1; };

	var card_types = [
		{
			name: 'American Express',
			pattern: /^3[47]/,
			valid_length: [15],
			class: 'amex'
		}, {
			name: 'diners_club_carte_blanche',
			pattern: /^30[0-5]/,
			valid_length: [14],
			class: 'diners-carteblanche'
		}, {
			name: 'diners_club_international',
			pattern: /^36/,
			valid_length: [14],
			class: 'diners-int'
		}, {
			name: 'JCB',
			pattern: /^35(2[89]|[3-8][0-9])/,
			valid_length: [16],
			class: 'jcb'
		}, {
			name: 'laser',
			pattern: /^(6304|670[69]|6771)/,
			valid_length: [16, 17, 18, 19],
			class: 'laser'
		}, {
			name: 'visa_electron',
			pattern: /^(4026|417500|4508|4844|491(3|7))/,
			valid_length: [16],
			class: 'visa-ectron'
		}, {
			name: 'Visa',
			pattern: /^4/,
			valid_length: [16],
			class: 'visa'
		}, {
			name: 'MasterCard',
			pattern: /^[25][1-5]/,
			valid_length: [16],
			class: 'mastercard'
		}, {
			name: 'maestro',
			pattern: /^(5018|5020|5038|6304|6759|676[1-3])/,
			valid_length: [12, 13, 14, 15, 16, 17, 18, 19],
			class: 'maestro'
		}, {
			name: 'Discover',
			pattern: /^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)/,
			valid_length: [16],
			class: 'discover'
		}
	];

	var card, card_type;
	var options = {};

	options.accept = (function() {
		var _i, _len, _results;
		_results = [];
		for (_i = 0, _len = card_types.length; _i < _len; _i++) {
			card = card_types[_i];
			_results.push(card.name);
		}
		return _results;
	})();

	var _j, _len1, _ref1;

	_ref1 = (function() {
		var _k, _len1, _ref1, _results;
		_results = [];

		for (_k = 0, _len1 = card_types.length; _k < _len1; _k++) {
			card = card_types[_k];
			if (_ref1 = card.name, __indexOf.call(options.accept, _ref1) >= 0) {
				_results.push(card);
			}
		}
		return _results;
	})();

	for (_j = 0, _len1 = _ref1.length; _j < _len1; _j++) {
		card_type = _ref1[_j];
		if (ccNo.match(card_type.pattern)) {
			return card_type;
		}
	}

	return null;
};

function clearBillingAddressForm() {
    var billingFields = $('.js-billing-form').find('input, select');
    billingFields.each(function() { // reset form
        $(this).val('').removeClass('valid').removeClass('error');
        $(this).removeAttr('aria-describedby').removeAttr('aria-invalid');
        $('.js-billing-form').find('span.error').remove();
        $('label[for$="_states_state"]').addClass('no-float');
    });
    $('select.country').val('US').trigger('co.countryupdate').removeClass('valid');
    if ($('.input-select.state').val() == '') {
        $('label[for$="_states_state"]').addClass('no-float');
    }
}

function initPromoGCToggle() {
    var $form = $('form.checkout-billing');
    $form.on('click', '.promo-toggle-radio-buttons .input-radio', function() {
        var $containerType = $(this).val(), // promo-code or gift-card
            $containerThis = $('.promo-content-container' + '.' + $containerType),
            $containerSibling = $containerThis.siblings('.promo-content-container');

        if (!$containerThis.hasClass('active')) {
            $containerThis.addClass('active');
        }
        if ($containerSibling.hasClass('active')) {
            $containerSibling.removeClass('active');
        }
    });
}

/**
 * @function
 * @description loads billing address, Gift Certificates, Coupon and Payment methods
 */
exports.init = function () {
    var $selectPaymentMethod = $('.cob-payment-type-toggle-item input[checked="checked"]');
    var selectedPaymentMethod = $selectPaymentMethod.val();
    // default payment method to first payment method on the list (either credit card or stripe)
    // needs to call before bindEvents()
    if (typeof selectedPaymentMethod == 'undefined') {
        $('.cob-payment-type-toggle-item input:nth(0)').attr('checked',true);
        $selectPaymentMethod = $('.cob-payment-type-toggle-item input[checked="checked"]');
        selectedPaymentMethod = $selectPaymentMethod.val();
    }

    internationalForms.init();
    bindEvents();
    initPromoGCToggle();
    promoCode.init(5);
    util.initCheckoutAddressFieldGroups($('form.cob-billing'));

    formPrepare.init({
        formSelector: 'form[id$="billing"]',
        continueSelector: '[name$="billing_save"]'
    });

    applepay.init();

    // default payment method to 'CREDIT_CARD'
    updatePaymentMethod((selectedPaymentMethod) ? selectedPaymentMethod : 'CREDIT_CARD');

    new masking.InputMask({
        masked: ".masked"
    });
};
