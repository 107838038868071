'use strict';

var dialog = require('./dialog'),
    ajax = require('./ajax'),
    newsletter = require('./newsletter'),
    signInNudge = require('./signinnudge');

var isDialogTriggered = false;
var modalProgress = 'email';
var $modalToggleButton = $('.js-newsletter-toggle');
var modalOpenDelay = $modalToggleButton.attr('data-modalopen-delay');
var teaserEnable = $modalToggleButton.attr('data-enabled');
var cookieExpirationPeriod = $modalToggleButton.attr('data-cookie-expiration');
var NEW_VISITOR_COOKIE_NAME = 'haNewVisitor';

/**
 * @function
 * @description create a function that returns the value of a specified cookie
 */
var getCookie = function (name) {
    var arg = name + '=';
    var alen = arg.length;
    var clen = document.cookie.length;
    var i = 0;
    while (i < clen) {
        var j = i + alen;
        if (document.cookie.substring(i, j) == arg)
            return 'here';
        i = document.cookie.indexOf(' ', i) + 1;
        if (i == 0) break;
    }
    return null;
}
var setCookie = function () {
    var expire = new Date();
    expire = new Date(expire.getTime() + (cookieExpirationPeriod * 24 * 60 * 60 * 1000));
    var hnvc = 'haNewVisitor=here; expires=' + expire.toUTCString() + ';path=/';
    document.cookie = (Resources.IS_PRODUCTION == 'true') ? hnvc + '; secure; samesite=None' : hnvc;

    window.localStorage.setItem('ha-expiration-date', expire);
}

var addAnalyticsData = function(){
    gtmAnalytics.submitModalImpression({
        event: "newsletter-sign-up-impression", 
        formType: "subscribe-popup"
    });
}

// open content in modal popup
var openModal = function (modalContent) {
    var lastFocus = document.activeElement;

    dialog.open({
        html: modalContent,
        options: {
            dialogClass: 'news-content-modal copy',
            modal: true,
            draggable: false,
            open: function() {
                attachModalEvents();
                $modalToggleButton.addClass('hide');

                // Removes default overlay behavior to support designed user experience
                $('.news-content-modal + .ui-widget-overlay').on('click', function(e){
                    e.preventDefault();
                    return false;
                });            
            },
            close: function () {
                $(document).trigger('newVisitorModal:close');
                lastFocus.focus();
            }
        },
        callback: function () {
            $(document).trigger('newVisitorModal:open');
            newsletter.init(true);
            
            addAnalyticsData();
        }
    });
}

var getModal = function (includeDelay) {
    isDialogTriggered = true;
    if (includeDelay == true) {
        setTimeout(function(){
            ajax.load({
                url: Urls.newsletterModal,
                callback: openModal
            });
        }, modalOpenDelay); 
    } else {
        ajax.load({
            url: Urls.newsletterModal,
            callback: openModal
        });
    }
}

var clearLocalStorageData = function() {
    window.localStorage.removeItem('ha-expiration-date');
    window.localStorage.removeItem('ha-newvisitor-modal');
    window.localStorage.removeItem('ha-newvisitor-progress');
    window.localStorage.removeItem('ha-encrypted-email');
}

var clearModalProgress = function() {
    var today = new Date();
    var expirationDate = window.localStorage.getItem('ha-expiration-date') != null ? new Date(window.localStorage.getItem('ha-expiration-date')) : '';

    if ((expirationDate.length > 0) && (today.toDateString() === expirationDate.toDateString())) {
        clearLocalStorageData();
    }
}

var checkForNewUser = function () {
    var _cookie = getCookie(NEW_VISITOR_COOKIE_NAME);
    var bfxInternational = false;
    if (SessionAttributes && SessionAttributes.BFX_INTERNATIONAL) {
        bfxInternational = SessionAttributes.BFX_INTERNATIONAL;
    }
    if (_cookie == null) {
        clearModalProgress();

        if(!bfxInternational) {
            getModal(true);
        }
        setCookie();
    } else {
        showModalToggleButton();
    }
}

var setModalProgress = function() {
    var $modalProgressIndicator = $('.js-newsletter-progress:not(.hidden)');

    if ($modalProgressIndicator.hasClass('js-email-step')) {
        modalProgress = 'email';
    } else if ($modalProgressIndicator.hasClass('js-phone-step')) {
        modalProgress = 'sms';
    } else if ($modalProgressIndicator.hasClass('news-exists')) {
        modalProgress = 'error';
    } else if ($modalProgressIndicator.hasClass('news-error')) {
        modalProgress = 'duplicate';
    } else {
        modalProgress = 'success';
    }
}

var showModalToggleButton = function(){
    if ((window.localStorage.getItem('ha-expiration-date') != null && window.localStorage.getItem('ha-newvisitor-modal') != null && window.localStorage.getItem('ha-newvisitor-progress') != null && teaserEnable == 'true')) {
        $modalToggleButton.removeClass('hide');
        attachModalToggleEvents();
    }
}

var handleModalClose = function(e) {
    setModalProgress();
    closeModal(e);

    if (modalProgress == 'email' || modalProgress == 'error') {
        minimizeModal(e);
    } else {
        clearLocalStorageData();
    }
}

var minimizeModal = function(e) {
    window.localStorage.setItem('ha-newvisitor-modal', 'true');
    window.localStorage.setItem('ha-newvisitor-progress', modalProgress);
    showModalToggleButton();
}

var closeModal = function(e) {
    var $newUserIntro = $('.news-square-content .news-intro');
	var $newUserSMS = $('.news-square-content .news-sms'); 
    var $newUserSubscribeSuccess = $('.news-square-content .news-success');
    if (modalProgress == 'sms') {
        $newUserIntro.addClass('hidden');
        $newUserSMS.addClass('hidden');
        $newUserSubscribeSuccess.removeClass('hidden');

        setTimeout(function(){
            dialog.close();
        }, 1000);
    } else {
        dialog.close();
    }

    $newUserIntro.removeClass('.js-email-completed');
}

var attachModalEvents = function() {
    var $modal = $('.news-content-modal');
    var includeSMS = $modal.find('.js-newsletter-container').attr('data-newsletter-includesms');

    if (includeSMS) {
        var localModalProgress = window.localStorage.getItem('ha-newvisitor-progress');
        if (localModalProgress == 'sms') {
            $modal.find('.js-phone-step').removeClass('hidden');
            $modal.find('.js-email-step').addClass('hidden');
        }
    }

    var $focusableElements = $modal.children().filter(function() {
        return typeof this.focus === 'function';
    });

    var firstFocusableElement = $focusableElements[0];
    var lastFocusableElement = $focusableElements[$focusableElements.length - 1];

    setTimeout(function() {
        firstFocusableElement.focus();
    }, 1000);

    $modal.on('click keydown', '.js-close-btn', function(e){
        if (e.type === 'click' || e.key === 'Enter' || e.code === 'Space') {
            e.preventDefault();
            handleModalClose(e);
        }
    })
    .closest('.ui-widget').on('keydown', function(e){
        if (e.key === 'Escape') {
            e.preventDefault();
            handleModalClose(e);
        } else if (e.key === 'Tab' || e.keyCode === 9) {
            if (e.shiftKey && (document.activeElement === firstFocusableElement)) {
                lastFocusableElement.focus();
                e.preventDefault();
            } else if (!e.shiftKey && (document.activeElement === lastFocusableElement)) {
                firstFocusableElement.focus();
                e.preventDefault();
            }
        }
    });
    $('.news-content-modal + .ui-widget-overlay').on('click', function(e){
        e.preventDefault();
        handleModalClose(e);
    });
}

var attachModalToggleEvents = function() {
    $modalToggleButton.on('click keydown', '.js-newsletter-close-tab', function(e){
        if (e.type === 'click' || e.key === 'Enter' || e.code === 'Space') {
            e.preventDefault();
            
            $modalToggleButton.addClass('hide');
            clearLocalStorageData();
        }
    })
    .on('click keydown', '.js-maximimze-modal-tab', function(e){
        if (e.type === 'click' || e.key === 'Enter' || e.code === 'Space') {
            e.preventDefault();
            
            getModal();
        }
    })
}

var bindEvents = function () {
    $(window).on("load", function () {
        if (signInNudge.isTriggered()) {
            setCookie();
            return;
        }

        if (showemailpopup) {
            checkForNewUser();
        }

    	if (typeof isemailsubscribepage != "undefined" && isemailsubscribepage) {
    		setCookie();
    	}
    });
}

/**
 * @function
 * @description email modal that appears to first time visitors to the site
 */
var init = function () {
    bindEvents();
};


module.exports = {
    init: init,
    getCookie: getCookie,
    isDialogTriggered:  function() {
        return isDialogTriggered;
    },
    NEW_VISITOR_COOKIE_NAME: NEW_VISITOR_COOKIE_NAME
}