'use strict';

const addToCartButton = require('./familyMatchingAddToCartButton');
const departmentButtons = require('./familyMatchingDepartmentButtons');
const familyMatchingTracking = require('./familyMatchingTracking');
const fmUtil = require('./familyMatchingUtil');
const util = require('../../../util');

function init() {
    addToCartButton.init();
    departmentButtons.init();
    familyMatchingTracking.init();

    $('.fm-pdp-all-tabs .tab').on('click', handleTabClick);

    $('.collection-container .swatch .trigger').on('click', handleSwatchClick);

    $('.return-to-step-one').on('click', () => {
        familyMatchingTracking.familyMatchAddMember();
        util.scrollToElement($('.fm-pdp-step.one'));
    });
}

function handleTabClick(){
    const $this = $(this);
    const currentSwatchCollectionID = $this.attr('data-swatch-collection');
    familyMatchingTracking.familyProductDetailRefinementTab($this);

    $('.tab').removeClass('active');
    $('.collection-container').removeClass('active');

    $('.fm-pdp-all-swatches').css('display', 'none');

    $this.addClass('active');
    $(`#${currentSwatchCollectionID}`).addClass('active');

    util.animateElementShow($('.fm-pdp-all-swatches'), 300)
}

function handleSwatchClick(){
    const $this = $(this);
    const controller = $('.collection-container').attr('data-url');
    const data = {
        id: $this.attr('id')
    }

    $('.fm-pdp-all-swatches .swatch').removeClass('active');
    $this.parent().addClass('active');

    fmUtil.showLoadingGraphic();

    $.ajax({
        url: controller,
        type: 'GET',
        data: data,
        success: buildDepartmentButtonsCallBack,
        error: (error) => {
            console.log('familyMatching.js handleSwatchClick() error:', error)
        }
    });
}

function buildDepartmentButtonsCallBack(res){
    try {
        fmUtil.resetAfterNewSwatchSelection();
        fmUtil.hideLoadingGraphic();
        $('.department-buttons-section').html(res);
        departmentButtons.init();
    } catch (error) {
        console.warn('minicart could not be shown');
    }

}

module.exports = {
    init: init
};
