'use strict';

function tabMenu() {
    /**
     * @function
     * @description Toggles panels in a tabbed menu
     */
    $('#product-detail-recommendations-container,.con-tabs').on('click','.js-tab-toggle',function() {
        toggleActiveTab($(this));
    });
}
function toggleActiveTab($tabButton) {
    var activeTabNumber = $tabButton.data('tab-number');
    $('.js-tab-toggle').removeClass('is-active');
    $('.js-tab').removeClass('is-active');
    $tabButton.addClass('is-active');
    $('.js-tab[data-tab-number=' + activeTabNumber + ']').addClass('is-active');
}

module.exports = tabMenu;
