'use strict';

var defaults = require('./chatDefaults');
var chatMenu = require('./chatmenu');

var CHAT_PROVIDER = window['chatProvider'];
var CHAT_CONFIGS = {};

function setChatWidgetConfigs(configs) {
    return {
        type: defaults.TYPE,
        logo: window.Urls.logo,
        rootUrl: defaults.ROOT_URL,
        tenant: defaults.TENANT,
        profiles: configs.chatProfiles || defaults.CHAT_PROFILES,
        showProfiles: defaults.SHOW_PROFILES,
        theme: window.Urls && window.Urls.chatTheme,
        useBusinessHours: defaults.USE_BUSINESS_HOURS
    }
}

function handleOpenFive9Chat(e) {
    e.preventDefault();
    const chatWidget = window[defaults.CHAT_WIDGET];

    if (!chatWidget) {
        return;
    }

    if (!chatWidget.widgetAdded) {
        window[defaults.CHAT_WIDGET].addWidget(CHAT_CONFIGS); 
    }

    window[defaults.CHAT_WIDGET].maximizeChat(CHAT_CONFIGS);
    chatMenu.openChat(chatMenu.VENDOR_FIVE9);
}

function instrumentChatHandlers(callback) {
    $('body').on("click", ".fcr-item.chat, .menu-bar-chat, .order-summary-chat", callback);
}

function initFive9Chat(configs) {
    CHAT_CONFIGS = setChatWidgetConfigs(configs);
    instrumentChatHandlers(handleOpenFive9Chat);
}

function handleOpenServiceCloudChat(e) {
    e.preventDefault();
    chatMenu.openChat(chatMenu.VENDOR_SERVICECLOUD);
}

function initServiceCloudChat() {
    instrumentChatHandlers(handleOpenServiceCloudChat);
}

module.exports = {
    init: function(configs) {
        switch (CHAT_PROVIDER) {
            case chatMenu.VENDOR_FIVE9:
                initFive9Chat(configs);    
                chatMenu.init(chatMenu.VENDOR_FIVE9);
                break;
            case chatMenu.VENDOR_SERVICECLOUD:
                initServiceCloudChat();
                chatMenu.init(chatMenu.VENDOR_SERVICECLOUD);
                break;
        } 
    }
}