'use strict';

exports.init = function() {
    let freeShippingThresholdMet = 'no';
    let cartTotalMinusGiftCards = 0;

    function updateProgressBar(threshold, cartTotalMinusGiftCards, isMixedBag) {
        const progress = cartTotalMinusGiftCards >= threshold ? 100 : (cartTotalMinusGiftCards / threshold) * 100;

        if(progress == 100){
            if(isMixedBag === 'true'){
                $('.progress-bar-container').hide();
            }
            $('.progress-bar').css("width", `${progress}%`);
            freeShippingThresholdMet = 'yes';
        }else{
            $('.progress-bar').css("width", `${progress}%`);
            freeShippingThresholdMet = 'no';
        }
    }
      
    function updateDynamicMessaging($unMetShippingMessage, threshold, cartTotalMinusGiftCards) {
        const remainingAmountUntilThreshold = threshold - cartTotalMinusGiftCards;
        const isCartPage = $('.pt_cart').length > 0;
        const $mobileTresholdMetPlaceHolder =  $('.scp-product-line-items');
        const $mobileProgressBar = $('.mobile-progress-bar').parents('.scp-order-summary-container');
    
        if(remainingAmountUntilThreshold <= 0){
            freeShippingThresholdMet = 'yes';
            
            $unMetShippingMessage.hide();
            
            if (isCartPage) {
                $mobileTresholdMetPlaceHolder.prepend($mobileProgressBar);
                $mobileProgressBar.addClass('mobile-progress-threshold-met');
                $('.shipping-message.met').show();
            } else {
                $('.shipping-message.met').show();
            }
        }
    
        if(remainingAmountUntilThreshold > 0){
            freeShippingThresholdMet = 'no';
    
            if($unMetShippingMessage.hasClass('dynamic')){
                const regex = /\$_|\$\d*\.?\d{0,2}/;
                const currentMessage = $unMetShippingMessage[0].innerText;
                const updatedMessage = currentMessage.replace(regex, `$${remainingAmountUntilThreshold.toFixed(2)}`);
    
                $unMetShippingMessage.text(updatedMessage);
            }
        }
    }
    
    if($('.progress-bar-container').length > 0){
        const threshold = parseFloat($(".progress-bar-container").attr("aria-valuemax"));
        cartTotalMinusGiftCards = parseFloat($(".progress-bar-container").attr("aria-valuenow"));
        const isMixedBag = $(".progress-bar-container").attr("data-ismixedbag");
    
        updateProgressBar(threshold, cartTotalMinusGiftCards, isMixedBag);
        $('.progress-bar-container').css('opacity', '1').css('transition', 'opacity 1s');
    }
    
    if($('.shipping-message.unmet').length > 0){
        const $unMetShippingMessage = $('.shipping-message.unmet');
        const threshold = parseFloat($unMetShippingMessage.data("threshold"));
        cartTotalMinusGiftCards = parseFloat($unMetShippingMessage.data("subtotal-minus-giftcards"));
    
        updateDynamicMessaging($unMetShippingMessage, threshold, cartTotalMinusGiftCards);
        $('.shipping-message').css('opacity', '1').css('transition', 'opacity 1s');
    }

    return {
        'threshholdAmountLeft': cartTotalMinusGiftCards,
        'freeShippingThresholdMet': freeShippingThresholdMet
    }
}