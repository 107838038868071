'use strict';

var ajax = require('../../ajax'),
    formPrepare = require('./formPrepare'),
    progress = require('../../progress'),
    updateSummary = require('./updateSummary'),
    internationalForms = require('../../internationalFields'),
    util = require('../../util'),
    breakpoints = require('../../breakpoints'),
    dialog = require('../../dialog'),
    initGiftMessageValidation = require('./giftMessageValidation');

var shippingMethods;
var $submit = $('.js-shipping-submit');

/**
 * @function
 * @description Initializes gift message box, if shipment is gift
 */
function giftMessageBox() {
    // if _isGift is not not checked, but _giftBox is... check _isGift checkbox
    if ($('input[name$="_isGift"]:checked').length <= 0 && $('input[name$="_giftBox"]:checked').length > 0) {
        $('input[name$="_isGift"]').prop('checked', 'checked');
    }

    // show gift message box, if shipment is gift
    // enableGiftMsg must be set to true in custom preference to toggle gift message
    if (window.SitePreferences && window.SitePreferences.ENABLE_GIFT_MSG === false) {
        return;
    }
    var $giftMessage = $('.checkout-shipping-method').find('.gift-message-text');
    if ($('input[name$="_isGift"]:checked').length > 0) {
        $giftMessage.slideDown(100);
    } else {
        $giftMessage.slideUp(100);
        $giftMessage.find('textarea.giftMessage').val('');
    }
}

/**
 * @function
 * @description Helper method which constructs a URL for an AJAX request using the
 * entered address information as URL request parameters.
 */
function getShippingMethodURL(url, extraParams) {
    var $form = $('.address');
    var params = {
        address1: $form.find('input[name$="_address1"]').val(),
        address2: $form.find('input[name$="_address2"]').val(),
        countryCode: $form.find('select[id$="_country"]').val(),
        stateCode: $form.find('select[id$="_state"]').val(),
        postalCode: $form.find('input[name$="_postal"]').val(),
        city: $form.find('input[name$="_city"]').val()
    };

    return util.appendParamsToUrl(url, $.extend(params, extraParams));
}

/**
 * @function
 * @description selects a shipping method for the default shipment and updates the summary section on the right hand side
 * @param
 */
function selectShippingMethod(shippingMethodID) {
    // nothing entered
    if (!shippingMethodID) {
        return;
    }
    // attempt to set shipping method
    var url = getShippingMethodURL(Urls.selectShippingMethodsList, { shippingMethodID: shippingMethodID });
    ajax.getJson({
        url: url,
        callback: function (data) {
            updateSummary(4);
            if (!data || !data.shippingMethodID) {
                window.alert('Couldn\'t select shipping method.');
                return false;
            }
            // display promotion in UI and update the summary section,
            // if some promotions were applied
            $('.shippingpromotions').empty();

            // TODO the for loop below isn't doing anything?
            // if (data.shippingPriceAdjustments && data.shippingPriceAdjustments.length > 0) {
            //     var len = data.shippingPriceAdjustments.length;
            //     for (var i=0; i < len; i++) {
            //         var spa = data.shippingPriceAdjustments[i];
            //     }
            // }
        }
    });
}

/**
 * @function
 * @description Make an AJAX request to the server to retrieve the list of applicable shipping methods
 * based on the merchandise in the cart and the currently entered shipping address
 * (the address may be only partially entered).  If the list of applicable shipping methods
 * has changed because new address information has been entered, then issue another AJAX
 * request which updates the currently selected shipping method (if needed) and also updates
 * the UI.
 */
function updateShippingMethodList() {
    var $shippingMethodList = $('#shipping-method-list');
    if (!$shippingMethodList || $shippingMethodList.length === 0) { return; }
    var url = getShippingMethodURL(Urls.shippingMethodsJSON);

    ajax.getJson({
        url: url,
        callback: function (data) {
            if (!data) {
                window.alert('Couldn\'t get list of applicable shipping methods.');
                return false;
            }
            if (shippingMethods && shippingMethods.toString() === data.toString()) {
                // No need to update the UI.  The list has not changed.
                return true;
            }

            // We need to update the UI.  The list has changed.
            // Cache the array of returned shipping methods.
            shippingMethods = data;
            // indicate progress
            progress.show($shippingMethodList);

            // load the shipping method form
            var smlUrl = getShippingMethodURL(Urls.shippingMethodsList);
            $shippingMethodList.load(smlUrl, function () {
                $shippingMethodList.fadeIn('fast');
                // rebind the radio buttons onclick function to a handler.
                $shippingMethodList.find('[name$="_shippingMethodID"]').on('click', function () {
                    selectShippingMethod($(this).val());
                });

                // update the summary
                updateSummary(4);
                progress.hide();
                
                //if nothing is selected in the shipping methods select the first one
                if ($shippingMethodList.find('.form-qty-radio:checked').length === 0) {
                    $shippingMethodList.find('.form-qty-radio:first').trigger("click"); //.prop('checked', 'checked');
                }
            });
        }
    });
}

function checkNarvarDestZip() {
	if ($('.narvaredd').length > 0) {
		var shipAddrZip = $('input[name$="_addressFields_postal"]').first().val();
		if (shipAddrZip != "") {
			var narvarDestZip = $('.narvaredd').first().attr('data-narvar-dest-zip');
			if (narvarDestZip != shipAddrZip) {
				$('.narvaredd').attr('data-narvar-dest-zip',shipAddrZip);
				narvar('getEdd', {});
			}
		}
	}
}

function bindEvents() {

    $('.address').on('change', 'input[name$="_addressFields_address1"], input[name$="_addressFields_address2"], select[name$="_addressFields_country"], select[name$="_addressFields_states_state"], input[name$="_addressFields_city"], input[name$="_addressFields_postal"]',
        updateShippingMethodList
    );
    $('.address').on('change', 'input[name$="_addressFields_postal"]', checkNarvarDestZip );
    $('input[name$="_shippingAddress_isGift"]').on('click', giftMessageBox);
    $('input[name$="_shippingAddress_giftBox"]').on('click', giftMessageBox);

	$('button[name$="shippingAddress_save"]').on('click', function (e) {
		if($(this).closest("form").valid()) {
        	$(this).addClass("is-disabled");
        	progress.show($(this));
		}
    });
    
    $(".co-shiptomultiplebutton").on("click", function(e) {
    	e.preventDefault();
    	
    	var $form = $(this).closest("form");
    	if($form) {
			$form.append("<input name='" + $(this).attr("name") + "' value='enter addresses' type='hidden' />");		
    		$form.trigger('submit');
    	}
    });

    // toggle the address dropdown and form
    $("input[name='toggle-address']").on('change', function() {
        var $shippingForm = $('.js-registry-enter-address-content');

        $(".form-inline-radio").toggleClass("checked");
        $(".js-toggle-address").toggleClass("is-form");

        if ($(this).val() == "js-address-form") {
            $(".js-select-address").addClass("hidden");
            $(".js-registry-enter-address-content").removeClass("hidden");
            $(".js-saved-addresses option:selected").removeAttr('selected');
            $(".js-saved-addresses option:first-child").attr('selected', 'selected');      
            $("#js-address-dropdown").attr("aria-checked","false");
            $("#js-address-form").attr("aria-checked","true");

            // Clear all field except country...
            $shippingForm.find('input').val('');
            $shippingForm.find('select').each(function() {
                if($(this).attr("name").indexOf("_country") == -1) {
                    $(this).val("");
                }
            });
            
            // Default to US country if no previous country is selected.
            var $country = $shippingForm.find("[name$='_country']");
            if($country.val().length == 0)
            {
                $country.val("US");
                util.changeFormSelection($country[0], "US");
                $country.trigger("click");
            }

            setSumbitActionIfSavedAddressSelected();

        } else {
            $(".js-select-address").removeClass("hidden");
            $(".js-registry-enter-address-content").addClass("hidden");
            $("#co-address-dropdown").attr("aria-checked","true");
            $("#co-address-form").attr("aria-checked","false");
        }
    });

    // Handle form reset when Borderfree cancel/close links are clicked
    handleBorderfreeCancel();

    // unbind attachBfxToSession callback and rebind it with url to navigate user back to the cart page.
    $(window)
        .off('bfx-contextChooser-submit', util.attachBfxToSession)
        .on('bfx-contextChooser-submit', { destUrl: Urls.cartShow }, util.attachBfxToSession);

    // Checkout Sign In / Forgot Password Modals
    $('.co-guest-signin-link').on('click', function(e) {
        e.preventDefault();

        util.launchSignInModal(this.href);
    });
    // END Checkout Sign In / Forgot Password Modals

	$('body').one('click', '.js-shipping-submit', function() {
		var gtmOption = null;
		var eventOptions = {};
		var coStep = $('.co-steps.active').hasClass('step-1') ? 2 : 3;

		// Set option
		if (coStep === 1) {
			gtmOption = 'new address';
			// Saved address
			// Check authenticated
			if ($('.js-gtm-customer-auth').val() === 'true') {
				var selectedAddress = $('#dwfrm_singleshipping_addressList option:selected').data('address')
				// Compare selected saved address to form (skip postal)
				if ($('#dwfrm_singleshipping_shippingAddress_addressFields_address1').val() == selectedAddress.address1
						&& $('#dwfrm_singleshipping_shippingAddress_addressFields_city').val() == selectedAddress.city
						&& $('#dwfrm_singleshipping_shippingAddress_addressFields_states_state').val() == selectedAddress.stateCode
						&& $('#dwfrm_singleshipping_shippingAddress_addressFields_country').val() == selectedAddress.countryCode) {
					gtmOption = 'saved address'
				}
			}
		} else {
			// Shipping method
			gtmOption = $("[name='dwfrm_singleshipping_shippingAddress_shippingMethodID']:checked").data('method-name')
		}

		// Check for applied coupon
		if ($('.js-gtm-coupon-applied').length > 0 && $('.js-gtm-coupon-applied').val() !== '') {
			eventOptions.promoCodeSubmitted = $('.js-gtm-coupon-applied').val();
		}

		if ($('#dwfrm_singleshipping_shippingAddress_addressFields_states_state').length > 0) {
			eventOptions.orderShippingState = $('#dwfrm_singleshipping_shippingAddress_addressFields_states_state').val();
		} else if ($('.js-gtm-shipping-state').val() !== null) {
			eventOptions.orderShippingState = $('.js-gtm-shipping-state').val();
		}

		if ($("[name='dwfrm_singleshipping_shippingAddress_shippingMethodID']").length > 0) {
			eventOptions.orderShippingMethod = $("[name='dwfrm_singleshipping_shippingAddress_shippingMethodID']:checked").data('method-name');
		} else if ($('.js-gtm-shipping-method').val() !== null) {
			eventOptions.orderShippingMethod = $('.js-gtm-shipping-method').val();
		}

		if ($("[name='dwfrm_singleshipping_shippingAddress_isGift']").length > 0) {
			eventOptions.orderContainsGift = $("[name='dwfrm_singleshipping_shippingAddress_isGift']").is(':checked');
			window.gtmProducts = window.gtmProducts || [];
			for (var i = 0; i < gtmProducts.length; i++) {
				gtmProducts[i].dimension16 = eventOptions.orderContainsGift;
			}
		} else if ($('.js-gtm-gift-order').length > 0 && $('.js-gtm-gift-order').val() !== null) {
			eventOptions.orderContainsGift = $('.js-gtm-gift-order').val() === 'true' ? true : false;
		}

        eventOptions.clickLocation = 'shipping';
        
		gtmAnalytics.submitCheckoutStep(coStep, gtmOption, eventOptions);
	});
}

function handleBorderfreeCancel() {
    var selectedSavedAddressID, selectedSavedAddressCountry, selectedSavedAddressesArray, lastSelectedAddressUS;

    if ($('select.js-saved-addresses').length > 0) {
        // Start an array of selected US saved addresses
        selectedSavedAddressID = $('select.js-saved-addresses').val();
        if (selectedSavedAddressID.length > 0) {
            selectedSavedAddressCountry = $('select.js-saved-addresses option:selected').data('address').countryCode;
            selectedSavedAddressesArray = [selectedSavedAddressID];

            // Keep track of subsequent US saved address selected
            $('select.js-saved-addresses').on('change', function() {
                selectedSavedAddressID = $('select.js-saved-addresses').val();
                if (selectedSavedAddressID.length > 0) {
                    selectedSavedAddressCountry = $('select.js-saved-addresses option:selected').data('address').countryCode;
                    if (selectedSavedAddressCountry !== '' && selectedSavedAddressCountry === 'US') {
                        selectedSavedAddressesArray.push(selectedSavedAddressID);
                    } else {
                        return;
                    }
                }
            });
        }
    }

    $('body').on('click', '.bfx-cc-logo-wrapper, .bfx-cc-cancel', function() {
        if ($('select.js-saved-addresses').length > 0) {
            if (selectedSavedAddressesArray.length > 0) {
                lastSelectedAddressUS = selectedSavedAddressesArray.pop(); // Get most recent selected US address in array
            }
            $('select.js-saved-addresses').val(lastSelectedAddressUS).trigger('change');
            $('.input-select.state').trigger('change');
        }
    });
}

function handleStateOnLoad() {
    var stateSelect = $('.input-select.state');
    $(document).ready(function() {
        if (stateSelect.val() && stateSelect.valid()) {
            stateSelect.addClass('valid');
        }
    });
}

function handleSelectedSavedAddress() {
    var savedAddressesSelect, selectedSavedAddressID;
    savedAddressesSelect = $('select.js-saved-addresses');

    if (savedAddressesSelect.length > 0) {
        savedAddressesSelect.on('change', function() {
            selectedSavedAddressID = $(this).val();
            var url = Urls.handleSavedAddressChange + '?addressID=' + selectedSavedAddressID;
            progress.show();
            $.get(url).done(function() {
                progress.hide();
            });
        });
    } else {
        return;
    }
}

/**
 * Set the form submission name based on if a 
 * registry address is selected.
 * - save - standard submission, requires valid form
 * - useThisAddress - form validation not enforced, address copied
 * into the user's cart on server side
 */
function setSumbitActionIfSavedAddressSelected() {
    $submit.attr('name', $submit.data('name-save'));
}

function initApoFpoModal() {
    $('form.co-shipping').on('click', '.co-apofpo-link', function(e) {
        e.preventDefault();
        var $this = $(this);
        $(document).trigger('ga.checkoutengagement', [$this.text().replace(/\n/g, ''), 'Shipping Address', 1]);

        var modalWidth, isIE11;

        isIE11 = util.detectIEEdge() && util.detectIEEdge() == '11';

        if (util.isMobile() || breakpoints.isMobile()) {
            modalWidth = '100%';
        } else if (breakpoints.isTablet()) {
            modalWidth = 295;
        } else if (!util.isMobile() || breakpoints.isDesktop()) {
            modalWidth = 335;
        }

        var settings = {
            url: $this.data('modal-content'),
            options: {
                dialogClass: 'checkout-modal apo-fpo',
                modal: true,
                draggable: false,
                width: modalWidth,
                height: 365,
                open: function() {
                    $('.ui-widget-overlay').css('opacity','0');
                    $('.ui-dialog-titlebar-close').trigger('focus');
                    $('.apo-fpo-more-details-link').trigger('blur');
                },
                position: {
                    my: isIE11 ? "left top+17" : "left top+35",
                    at: "left center",
                    of: ".co-apofpo-link",
                    collision: "fit none",
                }
            }
        };
        dialog.open(settings);

        $(window).on('resize', function() {
            dialog.close();
        });

        return false;
    });
}

function initPhoneHelpModal() {
    var $phoneHelp = $('form.address').find('.co-phone-help-modal'),
        $phoneFormRow = $('form.address').find('.form-row.co-phone');

    $phoneHelp.insertAfter($phoneFormRow.find('.field-wrapper'));

    $('form.co-shipping').on('click', '.co-phone-help-link', function(e) {
        e.preventDefault();

        var modalWidth, modalHeight, positionMy, positionAt;

        if ((util.isMobile() && window.screen.width < 768) || breakpoints.isMobile()) {
            modalWidth = '100%';
            modalHeight = 100;
            positionMy = 'left top+33';
            positionAt = 'left';
        } else if ((util.isMobile() && window.screen.width > 768) || breakpoints.isTablet()) {
            modalWidth = 375;
            modalHeight = 120;
            positionMy = 'right+18 top+33';
            positionAt = 'right';
        }


        var settings = {
            url: $(this).data('modal-content'),
            options: {
                dialogClass: 'checkout-modal phone-help',
                modal: true,
                draggable: false,
                width: modalWidth,
                height: modalHeight,
                open: function() {
                    $('.ui-widget-overlay').css('opacity','0');
                    $('.ui-dialog-titlebar-close').trigger('focus');
                },
                position: {
                    my: positionMy,
                    at: positionAt,
                    of: '.form-field.phone',
                    collision: "none"
                }
            }
        };
        dialog.open(settings);

        $(window).on('resize', function() {
            dialog.close();
        });

        return false;
    });
}

function initGiftHelpModal() {
    var $form = $('form.co-shipping-method'),
        $giftHelp = $form.find('.co-gift-help-modal'),
        $giftFormRow = $form.find('.form-row.co-gift');

    $giftHelp.insertAfter($giftFormRow.find('.field-wrapper'));

    $form.on('click', '.co-gift-help-link', function(e) {
        e.preventDefault();

        var modalWidth, modalHeight, positionMy, positionAt;

        if ((util.isMobile() && window.screen.width < 768) || breakpoints.isMobile()) {
            modalWidth = '100%';
            modalHeight = 'auto';
            positionMy = 'left top+33';
            positionAt = 'left';
        } else if ((util.isMobile() && window.screen.width > 768) || breakpoints.isTablet()) {
            modalWidth = 375;
            modalHeight = 'auto';
            positionMy = 'right+18 top+33';
            positionAt = 'right';
        } else if ((!util.isMobile() && window.screen.width > 1081) || breakpoints.isDesktop()) {
            modalWidth = "375";
            modalHeight = 'auto';
            positionMy = 'right+33 top+33';
            positionAt = 'right';
        }


        var settings = {
            url: $(this).data('modal-content'),
            options: {
                dialogClass: 'checkout-modal gift-help',
                modal: true,
                draggable: false,
                width: modalWidth,
                height: modalHeight,
                open: function() {
                    $('.ui-widget-overlay').css('opacity','0');
                    $('.ui-dialog-titlebar-close').trigger('focus');
                },
                position: {
                    my: positionMy,
                    at: positionAt,
                    of: '.form-row.co-gift',
                    collision: "fit none"
                }
            }
        };
        dialog.open(settings);

        $(window).on('resize', function() {
            dialog.close();
        });

        return false;
    });
}

function initLoqateAVS() {
    if ($('.js-loqate-avs:visible').length) {
        util.scrollToElement($('.js-loqate-avs'));
    }

    // When choosing suggestions update the form and close the panel
    $('.js-loqate-avs-suggestion').on('click', function () {
        var address = $(this).find('input[type="radio"]').prop('checked', true).data('suggestion');

        $('#dwfrm_singleshipping_shippingAddress_addressFields_address1').val(address.address1);
        $('#dwfrm_singleshipping_shippingAddress_addressFields_address2').val(address.address2);
        $('#dwfrm_singleshipping_shippingAddress_addressFields_city').val(address.city);
        $('#dwfrm_singleshipping_shippingAddress_addressFields_states_state').val(address.state);
        $('#dwfrm_singleshipping_shippingAddress_addressFields_postal').val(address.postalCode);

        $('.js-loqate-avs').hide();
        $('.js-shipping-address-fields').show();
    });

    // If the user selects a different address hide the AVS panel and show the
    // form again
    $('.js-saved-addresses').on('change', function () {
        $('.js-loqate-avs').remove();
        $('.js-shipping-address-fields').show();
    });

    // Remove the AVS panel when clicking the close button
    $('.js-loqate-avs-close').on('click', function () {
        $('.js-loqate-avs').remove();
        $('.js-shipping-address-fields').show();
    });
}

exports.init = function () {
    internationalForms.init(); 
    formPrepare.init({
        continueSelector: '[name$="shippingAddress_save"]',
        formSelector: '[id$="singleshipping_shippingAddress"]'
    });
    bindEvents();
    giftMessageBox();
    initGiftMessageValidation();
    updateShippingMethodList();
    util.initCheckoutAddressFieldGroups($('form.co-shipping'));
    initApoFpoModal();
    initPhoneHelpModal();
    initGiftHelpModal();
    initLoqateAVS();
    handleStateOnLoad();
    handleSelectedSavedAddress();
};

exports.updateShippingMethodList = updateShippingMethodList;
exports.setSumbitActionIfSavedAddressSelected = setSumbitActionIfSavedAddressSelected;
