'use strict';

module.exports = {
    TYPE : 'chat',
    REST_API : 'https://app.five9.com',
    CHAT_CONSOLE : 'https://app.five9.com/consoles/ChatConsole/index.html',
    CHAT_PROFILES : [''],
    TENANT : 'HannaAndersson',
    NOTIFICATION_TYPE : 'callback',
    CHAT_WIDGET: 'Five9SocialWidget',
    STOREFRONT : 'storefront',
    SHOW_PROFILES: false,
    ROOT_URL : 'https://app.five9.com/consoles/',
    FIELDS : [''],
    PROACTIVE_POPUP_ASSET : 'proactive-chat-popup',
    USE_BUSINESS_HOURS: true
}
