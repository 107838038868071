'use strict'

var debounce = require('lodash/debounce');

/**
 * Replaces invalid characters (such as emojis) and white space characters
 * @param {string} value
 * @param {string} replaceStr
 * @returns {string}
 */
function replaceInvalidCharacters(value, replaceStr) {
    var pattern=/[^a-zA-Z0-9- !."'_@&,()\n;:$%]*/ig;

    if(!value) {
        return value;
    }

    var newValue = value
                    .replace(pattern, replaceStr)
                    .replace(/\s+/g, ' ');

    return newValue;
}

/**
 * Handles message validation for gift message text field
 */
function handleMessageValidation() {
    var $this = $(this);
    var val = $(this).val();

    $this.val(replaceInvalidCharacters(val, ''));

    return;
}

/**
 * @function
 * @description Initializes gift message validation to exclude emojis and special character
 */
function initGiftMessageValidation() {
    var giftMessage = $('textarea[name$="_giftMessage"]');

    giftMessage.on('keyup blur paste', debounce(handleMessageValidation, 200));

    //Call gift message validation when delivery form is submitted
    $('form.delivery').on('submit', function() {
        giftMessage.val(replaceInvalidCharacters(giftMessage.val(), ''));
    });
}

module.exports = initGiftMessageValidation;
