'use strict';

/**
 * @function
 * @description to hold screen size breakpoints
 */
var breakpoints = {
    isMobile: function () {
        return window.innerWidth < 768;
    },
    isTablet: function () {
        return 768 <= window.innerWidth && window.innerWidth < 1081;
    },
    isDesktop: function () {
        return 1081 <= window.innerWidth;
    }
};
module.exports = breakpoints; 
