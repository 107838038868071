'use strict';

var dialog = require('../../dialog');
var newsletter = require('../../newsletter');
var validator = require('../../validator');
var util = require('../../util');

function handleBackInStockModal ($el, productIdsData) {
    var elData = $el.data();
    var notifyType = '';

    if (!elData.afbisSku) {
        return;
    }

    if (productIdsData) {
        notifyType = productIdsData.notifyType;
    } else if (elData.notifyType) {
        notifyType = elData.notifyType
    } else {
        var $productDataEl = $el.closest('.pdp-aplus__details').find('.js-product-ids');
         
        notifyType = $productDataEl.data('notify-type');
    }

    var url = util.appendParamsToUrl(window.Urls.backInStockNotifyModal, {
        pid: elData.afbisSku,
        notifytype: notifyType || 'backInStock'
    });

    var settings = {
        url: url,
        options: {
            width: 480,
            dialogClass: 'notify-me-modal',
            modal: true,
            draggable: false
        },
        callback: function() {
            // init/reinit necessary event bindings
            newsletter.init();
            validator.init();
        }
    };
    return dialog.open(settings);
}

function init() {
    // If shopper is borderfree international, hide some copy && exit.
    if (window.SessionAttributes && window.SessionAttributes.BFX_INTERNATIONAL) {
        $('.js-pdp-notify-me').find('.bfx-intl-hide').hide();
        return;
    }

    $('body')
        .on('click', '.js-pdp-notify-me', function(e) {
            var settings = {
                url: $(this).data('dialogUrl'),
                options: {
                    width: 480,
                    dialogClass: 'notify-me-modal',
                    modal: true,
                    draggable: false
                },
                callback: function() {
                    // init/reinit necessary event bindings
                    newsletter.init();
                    validator.init();
                }
            };
            return dialog.open(settings);
        })
        .on('click', '.js-pdp-bis-btn', function (e) {
            var $this = $(this);
            handleBackInStockModal($this);
        })
        .on('change', '#bis-notify-form-sizes', function (e) {
            var $form = $(this).closest('.js-back-in-stock-notify-form');

            if ($form.length > 0) {
                var $button = $form.find('.js-back-in-stock-notify-form-btn');
                $button.attr('data-campaigntag', $(this).val());
            }
            return;
        });

    $(document).on('ha:pdpVariationUpdate', function(e, $swatch, productIdsData) {
        if ($swatch.data('afbis') === true && $swatch.hasClass('selected')) {
            handleBackInStockModal($swatch, productIdsData);
        }

    })
}

module.exports = {
    init: init
}
