'use strict';

var floatingLabels = (function() {
    var settings = {
        formClass: 'form.floating-labels',
        formRowClass: '.form-row', // based on default SiteGen dynamic forms
        labelSelector: '.form-row > label', // based on default SiteGen dynamic forms
        labelClass: 'floating-label',
        labelExceptionClass: 'no-floating-label', // class to add to input elements that will suppress a floating label
        inputSelectors: '.form-field, .input-select', // based on default SiteGen dynamic forms
        inputClass: 'floating-label-input'
    };
    var form = document.querySelectorAll(settings.formClass);


    /**
     * @function moveLabels
     * @description Moves label element to after input/select - required for :placeholder-shown pseudo-class to target correctly
     */
    var moveLabels = function() {
        // Prevent function from running on IE11
        if(!!window.MSInputMethodContext && !!document.documentMode) {
            return;
        }

        if (!form) {
            return;
        } else {
            try {
                form.forEach(function(thisForm) {
                    var formElements = thisForm.querySelectorAll(settings.inputSelectors);
                    formElements.forEach(function(thisElement) {
                        if (thisElement && !thisElement.classList.contains(settings.labelExceptionClass)) {
                            var closestLabel = thisElement.closest(settings.formRowClass).querySelector('.' + settings.labelClass);
                            thisElement.after(closestLabel);
                            thisElement.classList.add(settings.inputClass);
                        }
                    });
                });
            } catch(e) {
                (console.error || console.log).call(console, e.stack || e);
                return;
            }
        }
    };


    /**
     * @function initLabels
     * @description Initializes labels with a .floating-label class & repositions in DOM via call to moveLabels() helper
     */
    var initLabels = function() {
        // Prevent function from running on IE11
        if(!!window.MSInputMethodContext && !!document.documentMode) {
            return;
        }
        if (!form) {
            return;
        } else {
            try {
                form.forEach(function(thisForm) {
                    var labels = thisForm.querySelectorAll(settings.labelSelector);
                    labels.forEach(function(thisLabel) {
                        if (thisLabel.nextElementSibling) {
                            var closestInput = thisLabel.nextElementSibling.querySelectorAll(settings.inputSelectors);
                            closestInput.forEach(function(thisInput) {
                                if (!thisInput.classList.contains(settings.labelExceptionClass)) {
                                    thisLabel.classList.add(settings.labelClass);
                                }
                            });
                        }
                    });
                });
                moveLabels();
            } catch(e) {
                (console.error || console.log).call(console, e.stack || e);
                return;
            }
        }
    };


    return {
        init: function() {
            initLabels();
        }
    };
})();

module.exports.init = floatingLabels.init;
