'use strict';

var html5Player = function () {
    //for video
    var videoClick = $('.vimeo-video-container video,.video-btn');
    // Play/pause functionality
    videoClick.bind('click', function (e) {
        //get ID of clicked video
        var clickedVidID = $(this).closest('.vimeo-video-container').find('video').attr('id');
        var clickedVid = $('#'+clickedVidID);
        $(e.target).closest('[data-contentid]').attr('data-clicked', ((clickedVid.get(0).paused) ? "start video" : "stop video"));
        // check if video is paused or playing
        // hide/show play button
        if (clickedVid.get(0).paused == true) {
            clickedVid.siblings('.video-btn').toggleClass('is-invisible');
            clickedVid.get(0).play();
        } else {
            clickedVid.siblings('.video-btn').toggleClass('is-invisible');
            clickedVid.get(0).pause();
        }
        // When the 'ended' event fires reset
        clickedVid.bind('ended', function () {
            // Reset the video to 0 might be more performant
            //video.currentTime = 0;
            clickedVid.siblings('.video-btn').toggleClass('is-invisible');
            clickedVid.get(0).load();
        });
    });
};

function bindEvents() {
    html5Player();
}

function init() {
    bindEvents();
}

exports.init = init;