'use strict';

/**
 * @description animates size chart link click
 **/

var ajax = require('../../ajax');
var dialog = require('../../dialog');
var util = require('../../util');
var $overlay = $('.size-chart-overlay.js-site-overlay');
var $cachedFocus;

function openTray($tray, url, $link) {
	ajax.load({
		target: $tray,
		url: url,
		callback: function() {
			var $garmentContainer = $tray.find('.garment-container');
			var $sizechartContainer = $tray.find('.size-chart-container');
			
			if ($link.hasClass('pdp-size-chart')) {
				$garmentContainer.hide();
				$sizechartContainer.show();

				setTimeout(function(){
					determineScrollMessage($sizechartContainer);
				}, 100);
			} else if ($link.hasClass('pdp-garment-measure')) {
				$sizechartContainer.hide();
				$garmentContainer.show();

				setTimeout(function(){
					determineScrollMessage($garmentContainer);
				}, 100);
			} else if ($link.hasClass('pdp-combined-size-chart')) {

				if($garmentContainer.length > 0) {
					$garmentContainer.show();
					setTimeout(function(){
						determineScrollMessage($garmentContainer);
					}, 100);
				}

				if($sizechartContainer.length > 0) {
					$sizechartContainer.show();
					setTimeout(function(){
						determineScrollMessage($sizechartContainer);
					}, 100);
				}

			}

			var $firstFocusableElement = $tray.find('#productSizeChart');
			var $lastFocusableElement = $tray.find('.close-sc');

			$firstFocusableElement.focus();

			// Traps focus to the tray for ADA compliance
			$tray.on('keydown', function(e){
				if (e.key === 'Tab' || e.keyCode === 9) {
					if (e.shiftKey && (document.activeElement === $firstFocusableElement.get(0))) {
						e.preventDefault();
						$lastFocusableElement.focus();
					} else if (!e.shiftKey && (document.activeElement === $lastFocusableElement.get(0))) {
						e.preventDefault();
						$firstFocusableElement.focus();
					}
				} else if (e.keyCode == 27) {
					closeTray($tray);
				}
			})
			.on('click', 'tr', function () {
				$tray.find('tr').removeClass('is-highlighted');
				$(this).addClass('is-highlighted');
			});
		}
	});
	$tray.addClass('is-open');
	$tray.attr('aria-hidden', false);
	$overlay.addClass('active');
}

function closeTray($tray) {
	$tray.removeClass('is-open');
	$tray.attr('aria-hidden', true);
	$overlay.removeClass('active');
	$cachedFocus.focus();
}

function slideTray($link) {
	var $tray = $('.js-tray-wrapper');
	openTray($tray, $link.attr('data-modal-target'), $link);
	$tray.animate({
		scrollTop: 0
	}, 300);
}

function determineScrollMessage($container){
	var $scrollContainer = $container.find('.psc-chart').get(0);
    var $scrollMessage = $container.find('.scrollMsg');

    if($scrollContainer.clientWidth < $scrollContainer.scrollWidth) {
        $scrollMessage.addClass('show');
    }
}

function sizeChartEventListeners(){
	$(".js-attr-size").on('change', function() {
		$(this).trigger('focus');
		if($('.pdpLite').length > 0){
			let counter = 0;
			const pdpLiteFullProductInterval = setInterval(function () {
				counter++;
				if($( ".redirect-full-pdp a" ).length == 0){
					const fullPdpUrl = util.removeParamFromURL(document.location.href, 'pdplite');
        			$( ".redirect-full-pdp" ).append('<a href=' + fullPdpUrl + ' class="full-product-url"> See Full Product Details </a>');
					clearInterval(pdpLiteFullProductInterval);
					sizeChartEventListeners();
				}
				if(counter > 100){
					clearInterval(pdpLiteFullProductInterval);
				}
			}, 300)
		}
	});

	$('body').on('click', '.is-normal-sc .nsc-col-data, .is-garment-specific .nsc-col-data', function (e) {
		e.preventDefault();
	}).on('click', '.js-size-chart-link', function (e) {
		e.preventDefault();
		if (dialog.isActive()) {
			dialog.close();
		}
		$cachedFocus = $(this);
		slideTray($(this));
		$('Html').addClass('noScroll');		
	}).on('click', '.js-size-chart-analytics', function(e) {
		e.preventDefault();
		var eventDescription = $(this).hasClass('pdp-garment-measure') ? 'garment measurement' : 'size chart';
		pushSizingModalEvent(eventDescription);
	}).on('click', '.sizebay-virtual-fitting-room', function(){
		pushSizingModalEvent('virtual-fitting-room');
	}).on('click', '.size-chart-overlay, .psc-close-slider, .close-sc', function (e) {
		e.preventDefault();
		closeTray($('.js-tray-wrapper'));
		$('Html').removeClass('noScroll');
	}).on('click', '.psc-description-link .learn-more', function (e) {
		e.preventDefault();
		$(this).hide();
		$('.psc-description-link .close').show();
		$('.psc-description').addClass("show");
		toggleSizeChart ();
	}).on('click', '.psc-description-link .close', function (e) {
		e.preventDefault();
		$('.psc-description').removeClass("show");
		var that = this;
		$(that).hide();
		$('.psc-description-link .learn-more').show();
		toggleSizeChart ();
	}).on('click', '.js-onpage-modal-link', function(e){
		e.preventDefault();
		$cachedFocus = $(this);
		dialog.open({
			html: $(this).parent().next('.psc-size-help-modal__content').html(),
			allowInteraction: true,
			options: {
				dialogClass: 'psc-size-help-modal-container'
			}
		});
		pushSizingModalEvent('open-modal');
	});
}

function toggleSizeChart () {
    var sizeChart = $('#productSizing');
    $('#productSizing').remove();
    $(sizeChart).appendTo('#pdpMain');
}

function pushSizingModalEvent (eventDesc) {
	var $modalData = $('.js-onpage-modal-link');
	if (!$modalData.length) {
		$modalData = $('.pdp-combined-size-chart');
	}
	var productName = $modalData.data('product-name');
	var productSku = $modalData.data('product-sku');
	var sizebayExcluded = $modalData.data('exclude-sizebay');

	window.dataLayer = window.dataLayer || [];
	window.dataLayer.push({
		'event': 'sizing-help-modal',
		'productSku': productSku,
		'productName': productName,
		'sizebayExcluded': sizebayExcluded,
		'actionLocation': 'detail',
		'sizeModalAction': eventDesc
		});
    return;
}

function init () {
	sizeChartEventListeners();

	(function($) {
		$.fn.hasScrollBar = function() {
			return this.get(0).scrollWidth > this.width();
        }
		if (window.location.hash) {
            var $link = $('.js-size-chart-link[data-size-chart="' + window.location.hash.substr(1) + '"');
            if ($link.length > 0) {
                slideTray($link);
            }
		}
	})(jQuery);
}

module.exports = function () {
	init();
}
