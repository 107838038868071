'use strict';

var util = require('./util');
var validateNewsletter = require('./validateNewsletter');

var regex = {
    phone : {
        us : /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/,
        ca : /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/,
        intl : /^[0-9 _./\\+()-]*$/
    },
    postal: {
        us : /^\d{5}(-\d{4})?$/,
		ca : /^[ABCEGHJKLMNPRSTVXYabceghjklmnprstvxy]{1}\d{1}[A-Za-z]{1} *\d{1}[A-Za-z]{1}\d{1}$/,
		fr : /^\d{5}$/,
		de : /^\d{5}$/,
		es : /^\d{5}$/,
		au : /^\d{4}$/,
		nz : /^\d{4}$/,
		gb : /^[\w\s]+$/,
		ua : /^\d{5}$/,
		ru : /^[0-9]{6}/,
		za : /^\d{4}$/,
		be : /^\d{4}$/,
		lu : /^\d{4}$/,
		nl : /^[1-9][0-9]{3}\s?[a-zA-Z]{2}$/,
		at : /^\d{4}$/,
		dk : /^\d{4}$/,
		se : /^(s-|S-){0,1}[0-9]{3}\s?[0-9]{2}$/,
		fi : /^\d{5}$/,
		ee : /^\d{5}$/,
		lv : /^(LV-)?[0-9]{4}$/,
		cz : /^\d{3}[ ]?\d{2}$/,
		pl : /^\d{2}[- ]{0,1}\d{3}$/,
		ch : /^(CH-)?[0-9]{4}$/,
		lt : /^(LT-)?[0-9]{5}$/,
		sk : /^((SK-)?[0-9]{5})|(([0-9]{5})|([0-9]{3}[ ]{0,1}[0-9]{2}))$/,
		si : /^(SI-)?[0-9]{4}$/,
		ie : /.*/,
        intl : /.*/
    },
    creditcard : {
		amex : /^(?:3[47][0-9]{13})$/,
		visa : /^(?:4[0-9]{12}(?:[0-9]{3})?)$/,
		master : /^(?:[25][1-5][0-9]{14})$/,
		discover : /^(?:6(?:011|5[0-9][0-9])[0-9]{12})$/
	},
    notCC: /^(?!(([0-9 -]){13,19})).*$/
};

var processErrorMessage = function(element, focusin) {
    var $formRow = $(element).closest(".form-row");
    $formRow.find('label').removeClass('error');
    $formRow.removeClass('error');
    $formRow.find("input.error").removeClass("error");
    $formRow.find(".form-caption.error-message").remove();
    if($formRow.find("span.error-message").length > 0) {
    	$formRow.find("span.error-message").remove();
    }

	if ($(element).attr('type') !== 'checkbox') {
		if($formRow.find("span.error").length > 0) {
			$formRow.find("span.error").remove();
		}
		if($formRow.find("span[id$='-error']").length > 0) {
			$formRow.find("span[id$='-error']").remove();
		}
	}
    
    if(!focusin) {
    	// This is for removing the error messages from <select> elements
    	if($(element).parent().hasClass('select-wrapper')){
    		$(element).parent('.select-wrapper').removeClass('error');
    		$(element).parent('.select-wrapper').closest('.form-row').removeClass('error');
    	}
        	
    	// Remove all error conditions in multi-ship for select boxes when they validate.
    	var _sa = $(element).closest(".shippingaddress");

   		if(_sa.length > 0) {
    		_sa.find(".select-wrapper.error").removeClass("error");
    		_sa.find(".select-address.error").removeClass("error");
    		if(_sa.find("span.error").length > 0) {
    			_sa.find("span.error").remove();
    		}
    	}
    }
};

// global form validator settings
var settings = {
    errorClass: 'error',
    errorElement: 'span',
    onkeyup: false,
    onfocusout: function (element) {
        if (!this.checkable(element)) {
            processErrorMessage(element, false);
            this.element(element);
        }
    },
    onfocusin: function(element) {
		if(!$(element).hasClass('error')){
			processErrorMessage(element, true);
			if($(element).attr('id') == 'contact-message-type'){
				this.element(element);
			}
		}
    },
    errorPlacement: function(error, element){
		if($(element).parent().hasClass('select-wrapper')){
            $(element).parent().addClass('error');
            $(element).addClass('error');
            $(element).parent('.select-wrapper').after(error);
            $($(element).parent('.select-wrapper')).closest('.form-row').addClass('error');
        } else if ($(element).parents('.js-quantity').length > 0) {
            $(element).parents('.js-quantity').after(error);
        } else if ($(element).parents('.js-sms-form').length > 0) {
            var $errorContainer = $(element).parent().find('.js-sms-error');
            $errorContainer.empty();
			error.addClass('emarsysErrorMsg');
            error.appendTo($errorContainer);
		} else {
			$(element).closest('.form-row').find('label').addClass('error');
			error.attr('aria-live','polite');
			error.appendTo($(element).parent());
        }
    },
    focusInvalid: true,
    invalidHandler: function(form, validator) {
        if (!validator.numberOfInvalids()) {
            return;
        }

        if (!$(validator.errorList[0].element).hasClass('noScrollTo')) {
            util.scrollBrowser($(validator.errorList[0].element).offset().top - 120);
        }
    }
};

var validateCardNo = function(value, el) {
	
	// Only toggle credit card type and validate if no asterics exist in credit card number.
	// Card length must be over 19 except for Amex
	if(value.indexOf("*") == -1 && (value.length < 17 || (value.length < 19 && !value.match(/^3[47]/)))) {
		return false;
	}

	return true;
};

var validateCardType = function(value, el) {
	if (value.indexOf('*') > -1) { 
		// Masked credit card number
		return true; 
	} else if(value.match(/^3[47]/)) {
		// Amex match
		return true;
	} else if(value.match(/^35(2[89]|[3-8][0-9])/)) {
		// JCB match
		return true;
	} else if(value.match(/^4/)) {
		// Visa match
		return true;
	} else if(value.match(/^[25][1-5]/)) {
		// MasterCard match
		return true;
	} else if(value.match(/^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)/)) {
		// Discover match
		return true;
	} else {
		// Not an accepted card type
		return false;
	}
}

function validateExpiry(value, el) {
	var dateTest = /^(0[1-9]|1[012])\/(2[2-9]|3[0-9])$/;
	if(dateTest.test(value)) {
		return true;
	} else {
		return false;
	}
}

function validateCvn(value, el) {
	if(value.length < 3) {
		return false;
	} else {
		return true;
	}
}

/**
 * @function
 * @description Validates a given phone number against the countries phone regex
 * @param {String} value The phone number which will be validated
 * @param {String} el The input field
 */
var validatePhone = function (value, el) {
	var $addrBox = $(el).closest(".address-box");
	var country;
	
	if($addrBox.length > 0) {
    	country = $addrBox.find("select[name$='_country']");
	} else {
		country = $(el).closest("form").find("select[name$='_country']");
	}    	
    	
    if (country.length === 0 || country.val().length === 0) {
        return true;
    }

	var _rgx;
    if (regex.phone[country.val().toLowerCase()]) {
    	_rgx = regex.phone[country.val().toLowerCase()];  
    }
    else {
    	_rgx = regex.phone.intl;  	
    }
    var isOptional = this.optional(el);
    var isValid = _rgx.test(value.trim());

    return isOptional || isValid;
};

var validateSMS = function(value, el) {
	var phoneNumber = $(el).val().replace(/\D/g,'');
	if (phoneNumber.length != 10) {
		return false;
	} else {
		return true;
	}
}

$('.joinLoyalty .phonenumber, .joinLoyalty .phonenumberreq').on('input', function(e){
	validateNewsletter.validatePhoneNumberFormat(e.target);
});

var validateLoyaltySMS = function(value, el) {
	var phoneNumber = $(el).val().replace(/\D/g,'');
	if (phoneNumber.length != 10 && phoneNumber.length != 0) {
		return false;
	} else {
		return true;
	}
}

var validateFirstName = function(value) {
	var validationRegex = new RegExp(/^\s*$/, 'ig')
	var isValid = !validationRegex.test(value);
	return isValid;
}

var validateLastName = function(value) {
	var validationRegex = new RegExp(/^\s*$/, 'ig')
	var isValid = !validationRegex.test(value);
	return isValid;
}

/**
 * @function
 * @description Validates a given Address field against the RegExp for Post Office Boxes
 * @param {String} value The address field which will be validated
 * @param {String} el The input field
 */
var validatePostOfficeBox = function (value, el) {
    // RegExp to test for PO Box from
    // https://gist.github.com/gregferrell/7494667
    // We then flip the result of the test to restrict against PO Boxes
    var pattern = new RegExp(/\bbox(?:\b$|([\s|\-]+)?[0-9]+)|(p[\-\.\s]?o.?[\-\s]?|post office\s)b(\.|ox)?/, 'ig');
    var isPOBox = pattern.test(value.trim());
    return !isPOBox;
};

/**
 * @function
 * @description Validates that a credit card owner is not a Credit card number
 * @param {String} value The owner field which will be validated
 * @param {String} el The input field
 */
var validateOwner = function (value) {
    var isValid = regex.notCC.test(value.trim());
    return isValid;
};

function validateEmail (value, el) {
	// This removes un-wanted characters from email input, such as emoticons.
	var _pattern=/[^a-zA-Z0-9- +!."'_@&,\/()\n;:$%]*/ig;
	
	$(el).val($(el).val().replace(_pattern, ""));

	var validationRegex = new RegExp(/^\w+([.-]?\w+)*(\+[\w]{1,})?@\w+([.-]?\w+)*(\.\w{2,3})+$/, 'ig')
	var isValid = validationRegex.test(value);
	return isValid;
}

var filterOutEmoticonsAndInvalids = function(value, el) {
	// This removes un-wanted characters from email input, such as emoticons.
	var _pattern=/[^a-zA-Z0-9- +!."'_@&,\/()\n;:$%]*/ig;
	
	$(el).val($(el).val().replace(_pattern, ""));

	return true;
};

var filterOutEmoticons = function(value, el) {
	// This removes emoticons from password fields.
	var _pattern=/[^a-zA-Z0-9- !."'_@&,\/()\n;:$%#^*=+{}<>?`~|[\]]*/ig;
	
	$(el).val($(el).val().replace(_pattern, ""));

	return true;
};

var emptyCheck = function(value) {
	if (value == '') {
		return false;
	} else {
		return true;
	}
};

$.validator.addMethod("js-gift-message", filterOutEmoticonsAndInvalids, ""); 
$.validator.addMethod("address2", filterOutEmoticonsAndInvalids, "");
$.validator.addMethod("subscriptionpostalcode", filterOutEmoticonsAndInvalids, "");
$.validator.addMethod("currentpassword", filterOutEmoticons, "");
$.validator.addMethod("newpassword", filterOutEmoticons, "");
$.validator.addMethod("ccnumber", validateCardNo, Resources.VALIDATE_CREDITCARD);
$.validator.addMethod("ccnumber", validateCardType, Resources.VALIDATE_CARDTYPE);
$.validator.addMethod("expiration", validateExpiry, Resources.VALIDATE_EXPIRY);
$.validator.addMethod("cvn", validateCvn, Resources.VALIDATE_CVN);
$.validator.addMethod('phone', validatePhone, Resources.VALIDATE_PHONE);
$.validator.addMethod('js-sms-phone', validateSMS, Resources.VALIDATE_PHONESMS);
$.validator.addMethod('phonenumber', validateLoyaltySMS, Resources.VALIDATE_LOYALTYPHONE);
$.validator.addMethod('phonenumberreq', validateLoyaltySMS, Resources.VALIDATE_LOYALTYPHONE);
$.validator.addMethod('firstname', validateFirstName, Resources.VALIDATE_FIRSTNAME);
$.validator.addMethod('lastname', validateLastName, Resources.VALIDATE_LASTNAME);
$.validator.addMethod('js-pobox', validatePostOfficeBox, Resources.INVALID_POBOX);
$.validator.addMethod('js-email', validateEmail, Resources.VALIDATE_EMAIL);
$.validator.addMethod('email', validateEmail, Resources.VALIDATE_EMAIL);
$.validator.addMethod('owner', validateOwner, Resources.INVALID_OWNER);
$.validator.addMethod('is-email-modal', emptyCheck, Resources.VALIDATE_EMAIL);
$.validator.addMethod('gift-cert-amount', function (value, el) {
    var isOptional = this.optional(el);
    var isValid = (!isNaN(value)) && (parseFloat(value) >= 5) && (parseFloat(value) <= 5000);
    return isOptional || isValid;
}, Resources.GIFT_CERT_AMOUNT_INVALID);

$.validator.addMethod('positivenumber', function (value) {
    if (value.trim().length === 0) { return true; }
    return (!isNaN(value) && Number(value) >= 0);
}, ''); // '' should be replaced with error message if needed

$.validator.addMethod('postal', function(value, el){
    
    var $addrBox = $(el).closest(".address-box");
	var country;
	
	if($addrBox.length > 0) {
    	country = $addrBox.find("select[name$='_country']");
	} else {
		country = $(el).closest("form").find("select[name$='_country']");
	}    
    
    if (country.length === 0 || country.val().length === 0) {
        return true;
    }
    var _rgx;
    if (regex.postal[country.val().toLowerCase()]) {
    	_rgx = regex.postal[country.val().toLowerCase()];  
    }
    else {
    	_rgx = regex.postal.intl;  	
    }
    var isOptional = this.optional(el);
    var isValid = _rgx.test(value.trim());

    return isOptional || isValid;
    
}, Resources.VALIDATE_POSTAL);


$.extend($.validator.messages, {
    required: Resources.VALIDATE_REQUIRED,
    remote: Resources.VALIDATE_REMOTE,
    email: Resources.VALIDATE_EMAIL,
    subscriptionpostalcode: Resources.VALIDATE_SUBSCRIPTIONPOSTALCODE,
    url: Resources.VALIDATE_URL,
    date: Resources.VALIDATE_DATE,
    dateISO: Resources.VALIDATE_DATEISO,
    number: Resources.VALIDATE_NUMBER,
    digits: Resources.VALIDATE_DIGITS,
    creditcard: Resources.VALIDATE_CREDITCARD,
    equalTo: Resources.VALIDATE_EQUALTO,
    maxlength: $.validator.format(Resources.VALIDATE_MAXLENGTH),
    minlength: $.validator.format(Resources.VALIDATE_MINLENGTH),
    rangelength: $.validator.format(Resources.VALIDATE_RANGELENGTH),
    range: $.validator.format(Resources.VALIDATE_RANGE),
    max: $.validator.format(Resources.VALIDATE_MAX),
    min: $.validator.format(Resources.VALIDATE_MIN)
});

var validator = {
    regex: regex,
    settings: settings,
    init: function () {
        var self = this;
        $('form:not(.suppress)').each(function () {
            $(this).validate(self.settings);
            $.each($(this).find('.form-field.required'), function(i, v){
				if($('.is-giftcard').length > 0){
					var fieldName = $(v).closest('.form-row').find('label').text();
					fieldName = fieldName.substring(0, fieldName.length - 2).toLowerCase();
					$(v).rules('add', {
						required : true,
						messages : {
							required : $.validator.format(Resources.VALIDATE_GIFTCARD_REQUIRED, fieldName)
						}
					});
				} else {
					$(v).rules('add', {
						required : true,
						messages : {
							required : $.validator.format(Resources.VALIDATE_DYNAMIC_REQUIRED, [$(v).closest('.form-row').find('label > span').text()])
						}
					});
				}		
            });
            $.each($(this).find('select.required'), function(i, v){
            	$(v).rules('add', {
            		required : true,
            		messages : {
            			required : $.validator.format(Resources.VALIDATE_DYNAMIC_REQUIRED, [$(v).closest('.form-row').find('label > span').text()])
            		}
            	});
            });
            $.each($(this).find('.js-email'), function(i, v){
            	$(v).rules('add', {
            		required: true,
            		messages : {
            			required: Resources.VALIDATE_EMAIL
            		}
            	});
            });
			$.each($(this).find('.js-sms-phone'), function(i, v){
            	$(v).rules('add', {
            		required: true,
					minlength: 14,
					maxlength: 14,
            		messages : {
            			required: Resources.VALIDATE_PHONESMS,
						minlength: Resources.VALIDATE_PHONESMS,
						maxlength: Resources.VALIDATE_PHONESMS
            		}
            	});
            });
			$.each($(this).find('.loginagreetotermsofuse'), function(i, v){
            	$(v).rules('add', {
            		required: true,
            		messages : {
            			required: Resources.TOU_MISSING
            		}
            	});
            });
        });
    },
    initForm: function (f) {
        $(f).validate(this.settings);
    }
};

module.exports = validator;
