'use strict';

var images = require('./images');
var variants = require('./variants');
var addToCart = require('./addToCart');
var addToWishlist = require('./addToWishlist');
var afterpaymessaging = require('../../afterpaymessaging');
var sizeChart = require('./sizeChart');
var imagesLoaded = require('imagesloaded');
var analytics = require('./analytics');
var notifyMe = require('./notifyMe');
var pixlee = require('../../pixlee');
var familyMatching = require('./familyMatching/familyMatching.js');

// Need load type for Pixlee integration
var loadType = 'pageLoad';

function initializeDom() {
    images.init();
    variants.init();
    addToCart.init();
    addToWishlist.init();
    notifyMe.init();

    if (typeof Pixlee !== 'undefined') {
        pixlee.init(loadType);
    }

    setStickyPositioning();
    sizeChart();
    setImageContainerHeight();
}

function initializeEvents() {
    // HD-7154
    // Temporary ADA requirement that should be replaced by TurnTo
    setTimeout(function(){
        if($('#tt-teaser').length > 0) {
            var adaEl = $('.pdp-aplus__top .TTteaser');
            var adaTargetEl = $('.tt-w-reviews-summary .tt-c-rating');
            var adaAriaLabel = $(adaEl).attr('aria-label');
            $(adaTargetEl).attr('aria-label', adaAriaLabel).attr('tabindex', '0').attr('role', 'img');
            $('.tt-l-grid__col').attr('tabindex','0');
        }
    }, 5000);

	$('.tooltip').on('click', function(e) {
		if($(this).attr('href') == '#') {
			e.preventDefault();
		}
	});

    // Stripe AfterPay Messaging
    afterpaymessaging.init();
    analytics.init();
    $(document).trigger('ga.productdetail');
}

function setParentCategoryActive() {
    var  elemArray = $('.breadcrumb-nav span.bn-link a');
    var parentCat = elemArray[0]
    parentCat = $(parentCat).attr('data-link-text');
    
    $('.pt_product-details #navigation .div-mega-1-name').each(function(){
        var itemText = $(this).text().replace(/\s/g,'');
        if(itemText == parentCat) {
            $(this).addClass('is-active');
        }
    });
}

/**
 * Init Product Details Page
 */
function init() {
    setParentCategoryActive();
    if ($('.js-family-matching').length) {
        images.init();
        sizeChart();
        notifyMe.init();
        familyMatching.init();
        analytics.init();
        initializeEvents();
    } else {
        initializeDom();
        initializeEvents();
    }
}

/**
 * Init Quick View Modal
 */
function initQuickview() {
    images.initQuickView();
    variants.initQuickView();
    addToCart.initQuickView();
    addToWishlist.initQuickView();
    analytics.initQuickView();   
    // Stripe AfterPay Messaging
    afterpaymessaging.init();

    if (typeof siteGaData.products != undefined) {
        $(document).trigger('ga.quickview');
    }
}

/**
 * Use jQuery plugin Sticky Kit
 * to set the sticky element to stick by
 * both top and bottom scrolling
 * @see https://github.com/leafo/sticky-kit
 */
function setStickyPositioning() {
    if (!$.fn.stick_in_parent()) { return; }
    if ($(window).innerWidth() <= 1080) { return; }
    var $stickyItem = $('.js-sticky-kit-bottom');

    // Offset the top by 91px, the calculated height of the
    // Fixed site header. Update this value as the site header
    // design changes
    $stickyItem.stick_in_parent({
        offset_top: 91
    });
}

/**
 * Set the min-height for the images loading container
 * This will help to prevent the page from jumping as new variants
 * and their images are loaded.
 *
 * Only call this function on page load
 */
function setImageContainerHeight() {
    if ($(window).innerWidth() > 1080) { return; }
    var $main1 = $('#pdp-aplus__images__main-1');
    var $pdpImages = $('.js-pdp-images');
    imagesLoaded($main1).on('done', function () {
        $pdpImages.css('min-height', $pdpImages.height());
    });
}

module.exports = {
    init: init,
    initQuickview: initQuickview
};
