'use strict';
// jQuery extensions

module.exports = function () {
    // params
    // toggleClass - required
    // triggerSelector - optional. the selector for the element that triggers the event handler. defaults to the child elements of the list.
    // eventName - optional. defaults to 'click'
    var getHeightProperty = function () {
        var browser_id = 0;
        var property = [
            // To avoid content overflow in synchronised boxes on font scaling, we
            // use 'min-height' property for modern browsers ...
            ['min-height', '0px'],
            // and 'height' property for Internet Explorer.
            ['height', '1%']
        ];

        var bMatch = /(msie) ([\w.]+)/.exec(navigator.userAgent.toLowerCase()) || [],
            browser = bMatch[1] || "",
            browserVersion = bMatch[2] || "0";

        // check for IE6 ...
        if (browser === 'msie' && browserVersion < 7) {
            browser_id = 1;
        }

        return {
            'name': property[browser_id][0],
            'autoheightVal': property[browser_id][1]
        };
    };
    $.getSyncedHeight = function (selector) {
        var max = 0;
        var heightProperty = getHeightProperty();
        // get maximum element height ...
        $(selector).each(function () {
            // fallback to auto height before height check ...
            $(this).css(heightProperty.name, heightProperty.autoheightVal);
            var val = parseInt($(this).css('height'), 10);
            if (val > max) {
                max = val;
            }
        });
        return max;
    };

    $.fn.syncHeight = function (config) {
        var defaults = {
            updateOnResize: false,  // re-sync element heights after a browser resize event (useful in flexible layouts)
            height: false
        };

        var options = $.extend(defaults, config);
        var e = this;
        var max = 0;
        var heightPropertyName = getHeightProperty().name;

        if (typeof (options.height) === "number") {
            max = options.height;
        } else {
            max = $.getSyncedHeight(this);
        }

        // set synchronized element height ...
        $(this).each(function () {
            $(this).css(heightPropertyName, max + 'px');
            // had to add 'height' here because 'min-height' would be correct, but some elements would come in
            // slightly over the min-height (e.g. 522.39) which would cause any item at min-height (e.g. 522) to 
            // not float to the left.
            $(this).css('height', max + 'px');
        });

        // optional sync refresh on resize event ...
        if (options.updateOnResize === true) {
            $(window).bind('resize.syncHeight', function () {
                $(e).syncHeight();
            });
        }
        return this;
    };

    $.fn.toggledList = function (options) {
        if (!options.toggleClass) { return this; }
        var list = this;
        return list.on(options.eventName || 'click', options.triggerSelector || list.children(), function (e) {
            e.preventDefault();
            var classTarget = options.triggerSelector ? $(this).parent() : $(this);
            classTarget.toggleClass(options.toggleClass);
            // execute callback if exists
            if (options.callback) { options.callback(); }
        });
    };

    $.fn.replaceClass = function (removals, additions) {
        var self = this;
        if (removals.indexOf( '*' ) === -1) {
            // Use native jQuery methods if there is no wildcard matching
            self.removeClass(removals);
            return !additions ? self : self.addClass( additions );
        }
        var patt = new RegExp('\\s' + removals.replace( /\*/g, '[A-Za-z0-9-_]+' ).split( ' ' ).join( '\\s|\\s' ) + '\\s', 'g');
        self.each( function (i, it) {
            var cn = ' ' + it.className + ' ';
            while (patt.test(cn)) {
                cn = cn.replace(patt, ' ');
            }
            it.className = cn.trim();
        });
        return !additions ? self : self.addClass(additions);
    };
}; 
