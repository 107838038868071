'use strict';

var each = require('lodash/each');
var bodyScrollLock = require('body-scroll-lock');
var scrolling = false;

var util = {
    /**
     * @function
     * @description appends the parameter with the given name and value to the given url and returns the changed url
     * @param {String} url the url to which the parameter will be added
     * @param {String} name the name of the parameter
     * @param {String} value the value of the parameter
     */
    appendParamToURL: function (url, name, value) {
        // quit if the param already exists
        if (url.indexOf(name + '=') !== -1) {
            return url;
        }
        var separator = url.indexOf('?') !== -1 ? '&' : '?';
        return url + separator + name + '=' + encodeURIComponent(value);
    },

    /**
     * @function
     * @description remove the parameter and its value from the given url and returns the changed url
     * @param {String} url the url from which the parameter will be removed
     * @param {String} name the name of parameter that will be removed from url
     */
    removeParamFromURL: function (url, name) {
        if (url.indexOf('?') === -1 || url.indexOf(name + '=') === -1) {
            return url;
        }
        var hash;
        var params;
        var domain = url.split('?')[0];
        var paramUrl = url.split('?')[1];
        var newParams = [];
        // if there is a hash at the end, store the hash
        if (paramUrl.indexOf('#') > -1) {
            hash = paramUrl.split('#')[1] || '';
            paramUrl = paramUrl.split('#')[0];
        }
        params = paramUrl.split('&');
        for (var i = 0; i < params.length; i++) {
            // put back param to newParams array if it is not the one to be removed
            if (params[i].split('=')[0] !== name) {
                newParams.push(params[i]);
            }
        }
        return domain + '?' + newParams.join('&') + (hash ? '#' + hash : '');
    },

    /**
     * @function
     * @description appends the parameters to the given url and returns the changed url
     * @param {String} url the url to which the parameters will be added
     * @param {Object} params
     */
    appendParamsToUrl: function (url, params) {
        var _url = url;
        each(params, function (value, name) {
            _url = this.appendParamToURL(_url, name, value);
        }.bind(this));
        return _url;
    },
    /**
     * @function
     * @description extract the query string from URL
     * @param {String} url the url to extra query string from
     **/
    getQueryString: function (url) {
        var qs;
        if (typeof url !== 'string') { return; }
        var a = document.createElement('a');
        a.href = url;
        if (a.search) {
            qs = a.search.substr(1); // remove the leading ?
        }
        return qs;
    },
    /**
     * @function
     * @description
     * @param {String}
     * @param {String}
     */
    elementInViewport: function (el, offsetToTop) {
        var top = el.offsetTop,
            left = el.offsetLeft,
            width = el.offsetWidth,
            height = el.offsetHeight;

        while (el.offsetParent) {
            el = el.offsetParent;
            top += el.offsetTop;
            left += el.offsetLeft;
        }

        if (typeof(offsetToTop) !== 'undefined') {
            top -= offsetToTop;
        }

        if (window.pageXOffset !== null) {
            return (
                top < (window.pageYOffset + window.innerHeight) &&
                left < (window.pageXOffset + window.innerWidth) &&
                (top + height) > window.pageYOffset &&
                (left + width) > window.pageXOffset
            );
        }

        if (document.compatMode === 'CSS1Compat') {
            return (
                top < (window.document.documentElement.scrollTop + window.document.documentElement.clientHeight) &&
                left < (window.document.documentElement.scrollLeft + window.document.documentElement.clientWidth) &&
                (top + height) > window.document.documentElement.scrollTop &&
                (left + width) > window.document.documentElement.scrollLeft
            );
        }
    },

    /**
     * @function
     * @description Appends the parameter 'format=ajax' to a given path
     * @param {String} path the relative path
     */
    ajaxUrl: function (path) {
        return this.appendParamToURL(path, 'format', 'ajax');
    },

    /**
     * @function
     * @description
     * @param {String} url
     */
    toAbsoluteUrl: function (url) {
        if (url.indexOf('http') !== 0 && url.charAt(0) !== '/') {
            url = '/' + url;
        }
        return url;
    },
    /**
     * @function
     * @description Loads css dynamically from given urls
     * @param {Array} urls Array of urls from which css will be dynamically loaded.
     */
    loadDynamicCss: function (urls) {
        var i, len = urls.length;
        for (i = 0; i < len; i++) {
            this.loadedCssFiles.push(this.loadCssFile(urls[i]));
        }
    },

    /**
     * @function
     * @description Loads css file dynamically from given url
     * @param {String} url The url from which css file will be dynamically loaded.
     */
    loadCssFile: function (url) {
        return $('<link/>').appendTo($('head')).attr({
            type: 'text/css',
            rel: 'stylesheet'
        }).attr('href', url); // for i.e. <9, href must be added after link has been appended to head
    },
    // array to keep track of the dynamically loaded CSS files
    loadedCssFiles: [],

    /**
     * @function
     * @description Removes all css files which were dynamically loaded
     */
    clearDynamicCss: function () {
        var i = this.loadedCssFiles.length;
        while (0 > i--) {
            $(this.loadedCssFiles[i]).remove();
        }
        this.loadedCssFiles = [];
    },
    /**
     * @function
     * @description Extracts all parameters from a given query string into an object
     * @param {String} qs The query string from which the parameters will be extracted
     * @returns {Object} of key: value pairs with param ID: params Value
     */
    getQueryStringParams: function (qs) {
        if (!qs || qs.length === 0) { return {}; }
        var params = {},
            unescapedQS = decodeURIComponent(qs);
        // Use the String::replace method to iterate over each
        // name-value pair in the string.
        unescapedQS.replace(new RegExp('([^?=&]+)(=([^&]*))?', 'g'),
            function ($0, $1, $2, $3) {
                params[$1] = $3;
            }
        );
        return params;
    },

    fillAddressFields: function (address, $form) {
    	for (var field in address) {
            if (field === 'ID' || field === 'UUID' || field === 'key') {
                continue;
            }

            // update the state fields
            if (field === 'countryCode') {
            	$form.find('[name$="_country"]').val(address[field]).trigger("change");
                $form.find('[name$="state"]').val(address.stateCode);
            }
        }
    },

    changePostalFieldTypes : function(fieldName, containerName, countryEl, value) {

        if ($(fieldName).length === 0){ return; }

        var updatePostalField = function(value) {
        	if(!countryEl) {
    			countryEl = $("[name$='_country']");
    		}

            if(containerName && $(containerName).length > 0) {
                if(value == null) {
                    value = $(countryEl).closest(containerName).find(fieldName).val();

                    // This captures cases when there is not actually a postal code as part of an address form. (ex: registry advanced search)
                    if(value === undefined) { return; }
                }

                if ($(countryEl).val() !== 'US' || ($(countryEl).val() == "US" && value.indexOf("-") > -1)) {
                    $(countryEl).closest(containerName).find(fieldName).removeAttr("inputmode").removeAttr("pattern");
                } else {
                    $(countryEl).closest(containerName).find(fieldName).attr("inputmode", "numeric").attr("pattern", "[0-9\\-]*");
                }
            } else {
                if(value == null) {
                    value = $(fieldName).val();

                    // This captures cases when there is not actually a postal code as part of an address form. (ex: registry advanced search)
                    if(value === undefined) { return; }
                }

                if ($(countryEl).val() !== 'US' || ($(countryEl).val() == "US" && value.indexOf("-") > -1)) {
                        $(fieldName).removeAttr("inputmode").removeAttr("pattern");
                } else {
                    $(fieldName).attr("inputmode", "numeric").attr("pattern", "[0-9\\-]*");
                }
            }
        };

        // This new function is to handle special cases of when an input type should be marked "number" for mobile and "text" for postal codes.
        if(countryEl) {
            updatePostalField(value);

            // Exit here if countryEl exists because we don't want to update all address areas if more than one exist.
            return;
        } else if(containerName && $(containerName).length == 0) {
            updatePostalField(value);
            return;
        }

        if(fieldName && containerName && $(containerName).length > 0) {
            $("select[name$='_country']").each( function() {
                if(value == null) {
                    value = $(this).closest(containerName).find(fieldName).val();
                }

                if ($(this).val() !== 'US' || ($(this).val() == "US" && value.indexOf("-") > -1)) {
                        $(this).closest(containerName).find(fieldName).removeAttr("inputmode").removeAttr("pattern");
                } else {
                    $(this).closest(containerName).find(fieldName).attr("inputmode", "numeric").attr("pattern", "[0-9\\-]*");
                }
                // reset this for each select field found.
                value = null;
            });
        }

    },
    initCheckoutAddressFieldGroups: function($form) {
        if (!$form || !$form.is('form')) {
            return;
        } else {
            var breakpoints = require('./breakpoints'),
                $stateSelect = $form.find('.input-select.state'),
                $stateSelectLabel = $stateSelect.next('label.floating-label'),
                $stateSelectFirstOption = $stateSelect.find('option').first();

            if($('.cc-billing-address-on-load').length > 0) {
                $form.find('.cc-billing-address-on-load .form-row.co-firstName, .cc-billing-address-on-load .form-row.co-lastName').wrapAll('<div class="share share-2"></div>');
                $form.find('.cc-billing-address-on-load .form-row.co-states, .cc-billing-address-on-load .form-row.co-zipcode').wrapAll('<div class="share share-2 maintain-row city-state-zip"></div>');
            } else {
                $form.find('.form-row.co-firstName, .form-row.co-lastName').wrapAll('<div class="share share-2"></div>');
                $form.find('.form-row.co-states, .form-row.co-zipcode').wrapAll('<div class="share share-2 maintain-row city-state-zip"></div>');
            }

            util.initStateSelect($stateSelect, $stateSelectLabel, $stateSelectFirstOption);
         
            $(window).on('load resize', function() {
                var $cityStateZip = $form.find('.share.city-state-zip'),
                $city = $form.find('.form-row.co-city');
                
                if (breakpoints.isTablet() || breakpoints.isDesktop()) {
                    $city.prependTo($cityStateZip);
                    $cityStateZip.removeClass('share-2').addClass('share-3');
                } else {
                    $city.insertBefore($cityStateZip);
                    $cityStateZip.removeClass('share-3').addClass('share-2');

                    var $inputField = $('.form-row .field-wrapper input');
                    $inputField.each(function(){
                        $(this).click(function(){
                            $(window).off("resize");
                        });
                    });
                } 
            });
        }
    },
    initStateSelect : function(stateSelect, stateSelectLabel, stateSelectFirstOption) {
        if (!stateSelect || !stateSelectLabel || !stateSelectFirstOption) {
            return;
        } else {
            if (stateSelect.val() !== '') {
                stateSelectLabel.removeClass('no-float');
            } else {
                stateSelectLabel.addClass('no-float');
            }

            // Adjust floating label behavior for State select element on page load and selection change
            stateSelectFirstOption.empty();
            stateSelectFirstOption.attr('label', '');
            stateSelectLabel.addClass('no-float');

            // Check for select value on page load if logged in to trigger floating label
            $(window).on('load', function() {
                if (stateSelect.val() !== '' && stateSelectLabel.hasClass('no-float')) {
                    stateSelectLabel.removeClass('no-float');
                }
            });

            stateSelect.on('change', function() {
                if ($(this).val() !== '') {
                    stateSelectLabel.removeClass('no-float');
                } else {
                    stateSelectLabel.addClass('no-float');
                }
            });
        }
    },
    changeFormSelection : function(selectElem, selectedValue) {
		if(!selectElem) return;
		var options = selectElem.options;
		if(options && options.length > 0) {
			// find index of value to select
			var idx = -1;
			for(var i=0; i<options.length; i++) {
				if(options[i].value == selectedValue) {
					idx = i;
					break;
				}
			}

			if( idx < 0 ) {
				selectElem.selectedIndex = 0;
				return(false);
			}

			selectElem.selectedIndex = idx;
			return(true);
		}

		return(false);
	},

    /**
     * @function
     * @description Clear out Address fields in a given form.
     * Works for inputs and selects. No validation event is triggered
     * @param {jQuery} $form element wrapped around address fields to target
     */
    clearAddressFields: function($form) {
        $form.find('input').val('');
        $form.find('select').val('');
    },
    /**
     * @function
     * @description Updates the number of the remaining character
     * based on the character limit in a text area
     */
    limitCharacters: function () {
        if($('form').find('textarea[data-character-limit]')){
            $('form').find('textarea[data-character-limit]').each(function () {
                var characterLimit = $(this).attr('data-character-limit');
                var charCountHtml = String.format(Resources.CHAR_LIMIT_MSG,
                    '<span class="char-remain-count">' + characterLimit + '</span>',
                    '<span class="char-allowed-count">' + characterLimit + '</span>');
                var charCountContainer = $(this).next('div.char-count');
                if (charCountContainer.length === 0) {
                    charCountContainer = $('<div class="char-count"/>').insertAfter($(this));
                }
                charCountContainer.html(charCountHtml);
    
                $(this).on('change', function () {
                    var text = $(this).val();
                    var charsUsed = text.length;
                    var charsRemain = characterLimit - charsUsed;
                    $(this).next('div.char-count').find('.char-remain-count').innerText = charsRemain;
                });
            });
        }
    },
    /**
     * @function
     * @description Updates the number of the remaining characters for Gift Message
     * based on the character limit in a text area
     */
    limitCharactersGiftMessage: function () {
        $('form').find('textarea.giftMessage[data-character-limit]').each(function () {
            var characterLimit = $(this).data('character-limit');
            var charCountHtml = String.format(Resources.CHAR_LIMIT_GIFT_MSG,
                '<span class="char-remain-count">' + 0 + '</span>',
                '<span class="char-allowed-count">' + characterLimit + '</span>');
            var charCountContainer = $(this).next('div.char-count');
            if (charCountContainer.length === 0) {
                charCountContainer = $('<div class="char-count"/>').insertAfter($(this));
            }
            charCountContainer.html(charCountHtml);
            // trigger the keydown event so that any existing character data is calculated
            $(this).trigger('change');
        });
        // Display remaining count on page load if message is already filled out and we come back from another checkout step
        $(window).on('load', function() {
            if ($('form').find('textarea[data-character-limit]').length > 0) {
                var msgCountOnLoad = $('form').find('textarea').attr("data-character-limit");
                $('form').find('.char-remain-count').html(msgCountOnLoad);
            }
        });
    },
    /**
     * @function
     * @description Binds the onclick-event to a delete button on a given container,
     * which opens a confirmation box with a given message
     * @param {String} container The name of element to which the function will be bind
     * @param {String} message The message the will be shown upon a click
     */
    setDeleteConfirmation: function (container, message) {
        $(container).on('click', '.delete', function () {
            return window.confirm(message);
        });
    },
    /**
     * @function
     * @description Scrolls a browser window to a given x point
     * @param {String} The x coordinate
     */
    scrollBrowser: function (xLocation) {
        if (scrolling) {
            return;
        }

        var $page = $('html, body');

        $page.on('scroll mousedown wheel DOMMouseScroll mousewheel keydown touchmove', function() {
            $page.stop();
            scrolling = false;
        });

        scrolling = true;
        $page.animate({
            scrollTop: xLocation
        }, 500, function () {
            $page.off('scroll mousedown wheel DOMMouseScroll mousewheel keydown touchmove');
            scrolling = false;
        });
    },

    /**
     * @function
     * @description Scrolls the viewport to the specified element while handling
     * header offset
     * @param {jQuery} $element
     */
    scrollToElement: function ($element) {
        util.scrollBrowser($element.offset().top - $('.site-header').height() - 15);
    },

    isMobile: function () {
        var mobileAgentHash = ['mobile', 'tablet', 'phone', 'ipad', 'ipod', 'android', 'blackberry', 'windows ce', 'opera mini', 'palm'];
        var    idx = 0;
        var isMobile = false;
        var userAgent = (navigator.userAgent).toLowerCase();

        while (mobileAgentHash[idx] && !isMobile) {
            isMobile = (userAgent.indexOf(mobileAgentHash[idx]) >= 0);
            idx++;
        }
        return isMobile;
    },
    /**
     * Check if Quick View is open
     * @returns {Boolean} true if open
     */
    isQuickviewOpen: function() {
        var isOpen = false;
        var $qv = $('#QuickViewDialog');

        if ($qv.length > 0 && $qv.dialog('isOpen')) {
            isOpen = true;
        }

        return isOpen;
    },
    /**
     * @function
     * @description Calls endpoint to assign bfxInternational session variable. Reloads current page or navigates to
     * url provided in the url properfy of the e.data object (i.e. e.data.destUrl)
     * @param {Event}
     */
    attachBfxToSession: function(e) {
        e.preventDefault();
        var url = e.data && e.data.destUrl;

        setTimeout(function() {
            window.stop();
            var selectedCountry = $('#bfx-cc-countries-select').val();
            var endpointURL = Urls.attachBfxToSession + '?country=' + selectedCountry;
            $.get(endpointURL, function() {
                if (url) {
                    window.location.replace(url);
                    return;
                }
                window.location.reload();
                return;
            });
        }, 100);
    },
    /**
     * detect IEEdge
     * returns version of IE/Edge or false, if browser is not a Microsoft browser
     */
    detectIEEdge: function() {
        var ua = window.navigator.userAgent;

        var msie = ua.indexOf('MSIE ');
        if (msie > 0) {
            // IE 10 or older => return version number
            return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
        }

        var trident = ua.indexOf('Trident/');
        if (trident > 0) {
            // IE 11 => return version number
            var rv = ua.indexOf('rv:');
            return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
        }

        var edge = ua.indexOf('Edge/');
        if (edge > 0) {
        // Edge => return version number
        return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
        }

        // other browser
        return false;
    },
    launchSignInModal: function(url) {
        var modalWidth, modalHeight;
        var breakpoints = require('./breakpoints'),
            validator = require('./validator'),
            floatingLabels = require('./floatingLabels'),
            progress = require('./progress'),
            page = require('./page'),
            dialog = require('./dialog');

        if ((this.isMobile() && window.screen.width < 768) || breakpoints.isMobile()) {
            modalWidth = window.innerWidth;
            modalHeight = window.innerHeight;
        } else if ($('.co-guest-signin').hasClass('co-loyalty-signin')) {
            modalWidth = 400;
            modalHeight = 'auto';
        } else if ((this.isMobile() && window.screen.width > 768) || breakpoints.isTablet()) {
            modalWidth = 698;
            modalHeight = 600;
        } else if (!this.isMobile() || breakpoints.isDesktop()) {
            modalWidth = 714;
            modalHeight = 600;
        }

        var onOpen = function () {
            validator.init();

            var xhr = new XMLHttpRequest();

            $('.co-modals-container')
                .on('click', '.co-passwordrequest-actions > .return-to-bag', function(e) {
                        e.preventDefault();
                        progress.show();
                        dialog.close();
                        page.redirect(Urls.cartShow);
                    })
                .on('click', '.co-passwordrequest-actions > .continue-shopping', function(e) {
                        e.preventDefault();
                        dialog.close();
                    })
                .on('click', '.sign-up-now-link', function(e) {
                    e.preventDefault();
                    progress.show();
                    dialog.close();
                    page.redirect(Urls.registerStart);
                })
                .on('click', '.js-co-modal-back-sign-in', function (e) {
                    e.preventDefault();
                    $('.co-signin-content-container').removeClass('hidden');
                    $('.co-forgotpassword-content-container').addClass('hidden');
                    $('.co-passwordrequest-confirmation-container').addClass('hidden');
                });

            $('.form-field').addClass('floating-label-input');
            floatingLabels.init();

            $('#co-password-reset').on('click', function(e) {
                e.preventDefault();
                $('.co-signin-content-container').addClass('hidden');
                $('.co-forgotpassword-content-container').removeClass('hidden');
                $('#dwfrm_requestpassword_email').trigger('focus');
            });
            util.showHidePassword();

            // sign-in
            var $signinForm = $('form[name$="_login"]');
            var $signinSubmit = $signinForm.find('button[name$="_login_login"]');
            $($signinForm).on('submit', function (e) {
                if (!$signinForm.valid()) {
                    return;
                }
                e.preventDefault();
                progress.show(dialog.$container);
                var data = $signinForm.serialize();
                // add form action to data
                data += '&' + $signinSubmit.attr('name') + '=';
                // make sure the server knows this is an ajax request
                if (data.indexOf('ajax') === -1) {
                    data += '&format=ajax';
                }
                $.ajax({
                    type: 'POST',
                    url: $signinForm.attr('action'),
                    data: data,
                    xhr: function() {
                        return xhr;
                    },
                    success: function (response) {
                        if (typeof response === 'object') {
                            if (!response.success && response.error === 'CSRF Token Mismatch') {
                                page.redirect(Urls.csrffailed);
                            }
                        } else if (typeof response === 'string') {
                            
                            // If we were redirected to the successful login page
                            // Or, since IE11 does not have `responseURL`, we can instead check for the popup HTML here
                            if (('responseURL' in xhr && xhr.responseURL.match(/loginsuccess=true/i)) ||
                                !response.match(/co-signin-content-container/i)) {

                                if(window.location.href.toString().indexOf('COShipping-SingleShipping') >= 0) {
                                    window.location.href = Urls.delivery;
                                } else {
                                    var refreshURL = window.location.href.concat('?checkoutlogin=true');
                                    window.location.href = refreshURL;
                                }

                            // Otherwise, repopulate the popup.
                            } else {
                                $('#dialog-container').html(response);
                                onOpen();
                            }
                        }
                    },
                    failure: function () {
                        progress.hide();
                        dialog.$container.html('<h1>' + Resources.SERVER_ERROR + '</h1>');
                    }
                });
            });

            // request password
            var $requestPasswordForm = $('[name$="_requestpassword"]');
            var $submit = $requestPasswordForm.find('[name$="_requestpassword_send"]');
            $($submit).on('click', function (e) {
                e.preventDefault();
                if (!$requestPasswordForm.valid()) {
                    return;
                }
                var data = $requestPasswordForm.serialize();
                // add form action to data
                data += '&' + $submit.attr('name') + '=';
                // make sure the server knows this is an ajax request
                if (data.indexOf('ajax') === -1) {
                    data += '&format=ajax';
                }
                $.ajax({
                    type: 'POST',
                    url: $requestPasswordForm.attr('action'),
                    data: data,
                    success: function (response) {
                        if (typeof response === 'object' &&
                                !response.success &&
                                response.error === 'CSRF Token Mismatch') {
                            page.redirect(Urls.csrffailed);
                        } else if (typeof response === 'string') {
                            $('.co-passwordrequest-confirmation-container').removeClass('hidden');
                            $('.co-forgotpassword-content-container').addClass('hidden');
                        }
                    },
                    failure: function () {
                        dialog.$container.html('<h1>' + Resources.SERVER_ERROR + '</h1>');
                    }
                });
            });
        }

        dialog.open({
            url: url,
            options: {
                dialogClass: 'checkout-modal hide-pointer signin',
                modal: true,
                draggable: false,
                width: modalWidth, // 714px TO-DO: Add logic to change width/height for tablet and mobile
                height: modalHeight, // 560px
                open: onOpen,
            }
        });
    },
    lockBodyScroll: function ($scrollElement) {
        bodyScrollLock.disableBodyScroll($scrollElement[0]);
        return;
    },
    unlockBodyScroll: function ($scrollElement) {
        if (!$scrollElement || $scrollElement.length === 0) {
            bodyScrollLock.clearAllBodyScrollLocks();
            return;
        }

        bodyScrollLock.enableBodyScroll($scrollElement[0]);
        return;
    },
    /**
     /**
     * @function
     * @description Returns the product SKU without the size included for marketing data tracking related
     * to add to cart.
     * @param {productID}
     */
    getVariationSKU: function (productID) {
        var productIdParts = productID.split("-");
        var offerID = (productIdParts.length > 0) ? productIdParts[0] : "";
        return offerID + (productIdParts.length > 1 ? "-" + productIdParts[1] : "");
    },
    showHidePassword: function(){
        var $passwordFieldWrapper = $('.form-row.password .field-wrapper');
        if($passwordFieldWrapper.length > 0){
            var $currentPasswordInput = $($passwordFieldWrapper).find('input.currentpassword');
            var $newPasswordInput = $($passwordFieldWrapper).find('input.newpassword');
            if($currentPasswordInput.length > 0){
                $currentPasswordInput.addClass('password');
            }
            if($newPasswordInput.length > 0){
                $newPasswordInput.addClass('password');
            }
            $passwordFieldWrapper.append('<input type="button" class="js-show-hide-password-btn" role="button" aria-label="Show/Hide Password"/>');
            $('.js-show-hide-password-btn').on('click', function(){
                var $passwordInput = $(this).parent().find('input.password');
                if($passwordInput.attr('type') ==  'password'){
                    $passwordInput.attr('type', 'text');
                    $(this).addClass('passwordVisible');
                }else{
                    $passwordInput.attr('type', 'password');
                    $(this).removeClass('passwordVisible');
                }
            });
        }
    },
    getDataLayerEventDetails: function (eventName) {
        return dataLayer.find(function(obj){
            return obj.event === eventName;
        })
    },
    animateElementShow: function($element, ms){
        if($element.is(':hidden')){
            $element.animate({
                height: 'toggle'
            }, ms);
        } 
    }
};
module.exports = util;
