var debounce = require('lodash/debounce');
var breakpoints = require('./breakpoints');

var $twoUpCarousels = $('.js-split-tile-carousel');
var twoUpSwiper = [];

function processSplitTileCarousels($carousels) {
    var isMobile = breakpoints.isMobile();
    var isTablet = breakpoints.isTablet();
    
    $carousels.each(function () {
        $this = $(this);
        var swiperId = $this.data('swiperid');
        var swiperObj = document.querySelector('.swiper-' + swiperId).swiper;

        if (isMobile || (isTablet && $this.hasClass('split-tile-tablet-slider'))) {
            if (!swiperObj) {
                twoUpSwiper.push(new Swiper('.swiper-' + swiperId, {
                    keyboard: {
                        enabled: true,
                        onlyInViewport: true
                    },
                    pagination: {
                        el: '.swiper-pagination-' + swiperId,
                        clickable: true
                    }
                }));
                $('.swiper-pagination').show();
            }
            return;
        }
        if (swiperObj) {
            $('.swiper-pagination').hide();
            swiperObj.destroy();
        }
        return;
    })
}

function init() {
    processSplitTileCarousels($twoUpCarousels);

    $(window).on('resize', debounce(function() {
        processSplitTileCarousels($twoUpCarousels);
    }, 200));

    return;
}

module.exports = {
    init: init
}