'use strict'

var util = require('./util');

var updateAddressForm = function(countryEl, stateCode) {
	
	var _newOptions = null;
	var _label = Resources.STATE_LABEL;
	var _initialOption = "";

	var $state, $postal, $stateUS, $stateCA, $stateAU, $addrBox = countryEl.closest(".address-box");
	var countryCode = countryEl.val();
	
	// Check if there is more than 1 address form on a page.
	if($("select[name$='_state']").length > 1 && $addrBox.length > 0) {
		$state = $addrBox.find("select[name$='_state']");
		$postal = $addrBox.find("input[name$='_postal']");
		$stateUS = $addrBox.find("select[name$='_stateUS']");
		$stateCA = $addrBox.find("select[name$='_stateCA']");
		$stateAU = $addrBox.find("select[name$='_stateAU']");
	} else {
		$state = $("select[name$='_state']");
		$postal = $("input[name$='_postal']");
		$stateUS = $("select[name$='_stateUS']");
		$stateCA = $("select[name$='_stateCA']");
		$stateAU = $("select[name$='_stateAU']");	
	}
	
	// Clear any errors and reset postal code if country changes.
	if($addrBox.length > 0) {
		$addrBox.find(".js-states").removeClass("error").show().find(".field-wrapper").removeClass("error").find("select, input").removeClass("error").attr("aria-describedby", $(this).attr("name"));
		$state.removeAttr("disabled").addClass("required");
		$addrBox.find('#' + $state.attr('name') + '-error').remove();
		$addrBox.find('#' + $postal.attr("name") + '-error').remove();
	} else {
		$(".js-states").removeClass("error").show().find(".field-wrapper").removeClass("error").find("select, input").removeClass("error").attr("aria-describedby", $(this).attr("name"));
		$state.removeAttr("disabled").addClass("required");
		$('#' + $state.attr('name') + '-error').remove();
		$('#' + $postal.attr("name") + '-error').remove();
	}
	
	// Disable text input for international.
	$state.closest(".field-wrapper").find("input.state").val("").attr("disabled", true).removeClass("required").addClass("hidden");
	
	// Disable select dropdown
	$state.attr("disabled", true).removeClass("required").addClass("hidden").next(".arrow-down").addClass("hidden");
	
	if(countryCode == "US")
	{
		_initialOption = "";

		// Enable select dropdown
		$state.attr("disabled", false).addClass("required").removeClass("hidden").next(".arrow-down").removeClass("hidden");

		_newOptions = $stateUS.children().clone();
		if($addrBox.length > 0) {
			$addrBox.find(".js-zipcode label span").text(Resources.ZIPCODE);
		} else {
			$(".js-zipcode label span").text(Resources.ZIPCODE);	
		}
	}
	else if(countryCode == "CA")
	{
		_initialOption = "";
		_label = Resources.PROVINCE_LABEL;
		
		// Enable select dropdown
		$state.attr("disabled", false).addClass("required").removeClass("hidden").next(".arrow-down").removeClass("hidden");
		
		_newOptions = $stateCA.children().clone();
		if($addrBox.length > 0) {
			$addrBox.find(".js-zipcode label span").text(Resources.POSTALCODE);
		} else {
			$(".js-zipcode label span").text(Resources.POSTALCODE);	
		}
	}
	else if(countryCode == "AU")
	{
		_initialOption = "";
		_label = Resources.TERRITORY_LABEL;
		
		// Enable select dropdown
		$state.attr("disabled", false).addClass("required").removeClass("hidden").next(".arrow-down").removeClass("hidden");

		_newOptions = $stateAU.children().clone();
		if($addrBox.length > 0) {
			$addrBox.find(".js-zipcode label span").text(Resources.POSTCODE);
		} else {
			$(".js-zipcode label span").text(Resources.POSTCODE);	
		}
	}
	else 
	{
		_label = Resources.STATE_PROVINCE;

		// Enable text input for international.
		$state = $state.closest(".field-wrapper").find("input.state");
		$state.closest(".field-wrapper").find("input.state").attr("disabled", false).addClass("required").removeClass("hidden");
		
		if($addrBox.length > 0) {
			$addrBox.find(".js-zipcode label span").text(Resources.POSTCODE);
		} else {
			$(".js-zipcode label span").text(Resources.POSTCODE);
		}
	}

	if(_newOptions != null) {
		$state.children().remove();
		$state.append(_newOptions);
		$state.addClass("required");
	}

	if($addrBox.length > 0) {
		$addrBox.find(".js-states label span").html(_label);
	} else {
		$(".js-states label span").html(_label);
	}
	
	// Event Address state/postal and registry search are optional
	if($state.closest(".form-row").hasClass("dwfrm_giftregistry_event_state") == false &&
		$state.closest(".form-row").hasClass("dwfrm_giftregistry_search_advanced_eventAddress_states_state") == false)
	{	
		$state.rules('add', {
       	 	required: true,
        	messages: {
            	required: $.validator.format(Resources.VALIDATE_DYNAMIC_REQUIRED, [_label])
        	}
    	});
	} 
	else 
	{
		// If we are in a registry event state, do not make state required.
		$state.removeClass("required");
	}
	
	if($addrBox.length > 0) {
		_label = $addrBox.find(".js-zipcode label span").text();
	} else {
		_label = $(".js-zipcode label span").text();
	}
	
	// Event Address state/postal and registry search are optional
	if ($postal.length > 0 && $postal.closest('.form-row').hasClass('dwfrm_giftregistry_event_postal') == false) {
		$postal.rules('add', {
			required: true,
			messages: {
				required: $.validator.format(Resources.VALIDATE_DYNAMIC_REQUIRED, [_label])
			}
		});
	} else if($postal.length > 0) {
		$postal.removeClass('required');
	}

	if(stateCode != "") {
		_initialOption = stateCode;
	}
	
	// Update state field
	$state.val(_initialOption);
	util.changeFormSelection($state[0], _initialOption);
};

function init() {
    
    var $country = $("select[name$='_country']");
    
    $country.on('change co.countryupdate', function () {
        updateAddressForm($(this), "");
        
        var $addrBox = $(this).closest(".address-box");
        
        if($(this).val() == "US" || $(this).val() == "CA") {
        	if($addrBox.length > 0) {
				$addrBox.find("input[name$='_phone']").mask('000-000-0000');
        	} else {
        		$("input[name$='_phone']").mask('000-000-0000');
        	}
        } else {
        	if($addrBox.length > 0) {
				$addrBox.find("input[name$='_phone']").unmask();
        	} else {
        		$("input[name$='_phone']").unmask();
        	}
        }
        
    });
    
    if($("select[name$='_country']").length == 1) {
    	updateAddressForm($("select[name$='_country']"), $(".js-shipping-form, .js-billing-form, .js-edit-address-form").data("state"));
    	
    	if($country.val() == "US" || $country.val() == "CA") {
			$("input[name$='_phone']").mask('000-000-0000');
    	}
    } else {
    	
    	$("select[name$='_country']").each(function() {
    		var $addrBox = $(this).closest(".address-box");
    		updateAddressForm($(this), $addrBox.data("state"));
    		
    		if($addrBox.length > 0) {
    			if($(this).val() == "US" || $(this).val() == "CA") {
					$addrBox.find("input[name$='_phone']").mask('000-000-0000');
    			}
    		}
    		
    	});
	}
	
	// ADA: Country & State dropdown labels
	$(".form-dropdown.country").attr("aria-label", "Select Country");
	$(".form-dropdown.state").attr("aria-label", "Select State");
	$(".form-dropdown.stateUS, .form-dropdown.stateAU, .form-dropdown.stateCA").attr({
		"aria-hidden": "true",
		"aria-label": "Select State/Province"
	});
	$(".form-field.state").attr("aria-label", "Select State/Province");
	$(".form-field.stateUS, .form-field.stateAU, .form-field.stateCA").attr({
		"aria-hidden": "true",
		"aria-label": "Select State/Province"
	});

	// on load, or when state select changes,
	// remove aria-describedby since we already have an aria-label
	$(".js-states select").removeAttr("aria-describedby");
	
	$('.js-states select').on('change', function () {
		// remove from multiple, hidden elements
		$(".js-states select").removeAttr("aria-describedby");
	});


}

module.exports.init = init;
