'use strict';

const fmUtil = require('./familyMatchingUtil');
const productTile = require('./familyMatchingProductTile');
const familyMatchingTracking = require('./familyMatchingTracking');
const util = require('../../../util');
const ms = 300;

function init() {
    $('.js-department-button').on('click', handleDepartmentButtonClick);
}

function handleDepartmentButtonClick() {
    updateButtonStyling(this);

    $('.choose-member-msg').hide();

    updateExactMatchesMessage(this);
    
    familyMatchingTracking.familyProductDetailRefinement($(this));

    if ($(this).attr('data-exact-matches-list')) {
        const controllerURL = $('.fm-pdp-department-buttons-container').attr('data-url');
        const setID = $('.fm-pdp-department-buttons-container').attr('data-pid');

        getProductTiles(controllerURL, setID, $(this).attr('data-exact-matches-list'), updateExactMatchProducts);

        if ($(this).attr('data-recommended-list')) {
            getProductTiles(controllerURL, setID, $(this).attr('data-recommended-list'), updateRecommendedProducts);
        } else {
            $('.recommended-products').hide();
        }
    }
    
    util.animateElementShow($('.fm-pdp-step.four'), ms);
}

function updateButtonStyling(departmentButton) {
    $('.js-department-button').removeClass('active')
    $(departmentButton).addClass('active');
}

function updateExactMatchesMessage(el) {
    const totalExactMatches = parseInt($(el).attr('data-exact-matches'))
    
    util.animateElementShow($('.exact-matches-msg'), ms);

    $('.exact-matches-msg').text(`${totalExactMatches} ${Resources.FM_EXACT_MATCHES}`);
}

function getProductTiles(controller, id, productIDs, callback) {
    const data = {
        id: id
    }

    fmUtil.showLoadingGraphic();

    if (productIDs) {
        data.productIDs = productIDs;
    }

    $.ajax({
        url: controller,
        type: 'GET',
        data: data,
        success: callback,
        error: (error) => {
            console.log('familyMatchingDepartmentButtons.js error:', error)
        }
    });
}

function updateExactMatchProducts(res) {
    fmUtil.hideLoadingGraphic();

    $('.product-results').html(res);

    if (!fmUtil.bundleHasItems()) {
        fmUtil.updateButtonState('select-item');
    } else {
        initSelectedItems();
    }

    util.animateElementShow($('.product-results'), ms);

    productTile.init();

    util.scrollToElement($('.fm-pdp-step.three'));
}

function updateRecommendedProducts(res) {
    $('.recommended-products .results').html(res);

    if (fmUtil.bundleHasItems()) {
        initSelectedItems();
    }

    $('.recommended-products').show();

    productTile.init();
}

function initSelectedItems() {
    const bundle = fmUtil.getCurrentBundle();
    
    for (const key of bundle.items.keys()) {
        const $sizeButton = $(`#${key.replace(/\//g, '_')}`);
        const $productTile = $sizeButton.closest('.fm-pdp-product-tile');

        productTile.addActiveProductStyling($sizeButton, $productTile);
    }
}

module.exports = {
    init: init
};
