'use strict';

var giftcert = require('../giftcert'),
    tooltip = require('../tooltip'),
    util = require('../util'),
    dialog = require('../dialog'),
    page = require('../page'),
    login = require('../login'),
    validator = require('../validator'),
    internationalFields = require('../internationalFields'),
    accountNav = require('./accountnav'),
    ajax = require('../ajax'),
    formPrepare = require('./checkout/formPrepare'),
    breakpoints = require('../breakpoints')

/**
 * @function
 * @description Initializes the events on the address form (apply, cancel, delete)
 * @param {Element} form The form which will be initialized
 */
function initializeAddressForm() {
    var $form = $('#edit-address-form');

    $form.find('input[name="format"]').remove();
    tooltip.init();
    validator.init();
    formPrepare.init({
        continueSelector: '[name$="address_edit"]',
        formSelector: '[id="edit-address-form"]'
    });
    internationalFields.init();

    /* global pca */
    if (typeof(pca) != 'undefined' && pca) { 
        pca.load();
    }

    $form.on('submit', function(e) {
        e.preventDefault();
        // Cancel form submit as we need to keep buttons as type submit for ADA compliance.
    });

    $form
        .on('click', '.apply-button', function (e) {
            e.preventDefault();
            if (!$form.valid()) {
                return false;
            }

            var url = util.appendParamToURL($form.attr('action'), 'format', 'ajax');
            var applyName = $form.find('.apply-button').attr('name');
            var formData = $form.serialize() + '&' + applyName + '=x';
            var options = {
                url: url,
                data: formData,
                type: 'POST'
            };
            $.ajax(options).done(function (data) {
                if (typeof (data) !== 'string') {
                    if (data.success) {
                        dialog.close();
                        page.refresh();
                    } else if (data.error) {
                        page.redirect(Urls.csrffailed);
                    } else {
                        window.alert(data.message);
                        return false;
                    }
                } else {
                    $('#dialog-container').html(data);
                    account.init();
                    tooltip.init();
                }
            });
        })
        .on('click', '.cancel-button, .close-button', function (e) {
            e.preventDefault();
            dialog.close();
        })
        .on('click', '.delete-button', function (e) {
            e.preventDefault();
            if (window.confirm(String.format(Resources.CONFIRM_DELETE, Resources.TITLE_ADDRESS))) {
                var url = util.appendParamsToUrl(Urls.deleteAddress, {
                    AddressID: $form.find('#addressid').val(),
                    format: 'ajax'
                });
                $.ajax({
                    url: url,
                    method: 'POST',
                    dataType: 'json'
                }).done(function (data) {
                    if (data.status.toLowerCase() === 'ok') {
                        dialog.close();
                        page.refresh();
                    } else if (data.message.length > 0) {
                        window.alert(data.message);
                        return false;
                    } else {
                        dialog.close();
                        page.refresh();
                    }
                });
            }
        })
        .on('click', '.tooltip', function(e) {
            e.preventDefault();
        });

    validator.init();
}
/**
 * @private
 * @function
 * @description Toggles the list of Orders
 */
function toggleFullOrder() {
    $('.order-items')
        .find('li.hidden:first')
        .prev('li')
        .append('<a class="toggle">View All</a>')
        .children('.toggle')
        .on('click', function () {
            $(this).parent().siblings('li.hidden').show();
            $(this).remove();
        });
}
/**
 * @private
 * @function
 * @description Binds the events on the address form (edit, create, delete)
 */
function initAddressEvents() {
    var addresses = $('#addresses');
    if (addresses.length === 0) { return; }

    addresses.on('click', '.address-edit, .address-create', function (e) {
        e.preventDefault();
        //country3 not getting passed to form select so country getting overwritten on save
        var cc3 = $(this).data('countrycode') || 'US';
        addresses.find('.current-edit-link').removeClass('current-edit-link');
        $(this).addClass('current-edit-link');

        dialog.open({
            url: this.href,
            options: {
                open: function () {
                    $('select[id$="_country"] option[value="' + cc3 + '"]').prop('selected', true);
                    initializeAddressForm();
                },
                dialogClass: 'co-edit-addaddress ma-edit-address',
                width: '90%',
                modal: true,
                draggable: false
            }
        });
    }).on('click', '.delete', function (e) {
        e.preventDefault();
        if (window.confirm(String.format(Resources.CONFIRM_DELETE, Resources.TITLE_ADDRESS))) {
            $.ajax({
                url: util.appendParamToURL($(this).attr('href'), 'format', 'ajax'),
                dataType: 'json'
            }).done(function (data) {
                if (data.status.toLowerCase() === 'ok') {
                    page.redirect(Urls.addressesList);
                } else if (data.message.length > 0) {
                    window.alert(data.message);
                } else {
                    page.refresh();
                }
            });
        }
    });
}
/**
 * @private
 * @function
 * @description Binds the events of the payment methods list (delete card)
 */
function initPaymentEvents() {
    $('.add-card').on('click', function (e) {
        e.preventDefault();
        dialog.open({
            url: $(e.target).attr('href'),
            options: {
                open: initializePaymentForm
            }
        });
    });

    var paymentList = $('.payment-list');
    if (paymentList.length === 0) { return; }

    util.setDeleteConfirmation(paymentList, String.format(Resources.CONFIRM_DELETE, Resources.TITLE_CREDITCARD));

    $('form[name="payment-remove"]').on('submit', function (e) {
        e.preventDefault();
        // override form submission in order to prevent refresh issues
        var button = $(this).find('.delete');
        $('<input/>').attr({
            type: 'hidden',
            name: button.attr('name'),
            value: button.attr('value') || 'delete card'
        }).appendTo($(this));
        var data = $(this).serialize();
        $.ajax({
            type: 'POST',
            url: $(this).attr('action'),
            data: data
        })
            .done(function () {
                page.redirect(Urls.paymentsList);
            });
    });
}

function initializePaymentForm() {
    $('#CreditCardForm').on('click', '.cancel-button', function (e) {
        e.preventDefault();
        dialog.close();
    });

}

function initCreateAccountEvents() {
	var $form = $('#RegistrationForm');
	if ($form.length) {
		if ($form.find('.mal-termsofusecheckbox-container').length) {
			var $touCB = $form.find('.agreetotermsofuse'), $touErr = $(".mal-termsofusecheckbox-container > .error-msg");
			$form.on('submit', function(e) {
				if (!$touCB.prop('checked')) { e.preventDefault(); $touErr.removeClass("hidden"); return false; }
			});
			$touCB.on('change', function(e) {
				if ($touCB.prop('checked')) { $touErr.addClass("hidden"); }
			});
		}
	}
}

function initMyAccountEvents() {
	if ($(".maoh-orderslist").length > 0) {
		var options = {};
	    options.url = util.ajaxUrl($(".maoh-orderslist").attr('data-geturl'));
	    options.target = $('.maoh-orderslist');
		ajax.load(options);
	}
	var maoLnkSel = ".mao-link";
	if ($(maoLnkSel).length > 0) {
		$('body').on('click', maoLnkSel, function(e) {
			addClickEventToDataLayer($(e.target).closest(maoLnkSel));
		});
	}
	var navLnkSel = ".ma-content";
	if ($(navLnkSel).length > 0) {
		$(navLnkSel).on('click', 'a', function(e) {
			addClickEventToDataLayer($(e.target));
		});
	}
}

function addClickEventToDataLayer($link) {
	var eventText = $link.attr('data-clickevent');
	if ((typeof eventText !== 'undefined') && eventText.length > 0) {
		window.dataLayer.push({
			event: eventText
		});
	}
}

function showNextChildInputs() {
    var $nextChild = $('.child-hide:first');
    $nextChild.removeClass('child-hide');
    $nextChild.addClass('child-border');
}

function initLoyaltyEvents() {
    $('.child-show-link').on('click keypress', function() {
        showNextChildInputs();
        var remainingElts = $('.child-hide');
        if(remainingElts.length == 0) {
            $(this).addClass('child-add-link-hide');
        }
    });

    $('.loyalty-childform.profile-update').on('submit', function(e) {
        var $childInputsWrapper = $('.child-border');
        $childInputsWrapper.removeClass('error');

        if ($('.child-hide').length && !$childInputsWrapper.length) {
            e.preventDefault();

            showNextChildInputs();
        } else if ($childInputsWrapper.length && !$childInputsWrapper.find('input').val() && !$childInputsWrapper.find('select').val()) {
            e.preventDefault();
            
            $childInputsWrapper.addClass('error');
            $childInputsWrapper.find('.child-missing').show();
        }
    });

    var cookies = document.cookie.split(';');
    var dontShowUpgradeModal = 'false';
    for (var i = 0; i < cookies.length; i++) {
        var tokens = cookies[i].split('=');
        var cookieKey = tokens[0].trim();
        if (cookieKey === 'dontShowUpgradeModal') {
            dontShowUpgradeModal = tokens[1];
        }
    }
        if($('.js-non-loyalty-indicator').length > 0 && $('.js-non-loyalty-indicator')[0].value == 'true' && dontShowUpgradeModal != 'true'
        && document.location.pathname.substring(document.location.pathname.lastIndexOf('/') + 1) != 'EmarsysNewsletter-EmailSettings'){
            $('.js-site-overlay').addClass('loyaltyUpgradeModalOverlay');
            $('html').addClass('has-overlay-active');
            $('.LoyaltyUpgradeModal').removeClass('hidden');
            window.onclick = function (event) {         
                if ( event.target !== $('.LoyaltyUpgradeModal')[0] && !$('.LoyaltyUpgradeModal').has(event.target).length) {
                    $('.LoyaltyUpgradeModal').addClass('hidden');
                    $('.js-site-overlay').removeClass('loyaltyUpgradeModalOverlay');
                    $('html').removeClass('has-overlay-active');
                    window.onclick = null;
                }
            }
        }

    $('.LoyaltyUpgradeModal').on('click', '.loyaltyUpgradeClose', function(e) {
        $('.LoyaltyUpgradeModal').addClass('hidden');
        $('.js-site-overlay').removeClass('loyaltyUpgradeModalOverlay');
        $('html').removeClass('has-overlay-active');
    });

    $('.LoyaltyUpgradeModal').on('click', '.upgrade-dont-show-button', function(e) {
        e.preventDefault();
        $('.LoyaltyUpgradeModal').addClass('hidden');
        $('.js-site-overlay').removeClass('loyaltyUpgradeModalOverlay');
        $('html').removeClass('has-overlay-active');
        var d = new Date();
        d.setTime(d.getTime() + (90*24*60*60*1000));
        var expires = "expires="+ d.toUTCString();
        document.cookie = "dontShowUpgradeModal = true;" + expires + ";path=/";
    });
}

function initPhoneNumberModal() {
    var $phoneFieldWrapper = $('.joinLoyalty .tel .field-wrapper');
    $phoneFieldWrapper.append('<input type="button" class="js-phonenumber-tooltip" role="button"/>');

    $('.joinLoyalty').on('click', '.js-phonenumber-tooltip', function(e) {
        e.preventDefault();

        var modalWidth, modalHeight, positionMy, positionAt;

        if(!util.isMobile() && window.innerWidth < 768){
            modalWidth = "375";
            modalHeight = 'auto';
            positionMy = 'right+8 top+33';
            positionAt = 'right';        
        } else if ((util.isMobile() && window.screen.width < 768) || breakpoints.isMobile()) {
            modalWidth = '100%';
            modalHeight = 'auto';
            positionMy = 'left top+33';
            positionAt = 'left';
        } else if ((util.isMobile() && window.screen.width > 768) || breakpoints.isTablet()) {
            modalWidth = 375;
            modalHeight = 'auto';
            positionMy = 'right+25 top+33';
            positionAt = 'right';
        } else if ((!util.isMobile() && window.screen.width > 1081) || breakpoints.isDesktop()) {
            modalWidth = "375";
            modalHeight = 'auto';
            positionMy = 'right+41 top+33';
            positionAt = 'right';
        }

        var settings = {
            url: $('.joinLoyalty .phoneInfo').data('modal-content'),
            options: {
                dialogClass: 'checkout-modal gift-help',
                modal: true,
                draggable: false,
                width: modalWidth,
                height: modalHeight,
                open: function() {
                    $('.ui-widget-overlay').css('opacity','0');
                    $('.ui-dialog-titlebar-close').trigger('focus');
                },
                position: {
                    my: positionMy,
                    at: positionAt,
                    of: '.joinLoyalty .js-phonenumber-tooltip',
                    collision: "fit none"
                }
            }
        };

        dialog.open(settings);

        $(window).on('resize', function() {
            dialog.close();
        });

        return false;
    });
}

/**
 * @private
 * @function
 * @description Binds the events of the order, address and payment pages
 */
function initializeEvents() {
    toggleFullOrder();
    initAddressEvents();
    initPaymentEvents();
    login.init();
    initCreateAccountEvents();
    initMyAccountEvents();
    initLoyaltyEvents();
    initPhoneNumberModal();
}

var account = {
    init: function () {
        initializeEvents();
        giftcert.init();
        accountNav.init();

        $('.phonenumber').each(function () {
            var accountPhoneNumber = $(this).html();
            if (accountPhoneNumber) {
                accountPhoneNumber = accountPhoneNumber.indexOf('-') > 0 ? accountPhoneNumber : (accountPhoneNumber.slice(0, 3) + "-" + accountPhoneNumber.slice(3, 6) + "-" + accountPhoneNumber.slice(6));
                $(this).replaceWith(accountPhoneNumber);
            }
        });

        // Initialize registration form validation
        if($("#RegistrationForm").length > 0) {
        	formPrepare.init({
        		continueSelector: '[name$="profile_confirm"]',
       			formSelector: '[id="RegistrationForm"]'
    		});
    	}
    },
    initCartLogin: function () {
        login.init();
    }
};

module.exports = account;
