'use strict';

function isValidEmail(el, validationObject, modalSignup) {
    var $element = $(el);
    var emailValue = $element.val();
    var isModalSignup = modalSignup;

    if (emailValue.length > 0) {
        var emailRegEx = /^\w+([.-]?\w+)*(\+[\w]{1,})?@\w+([.-]?\w+)*(\.\w{2,3})+$/;
        var currentEmail = emailRegEx.test(emailValue);

        if (currentEmail !== true) {
            addErrorMessages($element.get(0), "email");

            validationObject.isValidEmail = false;
        } else {
            clearErrors(el);

            validationObject.isValidEmail = true;
        }
    } else if (emailValue.length == 0 && isModalSignup == true) {
        clearErrors(el);

        validationObject.isValidEmail = true;
    }

    return validationObject.isValidEmail;
}

function isValidSMS(el, validationObject, modalSignup){
    var $element = $(el);
    var smsValue = $element.val();
    var isModalSignup = modalSignup;

    if (smsValue.length > 0 && smsValue.length < 14) {
        addErrorMessages($element.get(0), "sms");

        validationObject.isValidSMS = false;
    } else if ((smsValue.length == 0 && isModalSignup == true) || (smsValue.length == 14)){
        clearErrors(el);

        validationObject.isValidSMS = true;
    }

    return validationObject.isValidSMS;
}

function clearErrors(el){
    var $element = $(el);
    $element.removeClass('error');
    $element.prev('.newsletterSubscribeLabel').removeClass('error');
    $element.next('.js-error-container').removeClass('error').empty();
}

function addErrorMessages(el, inputType){
    if(el){
        var $element = $(el);
        var resourceMessage = inputType == "email" ? Resources.VALIDATE_EMAIL : Resources.VALIDATE_PHONESMS;
        $element.addClass('error');
        $element.prev('.newsletterSubscribeLabel').addClass('error');

        var errorMessage = document.createElement('span')
        errorMessage.innerHTML = resourceMessage;
        var $errorElement = $(errorMessage);
        $errorElement.addClass('error')
        $errorElement.attr('aria-live','polite');
        $errorElement.attr('id',el.id + '-error');
        if ($element.parents('.js-sms-form').length > 0) {
            $errorElement.addClass('emarsysErrorMsg');
            var $errorContainer = $element.parent().find('.js-sms-error');
            $errorContainer.empty();
            $errorElement.appendTo($errorContainer);
        } else if($element.parent().find('#' + el.id + '-error').length === 0){
            $errorElement.appendTo($($element).parent());
        }
    }
}

function validatePhoneNumberFormat(el){
	var $this = $(el);
	var phoneNumber = $this.val().replace(/\D/g, "");

	if (phoneNumber.length > 0 && phoneNumber.length < 3) {
		phoneNumber = '(' + phoneNumber;
	} else if (phoneNumber.length > 3 && phoneNumber.length < 6) {
		phoneNumber = '(' + phoneNumber.slice(0,3) + ') ' + phoneNumber.slice(3);
	} else if (phoneNumber.length > 6) {
		phoneNumber = '(' + phoneNumber.slice(0,3) + ') ' + phoneNumber.slice(3, 6) + '-' + phoneNumber.slice(6);
	}

	$this.val(phoneNumber);
}

function updateFormSubmit($submitButton, validationObject){
    $submitButton.prop('aria-disabled', false).removeClass('disabled');
    validationObject.isValidForSubmit = true;
}

function validateSubmit($newsForm, validationObject){
    var $email;
    var $phone;
    var $submit;

    if ($newsForm.hasClass('js-newsletter-modal-form')) {
        $email = $newsForm.find('.js-newsletter-email').length > 0 ? isValidEmail($newsForm.find('.js-newsletter-email').get(0), validationObject, true) : false;
        $phone = $newsForm.find('.js-newsletter-phone').length > 0 ? isValidSMS($newsForm.find('.js-newsletter-phone').get(0), validationObject, true) : false;
        $submit = $newsForm.find('.js-newsletter-modal-submit');

        if (($email != false && $newsForm.find('.js-newsletter-email').val().length > 0) || ($phone != false && $newsForm.find('.js-newsletter-phone').val().length > 0)) {
            updateFormSubmit($submit, validationObject);
        } else {
            addErrorMessages($newsForm.find('.js-newsletter-email').get(0), "email");
            addErrorMessages($newsForm.find('.js-newsletter-phone').get(0), "sms");
            validationObject.isValidForSubmit = false;
        }
    } else {
        $email = $newsForm.find('.js-email').length > 0 ? isValidEmail($newsForm.find('.js-email').get(0), validationObject, false) : false;
        $phone = $newsForm.find('.js-sms-phone').length > 0 ? isValidSMS($newsForm.find('.js-sms-phone').get(0), validationObject, false) : false;
        $submit = $newsForm.find('.fml-mailing-submit');

        if ($email != false || $phone != false) {
            updateFormSubmit($submit, validationObject);
        } else {
            addErrorMessages($newsForm.find('.js-email').get(0), "email");
            addErrorMessages($newsForm.find('.js-sms-phone').get(0), "sms");
            validationObject.isValidForSubmit = false;
        }
    }
    
    return validationObject.isValidForSubmit;
}

module.exports = {
	isValidEmail: isValidEmail,
	isValidSMS: isValidSMS,
    clearErrors: clearErrors,
    validatePhoneNumberFormat: validatePhoneNumberFormat,
    validateSubmit: validateSubmit
};
