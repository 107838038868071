'use strict';

var progress = require('../../progress');

exports.init = function() {
    var billingPage = $('.billing');
    if(window.location.href.indexOf('haloyal=true') > -1) {
        var errorIcon = $('.voucher-wrapper').attr('data-error-icon');
        var promoFieldContainer = $('.loyaltyMessaging');

        if ($(billingPage).length > 0) {
            var $voucherCombineWrapper = $('#primary');
        } else {
            var $voucherCombineWrapper = $('.scp-brand-voice');
        }
       
        $(promoFieldContainer).html(Resources.VOUCHER_PROMO_COMBINE_ERROR);

        if (window.location.href.indexOf('hideerror=true') > -1) {
            $('loyalty-combine-error').remove();
        } else {
            $($voucherCombineWrapper).prepend('<div class="loyalty-combine-error"><div class="error-message">' + '<img src="' + errorIcon + '">' + Resources.VOUCHER_COMBINE_ERROR + '</div></div>');
        }

        $('html, body').animate({
            scrollTop: $("#main").offset().top
        }, 500);
    }
    $('.voucher-input').on('change', function() {
        progress.show();
        var $voucherInput = $(this);
        var $voucherWrapper = $voucherInput.closest('.voucher-wrapper');
        var loyaltyRewardsChecked = $(this).is(':checked');
        var couponCode = $('.coupon-code .couponId');
        var couponCodes = [];

        if (couponCode.length > 0) {
            $(couponCode).each(function(){
                couponCodes.push($(this).text());
            });
        } else {
            couponCodes = false;
        }

        if (loyaltyRewardsChecked == true) {
            var url = $voucherInput.data('urlapply') + '?loyaltyrdmcheck=' + loyaltyRewardsChecked + '&couponarray=' + couponCodes;
        } else {
            var url = $voucherInput.data('urlremove') + '?loyaltyrdmcheck=' + loyaltyRewardsChecked;
        }

        $.get(url, function(data) {
            if (data.loaltyRemoveError == true) {
                $('.loyalty-combine-error').remove();
            }

            if (data && data.success && data.loyaltyCombine) {
                if ($voucherWrapper.hasClass('billing-vouchers')) {
                    window.location = Urls.billingPage;
                } else {
                    window.location = Urls.cartShow;
                }
            } else {
                $('.voucher-error, .loyalty-combine-error').remove();

                if(data.loyaltyCombine == false) {
                    if ($voucherWrapper.hasClass('billing-vouchers')) {
                        window.location = Urls.billingPage + '?haloyal=true&hideerror=' + data.loaltyRemoveError;
                    } else {
                        window.location = Urls.cartShow + '?haloyal=true&hideerror=' + data.loaltyRemoveError;
                    }
                } else {
                    $voucherWrapper.after('<div class="error voucher-error">' + Resources.VOUCHER_ERROR + '</div>');
                }
            }
        });
    });
}
