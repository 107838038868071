'use strict';

var ajax = require('../../ajax');
var validator = require('../../validator');
var progress = require('../../progress');
var util = require('../../util');
var images = require('./images');
var video = require('./video');
var analytics = require('./analytics');
var turnto = require('../../turnto');
var pixlee = require('../../pixlee');
var wishlist = require('./wishlist');
var afterpaymessaging = require('../../afterpaymessaging');

// Borderfree Requirement
var bfxprice = require('../../bfxprice');

var swatchesPerRowTablet = 10.5;
var swatchesPerRowMobile = 6.5;

var $window;
var $pdpMain;
var $pdpQv;
var ns = '.pdpapv';

var debounce = require('lodash/debounce');

function init() {
    $window = $(window);
    $pdpMain = $('#pdpMain');
    initSwatchCarousels();
    $(window)
        .off('resize', debouncedInitSwatchCarousels)
        .on('resize', debouncedInitSwatchCarousels);
    intlWishlistHide();
    initEvents($pdpMain);
    wishlist.init();
}

function initQuickView() {
    $window = $(window);
    $pdpQv = $('#pdpQv');
    initSwatchCarousels();
    $(window)
        .off('resize', debouncedInitSwatchCarousels)
        .on('resize', debouncedInitSwatchCarousels);
    intlWishlistHide();
    turnto.renderTileStars();
    initEvents($pdpQv);
}

/**
 * Init swatch carousel for mobile and tablet
 * Loop over each swatch anchor group and init if
 * the swatch count exceeds the row threshold
 */
function initSwatchCarousels() {
    var swiper = undefined;

    if ($window.width() > 1080) { return; }

    $('.js-swatch-anchor-group').each(function() {
        if (!swiper) {
            swiper = new Swiper('.pdp-aplus-bucketed-colors.swiper', {
                keyboard: {
                    enabled: true,
                    onlyInViewport: true
                },
                slideToClickedSlide: true,
                slidesPerView: swatchesPerRowMobile,
                spaceBetween: 5,
                breakpoints: {
                    // when window width is >= 769px
                    769: {
                        slidesPerView: swatchesPerRowTablet,
                        spaceBetween: 5
                    }
                },
            });
        }
    });
}

var debouncedInitSwatchCarousels = debounce(function () {
    initSwatchCarousels();
}, 200);

/**
 * Setup Event Handlers for Swatch Clicks
 * Permit swatch de-selection on Product Set pages
 * where 'Add Set Items' is enabled as an outfit builder
 * @param {jQuery} $pdpContainer
 */
function initEvents($pdpContainer) {
    var isSetPDP = $pdpContainer.data('product-type') === 'set';

    $pdpContainer.off(ns).on('click' + ns, '.js-swatchanchor ', function(e) {
        e.preventDefault();
        var $this = $(this);
        var $parent = $this.parents('.swatchanchor');
        var cannotDeselect = !isSetPDP;

        if ($this.hasClass('unselectable') || $parent.hasClass('unselectable')) { return; }
        if (cannotDeselect && ($this.hasClass('selected') || $parent.hasClass('selected'))) { return; }

        $this.toggleClass('selected');
        $parent.toggleClass('selected');
        updateContent(this.href, $pdpContainer, $this);
    });
    // work in progress, still trying to consolidate the code better and make the class more specific
    $pdpContainer.on('change', '.form-dropdown', function(e) {
        e.preventDefault();
        var $this = $(this);
        var $parent = $this.parents('.swatchanchor');
        var cannotDeselect = !isSetPDP;

        if ($this.hasClass('unselectable') || $parent.hasClass('unselectable')) { return; }
        if (cannotDeselect && ($this.hasClass('selected') || $parent.hasClass('selected'))) { return; }

        $this.toggleClass('selected');
        $parent.toggleClass('selected');
        updateContent(this.value, $pdpContainer, $this);
    });
    initIntlAddToCartRestrict();
    
}


function intlWishlistHide() {
    if (SessionAttributes && SessionAttributes.BFX_INTERNATIONAL) {
        $('.js-pdp-aplus-add-wishlist').addClass('intl-hide');
        $('.pdp-aplus-addcart__form').addClass('full-width');
    }
}

function initIntlAddToCartRestrict() {
    var $target = $('.bfx-add-to-bag-disable');
    // check if the target exists before trying to attach mutation observer
    if (!$target.length) {
        return;
    }
    var config = { childList: true };
    var callback = function(mutationsList, observer) {
        mutationsList.forEach(function(mutation) {
            if (mutation.type === 'childList') {
                $('.bfx-international-restriction-message').removeClass('hidden');
                $('.js-pdp-aplus-add-to-cart').removeClass('is-black').addClass('intl-disable');
            }
        });
    }

    var observer = new MutationObserver(callback);
    observer.observe($target[0], config);
}

function emarsysSendData(vIdSrc) {
    var variationId;
    var variationGroupId = $('.js-product-ids').data('cvg');

    variationGroupId = variationGroupId.toString().split('-')[0];

    if (vIdSrc == 'pdp') {
        variationId = $('.pdp-aplus-bucketed-color.selected a').data('swatch-id');
    } else {
        variationId = $('#pdpQv .pdp-aplus-bucketed-color.selected a').data('swatch-id');
    }
    
    var colorVariation = variationGroupId + '-' + variationId;

    if (ScarabQueue) {
        ScarabQueue.push(['view', colorVariation]);
        ScarabQueue.push(['go']);
    }
}

/**
 * Update the DOM with new variation content
 * Updates content in 3 places
 * - js-pdp-variations
 * - js-pdp-variations-top
 * - js-pdp-images
 * @param {String} url directed to Product-Variation controller for specified variant
 * @param {String} swatchID to use to determine if the page needs to scroll to the element
 * @param {JQuery} $pdpContainer to scope the content updates
 */
function updateContent(url, $pdpContainer, $swatch) {
    if (!url) { return; }
    progress.show($pdpContainer);

    var pdpContainerId = '#' + $pdpContainer.attr('id');
    var params = {
        format: 'ajax',
        source: $pdpContainer.data('product-type')
    };

    // ensure quickview updated data is always
    // marked as being for the quick view
    if (util.isQuickviewOpen()) {
        params.qv = '1';
    }

    var updatedUrl = util.removeParamFromURL(url, 'source');
    updatedUrl = util.appendParamsToUrl(updatedUrl, params);
    updatedUrl = analytics.addPageDataAnalyticsParams(updatedUrl);
    // temporary param while development is still in progress
    if($('.pdpLite').length > 0){
        updatedUrl = util.appendParamsToUrl(updatedUrl, {'pdplite': 'true'});
    }
    ajax.load({
        url: updatedUrl,
        callback: function (data) {
            var $pdpVariations = $swatch.parents('.js-pdp-variations');
            var $setTarget = $swatch.parents('.js-set-product');

            /**
            * For product sets the tiles are replaced directly instead of the
            * overall variations component. This allows features like cross
            * sell to function
            */
            if ($setTarget.length) {
                $setTarget.replaceWith(data);
            } else {
                $pdpVariations.empty().html(data);
            }

            var productIDsData = $pdpVariations.find('.js-product-ids').data();
            var productCVG = productIDsData.cvg;

            // move new content loaded to its correct container
            var $topVar = $(pdpContainerId + ' .js-pdp-variations-top-load');
            $(pdpContainerId + ' .js-pdp-variations-top').html($topVar.html());
            $topVar.remove();

            var $pixleeEnabled = SitePreferences.PIXLEE_ENABLED;
            
            if ($swatch.parents('.pdp-aplus__variant').length > 0 && !$swatch.parents('.pdp-aplus__variant').hasClass('is-amount')) {
                var variationIdSource;
                var $images = $(pdpContainerId + ' .js-pdp-images-load');
                $(pdpContainerId + ' .js-pdp-images').html($images.html());
                $images.remove();
                
                /**
                * If enabled detach pixlee header and details from DOM on desktop
                * only and append pixlee container to the PDP left column
                */
                if ($pixleeEnabled) {
                    var loadType = 'ajax';

                    // Initiate pixlee with the newly built DOM structure
                    if (typeof Pixlee !== 'undefined') {
                        pixlee.init(loadType);
                    }
                }

                // Push variation data to Emarsys
                if (util.isQuickviewOpen()) {
                    variationIdSource = 'quickview';
                } else {
                    variationIdSource= 'pdp';
                }
                emarsysSendData(variationIdSource);

                if (util.isQuickviewOpen()) {
                    images.initQuickView();
                } else {
                    images.init();
                }

                video.init();
            }

            // init/refresh components
            if (util.isQuickviewOpen()) {
                initSwatchCarousels();
                $(window)
                    .off('resize', debouncedInitSwatchCarousels)
                    .on('resize', debouncedInitSwatchCarousels);
                turnto.renderTileStars();

                // set data on variation container for product set functionality
                updateSetData($pdpVariations, productCVG);
            } else {
                initSwatchCarousels();
                $(window)
                    .off('resize', debouncedInitSwatchCarousels)
                    .on('resize', debouncedInitSwatchCarousels);

                $(document).trigger('scroll');

                // trigger sticky kit element size re-calculation
                $(document.body).trigger('sticky_kit:recalc');

                // set data on variation container for product set functionality
                updateSetData($pdpVariations, productCVG);
                updatePid(productCVG);

                // Try to update turnto reviews widget with new variation group id on PDP only (not quickview)
                try {
                    if (productCVG.length && params.source !== 'set') {
                        TurnToCmd('set', { 'sku': productCVG });
                        TurnToCmd('topComments.process');
                        turnto.loadTeaserCounts(productCVG);
                    }
                } catch (e) {
                    // silently fail if there is an issue
                }

                $(document).trigger('ha:pdpVariationUpdate', [$swatch, productIDsData]);
            }

            analytics.updateData();
            validator.init();
            progress.hide();

            // Borderfree Adjustment
            bfxprice.init();
            intlWishlistHide();
            initIntlAddToCartRestrict();

            // Adds styling class to resolve styling bug on carousels
            if ($('.more-to-love-recommendations .swiper').length > 0){
                $('.more-to-love-recommendations .swiper').each(function(){
                    this.classList.add('reinitialized-swiper');
                });
            }

            wishlist.init();

            // Get selected sku ID
            var skuSelected = $('.pdp-aplus-swatch.selected span h3').text().trim();
            var skuData = [];
            
            // Format variant SKU ATS data
            $('.pdp-aplus-bucketed-colors a').each(function(){
                var skuAtsData = $(this).attr('data-sizes-ats');
                var skuATS = JSON.parse(skuAtsData);
                var vGroupID = $(this).attr('data-swatch-id');
                var newSkuAtsData = {
                    'varGroup': vGroupID, 
                    'skuATS': skuATS
                };

                // Push to array so we can access and compare later
                skuData.push(newSkuAtsData);
                                    
            });

            // Check SKU ATS against variant groups and update style to indiacte availability
            for (var i in skuData) {
                var variationGroup = skuData[i].varGroup;
                var dataSkuAts = skuData[i].skuATS;

                if(dataSkuAts[skuSelected] == 0){
                    $('.pdp-aplus-bucketed-colors a').each(function() {
                        if($(this).attr('data-swatch-id') == variationGroup) {
                            $(this).addClass('is-unselectable');
                        }
                    });

                }
            }

            // Stripe AfterPay Messaging
            afterpaymessaging.init();
        }
    });

    // Update pid outside of variation load area
    function updatePid(parentPID) {
        var pid = $('input[name="pid"]').val();

        // Update in expander
        $('.js-expander-pid').html(pid || parentPID);
    }
}

/**
 * Update the variations container with CVG data
 * Then trigger a variant.changed event
 * @param {jQuery} $pdpVariations updated variations container
 * @param {String} cvg Variation Group ID
 */
function updateSetData($pdpVariations, cvg) {
    if (cvg && cvg.length) {
        $pdpVariations.attr('data-cvg', cvg);
    }

    // trigger variant change event for product set 'add to bag'
    if (util.isQuickviewOpen()) {
        $('.pdp-aplus-addcart__form ').prepend('<input type="hidden" name="source" value="quickview" />');
        $pdpQv.trigger('variant.changed', {element: $pdpVariations});
    } else {
        $pdpMain.trigger('variant.changed', {element: $pdpVariations});
    }
}

module.exports = {
    init: init,
    initQuickView: initQuickView
};
