'use strict';

var pixlee = {
    firePixleeWidget: function(loadType) {
        // Getting Pixlee configuration data from Site Preferences
        var $pixleeApiKey = SitePreferences.PIXLEE_API_KEY;
        var $pixleeWidgetAccountID = Number(SitePreferences.PIXLEE_WIDGET_ACCOUNT_ID);
        var $pixleeWidgetID = Number(SitePreferences.PIXLEE_WIDGET_ID);
        var $pixleeProductID;

        // Call init function once per page load and get correct variation group if ajax
        if (loadType == 'ajax' && $('.swatchanchor.selected').length > 0) {
            var $pixleeProductInfo = JSON.parse($('.swatchanchor.selected .js-swatchanchor').attr('data-analytics'));
            $pixleeProductID = $pixleeProductInfo.variationGroup;
            Pixlee.close(true);
        } else {
            var $pixleeProductInfo = $('.pdp-aplus-addcart__form input');

            // Getting product id relative to variation selection
            $($pixleeProductInfo).each(function(){
                if($(this).attr('name') == 'pid') {
                    $pixleeProductID = $(this).val();
                }
            });

            // Init on PageLoad
            Pixlee.init({apiKey: $pixleeApiKey});
        }

        // Call product widget
        Pixlee.addProductWidget({
            accountId: $pixleeWidgetAccountID,
            widgetId: $pixleeWidgetID,
            skuId: $pixleeProductID,
            subscribedEvents: ['photoOpened', 'ctaClicked']
        });

        /**
        * Need the resize function called immediately after any widgets are
        * loaded to insure iframe resizes properly relative to page content
        */
        Pixlee.resizeWidget();
            

        if (document.getElementById('pixlee-events-init')) { // presence of this element in the DOM means tracking is allowed
            Pixlee.acceptCookiePolicy();
        }
    },
    init: function(loadType) {
        var $isWuickView = $('#QuickViewDialog');
        if ($isWuickView.length == 0) {
            this.firePixleeWidget(loadType);
        }
    }
};

module.exports = pixlee;
