'use strict';

var account = require('./account'),
    breakpoints = require('../breakpoints'),
    util = require('../util'),
    promoCode = require('./checkout/promoCode'),
    progressBar = require('./checkout/progressBar'),
    loyaltyRewards = require('./checkout/loyaltyRewards'),
    progress = require('../progress'),
    applepay = require('../applepay'),
    afterpaymessaging = require('../afterpaymessaging');

// Borderfree Requirement
var borderfree = require('../borderfree');

/**
 * @private
 * @function
 * @description Binds events to the cart page (edit item's details, bonus item's actions, coupon code entry)
 */
function initializeEvents() {
    borderfree.handleEmptyCart();

    $('#cart-table').on('click', '.bonus-item-actions a, .item-details .bonusproducts a', function (e) {
        e.preventDefault();
    });

    $('form input[name$="_couponCode"]')
        // allow enter key for coupon code entry
        .on('keydown', function (e) {
            if (e.which === 13 && $(this).val().length !== 0) {
                $('#add-coupon').trigger('click');
                return false;
            }
        })
        // force uppercase for coupon code
        .on('input', function () {
            var $inputField = $(this);
            var inputValue = $inputField.val();
            $inputField.val(inputValue.toUpperCase());
        });

    //to prevent multiple submissions of the form when removing a product from the cart
    var removeItemEvent = false;
    $('button[name$="deleteProduct"]').on('click', function (e) {
        if (removeItemEvent) {
            e.preventDefault();
            progress.show();
        } else {
            removeItemEvent = true;
            progress.show();
        }
    });
    
    $('.narvareddcalc').on('click', 'button', function(e) {
    	var currentZip = $('.narvareddcalc button:first').html().split('<span')[0];
    	window.setTimeout(function() { narvarZipChangeCheck(currentZip, false); }, 1500);
    });
    
    $('.scp-item-user-actions').on('click', '.scp-add-to-wishlist', function(e) {
    	var $target = $(e.target);
    	var data = {
	        event: 'add-to-wishlist',
	        productSku: $target.attr('data-productid'),
	        productName: $target.attr('data-productname'),
	        actionLocation: 'My Bag',
	        badging: ''
	    };
	    window.dataLayer.push(data);
    });

    // Handle start checkout click and send to GTM
    $('body').on('click', '.main .js-checkout-start', function(e) {
        if ($('.basket-limit-error').length > 0 || $('.product-limit-error').length > 0) {
            return false;
        }

    	var gtmOption = ($(this).hasClass('js-apple-button')) ? 'applepay express' : null;
        var eventOptions = {};
        if ($('.js-gtm-gift-order').length > 0) {
        	eventOptions.orderContainsGift = $('.js-gtm-gift-order').val() === 'true' ? true : false;
        }
        // Check for applied coupon
        if ($('.js-gtm-coupon-applied').length > 0 && $('.js-gtm-coupon-applied').val() !== '') {
            eventOptions.promoCodeSubmitted = $('.js-gtm-coupon-applied').val();
        }
        // send button location
        var source = 'desktop';
        if (breakpoints.isMobile()) {
            if ($(this).closest('form').hasClass('scp-action-checkout')) {
                source = 'top-mobile';
            } else if ($(this).closest('form').hasClass('scp-cart-items-form')) {
                source = 'bottom-mobile';
            }
        }
        eventOptions.source = source;
        eventOptions.clickLocation = 'cart';
        gtmAnalytics.submitCheckoutStep(1, gtmOption, eventOptions);
    });

    loyaltyRewards.init();

    // Stripe AfterPay Messaging
    afterpaymessaging.init();

    // Progress Bar
    progressBar.init();
}

var lastUpdatedNarvarDestZip = "";
function narvarZipChangeCheck(currentZip, last) {
	if (last) {
		var newZip = $('.narvareddcalc button:first').html().split('<span')[0];
		if (newZip.length > 4 && newZip != currentZip && newZip != lastUpdatedNarvarDestZip) {
			$.ajax({
				url: util.appendParamsToUrl(Urls.setCartShipToZip, {"zipcode":newZip}),
	            type: 'GET',
	            cache: false,
	            async: true
	        });
			lastUpdatedNarvarDestZip = newZip;
		}
	} else {
		var $zipForm = $('.narvareddcalc form');
		window.setTimeout(function() { narvarZipChangeCheck(currentZip, ($zipForm.length < 1)); }, 500);
	}
}

function initMobileEstimatedTotal() {
    var origOrderTotal = $('.cart-order-totals').find('.order-value.scp-order-total').html(),
        estTotalContainerTop = $('.pt_cart.cart-redesign').find('.scp-est-total-afterpay .scp-est-total .value');

    if (estTotalContainerTop.length > 0) {
        $(window).on('load', function() {
            estTotalContainerTop.html(origOrderTotal);
        });
    } else {
        return;
    }
}

function initAltPaymentButtons() {
    var altPaymentsContainer = $('.scp-actions-top').find('.scp-alternate-payments'),
        applePayBtnContainer = altPaymentsContainer.find('.apple-button-wrapper'),
        applePayTermsContainer = altPaymentsContainer.find('.applepay-terms'),
        payPalBtnContainer = altPaymentsContainer.find('.paypal-cart-buttons-container');

    var config = { childList: true };
    var initMobileBtns = function() {
        if (util.isMobile() || breakpoints.isMobile()) {
            if ( (applePayBtnContainer.length > 0 && applePayBtnContainer.hasClass('available')) && payPalBtnContainer.length > 0 ) {
                $('body').addClass('has-applepay-btn');
                payPalBtnContainer.addClass('split');
                altPaymentsContainer.after(applePayTermsContainer);
            }
        } else {
            return false;
        }
    };

    var observer = new MutationObserver(function(mutations) {
        mutations.forEach(function() {
            initMobileBtns();
        });
    });
    observer.observe(document.body, config);
}

function scrollToCartError() {
    if ($('.cart-row-lineitem-error').length === 0) {
        return;
    } else {
        var firstError = $('.cart-row-lineitem-error').first(),
            siteHeaderHeight = $('.site-header').outerHeight();
        $('.scp-top-container').on('click', '.cart-error-view-items', function(e) {
            e.preventDefault();
            $('html, body').animate({
                scrollTop : firstError.offset().top - siteHeaderHeight
            }, 1000);
        });
    }
}

function adaButtonCompliance() {
    // Removes all top section duplicate buttons from the accesibility tree
    if ($('.scp-actions-top').length > 0 && (util.isMobile() || breakpoints.isMobile())) {
        var $buttons = $('.scp-actions-top').find('button, a, .paypal-cart-button, .apple-button');
        $buttons.each(function() {
            $(this).attr('aria-hidden', 'true').attr('tabindex', '-1');
        });
    }
}

exports.init = function () {
    initializeEvents(); // Cart
    initMobileEstimatedTotal();
    initAltPaymentButtons();
    scrollToCartError();
    adaButtonCompliance();
    promoCode.init(1, true);
    
    account.initCartLogin();

    applepay.init();

    if(window.siteGaData.removeProducts) {
        $(document).trigger('ga.removefromcart');
    }
};
