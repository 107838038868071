'use strict';

var overlay = {
    init: function () {
        $('.js-site-overlay').on('click', function () {
            if (!$('.js-site-overlay').hasClass('loyaltyUpgradeModalOverlay')) {
                overlay.hide();
            }
        });
    },
    show: function (className) {
        $('.js-site-overlay').addClass(className);
        $('html').addClass('has-overlay-active');
    },
    hide: function () {
        $('html').removeClass('has-overlay-active');
    }
}
overlay.init();
module.exports = overlay;
