'use strict';

var progress = require('../../progress');
var page = require('../../page');
var addToCart = require('./addToCart');

var $pdpMain;
var $pdpQv;
var $link;

/**
 * Setup events, uses delegates so this is not
 * required to be re-initalized when new variants
 * are loaded.
 */
function init() {
    $pdpMain = $('#pdpMain');
    $pdpMain.on('click', '.js-pdp-aplus-add-wishlist', addToWishlist);
}

function initQuickView() {
    $pdpQv = $('#pdpQv');
    $pdpQv.on('click', '.js-pdp-aplus-add-wishlist', addToWishlist);
}

function addToWishlist(e) {
    e.preventDefault();
    $link = $(this);
    progress.show($link);

    // show 'add' error message if disabled
    // this error is generated on page render
    if ($link.hasClass('js-disabled')) {
        addToCart.showError($link.parents('.js-pdp-variations'));
        setTimeout(progress.hide, 500);
        return;
    }

    var productID = $('input[name=pid]').val(); // Ensure the product ID is set or passed to the event trigger
    $(document).trigger('ga.addtowishlist', productID);

    page.redirect($link.attr('href'));
}

module.exports = {
    init: init,
    initQuickView: initQuickView
};
