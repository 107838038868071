'use strict';

var newVisitor = require('../newvisitor');

var CHAT_TEASER_EXPIRY = 'chatTeaserExpiry';

// Default configuration values
var TEASER_ENABLED = false;
var TEASER_EXPIRY_LENGTH = 5;
var TEASER_SHOW_DELAY = 5000;
var TEASER_SHOW_DURATION = 5000;

var teaserStartTimeout;
var teaserEndTimeout;

function getExpiryValue () {
    return window.localStorage.getItem(CHAT_TEASER_EXPIRY)
}

function setExpiryDate (currentDate, expiryDuration) {
    try {
        var newExpiryDate = getNewExpiryDate(currentDate, expiryDuration);
        window.localStorage.setItem(CHAT_TEASER_EXPIRY, newExpiryDate.toUTCString());
        return true;
    } catch (e) {
        console.error(e);
        return false;
    }
}

function getNewExpiryDate (currentDate, daysToAdd) {
    var expiryDate = new Date(currentDate);
    expiryDate.setDate(expiryDate.getDate() + parseInt(daysToAdd));
    return expiryDate;
}

function checkExpiryStatus (currentDate, expiryDate) {
    if (!expiryDate) {
        return false;
    }
    expiryDate = new Date (expiryDate);
    return currentDate.getTime() > expiryDate.getTime();
}

function showTeaserAfterDelay (onShow, onHide, showDelay, hideDelay) {
    teaserStartTimeout = window.setTimeout(function () {
        if (typeof onShow === 'function') {
            onShow();
        }
        teaserEndTimeout = window.setTimeout(function () {
            if (typeof onHide === 'function') {
                onHide();
            }
        }, hideDelay);  
    }, showDelay);
}

function checkTeaserPrereqs () {
    if (!newVisitor.getCookie(newVisitor.NEW_VISITOR_COOKIE_NAME)) {
        return false;
    }
    return true;
}

function getConfigs ($el) {
    var data = $el.data();

    return {
        enabled: data.teaserEnabled || TEASER_ENABLED,
        expirationDuration: data.teaserExpiry || TEASER_EXPIRY_LENGTH,
        showDuration: data.teaserHideDelay || TEASER_SHOW_DURATION,
        showDelay: data.teaserShowDelay || TEASER_SHOW_DELAY
    };
}

function start ($chatMenu, onShow, onHide) {
    var teaserConfigs = getConfigs($chatMenu);

    if (!teaserConfigs.enabled) {
        return;
    } 

    if (!checkTeaserPrereqs()) {
        return;
    }

    var currentDate = new Date();
    var expiryDate = getExpiryValue();

    if (expiryDate && !checkExpiryStatus(currentDate, expiryDate)) {
        return;
    }
    
    var expirySet = setExpiryDate(currentDate, teaserConfigs.expirationDuration);
    
    if (!expirySet) {
        return;
    }
    
    showTeaserAfterDelay(onShow, onHide, teaserConfigs.showDelay, teaserConfigs.showDuration);
    return;    
}

module.exports = {
    start: start,
    clearTeaserEndTimout: function () {
        if (teaserEndTimeout) {
            window.clearTimeout(teaserEndTimeout);
        }
    },
    clearTeaserStartTimout: function () {
        if (teaserStartTimeout) {
            window.clearTimeout(teaserStartTimeout);
        }
    }
}
