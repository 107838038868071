'use strict';

var address = require('./address'),
    billing = require('./billing'),
    multiship = require('./multiship'),
    shipping = require('./shipping'),
    orderSummary = require('./orderSummary'),
    util = require('../../util');

/**
 * @function Initializes the page events depending on the checkout stage (shipping/billing)
 */
exports.init = function () {
    orderSummary.init();
    address.init();
    if ($('.checkout-shipping').length > 0 || $('.checkout-shipping-method').length > 0) {
        shipping.init();
    } else if ($('.checkout-multi-shipping').length > 0) {
        multiship.init();
    } else {
        billing.init();
    }

    //if on the order review page and there are products that are not available diable the submit order button
    if ($('.order-summary-footer').length > 0) {
        if ($('.notavailable').length > 0) {
            $('.order-summary-footer .submit-order .button-fancy-large').attr('disabled', 'disabled');
        }
    }

    // a11y retain focus after dropdown value changes (Safari, Edge, IE11)
    $(".form-dropdown").on('change', function() {
        $(this).trigger('focus');
    });

    $(".form-dropdown").on('focusout', function() { 
        $(this).closest('.select-wrapper').removeClass('focus');
    });

    $(".form-dropdown").on('focus', function() {
        $(this).closest('.select-wrapper').addClass('focus');
    });

    // ADA Saved Address
    $(".js-saved-address-fieldset label span").addClass("is-hidden");
    $(".js-saved-address-fieldset label span").append("Saved Addresses");
    if ($(".js-single-shipping-toggle-wrapper").hasClass("is-hidden")) {
        $(".js-saved-address-fieldset label").attr({
            "aria-label":"Saved Addresses",
            "aria-hidden":"true"
        });
    } else {
        $(".js-saved-address-fieldset label").attr({
            "aria-label":"Saved Addresses",
            "aria-hidden":"false"
        });
    }

    // Launch modal sign in from header button
    $('.sh-promo-bar-drop-down-button.button.signin').on('click touchstart mousedown', function(e) {
        e.preventDefault();
        util.launchSignInModal(Urls.loginModal);
    });
	
	// Previous step GTM event
	$('body').one('click', '.js-previous-link', function() {
		gtmAnalytics.submitCheckoutPrevious();
	});

    $(document).trigger('ga.checkoutstep');
};
