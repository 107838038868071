'use strict';

var addProductToCart = require('./product/addToCart'),
    ajax = require('../ajax'),
    page = require('../page'),
    quickview = require('../quickview');

/**
 * @private
 * @function
 * @description Binds the click events to the remove-link and quick-view button
 */
function initializeEvents() {
    $('#compare-table').on('click', '.remove-link', function (e) {
        e.preventDefault();
        ajax.getJson({
            url: this.href,
            callback: function () {
                page.refresh();
            }
        });
    })
    .on('click', '.open-quick-view', function (e) {
        e.preventDefault();
        var url = $(this).closest('.product').find('.thumb-link').attr('href');
        quickview.show({
            url: url,
            source: 'quickview',
            callback : function() { 
            	// FadeIn product content once all product logic and events have loaded.
        		$(".product-col-2").fadeIn(500);
        	}
        });
    });

    $('#compare-category-list').on('change', function () {
        $(this).closest('form').trigger('submit');
    });
}

exports.init = function () {
    initializeEvents();
    addProductToCart();
};
