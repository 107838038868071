'use strict';

var address = require('./address'),
    formPrepare = require('./formPrepare'),
    dialog = require('../../dialog'),
    util = require('../../util'),
    progress = require('../../progress'),
    ajax = require('../../ajax'),
    updateSummary = require('./updateSummary'),
    internationalFields = require('../../internationalFields'),
    validator = require('../../validator'),
    initGiftMessageValidation = require('./giftMessageValidation');

/**
 * @function
 * @description Initializes gift message box for multiship shipping
 */
function giftMessageBoxDisplay() {
    $('.js-gift-message').each(function(){
        var $giftMessage = $(this);
        if ($giftMessage.siblings().find('input[name$="_isGift"]:checked').length > 0 || $giftMessage.siblings().find('input[name$="_giftBox"]:checked').length > 0) {
            $giftMessage.removeClass('is-hidden');
            if ($giftMessage.siblings().find('input[name$="_isGift"]:checked').length <= 0) {
            		$giftMessage.siblings().find('input[name$="_isGift"]').prop('checked', 'checked');
	    		}
        } else {
            $giftMessage.addClass('is-hidden');
        }
    });
}

/**
 * @function
 * @description selects a shipping method for the default shipment and updates the summary section on the right hand side
 * @param
 */
var selectMultiShippingMethod = function(shippingMethodID, shipmentUUID) {

    // attempt to set shipping method
    var url = util.appendParamsToUrl(Urls.selectMultiShippingMethod, { shippingMethodID: shippingMethodID, shipmentUUID : shipmentUUID }); 
    ajax.getJson({
        url: url,
        callback: function (data) {
            updateSummary(3);
            if (!data || !data.shippingMethodID) {
                window.alert('Couldn\'t select shipping method.');
                return false;
            }
        }
    });
}

/**
 * @function
 * @description capture add edit adddress form events
 */
function addEditAddress(target) {
    var $addressForm = $('[name$="multishipping_editAddress"]'),
    	$addressFormBtn = $('[name$="editAddress_save"]'),
        $addressDropdown = $addressForm.find('select[name$=_addressList]'),
        $addressList = $addressForm.find('.address-list');

	validator.init();

    formPrepare.init({
        continueSelector: '[name$="editAddress_save"]',
        formSelector: '[name$="multishipping_editAddress"]'
    });
    internationalFields.init(); 
    
    $addressDropdown.on('change', function (e) {
        e.preventDefault();

        var selectedAddress = $addressList.find('select').val();
        if (selectedAddress !== 'newAddress') {
            selectedAddress = $.grep($addressList.data('addresses'), function (add) {
                return add.UUID === selectedAddress;
            })[0];
            $addressForm.find('input[type="text"], .input-text, .input-select').val('');
            // proceed to fill the form with the selected address
            util.fillAddressFields(selectedAddress, $addressForm);
        } else if (selectedAddress === 'newAddress') { 
            //reset the form if the value of the option is not a UUID
            $addressForm.find('input[type="text"], .input-text, .input-select').val('');
        }
		
		formPrepare.validateForm();
		var $requiredInputs = $('[name$="multishipping_editAddress"]').find(":input.required");
		
		$requiredInputs.each(function () {
			$(this).trigger('focus');
			$(this).trigger('blur');
		});
    });

    $addressForm.on('click', '.cancel', function (e) {
        e.preventDefault();

        dialog.close();
    });

    $addressFormBtn.on('click', function (e) {
        e.preventDefault();
        
        formPrepare.validateForm();
        
        if (!$addressForm.valid()) {
            return false;
        }
        
        if($(this).hasClass('js-avs')) {
			ajax.getJson({
				url: Urls.addEditAddress,
				data: $addressForm.serialize(),
				async: false,
				callback: function (response) {
					
					if (!response.success) {
						$('#multiaddresserror').html(Resources.COULD_NOT_SAVE_ADDRESS);
						return;
					}
					$('#multiaddresserror').toggleClass('hidden', response.success);

					var address = response.address,
						$shippingAddress = $(target).closest('.shippingaddress'),
						$select = $shippingAddress.find('.select-address'),
						_newOption = '<option value="' + address.UUID + '">' +
							((address.ID) ? '(' + address.ID + ')' : address.firstName + ' ' + address.lastName) + ', ' + ((address.companyName) ? address.companyName + ', ' : '') +
							address.address1 + ', ' + address.city + ', ' + address.stateCode + ', ' + address.postalCode +
							'</option>';

					// Select "ship to address" radio option
					$shippingAddress.find(".edit-address input.form-qty-radio").prop("checked", "checked");

					// Does current option exist?
					var $option = $select.find('option[value="' + address.UUID + '"]');

					if($option.length == 1) {
						$option.remove();
						$select.append(_newOption);
					} else {
						$select.append(_newOption); 
					}

					// Always set new address we selected or saved as the newly selected option.
					$select.find('option[value="' + address.UUID + '"]').prop('selected', 'selected');
					
					// Close dialog and show address dropdown
					dialog.close();
					
					// cleanup error if any
					var _validator = $select.closest("form").validate();
					_validator.element($select);
					$select.closest(".select-wrapper").removeClass("error");
					if($select.closest(".js-multiship-lineitem").find("span.error").length > 0) {
						$select.closest(".js-multiship-lineitem").find("span.error").remove();
					}
					
					$select.closest(".js-multiship-lineitem").find(".selected-store-address").hide(); // Hide "ship to store" address
					$select.closest(".select-wrapper").fadeIn();
					
					// Add new "ship to address" to all other select boxes to keep them all in sync
					$(".scp-item-list .edit-address").find("select.select-address").each(function() {
						// Update all other select boxes for ship to addresses.
						if($(this).attr("name") != $select.attr("name")) {
							var $option = $(this).find('option[value="' + address.UUID + '"]');

							if($option.length == 1) {
								$option.remove();
								$(this).append(_newOption);
							} else {
								$(this).append(_newOption);
							}
					
							// Change labeling on select
							var $optionNoValue = $(this).find('option[value=""]');
				
							if($optionNoValue.length == 1) {
								$optionNoValue.html($optionNoValue.data("with-option"));
							}
								
							// Display select dropdowns for other product line items when they have data
							// unless it already has a store selected.
							if($(this).closest(".edit-address").find("input.form-qty-radio").is(":checked")) {
								$(this).closest(".select-wrapper").fadeIn();
							}
						}
					});
						
					initMultiShipForm();
				}
			});
		}
	});
}

function initMultiShipForm() {
    formPrepare.init({
        continueSelector: '[name$="addressSelection_save"]',
        formSelector: '[id$="multishipping_addressSelection"]'
    });
}

var giftRecipientPromptTemplate = function () {
    return [
        '<h1 class="h3 sts-zip-title">' + Resources.ADD_GIFT_RECIPIENT + '</h1>',
        '<div id="preferred-store-panel" class="js-gift-recipient-form">',
        '    <input type="text" id="addGiftRecipient" class="form-field js-gift-recipient-field" placeholder="' + Resources.ENTER_GIFT_RECIPIENT + '" name="addGiftRecipient"/>',
        '</div>'
    ].join("\n");
};

var setGiftRecipient = function(recipient, giftRecipientContainer) {
	var $selectWrapper = giftRecipientContainer.find(".select-wrapper");
	
	if($selectWrapper.length == 1) {
		ajax.getJson({
            url: Urls.addGiftRecipient,
            data: { giftRecipient : recipient},
            async: false,
            callback: function (response) {
            	
            	if (!response.success) {
                    //$('#multiaddresserror').html(Resources.COULD_NOT_SAVE_ADDRESS);
                    return;
                }
            	
				var $select = $selectWrapper.find('.select-gift-recipient');
		
				// New option to add to gift dropdown
				var _newOption = '<option value="' + recipient + '">' + recipient + '</option>';
		
				// Does current option exist?
				var $option = $select.find('option[value="' + recipient + '"]');

				if($option.length == 1) {
					$option.remove();
            		$select.append(_newOption);
				} else {
					$select.append(_newOption);
				}

				// If we added a new option, always set it to selected.
        		$select.find('option[value="' + recipient + '"]').prop('selected', 'selected').trigger('change');
	
				// Change labeling on select
				var $optionNoValue = $select.find('option[value=""]');
		
				if($optionNoValue.length == 1) {
					$optionNoValue.html($optionNoValue.data("with-option"));	
				}

				// If "this is a gift for" is not checked yet, we should check it.
				giftRecipientContainer.find(".form-qty-checkbox.gift-recipient").prop("checked", "checked");
	
				// Add new gift recipient to all other "this is a gift for" select boxes to keep them all in sync
				$(".scp-item-list").find("select.js-saved-gift-recipients").each(function() {
					// Update all other select boxes for gift recipients.
					if($(this).attr("name") != $select.attr("name")) {
						var $option = $(this).find('option[value="' + recipient + '"]');

						if($option.length == 1) {
							$option.remove();
            	 			$(this).append(_newOption);
						} else {
				 			$(this).append(_newOption);
						}
			
						// Change labeling on select
						var $optionNoValue = $(this).find('option[value=""]');
		
						if($optionNoValue.length == 1) {
							$optionNoValue.html($optionNoValue.data("with-option"));
						}
					}
				});	
	
				$selectWrapper.fadeIn();
            }
    	});
	}
};

var giftRecipientPrompt = function(giftRecipientContainer) {

	dialog.open({
		html: giftRecipientPromptTemplate(),
		options: {
			dialogClass: 'sts-zip',
			modal: true,
			draggable: false,
			buttons: [{
				text: Resources.CONTINUE,
				class: 'button sts-add',
				click: function () {
					var giftRecipient = $('#addGiftRecipient').val();
					if (giftRecipient) {
						$('.js-gift-recipient-field').removeClass('error');
						$('.js-sts-error').remove();
						
						setGiftRecipient(giftRecipient, giftRecipientContainer);

						dialog.close();
					} else {
						$('.js-sts-error').remove();
						$('.js-gift-recipient-form').append('<span class="error js-sts-error">' + Resources.VALIDATE_REQUIRED + '</span>');
						$('.js-gift-recipient-field').addClass('error');
					}
				}
			}],
			open: function () {
				$('#addGiftRecipient').on('keypress', function (e) {
					if (e.which === 13) {
						// trigger the continue button
						$('.ui-dialog-buttonset .ui-button').trigger('click');
					}
				});
				
				var _pattern=/[^a-zA-Z0-9- !."'_@&,()\n;:$%]*/ig;
    			$('.js-gift-recipient-field').on('keyup', function(e) {
		
	   				var $th = $(this);	    
	    			$th.val($th.val().replace(_pattern, ""));

				}).bind('paste',function(e) { 
					var $th = $(this);
	    			setTimeout(function() {  
	    				var _val = $th.val();
	    				_val.replace(_pattern, "");
	    				$th.val(_val.replace(/\s+/g,' '));
	    			},200);
				});
			}
		}
	});
};

/**
 * @function
 * @description shows gift message box in multiship, and if the page is the multi shipping address page it will call initmultishipshipaddress() to initialize the form
 */
exports.init = function () {
    giftMessageBoxDisplay();
    
    if ($('.cart-row .shippingaddress .select-address').length > 0) {
        initMultiShipForm();
    }

    initGiftMessageValidation();
    
    $('.edit-address').on('click', 'a', function (e) {
        e.preventDefault();
        
        dialog.open({
            url: this.attributes.href.value,
            options: {
                open: function () {
                    address.init();
                    addEditAddress(e.target);
                    internationalFields.init();
                },
                dialogClass: 'co-edit-addaddress',
                width: '90%',
                modal: true,
                draggable: false
            }
        });
    });
    
    // Gift Messaging Event
    $('.js-is-gift, .mss-gift').on('change', function () {
        giftMessageBoxDisplay($(this).siblings('.js-gift-message'));
    });
    
    $(".edit-gift-recipient").on("click", ".form-qty-checkbox.gift-recipient", function(e) {
    	if($(this).prop("checked") == true) {
    		$(this).closest(".edit-gift-recipient").find(".select-wrapper").fadeIn();
    	} else {
    		$(this).closest(".edit-gift-recipient").find(".select-wrapper").hide();
    		
    		// Reset any gift recipient selections when checkbox is unchecked.
    		$(this).closest(".edit-gift-recipient").find("select.select-gift-recipient option").each( function() {
    			$(this).prop("selected", false);
    		});
    	}
    });
    
    // Check to see if "Ship to address" is checked, if so display the dropdown.
    $(".scp-item-list").find(".edit-address .form-qty-radio").each( function(e) {	
    	var $sel = $(this).closest(".edit-address").find(".select-address");
    	
    	if($(this).prop("checked") == true && $sel && $sel[0].options.length > 1) {
    		$sel.parent().fadeIn();
    	}
    });
    
    // Check to see if "Free ship to store" is checked, if so display the dropdown.
    $(".scp-item-list").find(".instore-delivery input.selected-store").each( function(e) {	
    	var $selstore = $(this).closest(".instore-delivery").find(".selected-store-address");
    	
    	if($selstore && $selstore.data("store-id")) {
    		$selstore.fadeIn();
    	}
    }); 
    
    // Customer "Ship to Address" radio button click
    $(".scp-item-list .edit-address").on("click", ".form-qty-radio", function() {
    	var $sel = $(this).closest(".edit-address").find(".select-address");
    	
    	if($(this).prop("checked") == true && $sel && $sel[0].options.length > 1) {
    		$sel.parent().fadeIn();
    	}
    });
    
    // Check to see if a gift recipient is selected, if so display the dropdown and select "this is a gift for"
    $(".scp-item-list").find(".edit-gift-recipient select.select-gift-recipient").each( function(e) {   	
    	var $opt = $(this).find("option:selected");
    	
    	if($opt && $opt[0].index > 0) {
    		$(this).closest(".edit-gift-recipient").find("input.gift-recipient").prop("checked", true);
    		$(this).closest(".select-wrapper").fadeIn();
    	}
    });
    
    $(".edit-gift-recipient a").on("click", function(e) {
    	e.preventDefault();
    	
    	var _giftRecipientContainer = $(this).closest(".edit-gift-recipient");
    	
    	giftRecipientPrompt(_giftRecipientContainer);
    	
    });
    
    $('button[name$="addressSelection_save"]').on('click', function (e) {
    	var $clicked = $(this);
    	
    	if($(this).closest("form").valid()) {
    		$('button[name$="addressSelection_save"]').each(function() {
        		$(this).addClass("is-disabled");
        		progress.show($clicked);
    		});
    	}
    });
    
    $('button[name$="shippingOptions_save"]').on('click', function (e) {
    	var $clicked = $(this);
    	
    	if($(this).closest("form").valid()) {
    		$('button[name$="shippingOptions_save"]').each(function() {
        		$(this).addClass("is-disabled");
        		progress.show($clicked);
    		});
    	}
    });
    
    $(".checkoutmultishipping").on("change", ".mss-shipmethod select", function() {
    	var _shippingMethodID = $(this).find("option:selected").val();
    	var _shipmentUUID = $(this).data("shipmentuuid");
    	
    	if(_shippingMethodID && _shipmentUUID) {
    		selectMultiShippingMethod(_shippingMethodID, _shipmentUUID);
    	}
    });

};
