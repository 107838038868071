'use strict';

/**
 * Jquery for the upper nav in the header.  This includes the slide down lists, sign in and my cart drop downs.
*/

var breakpoints = require('./breakpoints'),
	overlay = require('./overlay');

var topnav = {
	init : function() {

		var mobileBanner = document.querySelector('.sh-banner-right');
		if(mobileBanner){
			var HannaRewardsHeader = mobileBanner.querySelector('.hannaRewardsHeader');
			if(HannaRewardsHeader){
				HannaRewardsHeader.outerHTML = '<h2 class="header hannaRewardsHeader">' + HannaRewardsHeader.innerHTML + '</h2>';
			}
		}
		// Attaching mousedown event to sign in button becuase
		// click event is not always triggered
		if ($('.loyalty-dropdown-display').length > 0){
			$('.loyalty-dropdown-display').on('mousedown', function(e){
				var $targetElement = $(e.target);
				var isButton = $targetElement.hasClass('button');
				if(isButton) {
					var buttonLink = $targetElement.attr('href');
					window.location.href = buttonLink;
				}
			});
		}

		$('.sh-promo-bar-item--dropdown.sign-in')
			.on('click', function(e) {
				if ($(e.target).is('a')) {
					return;
				}
				if($(e.target).hasClass('loyalty-close-icon')){
					overlay.hide();
					$('.js-site-overlay').removeClass('loyalty-overlay');
					if ($('.loyalty-dropdown-display').is(':visible')) {
						topnav.close($('.loyalty-show'));
					}
				}
				//Close nav menu 
				if($('.js-menu-toggle').hasClass('is-active')){
					$('.js-expand-toggle, .js-expand-item').removeClass('is-active');
					$('.js-site-nav').removeClass('is-active');
					$('.js-site-nav').attr('aria-expanded', 'false');
					$('body').removeClass('has-menu-active');
					$('.hamburger').removeClass('is-active');
					overlay.hide();
				}
				var $this = $(this);
				var $dropdown = $this.find('.sh-promo-bar-item__dropdown');
				
				if ($dropdown.is(':visible')) {
					if($(e.target).parent().hasClass('sh-promo-bar-item__icon--sign-in')){
						topnav.close($this);
						if ($dropdown.hasClass('loyalty-dropdown-display')) {
							overlay.hide();
							$('.js-site-overlay').removeClass('loyalty-overlay');
						}
					}
				} else {
					e.preventDefault();
					topnav.open($this);
					if (!$('.pt_checkout').length && $dropdown.hasClass('loyalty-dropdown-display') && (!$dropdown.hasClass('loyalty-dropdown-registered') || breakpoints.isDesktop())) {
						overlay.show('loyalty-overlay');
						$('.loyalty-overlay').on('click', function () {
							overlay.hide();
							$('.js-site-overlay').removeClass('loyalty-overlay');
							if ($('.loyalty-dropdown-display').is(':visible')) {
								topnav.close($('.loyalty-show'));
							}
						});
					}
					const $accountFlyout = $('#wrapper:not(.pt_checkout) .sh-promo-bar-item__dropdown.loyalty-dropdown-display');
					const $searchBarInput = $('.sh-middle-right-inner');
					const $searchBarContainer = $('.sh-mobile-search-container');
					if($accountFlyout.length > 0){
						if(breakpoints.isMobile()){
							if(window.scrollY === 0 && $searchBarInput.length > 0 && $searchBarContainer.length > 0){
								const regex = /[a-zA-Z]/g;
								const $searchBarMarginTop = $searchBarInput.css('margin-top').replace(regex, '');
								const $searchBarMarginBottom = $searchBarInput.css('margin-bottom').replace(regex, '');
								const $wunderKindBanner = $('.site-header .bx-align');
								let $globalNavHeightMinusElements = 0;
								if($wunderKindBanner.length > 0){
									$globalNavHeightMinusElements = $('.site-header').height() - $searchBarContainer.height() - $searchBarMarginTop - $searchBarMarginBottom + $wunderKindBanner.height();
								}else{
									$globalNavHeightMinusElements = $('.site-header').height() - $searchBarContainer.height() - $searchBarMarginTop - $searchBarMarginBottom;
								}
								$accountFlyout.css('top', $globalNavHeightMinusElements);
								$('.loyalty-overlay').css('top', $globalNavHeightMinusElements);
							}else{
								const $globalNavHeight = $('.site-header').height();
								$accountFlyout.css('top', $globalNavHeight);
								$('.loyalty-overlay').css('top', $globalNavHeight);
							}
						}else{
							$accountFlyout.css('top', 0);
							$('.loyalty-overlay').css('top', 0);
						}
					}
				}
			})
			.on('blur', function() {
				if ($('.pt_checkout').length || breakpoints.isMobile() || breakpoints.isTablet()) {
					if ($('.sh-promo-bar-item__dropdown').is(':visible')) {
						topnav.close($(this));
					}
				}
			});

		// promo bar a11y accessibility
		var navControlKeys = ['13','32']
		var $promoBarItems = $('.sh-promo-bar-item:not(.search)');

		$promoBarItems
			.on('keydown', function(e) {
				if (navControlKeys.indexOf(e.keyCode.toString()) > -1 && $(this).is(':focus')) {
					e.preventDefault();
					$(this).attr('data-toggle-state') === '0' ? $(this).attr('data-toggle-state', '1').mouseenter() : $(this).attr('data-toggle-state', '0').mouseout();
				} else if (e.keyCode == 32) {
					e.preventDefault();
				}
			})
			.on('focus', function() {
				$promoBarItems.not($(this)).attr('data-toggle-state', '0').mouseout();
			})
			.on('blur', function(e) {
				if (!this.contains(e.relatedTarget)) {
					$promoBarItems.attr('data-toggle-state', '0').mouseout();
				}
			})
			.on('focusout', 'a, button', function(e) {
				if (!$promoBarItems.has(e.relatedTarget).length > 0) {
					$promoBarItems.attr('data-toggle-state', '0').mouseout();
				}
			});

		//HD-4496 Mobile nav layout adjustment
			if(breakpoints.isMobile()){
				var megaThreeDivs = Array.prototype.slice.call($(".div-mega-3"));
				megaThreeDivs.forEach(function(div){
					if(div.classList.contains("moveToSecondToLast")){
						var divMegaDropDownCol = document.createElement("div");
						divMegaDropDownCol.classList.add("mega-drop-down__col");
						var ulMegaTwo = div.closest("div > ul");
						var megaDropDownCatCont = div.closest(".mega-drop-down-category-container");
						ulMegaTwo.remove();
						megaDropDownCatCont.insertBefore(divMegaDropDownCol, megaDropDownCatCont.lastElementChild);
						divMegaDropDownCol.append(ulMegaTwo);
					}
				});
			};

		// close nav item if user presses escape
		$(document).on('keydown', function(e) {
			if (e.key === 'Escape') {
				topnav.close($(this));
			}
		});
	},
	
	open : function(dropdownTray, triggerCloseDelay) {
		var $dropdown = dropdownTray.find('.sh-promo-bar-item__dropdown');
		if (!$('.pt_checkout').length && $dropdown.hasClass('loyalty-dropdown-registered') && (breakpoints.isMobile() || breakpoints.isTablet())) {
			window.location = Urls.accountShow;
			return;
		}

		if (window.SessionAttributes && window.SessionAttributes.BFX_INTERNATIONAL) {
			dropdownTray.find('.bfx-us-hide').show();
			dropdownTray.find('.bfx-intl-hide').hide();
		} else {
			dropdownTray.find('.bfx-us-hide').hide();
			dropdownTray.find('.bfx-intl-hide').show();
		}

		$dropdown.stop().slideDown(200);
		if (dropdownTray.not('.my-bag').length > 0) {
			dropdownTray.attr('aria-expanded', 'true');
		}

		if (triggerCloseDelay != null) {
			var dropdownTrayClose = setTimeout(topnav.close.bind(this, dropdownTray), triggerCloseDelay);

			dropdownTray.one('click touchstart', function () {
				clearTimeout(dropdownTrayClose);
			});
		}
	},

	close : function(dropdownTray) {
		if ($('.sh-promo-bar-item__dropdown').hasClass('loyalty-dropdown-display') && (breakpoints.isMobile() || breakpoints.isTablet())) {
			overlay.hide();
			$('.js-site-overlay').removeClass('loyalty-overlay');
		}
		dropdownTray.find('.sh-promo-bar-item__dropdown').stop().slideUp(200);
		if (dropdownTray.not('.my-bag').length > 0) {
			dropdownTray.attr('aria-expanded', 'false');
		}
	}
};

module.exports = topnav;