/**
 * @function
 * @description Initializes the tooltip-content and layout
 */
exports.init = function () {
  var $afterpayContainers = $('.afterpay-clearpay-message');


  if ($afterpayContainers.length == 0) {
    return;
  }

  $afterpayContainers.each(function (index) {
    var $afterpayMessage = $(this);
    var stripe = Stripe(document.getElementById('stripePublicKey').value);
    var elements = stripe.elements();
    var amount = $afterpayMessage.data('amount') * 100;

    $afterpayMessage.attr('id', 'afterpay-message-' + index);

    var options = {
      amount: amount,
      currency: 'USD',
      min: 2000,
      showUpperLimit: false,
      logoType: 'lockup',
      introText: 'Or',
      modalTheme: 'white'
    }

    var afterpayClearpayMessageElement = elements.create('afterpayClearpayMessage', options);

    afterpayClearpayMessageElement.mount('#' + $afterpayMessage.attr('id'));
  });
};
