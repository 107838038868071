'use strict';

var button = require('../../button');
var ajax = require('../../ajax');
var util = require('../../util');
var minicart = require('../../minicart');
var progress = require('../../progress');
var page = require('../../page');
var analytics = require('./analytics');
var breakpoints = require('../../breakpoints');

// Borderfree Requirement
var bfxprice = require('../../bfxprice');
var pinterestTracking = require('../../trackingData/pinterestTracking');
var tiktokTracking = require('../../trackingData/tiktokTracking');
var facebookTracking = require('../../trackingData/facebookTracking');

var $pdpMain;
var $pdpQv;
var $button;
var $form;
var $mainCntnr;

var customButton = false;
window.dataLayer = window.dataLayer || [];
var sticky = false;

/**
 * Setup events, uses delegates so this is not
 * required to be re-initalized when new variants
 * are loaded.
 */
function init($customButton) {
    $button = $customButton;
    if ($customButton) {
        customButton = true;
    }

    $pdpMain = $('#pdpMain');
    $pdpMain.on('submit', '.pdp-aplus-addcart__form', addToCartSubmit);
    $mainCntnr = $pdpMain;
    if($('.pdp-aplus-addcart__form').length > 0){
        const addToCartForm = $('.pdp-aplus-addcart__form')[0];
        if(breakpoints.isMobile() && !addToCartForm.innerText.includes('SOLD OUT') && !addToCartForm.innerText.includes('COMING SOON') && $('.stickyAddCart').length > 0 && $('.pdpLite').length == 0){
            stickyAddToCart();
            if($('.addToCartPrice').length > 0){
                $('body').on('click', '.pdp-aplus-swatch:not(.pdp-aplus-qv .pdp-aplus-swatch), .pdp-aplus-bucketed-color:not(.pdp-aplus-qv .pdp-aplus-bucketed-color)', addToCartBtnPrice);
                if(addToCartForm.innerText.includes('ADD TO BAG')){
                    addToCartBtnPrice();
                }
            } else if($('.pdp-aplus-selected-price').length > 0) {
                $('.pdp-aplus-selected-price').removeClass('hide-mobile');
            }
        } else if($('.pdp-aplus-selected-price').length > 0) {
            $('.pdp-aplus-selected-price').removeClass('hide-mobile');
        }
    }
}

function initQuickView() {
    $pdpQv = $('#pdpQv');
    $pdpQv.on('submit', '.pdp-aplus-addcart__form', addToCartSubmit);
    $mainCntnr = $pdpQv;
}

/**
 * Handle the add to cart form submit
 * @param {Event} e form submit event
 */
function addToCartSubmit(e) {
    e.preventDefault();
    var $this = $(this);
    var source = '';

    if (!customButton) {
        $button = $this.find('button');
    }

    // display warning message if disabled
    if ($button.hasClass('js-disabled')) {
        button.updateBtnWarning($button);
        return;
    }

    // special handing for loyalty exclusive and early access
    if ($button.hasClass('loyalty-warning')) {
        $form = $this.is('form') ? $this : $this.closest('form');
        var url = $form.attr('action');
        window.location.href = url;
        return;
    }

    var isGiftCardAdd = $this.find('.js-gift-card').length > 0;
    var dateValidate = checkForDateValidation($('.js-gift-send-date'));
    var gcFormValid = true;

    if (isGiftCardAdd) {
        gcFormValid = $(".js-gift-card-form").valid();
    }

    if (gcFormValid && dateValidate) {
        $form = $this.is('form') ? $this : $this.closest('form');

        if ($form.find('input[name="source"]').length) {
            source = $form.find('input[name="source"]').val();
        }

        progress.show($button);

        var _data;
        if($this.parent().find(".js-gift-card-form").length > 0) {
            _data = $("." + $form[0].className + ", .js-gift-card-form").serialize();
        } else {
            _data = $form.serialize();
		}

		var category = $('.js-pdp-category').val();
		var imageCount = $('.js-pdp-images').data('image-count')
		_data += '&category=' + category + '&imageCount=' + imageCount;

        var options = {
            url: util.ajaxUrl($form.attr('action')),
            data: _data,
            callback: addToCartCallback,
            source: source
        }

        ajax.post(options);
    }
}

/**
 * Handle the response from the server
 * This is the callback from addToCartSubmit's ajax.post()
 * @param {HTML} response markup from the server
 */
function addToCartCallback(response, xhr) {
    /*
     * HD-3509: Added for a specific fail scenario where
     * users are able to add variation groups to cart.
     * In this case, response is JSON and should be parsed.
     */
    if (xhr && xhr.getResponseHeader("Content-Type") === 'application/json') {
        try {
            response = JSON.parse(response);
        } catch (e) {
            console.error(e);
        }
    };

    if (response && response.success === false) {
        console.error('There was an error adding the product to the cart');

        if (response.redirect) {
            window.location.href = response.redirect;
        }

        return;
    }

    if (!response || response.error) {
        console.error('An error occurred');
        console.log(response);
        button.updateBtnError($button);
        var msg = window.Resources.ADD_TO_CART_FAIL.replace('{0}', $form.find('input[name=pid]').val());
        showErrorMessage($button, msg);
        return;
    }

    if( $('.pdo-price-sales').length > 0){
        $('.pdo-price-sales').addClass('hide');
    }
    if( $('.pdo-price-standard').length > 0){
        $('.pdo-price-standard').addClass('hide');
    }
    button.updateBtnSuccess($button, true, 2000, false);

    // handle any updates/redirects
    if ((window.pageContext && window.pageContext.ns === 'product' && this.source === 'cart') || (window.pageContext && window.pageContext.ns === 'cart' && this.source === 'quickview')) {
        page.redirect(window.Urls.cartShow);
    } else if (window.pageContext && window.pageContext.ns === 'cart') {
        setTimeout(page.refresh, 1000);
    } else {
        if(util.isQuickviewOpen() && !breakpoints.isDesktop()) {
            $('.ui-dialog-titlebar-close').trigger('click');
        }

        minicart.show(response);
    }

    // pass along analytics data
    var data = analytics.updateData();
    if (!data) { return }

    $(document).trigger('ga.addtocart');

    // price from 'selected-price'
    var $priceData = $form.closest('.js-pdp-variations').find('.js-analytics-price');
    if ($priceData.length) {
        var eventInfo = {
            standard: $priceData.data('standard-price'),
            sales: $priceData.data('sales-price'),
            pid: $form.closest('.js-pdp-variations').find('.js-product-ids').data('pid')
        };

        data.product.dimension3 = eventInfo.sales || eventInfo.standard;
    }

    if ($pdpMain) {
        $pdpMain.trigger('product.addtocart', eventInfo);
    }

    // actionLocation
    if (!data.extraData.hasOwnProperty('location') || data.extraData.location == '') {
    	data.extraData.location = util.isQuickviewOpen() ? 'quickbuy' : 'detail';
    }
    var $tierOneMsg = $mainCntnr.find('.tier-one-msg');
    data.product.tierOneMsg = ($tierOneMsg.length) ? $tierOneMsg.first().text() : "";
    gtmAnalytics.pdpAddToBag(data.product, data.extraData);
    pinterestTracking.addToBag(data);
    tiktokTracking.addToBag(data);
    facebookTracking.addToBag(data);

    // Borderfree Adjustment
    bfxprice.init();

    // Fire Emarsys event
    if (ScarabQueue) {
        $.ajax({
            url: EmarsysUrls.emarsysAddToCartAjax
        }).done(function (data) {
            if (data) {
                ScarabQueue.push(['cart', data]);
                ScarabQueue.push(['go']);
            }
        });
    }
    
    if (typeof abTastyFlag != "undefined" && abTastyFlag) {
    	if (typeof window.abtasty != "undefined") {
    		var skuStyle = data.product.id.split('-')[0];
    		var itemData = {
    			tid: skuStyle + "-AddToCart",
    			in: data.product.name,
				ip: data.product.price,
				iq: !data.quantity ? 1 : data.quantity,
				ic: skuStyle,
				iv: !data.product.dimension7 ? "" : data.product.dimension7
    		};
    		window.abtasty.send("item", itemData);
    		if (Resources.IS_PRODUCTION != 'true') {
    			console.log("window.abtasty.send('item'," + JSON.stringify(itemData) + ") called successfully.");
    		}
    	}
    }

    resetGiftCardForm();  
}

//Resets giftcard form to empty after succesfully added to cart
function resetGiftCardForm(){
    var $giftcardForm = $('.js-gift-card-form');
    $giftcardForm.trigger("reset");
}

/**
 * Show an error message for the add to cart section
 * If the error container is not in view, scroll it into view
 * before animating in the error
 * @param {jQuery} $element Parent element for targeting error message
 * @param {String} [msg] Error message to show (optional)
 */
function showErrorMessage($element, msg) {
    var $error = $element.find('.pdp-aplus-add__error')

    if (msg) { $error.text(msg); }
    var inViewport = util.elementInViewport($error[0], 300);

    if (!inViewport) {
        if (util.isQuickviewOpen()) {
            $('.pdp-aplus-qv__right').animate({scrollTop: $error.position().top + 100}, 500);
        } else {
            util.scrollBrowser($error.position().top + 300);
        }
        setTimeout(function() {
            $error.slideDown(200);
        }, 500);
    } else {
        $error.slideDown(200);
    }
}

var checkForDateValidation = function (el) {
    var isValidDate = true;
    if ($(el).is('*')) {
        var dateValue = $(el).val();
        var now = new Date();
        if (dateValue == "") {
        	dateValue = ("0" + (now.getMonth() + 1)).slice(-2) + "/" + ("0" + (now.getDate())).slice(-2) + "/" + now.getFullYear();
        	$(el).val(dateValue);
        }
        var dateRegEx = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/;
        var currentDate = dateRegEx.test(dateValue);
        now.setHours(0,0,0,0);
        var today = new Date(dateValue);

        if (currentDate !== true || now > today) {
            $(el).addClass('error');
            $('.js-gift-send-date-label').addClass('error');
            if ($('.js-date-error').length == 0) {
                $('<span id="date-error" class="js-date-error error js-email-error-GiftSendDate">Please enter a valid date.</span>').insertAfter('.js-gift-send-date');
            }
            isValidDate = false;
        } else {
            $(el).removeClass('error');
            $('.js-gift-send-date-label').removeClass('error');
            $('.js-date-error').remove();
            isValidDate = true;
        }
    }
    return isValidDate;
};

function addToCartBtnPrice(){
    var counter = 0;
    var addToBagShown = setInterval(function(){
        counter++;
        if($('.pdp-aplus-addcart__form').length > 0){
            if($('.pdp-aplus-addcart__form')[0].innerText.includes('ADD TO BAG') && $('.pdp-aplus-selected-price').length > 0){
                if($('.pdo-price-sales').length > 0 && $('.js-pdp-aplus-add-to-cart .button-text').length > 0)
                {
                    if($('.pdo-price-standard').length > 0){
                        $('.pdo-price-standard').insertAfter('.js-pdp-aplus-add-to-cart .button-text');
                        $('.pdo-price-standard').css('padding', '5px')
                        $('.pdo-price-sales').insertAfter('.pdo-price-standard');
                        $('.pdo-price-sales').css('color', 'white')

                    } else {
                        $('.pdo-price-sales').insertAfter('.js-pdp-aplus-add-to-cart .button-text');
                        $('.pdo-price-sales').css('padding', '5px')
                    }
                    if($('.pdp-aplus-selected-price').length > 0){
                        $('.pdp-aplus-selected-price').remove();
                    }
                    if($('.pdp-aplus-tier-eight-msg').length > 0){
                        $('.pdp-aplus-tier-eight-msg').remove();
                    }
                }
                clearInterval(addToBagShown);
            }
        }
        if(counter > 100){
            clearInterval(addToBagShown);
        }
    } , 100);
}

/**
 * Handle the add to cart sticky functionality
 */
function stickyAddToCart() {
    var addToCartForm = $('.pdp-aplus-addcart__form');
    var addToCartButton = $('.js-pdp-aplus-add-to-cart');

    if(addToCartForm.length > 0 && addToCartButton.length > 0){
        var counter = 0;
        var styliticsInterval = setInterval(function(){
            counter++;
            if($('.stylitics-bundle-container').length > 0){
                $('body').on('click', '.stylitics-bundle-container', function(){
                    if(sticky === true){
                        disableSticky(addToCartForm, addToCartButton);
                        $('.stylitics-modal-inner').one('focusin', function () {
                            $('.stylitics-modal-inner').on('focusout', function () {
                                if(sticky === false){
                                    setTimeout(function(){
                                        if($('.stylitics-modal-inner').length == 0){
                                            enableSticky(addToCartForm, addToCartButton);
                                        }         
                                    } , 100);
                                }
                            });
                        });
                    }
                });
                clearInterval(styliticsInterval);
            }
            if(counter > 10){
                clearInterval(styliticsInterval);
            }
        } , 1000);
        $(window).on('scroll', function () {
            addToCartForm = addToCartForm && addToCartForm.length > 0 ? addToCartForm : $('.pdp-aplus-addcart__form');

            if (!addToCartForm || !addToCartForm[0]) {
                return;
            }

            var addToCartBoundingRectangle = addToCartForm[0].getBoundingClientRect();

            if (addToCartBoundingRectangle.y === 0){
                addToCartForm = $('.pdp-aplus-addcart__form');
            }

            var styliticsModalInnerLength = $('.stylitics-modal-inner').length;

            if (sticky === false && addToCartBoundingRectangle.y < 0 && styliticsModalInnerLength === 0){
                enableSticky(addToCartForm, addToCartButton);
            } else if (sticky === true && ($('.pdp-aplus-price-add-promo')[0].getBoundingClientRect().y > 0) || styliticsModalInnerLength > 0){
                disableSticky(addToCartForm, addToCartButton);
            }
        });
    }
}

function enableSticky(addToCartForm, addToCartButton){
    sticky = true;
    addToCartButton.addClass('stickyAddToCartButton');
    addToCartForm.addClass('stickyAddToCart')
    $('.pdp-aplus__right-col-2').css('will-change', 'unset');
    $('body').on('click', '.stickyAddToCart', stickySelectSize);
    if(addToCartForm[0].innerText.includes('SELECT A SIZE') && addToCartButton[0].classList.contains('warning')){
        addToCartButton.removeClass('warning');
    }
    $('.js-chat-menu').css('bottom', '90px');
}

function disableSticky(addToCartForm, addToCartButton){
    sticky = false;
    addToCartForm.removeClass('stickyAddToCart')
    $('.pdp-aplus__right-col-2').css('will-change', 'transform');
    $('body').off('click', '.stickyAddToCart', stickySelectSize);
    $('.js-chat-menu').css('bottom', '20px');
    addToCartButton.removeClass('stickyAddToCartButton');
    addToCartButton.css('display', 'block');
}


function stickySelectSize(){
    const addToCartform = $('.pdp-aplus-addcart__form');
    if(addToCartform[0].innerText.includes('SELECT A SIZE')){
        $('.stickyAddToCartButton').css('display', 'none');
        const heeaderBanner = $('.sh-banner-wrapper');
        const sizeHeader = $('.pdp-aplus__variant-size-header');
        const y = sizeHeader[0].getBoundingClientRect().top + window.scrollY - heeaderBanner[0].getBoundingClientRect().bottom - 20;
        window.scroll({
            top: y,
            behavior: 'smooth'
        });
    }
}

module.exports = {
    init: init,
    initQuickView: initQuickView,
    showError: showErrorMessage
};
