'use strict';

var ajax = require('./ajax'),
	util = require('./util'),
	floatingLabels = require('./floatingLabels'),
	validator = require ('./validator');

window.dataLayer = window.dataLayer || [];

// Full Name Field
const fullNameString = 'fullName';
const fullNameField = document.createElement('input');
const fullNameProps = {
	type: 'text',
	class: `form-field required ${fullNameString}`,
	id: fullNameString,
	placeholder: '',
	required: '',
	name: fullNameString,
	maxlength: 100,
	labeltext: Resources.CONTACT_FULL_NAME
};

//First Name Field
const firstNameString = 'firstName';
const firstNameField = document.createElement('input');
const firstNameProps = {
	type: 'text',
	class: `form-field required ${firstNameString}`,
	id: firstNameString,
	placeholder: '',
	required: '',
	name: firstNameString,
	maxlength: 100,
	labeltext: Resources.CONTACT_FIRST_NAME
};

// Last Name Field
const lastNameString = 'lastName';
const lastNameField = document.createElement('input');
const lastNameProps = {
	type: 'text',
	class: `form-field required ${lastNameString}`,
	id: lastNameString,
	placeholder: '',
	required: '',
	name: lastNameString,
	maxlength: 100,
	labeltext: Resources.CONTACT_LAST_NAME
};

// Email Field
const emailString = 'email';
const emailField = document.createElement('input');
const emailProps = {
	type: 'email',
	class: `form-field required ${emailString}`,
	id: emailString,
	placeholder: '',
	required: '',
	name: emailString,
	maxlength: 250,
	labeltext: Resources.CONTACT_EMAIL
};

// Phone Field
const phoneString = 'phone';
const phoneField = document.createElement('input');
const phoneProps = {
	type: 'tel',
	class: `form-field ${phoneString}`,
	id: phoneString,
	placeholder: '',
	name: phoneString,
	labeltext: Resources.CONTACT_PHONE
};

// Order Date Field
const orderDateString = 'orderDate';
const orderDateField = document.createElement('input');
const orderDateProps = {
	type: 'text',
	class: `form-field ${orderDateString}`,
	id: orderDateString,
	placeholder: '',
	name: orderDateString,
	labeltext: Resources.CONTACT_ORDER_DATE
};

// Order Num Field
const orderNumString = 'orderNum';
const orderNumField = document.createElement('input');
const orderNumProps = {
	type: 'text',
	class: `form-field ${orderNumString}`,
	id: orderNumString,
	placeholder: '',
	name: orderNumString,
	labeltext: Resources.CONTACT_ORDER_NUM
};

// Tracking Num Field
const trackingNumString = 'trackingNum';
const trackingNumField = document.createElement('input');
const trackingNumProps = {
	type: 'text',
	class: `form-field ${trackingNumString}`,
	id: trackingNumString,
	placeholder: '',
	name: trackingNumString,
	labeltext: Resources.CONTACT_TRACKING_NUM
};

// Issue Field
const issueString = 'issue';
const issueField = document.createElement('textarea');
const issueProps = {
	class: `input-textarea form-field required ${issueString}`,
	id: issueString,
	placeholder: '',
	required: '',
	name: issueString,
	maxlength: 1000,
	rows: 4,
	cols: 10,
	'data-character-limit': 1000,
	labeltext: Resources.CONTACT_ISSUE
};

// Expectation Field
const expectationString = 'expectation';
const expectationField = document.createElement('textarea');
const expectationProps = {
	class: `input-textarea form-field required ${expectationString}`,
	id: expectationString,
	placeholder: '',
	required: '',
	name: expectationString,
	maxlength: 1000,
	rows: 4,
	cols: 10,
	'data-character-limit': 1000,
	labeltext: Resources.CONTACT_EXPECTATION
};

// Additional Info Field
const additionalInfoString = 'additionalInfo';
const additionalInfoField = document.createElement('textarea');
const additionalInfoProps = {
	class: `input-textarea form-field ${additionalInfoString}`,
	id: additionalInfoString,
	placeholder: '',
	name: additionalInfoString,
	maxlength: 1000,
	rows: 4,
	cols: 10,
	'data-character-limit': 1000,
	labeltext: Resources.CONTACT_ADDITIONAL_INFO
};

// Message Field
const messageString = 'message';
const messageField = document.createElement('textarea');
const messageProps = {
	class: `input-textarea form-field ${messageString}`,
	id: messageString,
	placeholder: '',
	name: messageString,
	maxlength: 1000,
	rows: 4,
	cols: 10,
	'data-character-limit': 1000,
	labeltext: Resources.CONTACT_MESSAGE
};

// Reason For Canceling Field
const reasonForCancelingString = 'reasonForCanceling';
const reasonForCancelingField = document.createElement('select');
const reasonForCancelingProps = {
	class: `input-select form-dropdown required floating-label-input ${reasonForCancelingString}`,
	id: reasonForCancelingString,
	placeholder: '',
	required: '',
	name: reasonForCancelingString,
	labeltext: Resources.CONTACT_REASON_FOR_CANCELING
};

function getFieldsAndProps(messageType){
	if(messageType == 'help'){
		return [[firstNameField,  firstNameProps], [lastNameField, lastNameProps], [emailField, emailProps], [phoneField, phoneProps], [orderDateField, orderDateProps], [orderNumField, orderNumProps], [trackingNumField, trackingNumProps], [additionalInfoField, additionalInfoProps]];
	}else if(messageType == 'productinquiry' || messageType == 'sizing' || messageType == 'itemavailability'){
		return [[firstNameField, firstNameProps], [lastNameField, lastNameProps], [emailField, emailProps], [phoneField, phoneProps], [orderDateField, orderDateProps], [orderNumField, orderNumProps], [additionalInfoField, additionalInfoProps]];
	}else if(messageType == 'websiteissues'){
		return [[fullNameField, fullNameProps], [emailField, emailProps], [issueField, issueProps], [expectationField, expectationProps], [additionalInfoField, additionalInfoProps]];
	}else if(messageType == 'loyaltyeditchildsize'){
		return [[firstNameField, firstNameProps], [lastNameField, lastNameProps], [emailField, emailProps], [messageField, messageProps]];
	}else if(messageType == 'loyaltycancel'){
		return [[firstNameField, firstNameProps], [lastNameField, lastNameProps], [emailField, emailProps], [phoneField, phoneProps], [reasonForCancelingField, reasonForCancelingProps], [messageField, messageProps]];
	}else if(messageType){
		return [[fullNameField, fullNameProps], [emailField, emailProps], [phoneField, phoneProps], [messageField, messageProps]];
	}
}

function setDynamicFieldAttributes(field, props){
	for (const prop in props) {
		if(prop !== 'labeltext' && prop !== 'text'){
			field.setAttribute(prop, props[prop]);
		}
	}
}

function createDynamicLabel(fieldID, fieldLabelText, required){
	const textSpan = document.createElement('span');
	const floatingLabel = document.createElement('label');
	const floatingLabelProps = {
		class: 'floating-label',
		for: fieldID
	}

	setDynamicFieldAttributes(floatingLabel, floatingLabelProps);
	textSpan.innerText = fieldLabelText;

	if(required){
		floatingLabel.append(textSpan);
		floatingLabel.append('*');
	}else{
		floatingLabel.append(textSpan);
	}
	
	return floatingLabel;
}

function buildForm(fields){
	for(let i = 0; i < fields.length; i++){
		const field = fields[i][0];
		const fieldProps = fields[i][1];

		setDynamicFieldAttributes(field, fieldProps);

		// Form Row
		const formRow = document.createElement('div');
		formRow.setAttribute('class', 'form-row');

		// Field Wrapper
		const fieldWrapper = document.createElement('div');
		fieldWrapper.setAttribute('class', 'field-wrapper');

		// Dynamic Label
		const isRequired = fieldProps.hasOwnProperty('required');
		const label = createDynamicLabel(fieldProps.id, fieldProps.labeltext, isRequired);

		$('.form-fields-main-container').append(formRow);
		$(formRow).append(label);
		$(formRow).append(fieldWrapper);
		$(fieldWrapper).append(field);

		if(field == reasonForCancelingField){
			fieldWrapper.classList.add('select-wrapper');

			const selectArrow = document.createElement('div');
			selectArrow.setAttribute('class', 'arrow-down');
			fieldWrapper.append(selectArrow);

			const defaultOptionString = Resources.CONTACT_US_DEFAULT_CANCEL;
			const defaultOption = document.createElement('option');
			const defaultOptionProps = {
				value: defaultOptionString,
				selected: '',
				disabled: '',
				text: defaultOptionString
			};
			setDynamicFieldAttributes(defaultOption, defaultOptionProps);
			defaultOption.innerText = defaultOptionProps.text;
			reasonForCancelingField.append(defaultOption);

			const customOptions = SitePreferences.CANCEL_ACCOUNT_OPTIONS;
			if(customOptions.length > 0){
				customOptions.forEach(function(option){
					const customOption = document.createElement('option');
					customOption.setAttribute('value', option);
					customOption.innerText = option;
					reasonForCancelingField.append(customOption);
				});
			}
		}

		if(field == firstNameField || field == lastNameField || field == phoneField || field == orderDateField){
			const fieldsWrapperRow = document.createElement('div');
			fieldsWrapperRow.setAttribute('class', 'fields-wrapper row');

			if(field == firstNameField || field == lastNameField ){
				formRow.classList.add('wrap-first-last-name')
				if(field == lastNameField){
					$('.wrap-first-last-name').wrapAll(fieldsWrapperRow);				
				}
			}

			if(field == phoneField || field == orderDateField){
				formRow.classList.add('wrap-phone-order-date')
				if(field == orderDateField){
					$('.wrap-phone-order-date').wrapAll(fieldsWrapperRow);
				}
			}
		}
	}
}

function bindEvents() {
	$('#contact-message-type').on('change', function(event){
		$('.form-fields-main-container').empty();
		if(reasonForCancelingField.hasChildNodes()){
			$(reasonForCancelingField).empty();
		}
		var messageType = event.target.value;
		const fields = getFieldsAndProps(messageType);
		buildForm(fields);
		floatingLabels.init();
		util.limitCharacters();
		validator.init();
	});

	$('#contact-us-page-form').on('submit', function(event){
		if($(this).closest("form").valid()) {
			event.preventDefault();
			var messageType = $('select[name=contact-message-type] option:selected').text();
			var messageTypeValue = $('select[name=contact-message-type]').val();
			const fields = getFieldsAndProps(messageTypeValue);
			var formData = {
				messageType: messageType,
				messageTypeValue: messageTypeValue
			};

			fields.forEach(function(element){
				const field = element[0];
				const id = $(field).attr('id');
				formData[id] = $(field).val();
			});

			window.dataLayer.push({
				'event': 'contact-us-submission',
				'formType': 'contact-us',
				'messageType': messageType,  
			});

			// send email
			var url = util.appendParamsToUrl(Urls.contactUsSendMail, formData);
			ajax.getJson({
				url: url,
				callback: function() {
					$('#contact-us-page-form').toggleClass('hide');
					$('.contact-us-success-message-container').toggleClass('hide');
				}
			});
		}
	});
}

/**
 * @function
 * @description sends emails to customer service from the contact us page
 */
exports.init = function () {
    bindEvents();
};
