'use strict'

var breakpoints = require('../../breakpoints'),
    util = require('../../util');

var orderSummary = $('#main').find('#secondary.co-summary'),
    DOM = {
        summary: {
            contentsContainer: '.co-summary-contents-container',
            headerToggle: '.co-section-header-toggle',
            headerTotal: '.co-section-header-total',
            hideSummary: '.co-section-header-hide-summary',
            hideLink: '.hide-summary',
            toggleArrow: '.toggle-arrow',
            totalsTable: '.order-totals-table',
            orderTotal: '.order-total .order-value'
        },
        pli: {
            container: '.co-mini-cart-pli-container',
            toggleLink: '.toggle-cart-items',
            editLink: '.edit-cart-items',
            condensed: '.co-mini-cart-pli-container-condensed'
        }
    };

/**
 * @function toggleOrderSummary
 * @description Initializes toggle actions on the main Order Summary container in checkout
 * @param {String} listener Will be either 'click' or 'touchstart' conditional on device or browser
 */
function toggleOrderSummary(listener) {
    var toggleActions = function() {
        $(DOM.summary.contentsContainer).stop().slideToggle('fast');
        $(DOM.summary.headerTotal).toggleClass('active');
        $(DOM.summary.hideSummary).toggleClass('active');
        $(DOM.summary.toggleArrow).toggleClass('active');
        if($(DOM.summary.hideSummary).attr('aria-expanded') == 'true') {
            $(DOM.summary.hideSummary).attr('aria-expanded', 'false');
        } else {
            $(DOM.summary.hideSummary).attr('aria-expanded', 'true');
        }
        scrollToTop();
    };

    $(orderSummary).on(listener, DOM.summary.headerToggle, toggleActions);
    $(orderSummary).on(listener, DOM.summary.hideLink, toggleActions);
};

/**
 * @function toggleMinicartLineItems
 * @description Initializes toggle actions on the inner PLI container of Order Summary in checkout
 * @param {String} listener Will be either 'click' or 'touchstart' conditional on device or browser
 */
function toggleMinicartLineItems(listener) {
    var toggleActions = function() {
        $(DOM.pli.container).stop().slideToggle('fast', function() {
            if ($(this).is(':visible')) {
                $(DOM.pli.toggleLink).html(Resources.HIDE_DETAILS);
                $(DOM.pli.toggleLink).attr('title', Resources.HIDE_DETAILS);
                $(DOM.pli.toggleLink).attr('aria-expanded', 'true');
                $(DOM.pli.editLink).toggleClass('active');
            } else {
                $(DOM.pli.toggleLink).html(Resources.SHOW_DETAILS);
                $(DOM.pli.toggleLink).attr('title', Resources.SHOW_DETAILS);
                $(DOM.pli.toggleLink).attr('aria-expanded', 'false');
                $(DOM.pli.editLink).toggleClass('active');
                $(DOM.pli.condensed).addClass('active');
            }
        });
        $(DOM.pli.condensed).removeClass('active');
    };

    $(orderSummary).on(listener, DOM.pli.toggleLink, toggleActions);
};

/**
 * @function copyOrderTotalToHeader
 * @description Copies order total value from main order totals section and copies to the mobile Order Summary header in collapsed state
 */
function copyOrderTotalToHeader() {
    var orderTotalOrig = $(DOM.summary.totalsTable).find(DOM.summary.orderTotal).html(),
        orderTotalCopy = $(DOM.summary.headerTotal);

    $(document).ready(function() {
        $(orderTotalCopy).html(orderTotalOrig);
    });
};

/**
 * @function resetMobileOrderSummary
 * @description Resets mobile Order Summary containers if window is resized from mobile viewport width to desktop experience
 */
function resetMobileOrderSummary() {
    // Reset main Order Summary container
    $(DOM.summary.contentsContainer).removeAttr('style');
    $(DOM.summary.headerTotal).addClass('active');
    $(DOM.summary.hideSummary).removeClass('active');
    $(DOM.summary.toggleArrow).removeClass('active');

    // Reset product line items container
    $(DOM.pli.toggleLink).html(Resources.SHOW_DETAILS);
    $(DOM.pli.toggleLink).attr('title', Resources.SHOW_DETAILS);
    $(DOM.pli.editLink).removeClass('active');
    $(DOM.pli.container).removeAttr('style');
    $(DOM.pli.condensed).addClass('active');
};

/**
 * @function scrollToTop
 * @description Resets page back to top when mobile order sumamry is collapsed from a link further down on the page
 */
function scrollToTop() {
    $('html, body').animate({scrollTop: 0}, 200);
}

exports.init = function() {
    var checkoutPrimary = $('#main').find('#primary'),
        progressContainer = $('#main').find('.co-progress-container'),
        clickTouchListener = 'click';

    $(window).on('load resize', function() {
        if ( (breakpoints.isMobile() || breakpoints.isTablet()) || util.isMobile() ) {
            if (util.isMobile()) {
                clickTouchListener = 'touchstart';
            }
            orderSummary.detach().insertAfter(progressContainer);
            copyOrderTotalToHeader();
        } else {
            orderSummary.insertAfter(checkoutPrimary);
            resetMobileOrderSummary();
        }
    });
    toggleOrderSummary(clickTouchListener);
    toggleMinicartLineItems(clickTouchListener);
}

// Export this for access in updateSummary.js after DOM re-write
exports.copyOrderTotalToHeader = copyOrderTotalToHeader;