'use strict';

var player = {};
var desktop = $(window).innerWidth() > 1080;
var autoplay = false;

var createPlayer = function(videoPlayer, videoID, forceAutoPlay, callback) {
    var vimeoID = $(videoID).data('vimeo-desktop-id');

    var ap = $(videoID).data('vimeo-ap');
    if(typeof ap !== 'undefined')
        autoplay = ap;

    if (!desktop) {
        if(typeof $(videoID).data('vimeo-mobile-id') !== 'undefined')
            vimeoID = $(videoID).data('vimeo-mobile-id');
    }

    var options = {
        id: vimeoID,
        autoplay: false,
        loop: false
    };
    player = new Vimeo.Player(videoPlayer, options);
    callback();
}

var bindPlayerEvents = function(videoID) {
    /*********************************************
    when video is within fully in viewpoint autoplay
    video on Desktop only
    *********************************************/
    if (desktop && autoplay) {
        inView.offset(1);
        inView(videoID).once('enter', function() {
            player.play();
        });
    }
}

var initVideos = function() {
    $('.video-responsive').each(function() {
        var videoPlayer = $(this).attr('id');
        var videoID = '#' + videoPlayer;

        /*********************************************
        when video is within 500 pixels from the bottom
        of the viewpoint create player
        *********************************************/
        inView.offset(-500);
        inView(videoID).once('enter', function() {
            createPlayer(videoPlayer, videoID, false, function() {
                bindPlayerEvents(videoID);
            });
        });
    });
}

function bindEvents() {
  initVideos();
}

function init(){
    bindEvents();
}

exports.init = init;