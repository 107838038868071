'use strict';

const util = require('../../../util');
const breakpoints = require('../../../breakpoints');
const fmUtil = require('./familyMatchingUtil');
const minicart = require('../../../minicart');
const debounce = require('lodash/debounce');

const $button = $('.js-family-matching-button');
const $container = $button.closest('.js-family-matching-button-wrapper');

function init() {
    initStickyATC();

    $button.on('click', handleATCButtonClick);

    $(window).on('resize', debounce(()=>{
        initStickyATC();
    }, 200));
}

function initStickyATC(){
    if(!breakpoints.isDesktop()){
        $container.addClass('sticky');

        $('.js-chat-menu').css('bottom', ($container.height() + 30));
    }else{
        $container.removeClass('sticky');

        $('.js-chat-menu').css('bottom', '20px');
    }
}

function handleATCButtonClick(e){
    const currentButtonState = $(this).attr('data-button-state');

    if(currentButtonState === 'default'){
        util.scrollToElement($('.fm-pdp-step.two'))
    }

    if(currentButtonState === 'select-item'){
        util.scrollToElement($('.fm-pdp-step.three'));
    }

    if(currentButtonState === 'add-to-cart'){
        /**
         * Simulated jquery clicks do not have originalEvent.
         */
        addBundleToCart(e.originalEvent ? 'main-atc' : 'pseudo-atc');
    }
}

function addBundleToCart(clickLocation){
    const controller = $button.attr('data-url');
    const bundle = fmUtil.getCurrentBundle();
    const bundleItems = [];

    for(const value of bundle.items.values()){
        bundleItems.push(value);
    }

    $button.addClass('disabled');

    fmUtil.showLoadingGraphic();

    $.ajax({
        url: controller,
        type: 'POST',
        data: {
            bundleItems: JSON.stringify(bundleItems)
        },
        success: handleAddBundleToCartResponse(clickLocation),
        error: (error) => {
            console.log('familyMatchAddToCartButton error:', error)
        },
    });
}

function handleAddBundleToCartResponse(clickLocation) {
    return function(res) {
        fmUtil.hideLoadingGraphic();
        minicart.show(res);
        $(document).trigger('ga.addtocart', [null, clickLocation]);
        fmUtil.resetFamilyMatchPDP();
    }
}

module.exports = {
    init: init
};
