var debounce = require('lodash/debounce');
var util = require('./util');
var VimeoPlayer = require('@vimeo/player');

var VIMEO_URL = 'https://player.vimeo.com/video/';

/**
 * Returns the ratio of an element as a percentage of height / width
 *
 * @param {jQuery} $el - jQuery Object
 * @returns {number}
 */
var getVideoRatio = function($el) {
    var height = $el.height();
    var width = $el.width();
    return (height / width) * 100;
};

/**
 * Helper function to update data clicked attribute
 *
 * @param {jQuery} $el - jQuery Object. Should be the child of the data container.
 * @param {string} action - the value you want to pass the action to
 */
var trackVideoClick = function($el, action) {
    var $trackingContainer = $el.closest('[data-contentid]');
    $trackingContainer.attr('data-clicked', action);
}

/**
 * Creates a Vimeo Player object and inserts embedded vimeo player into the DOM.
 *
 * @param {string} id - The ID of the element to insert the created embedded player 
 * @param {string} url - The url of the video 
 * @param {jQuery} $el - jQuery Object. The video container element.
 * @returns {object} - Vimeo Player object.
 */
var createEmbedPlayer = function(id, containerId, $el) {
    var vimeoSrc = VIMEO_URL + id;

    // creates autoplay options
    if ($el.data('vimeo-autoplay')) {
        vimeoSrc = util.appendParamsToUrl(vimeoSrc, {
            'loop': 1,
            'muted': 1,
            'autopause': 0
        });
    }

    // create iframe element
    var $iframe = $('<iframe></iframe>');
    $iframe.attr({
        src: vimeoSrc,
        title: containerId,
        allow: 'autoplay; fullscreen',
        allowfullscreen: ''
    });
    // and attach it to the DOM
    $('#' + containerId).append($iframe);

    // initialize the vimeo player
    var embedPlayer = new VimeoPlayer.default($iframe);

    // add custom functionality to player
    embedPlayer.on('play', function(){
        trackVideoClick($el, 'start video');
    });
    embedPlayer.on('pause', function(){
        trackVideoClick($el, 'stop video');
    });

    return embedPlayer;
}

/**
 * Creates data store to hold Vimeo player objects
 *
 * @returns {object}
 */
var VideoStore = function(){
    var videoPlayers = {};

    function create(id, containerId, $el) {
        var videoPlayer = createEmbedPlayer(id, containerId, $el);
        videoPlayers[containerId] = videoPlayer;
        return videoPlayer;
    }

    function get(id) {
        return videoPlayers[id];
    }
    
    return {
        create: create,
        get: get
    }
}

/**
 * Initialize module and bind clicks events
 */
var init = function() {
    var videoStore = new VideoStore();
    var $videoContainer = $('.js-video-container');
    var $videoAutoplay = $videoContainer.filter('[data-vimeo-autoplay=true]');

    if (!$videoContainer.length > 0) {
        return;
    }

    $videoContainer.each(function(){
        var $this = $(this);
        var _id = $this.data('videoId');
        var _containerId = $this.data('containerId');

        videoStore.create(_id, _containerId, $this);
        $this.attr('data-video-ready', true);
    });

    if ($videoAutoplay.length > 0) {
        $(window).on('scroll', debounce(function() {
            $videoAutoplay.each(function() {
                var $this = $(this);
                var _id = $this.data('containerId');
                var player = videoStore.get(_id);
                if (util.elementInViewport($this.get(0), 200)) {
                    player.play();
                } else {
                    player.pause();
                }
            });
        }, 200));
    }

    // attach event listener
    $('body').on('click keydown', '.js-video-container, .js-video-btn', function(e){
        if (e.type === 'click' || e.keyCode === 32 || e.keyCode === 13) {
            e.stopPropagation();
            var $videoContainer = $(this).closest('.js-video-container');
            var _id = $videoContainer.data('containerId');
            var player = videoStore.get(_id);
            player.play();

            // calculate ratio of video & set padding-bottom for container
            var $videoEmbed = $videoContainer.find('.video-embed__container');
            var videoRatio = getVideoRatio($videoContainer) + '%';
            
            $videoEmbed.css('padding-bottom', videoRatio);
            $videoContainer.attr('data-video-cover', false);
            $videoContainer.find('iframe').trigger('focus');
        }
    });
}

module.exports.init = init;
