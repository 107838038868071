'use strict'

var progress = require('../../progress'),
    orderSummary = require('./orderSummary'),
    breakpoints = require('../../breakpoints'),
    util = require('../../util');

// Borderfree Requirement
var bfxprice = require('../../bfxprice');

/**
 * @function
 * @description updates the order summary based on a possibly recalculated basket after a shipping promotion has been applied
 */
function updateSummary(checkoutstep) {
	
    var $summary = $('#secondary.summary');
    
    // indicate progress
    progress.show($summary);

    // load the updated summary area
    $summary.load(Urls.summaryRefreshURL, { step : checkoutstep }, function () {
        // hide edit shipping method link
        $summary.fadeIn('fast');
        $summary.find('.checkout-mini-cart .minishipment .header a').hide();
        $summary.find('.order-totals-table .order-shipping .label a').hide();

        // Re-bind mobile order summary header total display
        if ( (breakpoints.isMobile() || breakpoints.isTablet()) || util.isMobile() ) {
            orderSummary.copyOrderTotalToHeader();
        }
    });

    // Borderfree Requirement
    bfxprice.init();
}

module.exports = updateSummary;
