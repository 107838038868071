'use strict';

var dialog = require('../dialog');

var $template;

function generateTemplate() {
    var $temp = $('<div class="promo-drawer-dialog__content"></div>');

    // add empty div with tabindex... workaround to control the element that 
    // that jquery UI dialog focuses on when opening the dialog
    $temp.prepend('<div tabindex="0"></div>');
    $temp.append('<div class="promo-drawer-dialog__details-copy"></div>');
    $temp.append('<p><button class="promo-drawer-dialog-close text-decoration-underline icon-button">Close</button></p>');

    $template = $temp;
    return $template;
}

function open(e) {
    e.preventDefault();

    var $this = $(this);
    var $linksContainer = $this.closest('.promo-drawer__card-links');

    var detailsHtml = $linksContainer.data('detailsContent');
    var $dialogContainer = $template || generateTemplate();
    $dialogContainer.find('.promo-drawer-dialog__details-copy').html(detailsHtml);

    var settings = {
        html: $dialogContainer,
        options: {
            dialogClass: 'promo-drawer-dialog',
            modal: true,
            draggable: false,
            width: 500,
            open: function() {
                $('.promo-drawer-dialog-close').on('click', function() {
                    dialog.close();
                });
            }
        }
    };

    return dialog.open(settings);
}

module.exports = {
    open: open
}