var countDownContainer = document.querySelector('.cdc-container');
if (countDownContainer) {
    var countDownTextContainer = countDownContainer.querySelector('.cdc-text-container');
    var countDownLinksContainer = countDownContainer.querySelector('.cdc-links-container');
    var countDownCounterContainer = countDownContainer.querySelector('.cdc-counter-container');
    var countDownTimeWrappers = countDownCounterContainer.querySelectorAll('.cdc-time-wrapper');
    var countDownColons = countDownCounterContainer.querySelectorAll('.cdc-colon');
    var countDownDays = countDownCounterContainer.querySelector('.cdc-days');
    var countDownHrs = countDownCounterContainer.querySelector('.cdc-hrs');
    var countDownMin = countDownCounterContainer.querySelector('.cdc-min');
    var countDownSec = countDownCounterContainer.querySelector('.cdc-sec');
    var countDownComplete = countDownContainer.querySelector('.cdc-completed');
    var countDownDaysOnlyContainer = countDownCounterContainer.querySelector('.cdc-days-only-container');
    var countDownDaysOnly = countDownDaysOnlyContainer.getAttribute('data-countdown-days-only'); 


    var unformattedPromoEndString = countDownCounterContainer.getAttribute('data-countdown-date');
    var promoEndArray = unformattedPromoEndString.split(' ');
    var promoEndYear = promoEndArray[5];
    var months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
    var promoEndMonth = months[new Date(unformattedPromoEndString).getMonth()];
    var promoEndDay = promoEndArray[2];
    var promoEndTime = 'T23:59:59';
    var formattedPromoEndString = [promoEndYear, promoEndMonth, (promoEndDay+promoEndTime)].join('-');
    var promoEnd = new Date(formattedPromoEndString).getTime();
    var now = Date.now();
    var timeRemaining = promoEnd - now;
    var timePad = '0';
    var second = 1000;
    var minute = second * 60;
    var hour = minute * 60;
    var day = hour * 24;
}

function setTimePad(time) {
    if (time.length < 2) {
        return timePad.concat(time);
    } else {
        return time;
    }
}

function clearTime() {
    var cleared = '00'
    countDownDays.innerText = cleared;
    countDownHrs.innerText = cleared;
    countDownMin.innerText = cleared;
    countDownSec.innerText = cleared;
}

function init() {
    if(countDownContainer){
        var countDown = setInterval(function(){
            now = Date.now();
            timeRemaining = promoEnd - now;
            countDownDays.innerText = Math.ceil(timeRemaining / day).toString() + ' days';
            if (Math.ceil(timeRemaining / day) <= 1) {
                if(countDownDaysOnly != "false"){
                    countDownDays.innerText = Math.ceil(timeRemaining / day).toString() + ' day';    
                }else{
                    if(!countDownDaysOnlyContainer.classList.contains('hidden')){
                        countDownDaysOnlyContainer.classList.add('hidden');
                        countDownTimeWrappers.forEach(function(container){
                            container.classList.remove('hidden');
                        }); 
                        countDownColons.forEach(function(colon){
                            colon.classList.remove('hidden');
                        });
                    }
                    countDownHrs.innerText = setTimePad(Math.floor((timeRemaining % day) / hour).toString());
                    countDownMin.innerText = setTimePad(Math.floor((timeRemaining % hour) / minute).toString());
                    countDownSec.innerText = setTimePad(Math.floor((timeRemaining % minute) / second).toString());
                }
            }
        }, second);


        if (timeRemaining < 0) {
            clearInterval(countDown);
            clearTime();
            countDownComplete.classList.remove('hidden');
            countDownTextContainer.classList.add('hidden');
            countDownLinksContainer.classList.add('hidden');
            countDownCounterContainer.classList.add('hidden');
        }
    }
}

module.exports = {
    init: init
}