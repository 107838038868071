'use strict';

var util = require('../util');

function productView(pageData) {
	if (window.MARKETINGPIXELS.enableTikTokTracking != "true") {
        return;
    }

    var trackObj = {
        content_id: pageData.product.id,
        content_type: pageData.product.dimension15 == '' || pageData.product.dimension15 == 'no size selected' ? 'product_group' : 'product',
        value: pageData.product.price,
        currency: window.CURRENCY_CODE
    }

    ttq.track('ViewContent', trackObj);

    if (window.enableMarketingPixelDebug == "true") {
        console.log("ttqDataObj: " + JSON.stringify(trackObj));
        console.log("TikTok Product View");
    }
}

function addToBag(addToBagData) {
    if (window.MARKETINGPIXELS.enableTikTokTracking != "true") {
        return;
    }

	var trackObj = {
        content_id: util.getVariationSKU(addToBagData.product.id),
        content_type: 'product',
        content_name: addToBagData.product.name,
        quantity: !addToBagData.quantity ? 1 : addToBagData.quantity,
        value: addToBagData.product.price,
        currency: window.CURRENCY_CODE
	};
    ttq.track('AddToCart', trackObj);

    if (window.enableMarketingPixelDebug == "true") {
        console.log("ttqkDataObj: " + JSON.stringify(trackObj));
        console.log("TikTok AddToCart");
    }
}

module.exports = {
	productView: productView,
	addToBag: addToBag
};
