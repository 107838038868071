'use strict';

var chatTeaser = require('./chatteaser');
var fixStickyNav = require('../stickyNav').fixStickyNav;
var bodyScrollLock = require('body-scroll-lock');

var KEY_ENTER = 'Enter';
var KEY_ARROW_UP = 'ArrowUp';
var KEY_ARROW_DOWN = 'ArrowDown';
var KEY_ESCAPE = 'Escape';

var MENU_CLOSED = 'closed';
var MENU_OPEN = 'open';
var MENU_CHAT_OPEN = 'chat open';
var MENU_TEASER_OPEN = 'teaser open';

var REGEX_ARROW = new RegExp(KEY_ARROW_UP + '|' + KEY_ARROW_DOWN);

var VENDOR_FIVE9 = 'five9';
var VENDOR_HERO = 'hero';
var VENDOR_SERVICECLOUD = 'servicecloud';

var $chatMenu = {};
var $chatMenuUnderlay = {};
var $chatMenuController = {};

var state = {
    initialized: false,
    menuStatus: MENU_CLOSED,
    selectedChatVendor: null
};

var vendorConfigs = {
    five9: {
        openEventDesc: 'chat-customer-care',
        open: function () {
            $('.fcr-item.chat').last().trigger('click');
            $('#five9-minimize-button').attr('tabindex','0');
            setTimeout(function() {
                var frame = document.getElementById('embedded-frame');
                if (frame) {
                    document.getElementById('embedded-frame').focus();
                }
            }, 500);
            $('body').on('click.chatmenu.five9close', '#five9-minimize-icon', function (e) {
                state.selectedChatVendor = null;
                updateMenuStatus(MENU_CLOSED);
                $('body').off('click.chatmenu.five9close');
                return;
            });
            return;
        },
        close: function () {
            $('#five9-minimize-icon').trigger('click');
            return;
        }
    },
    hero: {
        openEventDesc: 'chat-personal-shopper',
        open: function () {
            if (heroGuard()) {
                hero('show');
            }
            return;
        },
        close: function () {
            if (heroGuard()) {
                hero('hide');
            }
            return;
        }
    },
    servicecloud: {
        openEventDesc: 'chat-customer-care',
        open: function() {
            bodyScrollLock.disableBodyScroll();
            $(window).off('scroll', fixStickyNav);
            embeddedservice_bootstrap.utilAPI.launchChat().then(() => {
                window.dispatchEvent(new MessageEvent('message', {
                    data: { method: 'ESW_APP_MAXIMIZE' },
                    origin: window.serviceCloudChatDomain,
                    source: document.getElementById('embeddedMessagingFrame').contentWindow
                }));
            }).catch((err) => console.log(err));
        },
        close: function() {
            if (window.embeddedservice_bootstrap) {
                embeddedservice_bootstrap.utilAPI.hideChatButton();
            }
            bodyScrollLock.clearAllBodyScrollLocks();
            $(window).off('scroll', fixStickyNav).on('scroll', fixStickyNav);
            $('closeButton.slds-button').trigger('click');
        }
    }
}

function heroGuard () {
    return !!(window.hero);
}

function open () {
    if ($chatMenu.length > 0) {
        $chatMenu.addClass('show');
        $chatMenu.removeClass('show--teaser');

        $chatMenuUnderlay.addClass('dis-block');
        $chatMenuUnderlay.on('click.chatmenu', function (e) {
            updateMenuStatus(MENU_CLOSED);
        });
        $chatMenu.off('click.chatmenu.teaser');

        $chatMenuController.attr('aria-expanded', 'true');
    }
    return;
};

function openTeaser () {
    if ($chatMenu.length > 0) {
        $chatMenu.addClass('show show--teaser');
        $chatMenuController.attr('aria-expanded', 'false');
        $chatMenu.on('click.chatmenu.teaser', '.chat-menu__header', function () {
            updateMenuStatus(MENU_OPEN);
        })
    }
    return;
};

function close () {
    if ($chatMenu.length > 0) {
        $chatMenuUnderlay.removeClass('dis-block');
        $chatMenuUnderlay.off('click.chatmenu');

        $chatMenu.removeClass('show show--teaser');
        $chatMenu.off('click.chatmenu.teaser');

        if (state.menuStatus === MENU_CLOSED) {
            $chatMenu.removeClass('show--chat-open');
            $chatMenuController.attr('aria-expanded', 'false');
            return;
        }

        if (state.menuStatus === MENU_CHAT_OPEN) {
            $chatMenu.addClass('show--chat-open');
        }
    }
    return;
};

function updateMenuStatus (menuStatus) {
    state.menuStatus = menuStatus;

    chatTeaser.clearTeaserStartTimout();
    chatTeaser.clearTeaserEndTimout();

    var menuControllerAriaLabel;

    switch (menuStatus) {
        case MENU_OPEN:
            open();
            menuControllerAriaLabel = 'Close chat menu';
            break;

        case MENU_CHAT_OPEN:
            close();
            menuControllerAriaLabel = 'Close chat';
            break;

        case MENU_CLOSED:
            close();
            menuControllerAriaLabel = 'Open chat menu';
            break;
        
        case MENU_TEASER_OPEN:
            openTeaser();
            break;
    }

    if ($chatMenuController && typeof $chatMenuController.attr === 'function') {
        $chatMenuController.attr('aria-label', menuControllerAriaLabel);
    }
    return;
}

function pushChatMenuEvent (eventDesc) {
	window.dataLayer = window.dataLayer || [];
	window.dataLayer.push({
		'event': eventDesc
	});
    return;
}

function handleChatVendorOpen (selectedVendor) {
    if (state.selectedChatVendor === selectedVendor || !vendorConfigs[selectedVendor]) {
        return;
    }

    for (var vendor in vendorConfigs) {
        if (Object.prototype.hasOwnProperty.call(vendorConfigs, vendor)) {
            if (vendor === selectedVendor) {
                state.selectedChatVendor = vendor;
                vendorConfigs[vendor].open();
                updateMenuStatus(MENU_CHAT_OPEN);
            } else {
                vendorConfigs[vendor].close();
            }
        }
    }
}

function checkIfHideForInternational () {
    var isInternationalCustomer = window.SessionAttributes && window.SessionAttributes.BFX_INTERNATIONAL;
    var hideInternational = window.herochatFlags && !window.herochatFlags.showInternational;

    return isInternationalCustomer && hideInternational;
}

function checkInitPrereqs () {
    // Check if initialized already or if heroChatFlags are not set globally
    if (state.initialized || !window.herochatFlags) {
        return false;
    }

    // If hero is enabled AND NOT excluded from the page (exclusion pages are managed via custom preference) AND hero chat
    // IS NOT available => defer initialzation.
    // The init function is made global and will be called again via a hero callback when it is available.
    if (window.herochatFlags.heroEnabled && !window.herochatFlags.excludeHeroChat && !window.herochatFlags.chatAvailable) {
        return false;
    }

    if (!window.herochatFlags.showChatIcon) {
        return false;
    }

    return true;
}

function init(providedChatProvider) {  
    var chatProvider = providedChatProvider || window.chatProvider;
    if (!checkInitPrereqs()) {
        return;
    }

    if (checkIfHideForInternational()) {
        return;
    }

    $chatMenu = $('.js-chat-menu');

    if ($chatMenu.length > 0) {
        state.initialized = true;
        if (window.herochatFlags) {
        	window.herochatFlags.chatMenuInitialized = true;
        }
        $chatMenuUnderlay = $('.js-chat-menu-underlay');
        $chatMenuController = $('.js-chat-menu-toggle');
        $chatMenuController.removeClass("hidden");

        if (window.herochatFlags && window.herochatFlags.messengerShown) {
            updateMenuStatus(MENU_CHAT_OPEN);
            state.selectedChatVendor = VENDOR_HERO;
        } else if (heroGuard()) {
            hero('onShow', function () {
                updateMenuStatus(MENU_CHAT_OPEN);
                state.selectedChatVendor = VENDOR_HERO;
            });
        }

        if (heroGuard()) {
            hero('onHide', function () {
                if (state.menuStatus === MENU_CHAT_OPEN && state.selectedChatVendor === VENDOR_HERO) {
                    updateMenuStatus(MENU_CLOSED);
                }
            });
        }

        window.addEventListener("message", function(message) {
            if (message && message.data && message.data.method) {
                switch (message.data.method) {
                    case 'ESW_CLEAR_WEBSTORAGE_EVENT':
                        bodyScrollLock.clearAllBodyScrollLocks();
                        updateMenuStatus(MENU_CLOSED);
                        $(window).off('scroll', fixStickyNav).on('scroll', fixStickyNav);
                        state.selectedChatVendor = null;
                        break;
                    default:
                        break;
                }
            }
        });

        $chatMenu
            .on('focusout.chatmenu', function(e) {
                if (e.relatedTarget) {
                    var $relatedTarget = $(e.relatedTarget);
                    var $openDropUps = $chatMenu.filter('.show');

                    if ($openDropUps.length > 0 && $relatedTarget.closest('.js-chat-menu.show').length === 0) {
                        updateMenuStatus(MENU_CLOSED);
                    }
                }
            })
            .on('keydown.chatmenu', function(e) {
                if (REGEX_ARROW.test(e.key)) {
                    e.preventDefault();
                    e.stopPropagation();
                    var $menuItems = $(this).find('.chat-menu__option');
                    var index = $menuItems.index(e.target);
        
                    if (e.key === KEY_ARROW_UP && index > 0) {
                        index--;
                    }
        
                    if (e.key === KEY_ARROW_DOWN && index < $menuItems.length - 1) {
                        index++; 
                    }
                    
                    index = index === -1 ? 0 : index;
                    if (state.menuStatus === MENU_CLOSED) {
                        updateMenuStatus(MENU_OPEN);
                    }
                    $menuItems[index].focus();
                }

                if (e.key === KEY_ESCAPE) {
                    if (state.menuStatus === MENU_OPEN) {
                        updateMenuStatus(MENU_CLOSED);
                        $chatMenuController.focus();
                    }
                    return;
                }
            })
            .on('click.chatmenu keydown.chatmenu', '.chat-menu__close', function (e) {
                if (e.type === 'click' || e.key === KEY_ENTER) {
                    e.stopPropagation();
                    e.preventDefault();
                    
                    if (state.menuStatus === MENU_TEASER_OPEN) {
                    	pushChatMenuEvent('tooltip-close');
                    }
        
                    updateMenuStatus(MENU_CLOSED);

                    if (e.target === document.activeElement) {
                        $chatMenu.find('.js-chat-menu-toggle').trigger('focus');
                    }
                    return;
                }
            })
            .on('click.chatmenu keydown.chatmenu', '.js-chat-menu-toggle', function (e) {
                if (e.type === 'click' || e.key === KEY_ENTER) {
                    e.stopPropagation();
                    e.preventDefault();

                    switch (state.menuStatus) {
                        case MENU_CLOSED:
                        case MENU_TEASER_OPEN:
                            if (window.herochatFlags && window.herochatFlags.messengerShown) {
                                vendorConfigs.hero.close();
                            } else if (window.herochatFlags && !window.herochatFlags.chatAvailable) {
                                vendorConfigs[chatProvider].open();
                                state.selectedChatVendor = chatProvider;
                                updateMenuStatus(MENU_CHAT_OPEN);
                            } else {
                                updateMenuStatus(MENU_OPEN);
                                pushChatMenuEvent('chat-bubble');
                            }
                            break;
                        
                        case MENU_OPEN:
                            updateMenuStatus(MENU_CLOSED);
                            break;
                        
                        case MENU_CHAT_OPEN:
                            vendorConfigs[state.selectedChatVendor].close();
                            state.selectedChatVendor = null;
                            updateMenuStatus(MENU_CLOSED);
                            break;

                        default:
                            break;
                    }
                    return;
                }
            })
            .on('click.chatmenu', '.chat-menu__option', function (e) {
                e.preventDefault();

                var vendor = $(this).data('vendor') === 'hero'
                    ? "hero"
                    : chatProvider;

                if (!vendor) {
                    return;
                }

                if (vendorConfigs[vendor]) {
                    handleChatVendorOpen(vendor);
                    pushChatMenuEvent(vendorConfigs[vendor].openEventDesc);
                }
                return;
            });

        chatTeaser.start($chatMenu, 
            function () {
                updateMenuStatus(MENU_TEASER_OPEN);
            },
            function () {
                updateMenuStatus(MENU_CLOSED);
            });
    }
    return;
}

window.chatMenuInit = init;

module.exports = {
    init: init,
    openChat: handleChatVendorOpen,
    VENDOR_HERO: VENDOR_HERO,
    VENDOR_FIVE9: VENDOR_FIVE9,
    VENDOR_SERVICECLOUD: VENDOR_SERVICECLOUD,
};
