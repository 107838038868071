'use strict';

var util = require('../../util');
var shipping = require('./shipping');

/**
 * @function
 * @description Selects the first address from the list of addresses
 */
exports.init = function () {
    var $form = $('.address');
    // select address from list
    $('select[name$="_addressList"]').on('change', function () {
        var selected = $(this).children(':selected').first();
        var selectedAddress = $(selected).data('address');
        if (!selectedAddress) { return; }

        var $addrBox = $(this).closest(".address-box");
        shipping.setSumbitActionIfSavedAddressSelected();

        for (var field in selectedAddress) {
        	// Never ignore "type" here as it is used to determine whether to hide registry addresses from checkout.
            if (field === 'ID' || field === 'UUID' || field === 'key') {
                continue;
            }

            if (field.indexOf("Code") == -1) {
                // Ensure that the credit card type is not erroneously changed when updating address fields
                $form
                    .find('[name$="' + field + '"]')
                    .not('[name$="creditCard_type"]')
                    .val(selectedAddress[field]);
            }
        }

        if($addrBox.length > 0) {
            $addrBox.find('[name$="_country"]').val(selectedAddress.countryCode).trigger("change");
            $addrBox.find('[name$="_state"]').val(selectedAddress.stateCode);
            util.changeFormSelection($addrBox.find('[name$="_state"]')[0], selectedAddress.stateCode);
            util.changePostalFieldTypes("[name$='_postal']", ".address-box", $addrBox.find('[name$="_country"]'), selectedAddress.postalCode);
            $addrBox.find('[name$="_postal"]').val(selectedAddress.postalCode);
        } else {
            $form.find('[name$="_country"]').val(selectedAddress.countryCode).trigger("change");
            $form.find('[name$="_state"]').val(selectedAddress.stateCode);
            util.changeFormSelection($form.find('[name$="_state"]')[0], selectedAddress.stateCode);
            util.changePostalFieldTypes("[name$='_postal']", "", $form.find('[name$="_country"]'), selectedAddress.postalCode);
            $form.find('[name$="_postal"]').val(selectedAddress.postalCode);
        }

        shipping.updateShippingMethodList();

        if($addrBox.length > 0) {
            $addrBox.find('.error').removeClass('error');
        } else {
            if ($form.hasClass('checkout-billing')) { // skip credit card fields if on billing page
                $('input.error, select.error').not('.ccnumber,.creditcardexpiry,.cvn').removeClass('error');
                $('span.error').not('[id*="paymentMethods_creditCard"]').removeClass('error');
                $('span[id$="-error"]').not('[id*="paymentMethods_creditCard"]').remove();
            } else {
                $('.error').removeClass('error');
                $('span[id$="-error"]').remove();
            }
            $form.find('input, select').trigger('blur'); // trigger valid checkmark if populated field contains value
        }
    });
};