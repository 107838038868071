'use strict';

var util = require('./util');
var debounce = require('lodash/debounce');

var currentQuery = null,
    lastQuery = null,
    currentType = null,
    runningType = null,
    currentText = null,
    listTotal = -1,
    listCurrent = -1,
    breakpoints = require('./breakpoints'),
    delay = 200,
    $resultsContainer,
    ajaxRequest = null;

// Borderfree Requirement
var bfxprice = require('./bfxprice');

/**
 * @function
 * @description Handles keyboard's arrow keys
 * @param keyCode Code of an arrow key to be handled
 */
function handleArrowKeys(keyCode) {
    switch (keyCode) {
        case 38:
            // keyUp
            listCurrent = (listCurrent <= 0) ? (listTotal - 1) : (listCurrent - 1);
            break;
        case 40:
            // keyDown
            listCurrent = (listCurrent >= listTotal - 1) ? 0 : listCurrent + 1;
            break;
        default:
            // reset
            listCurrent = -1;
            return false;
    }

    $resultsContainer.children().removeClass('selected').eq(listCurrent).addClass('selected');
    $('input[name="q"]').val($resultsContainer.find('.selected .suggestionterm').first().text());
    return true;
}

function getLinkType(event) {
    var targetTagName = event.target.tagName;
    return targetTagName === 'IMG' || targetTagName === 'PICTURE' ? 'image' : 'text';
}

function buildAnalyticsClickData(e, $eventCurrTarget, searchQuery) {
    return {
        searchTerm: searchQuery,
        linkType: getLinkType(e),
        linkCategory: $eventCurrTarget.closest('[data-category-type]').data('categoryType')
    };
}

/**
 * @function
 * @description trigger suggest action
 */
function suggest() {
    // Skip getting new results if the current query is the same as the last
    // query attempted
    if (lastQuery === currentQuery) {
        return;
    }

    runningType = currentType;

    // build the request url
    var reqUrl;
    if (currentType == null) {
        reqUrl = util.appendParamToURL(Urls.searchsuggest, 'q', currentQuery);
    } else if (currentType == "category") {
        reqUrl = util.appendParamToURL(Urls.topresults, 'cgurl', currentQuery);
        reqUrl = util.appendParamToURL(reqUrl, 'cgname', currentText);
    } else {
        reqUrl = util.appendParamToURL(Urls.topresults, 'q', currentQuery);
    }

    // pass the display value back to the server so it can be included in the returned HTML fragment
    if (currentText) {
        reqUrl = util.appendParamToURL(reqUrl, 'text', currentText);
    }

    // on mobile send along a parameter so the product results can be removed
    if (breakpoints.isMobile()) {
        reqUrl = util.appendParamToURL(reqUrl, 'breakpoint', 'mobile');
    }

    if (ajaxRequest) {
        ajaxRequest.abort();
    }

    // execute server call
    ajaxRequest = $.get(reqUrl, function (data) {
        var suggestionHTML = data,
            ansLength = suggestionHTML.trim().length;

        // if there are results populate the results div
        if (runningType == null) {
            if (ansLength === 0) {
                clearResults();
            } else {
                // update the results div
                if($(".search-suggestion-wrapper").length == 0) {
                    $(suggestionHTML).insertBefore($resultsContainer);
                } else {
                    $('.search-suggestion-wrapper').css('display', 'block');
                    $(".search-suggestion-wrapper").html("").html($(suggestionHTML).html());
                }
            }
        }

        debouncedSuggest();
        hideLeftPanel();

        // Borderfree Adjustment
        bfxprice.init();

        currentText = null;
        ajaxRequest = null;

        $('.search-suggestion-wrapper a').on('click', function() {
            var $link = $(this);
            dataLayer.push({
                'event': 'search-auto-result-click',
                'searchTerm': currentQuery,
                'searchResult': $link.data('product-name') ? $link.data('product-name') : $link.text().trim()
            });
        });
    });

    // Record the query that has been executed so the site doesn't waste
    // recources calling it again
    lastQuery = currentQuery;
}

const debouncedSuggest = debounce(suggest, delay);

function clearResults() {
    if (!$('search-suggestion-wrapper')) { return; }
    $('.search-suggestion-wrapper').fadeOut(200, function () {$('.search-suggestion-wrapper').empty();});
}

function hideLeftPanel() {
    //hide left panel if there is only a matching suggested custom phrase
    if ($('.search-suggestion-left-panel-hit').length === 1 && $('.search-phrase-suggestion a').text().replace(/(^[\s]+|[\s]+$)/g, '').toUpperCase() === $('.search-suggestion-left-panel-hit a').text().toUpperCase()) {
        $('.search-suggestion-left-panel').css('display', 'none');
        $('.search-suggestion-wrapper-full').addClass('search-suggestion-wrapper');
        $('.search-suggestion-wrapper').removeClass('search-suggestion-wrapper-full');
    }
}

function init(container, defaultValue) {

    var $searchContainer = $(container);
    var $searchForm = $searchContainer.find('form[name="simpleSearch"]');
    var $searchField = $searchForm.find('input[name="q"]');

    // disable browser auto complete
    $searchField.attr('autocomplete', 'off');

    // on focus listener (clear default value)
    $searchField.on('focus', function () {
        if (!$resultsContainer) {
            // create results container if needed
            $resultsContainer = $('<div/>').attr('id', 'search-suggestions').attr('class', 'shs-suggestions').appendTo($searchContainer);
        }
        if ($searchField.val() === defaultValue) {
            $searchField.val('');
        }

        if ($searchField.val().length == 0) {
            // Return "Trending Items" on search field focus when no search term has been entered.
            currentType = null;
            currentQuery = '';

            debouncedSuggest();
        }
    });

    $(document).on('click', function (e) {
        if (!$searchContainer.is(e.target)) {
            setTimeout(clearResults, 200);
        }
    });
    
    // on key up listener
    $searchField.on('keyup', function (e) {
        currentType = null;

        // get keyCode (window.event is for IE)
        var keyCode = e.keyCode || window.event.keyCode;

        // check and treat up and down arrows
        if (handleArrowKeys(keyCode)) {
            return;
        }

        // check for an ENTER or ESC
        if (keyCode === 13 || keyCode === 27) {
            clearResults();
            return;
        }

        currentQuery = $searchField.val().trim();

        debouncedSuggest();
    });

        // Watch the results for clicks
    var $search = $('.js-header-search');

    $search.on('click', '.js-ps-view-more', function(e) {
        if (window.gtmAnalytics) {
            var clickData = buildAnalyticsClickData(e, $(this), $searchField.val());
            window.gtmAnalytics.submitPreviewSearchClick(clickData);
        }
    });
}

module.exports = { init };
