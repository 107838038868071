"use strict";

var util = require('./../util');

function init() {
    
    if($('.loyalty-interrupter-container').length > 0){
        $('.loyalty-interrupter-container').on('click', '.loyalty-decline', function() {
            dataLayer.push({
                "event" : "loyalty-upgrade",
                "event-category" : "loyalty upgrade steps",
                "event-action" : "upgrade",
                "event-label" : "loyalty upgrade decline"
            });
        });
    }

    if($('.loyalty-discount').length > 0) {
        var voucherAmount = $('.loyalty-discount').parent().children('.scp-order-total').children().html().substring(1);
            dataLayer.push({
                "event" : "loyalty-redeem-rewards",
                "checkout-step" : "cart",
                "voucher-amount" : voucherAmount
            });
    }

    if($('.loyalty-success-banner').length > 0 || $('.newLoyaltyConfirmation').length > 0){
            var smsOptInStatus = $('.newLoyaltyConfirmation').attr('data-sms');

            if($('.loyalty-success-banner').length > 0){
                var regStep = $('.loyalty-success-banner').attr('data-regstep');
            } else if($('.newLoyaltyConfirmation').length > 0){
                var regStep = $('.newLoyaltyConfirmation').attr('data-regstep');
            }
            var userDetails = util.getDataLayerEventDetails("dataLayer-initialized").userDetails;

            if (userDetails.customerPhoneNumber.length > 0) {
                var phoneNumberInput = "yes";
            } else {
                var phoneNumberInput = "no";
            }
            
            if(regStep == 'newRegistration'){
                dataLayer.push({
                    "event" : "loyalty-join-now",
                    "event-category" : "loyalty register steps",
                    "event-action" : "register",
                    "event-label" : "loyalty signup - completion",
                    "userID" : userDetails.userID,
                    "loyaltyID" : userDetails.loyaltyID,
                    "Children-Number" : userDetails.ChildrenNumber,
                    "phone-number-input" : phoneNumberInput,
                    "marketing-opt-in" : smsOptInStatus
                });
            } else if (regStep == "upgrade"){
                dataLayer.push({
                    "event" : "loyalty-upgrade",
                    "event-category" : "loyalty upgrade steps",
                    "event-action" : "upgrade",
                    "event-label" : "loyalty upgrade completion",
                    "userID" : userDetails.userID,
                    "loyaltyID" : userDetails.loyaltyID,
                    "Children-Number" : userDetails.ChildrenNumber,
                    "phone-number-input" : phoneNumberInput,
                    "marketing-opt-in" : smsOptInStatus
                });
            }
    }

    if($('.ld-faqs').length > 0){
        var availableRewards= JSON.parse($('.ld-benefits').attr('data-availablerewards')) 
        var loyaltyID=$('.ld-benefits').attr('data-loyaltyid');
        var benefitsLabel = availableRewards.hasMaxRewards;
        dataLayer.push({
            "event" : "loyalty-benefits",
            "event-category" : "ecommerce",
            "event-action" : "loyalty benefits balance view",
            "event-label" : benefitsLabel? 'maxed account' : 'non maxed account',
            "loyaltyID" : loyaltyID,
            "points-balance" : availableRewards.balance % availableRewards.pointsPerReward,
            "rewards-balance" : availableRewards.value
        });

        $('.ld-faq-slot').on('click', '.js-expander', function() {
            dataLayer.push({
                "event" : "loyalty-faq",
                "event-category" : "loyalty frequently asked questions",
                "event-action" : "question subject",
                "event-label" : $(this)[0].children[0].innerText
            });
        });

    }
        $('body').on('click', '.loyalty-button-tagging', function(e) {
            var buttonLocation;

            if($(e.currentTarget).parent().parent()[0].className.split(' ')[0] == ("sh-promo-bar-item__dropdown")){
                buttonLocation = "utility navigation";
            } else if($(e.currentTarget).parent()[0].className.split(' ')[0] == ("menu-bar-footer")){
                buttonLocation = "mobile hamburger menu";
            } else{
                buttonLocation = "banner";
            }

            if(util.getDataLayerEventDetails("dataLayer-initialized").sessionDetails.loggedIn == "false"){
                dataLayer.push({
                    "event" : "loyalty-join-now-button",
                    "button-location" : buttonLocation
                });
            } else {
                dataLayer.push({
                    "event" : "loyalty-upgrade-button",
                    "button-location" : buttonLocation
                });
            }
        });

        $('.menu-bar-loyalty-row').on('click', '.loyalty-dashboard-link', function() {
            dataLayer.push({
                "event" : "loyalty-view-rewards"
            });
        });
    
        if($('.pt_cart .voucher-input').length > 0){
            $('.pt_cart').on('click', '.voucher-input', function() {
                    if($('.voucher-input')[0].checked){
                        document.cookie = "redeemRewardPage=; expires=Thu, 18 Dec 2013 12:00:00 UTC;path=/";
                    } else {
                        document.cookie = "redeemRewardPage=cart;path=/";
                    }
            });
        }

        if($('.pt_checkout .voucher-input').length > 0){
            $('.pt_checkout').on('click', '.voucher-input', function() {
                    if($('.voucher-input')[0].checked){
                        document.cookie = "redeemRewardPage=" + $('.checkout-progress-indicator .active')[0].className.split(' ')[0] + ";path=/";
                    } else {
                        document.cookie = "redeemRewardPage=; expires=Thu, 18 Dec 2013 12:00:00 UTC;path=/";
                    }
            });
            if($('.voucher-input')[0].checked){
                $('.pt_checkout').on('click', '.js-billing-submit', function() {
                    var cookies = document.cookie.split(';');
                    for (var i = 0; i < cookies.length; i++) {
                        var tokens = cookies[i].split('=');
                        var cookieKey = tokens[0].trim();
                        if (cookieKey === 'redeemRewardPage') {
                            var redeemRewardPage = tokens[1];
                            var voucherAmount = $('.voucher-amount .usable-amount').html().trim();
                            voucherAmount = voucherAmount.substring(0,voucherAmount.indexOf("\n"));
                            dataLayer.push({
                                "event" : "loyalty-redeem-rewards",
                                "checkout-step" : redeemRewardPage,
                                "voucher-amount" : voucherAmount
                            });
                            document.cookie = "redeemRewardPage=; expires=Thu, 18 Dec 2013 12:00:00 UTC;path=/";
                        }
                    }
                });
            }
        }
 
    var $loyaltyRegistrationEmailInput = $('#LoyaltyEmailForm.new-sign-up input');

    $loyaltyRegistrationEmailInput.on('blur', function(){
        if(this.value){
            sessionStorage.setItem("loyaltycustomeremail", this.value);
        }  
    });
    
    $(window).on('load', function(){ 
        // Hanna Loyalty Step 2 of registration Chrome autofills last name with email value
        setTimeout(function(){
            var loyaltyInput = $('#LoyaltyNewRegistration .lastname');
            $(loyaltyInput).val('');
        },1000);
    
        var sessionEmail = sessionStorage.getItem("loyaltycustomeremail");
        if($loyaltyRegistrationEmailInput.length && sessionEmail){
            $loyaltyRegistrationEmailInput[0].value = sessionEmail;
        }
    });
    
    var $loyaltyRegistrationForm = $('#LoyaltyNewRegistration.loyalty-regform')
    $loyaltyRegistrationForm.on('submit', function(){
        sessionStorage.removeItem("loyaltycustomeremail")
    });

    $('.loyalty-success-banner').on('click', '.loyalty-confirmation-close', function() {
        $('.loyalty-success-banner').css('display','none');
    });
    
}

module.exports = {
    init: init
}