'use strict';

var util = require('./util');

var page = {
    title: '',
    type: '',
    params: util.getQueryStringParams(window.location.search.substr(1)),
    refreshUrl: '',
    redirect: function (newURL) {
        setTimeout(function () {
            window.location.href = newURL;
        }, 0);
    },
    refresh: function () {
    	var refreshUrl = this.refreshUrl;
    	this.refreshUrl = '';
        setTimeout(function () {
            window.location.assign((refreshUrl == '') ? window.location.href : refreshUrl);
        }, 500);
    }
};

module.exports = page;
