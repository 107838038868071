'use strict';

var ajax = require('./ajax');

var contentObjId = "#GatedPageDialog";
var tempOverlayId = "#GatedPageDialogTempOverlay";
var dialogclass = "privatesale-signin-modal";
var $contentObj;
var positioning = {my:"center", at:"center", of:window};
var okToClose = false;

var openModal = function () {
	$contentObj.dialog({
		dialogClass: dialogclass,
		autoOpen: true,
		modal: true,
		overlay: { opacity: 0.5, background: 'black' },
		resizable: false,
        draggable: false,
        beforeClose: function() {
        	if (okToClose) {
        		$('html, body').removeClass('has-fixed-modal');
        	}
        	return okToClose;
        }
	});
	if ($(tempOverlayId).length) { $(tempOverlayId).addClass('hidden'); }
	$('html, body').addClass('has-fixed-modal');
	$('.site-header').css('z-index','10100');
	window.setTimeout(function() { positionModal(); }, 1000);
	$(window).on('resize', function() {
		positionModal();
	});
	$('.emailVerificationForm').on('click', '.button', function (e) {
        e.preventDefault(); e.stopPropagation();
        verifyEmailSignIn($(this));
    });
};

var verifyEmailSignIn = function ($btn) {
	var $currentForm = $btn.closest('.emailVerificationForm');
	var $emailAddress = $currentForm.find('.js-email');
	if ($emailAddress.val() != "") {
		if (!$currentForm.valid()) {
		} else {
			$('.po-signin-errormsg').addClass('hidden');
			var url = $currentForm.attr('action') + '&format=ajax&email=' + encodeURIComponent($emailAddress.val());
			$currentForm.find('.ajaxLoadingHolder').addClass('ajax-loading');
			var htmlTemp = $currentForm.find('.ajaxLoadingHolder').html();
			$currentForm.find('.ajaxLoadingHolder').html('');
			var options = {
				url: url,
				callback: function (response) {
					if (response.status == "ok") {
						okToClose = true; $contentObj.dialog("close");
					} else {
						$currentForm.find('.ajaxLoadingHolder').removeClass('ajax-loading');
						$currentForm.find('.ajaxLoadingHolder').html(htmlTemp);
						$('.po-signin-errormsg').removeClass('hidden');
					}
				}
			};
			ajax.getJson(options);
		}
	} 
};

var positionModal = function () {
	$contentObj.dialog("option", "position", positioning);
};

var bindEvents = function () {
    $(window).on("load", function () {
   		if ($(contentObjId).length && $(contentObjId).html().length > 0) {
   			$contentObj = $(contentObjId); openModal();
   		}
    });
};

exports.init = function () {
    bindEvents();
};
