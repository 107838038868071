'use strict';

var util = require('./util');
var breakpoints = require('./breakpoints');

/**
 * @function
 * @description Initializes the tooltip-content and layout
 */
exports.init = function () {
    var toolTipInitialized = false;
    var isIE11 = util.detectIEEdge() && util.detectIEEdge() == '11';

    $(document).tooltip({
        items: '.tooltip',
        content: function () {
            return $(this).find('.tooltip-content').html();
        },
        position: {
            my: isIE11 ? "right+28 top+16" : "right+48 top+33",
            at: "right",
            collision: "fit none"
        }
    });

    $('.share-link').on('click', function (e) {
        e.preventDefault();
        var target = $(this).data('target');
        if (!target) {
            return;
        }
        $(target).toggleClass('active');
    });

//// Custom Tooltip Configs ////

    // Checkout / Information / Phone field
    $('.pt_checkout .form-row.co-phone').tooltip({
        classes: {
            ".ui-tooltip": "phone-help"
        },
        items: '.tooltip',
        track: true,
        content: function () {
            return $(this).find('.tooltip-content').html();
        },
        position: {
            my: isIE11 ? "right+28 top+16" : "right+48 top+33",
            at: "right",
            of: ".form-row.co-phone .form-field-tooltip",
            collision: "fit none"
        }
    });

    // Checkout / Shipping / Gift field
    $('.pt_checkout .form-row.co-gift').tooltip({
        classes: {
            ".ui-tooltip": "gift-help"
        },
        items: '.tooltip',
        track: true,
        content: function () {
            return $(this).find('.tooltip-content').html();
        },
        position: {
            my: isIE11 ? "right+28 top+16" : "right+48 top+33",
            at: "right",
            of: ".form-row.co-gift .form-field-tooltip",
            collision: "fit none"
        }
    });

    // Checkout / Payment / Giftcard field
    $('.pt_checkout .form-row.cob-giftcard').tooltip({
        classes: {
            ".ui-tooltip": "gift-help"
        },
        items: '.tooltip',
        track: true,
        content: function () {
            return $(this).find('.tooltip-content').html();
        },
        position: {
            my: isIE11 ? "right+28 top+16" : "right+48 top+33",
            at: "right",
            of: ".form-row.cob-giftcard .form-field-tooltip",
            collision: "fit none"
        }
    });

    $('.pdp-aplus .pdp-aplus__promo-item').tooltip({
        items: '.tooltip',
        track: true,
        content: function () {
            return $(this).find('.tooltip-content').html();
        },
        position: {
            my: isIE11 ? "right+28 top+16" : "right+48 top+33",
            at: "right",
            of: ".pdp-aplus__promo-item__msg",
            collision: "fit none"
        }
    });

    //Quick View PLP 
    var mobileScreen = breakpoints.isMobile();

    $(".pdp-aplus__promo-item__msg").tooltip({
        items: '.tooltip',
        track: false,
        content: function () {
            return $(this).find('.tooltip-content').html();
        },
        position: {
            my: mobileScreen ? "center-28 top+23" : "right+32 top+23",
            at: mobileScreen ? "center bottom" : "right bottom",
            collision: "fit none"
        }
    });

    $('.loyaltyTooltip').on('click', function() {
        $(this).addClass("on");
        if(!toolTipInitialized){
            $(this).tooltip();
            toolTipInitialized = true;
        }
    });
    
    $(document).on('click', '.loyaltyTooltip.on', function () {
        $(this).tooltip('close');
        $(this).removeClass("on");
    });
    
    $(".loyaltyTooltip").on('mouseout', function (e) {
        e.stopImmediatePropagation();
    });
    
    $(".loyaltyTooltip").on('mouseover', function (e) {
        e.stopImmediatePropagation();
    });
};
