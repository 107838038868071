'use strict';

var isFunction = require('lodash/isFunction');
var util = require('./util');


/**
 * @function
 * @description The loadTeaserCounts function first loads the UGC counts maintained by TurnTo and then calls the ​populateTeaser​ function.
 * @name loadTeaserCounts
 * @param {String} sku the product ID 
 */
function loadTeaserCounts(sku) {
	var xhr = new XMLHttpRequest();
	var turntoUrl = window.turnToConfig && window.turnToConfig.turntoUrl;
	var siteKey = window.turnToConfig && window.turnToConfig.siteKey;

	if (turntoUrl.length == 0 || siteKey.length == 0) {
		return;
	}
	
	var ugcCountsUrl = 'https://cdn-ws.' + turntoUrl +'/v5/sitedata/' + siteKey + '/' + sku + '/d/ugc/counts/' + turnToConfig.locale;
	xhr.open('GET', ugcCountsUrl, true);
	xhr.addEventListener('load', function() {
		/*sample return JSON
			{
				"questions": 0,
				"directQuestions": 0, 
				"answers": 0, 
				"directAnswers": 0, 
				"reviews": 100,
				"avgRating": 2.91, 
				"relatedReviews": 0, 
				"comments": 0, 
				"active": true
			}
		*/
		if (xhr.responseText) { 
			populateTeaser(JSON.parse(xhr.responseText));
		} 
	});
	xhr.send(); 
}

/**
 * @function
 * @name populateTeaser
 * @description This function calls other functions to build specific parts of your CGC Teaser. It should be modified to account for what content types you want to promote and/or the messaging you want based on the count returned. It ends with the ​writeReview​ function that spawns the TurnTo review form from your CGC Teaser. Note: If the reviews list and/or comments are initially hidden under a tab, see the ​Linking to your Content Underneath a Tab​ section for an example of how to handle this situation.
 * @param {Object} counts response from ugc counts URL
 */
function populateTeaser(counts) {
	var fragment = document.createDocumentFragment(); 
	if (counts.reviews > 0) { // has reviews
		fragment.appendChild(generateTeaserStars(counts.avgRating)); 
		fragment.appendChild(generateReadReviews(counts.reviews));
		addCountToExpanderTitle('.reviews .toggle', Resources.TURNTO_REVIEWS, counts.reviews);
		if($('.pdpLite').length == 0){
			if (counts.questions > 0) {
				fragment.appendChild(document.createTextNode(' | '));
				fragment.appendChild(generateQuestions(counts.questions, counts.answers));
				addCountToExpanderTitle('.qa .toggle', Resources.TURNTO_QA, counts.questions);
			}
			fragment.appendChild(document.createTextNode(' or '));
			fragment.appendChild(generateWriteReview('Write a Review'));
		}
	} else if($('.pdpLite').length == 0){ // no reviews
		if (counts.questions > 0) {
			fragment.appendChild(generateQuestions(counts.questions, 0));
			fragment.appendChild(document.createTextNode(' or '));
			addCountToExpanderTitle('.qa .toggle', Resources.TURNTO_QA, counts.questions);
		}
		fragment.appendChild(generateWriteReview('Be the first to write a review'));
	}
	var teaserElem = document.getElementsByClassName('TTteaser');

	if (!teaserElem[0]) {
		return;
	}
	teaserElem[0].appendChild(fragment);

	// add event listener to handle click to open the write a review screen 
	if($('.TTteaser__write-review').length > 0){
		document.querySelector('.TTteaser__write-review').addEventListener('click', function() {
			TurnToCmd('reviewsList.writeReview');
		});
	}

	function handleTeaserClick(toggle, toggleContent){
		if($('.pdpLite').length == 0){
			$('html, body').animate({scrollTop: $('.pdp-aplus__reviews').offset().top - 140}, 500);

			if(!$(toggle).hasClass('expanded')){
				$(toggle).addClass('expanded');
				$(toggleContent).show();
			}
		}
	}
	if($('.pdpLite').length == 0){
		$('.TTteaser__read-reviews, #tt-teaser .TTteaser__rating').on('click', () => handleTeaserClick('.reviews .toggle', '.reviews .toggle-content'));
		$('.TTteaser__read-qa').on('click', () => handleTeaserClick('.qa .toggle', '.qa .toggle-content'));
	} else if($('.pdpLite').length > 0){
		let originalScreenWidth = $(window).innerWidth();
		$(window).on('resize.turnTo', function () {
			if ((originalScreenWidth < 768 && $(window).innerWidth() > 768) || (originalScreenWidth > 768 && $(window).innerWidth() < 768)) {
				if($('.pdpLiteTop .TTteaser__rating').length == 0){
					teaserElem[0].appendChild(generateTeaserStars(counts.avgRating)); 
					teaserElem[0].appendChild(generateReadReviews(counts.reviews));
					$(window).off('resize.turnTo');	
				}
			}
		});
	}
}

/**
 * @function
 * @name createTeaserElement
 * @description This is a helper function used by other functions to create an element with a specific tag name and class name. Optionally pass “text” to populate the element with the provided text.
 * @param {String} tag type of DOM element
 * @param {String} className name of the element class
 * @param {String} text value to be added to the element
 * @return {Object} el DOM object
 */
function createTeaserElement(tag, className, text) { 
	var el = document.createElement(tag); 
	el.setAttribute('class', className);
	if (text) {
		el.innerText = text; 
	}
	return el; 
}

/**
 * @function
 * @name generateWriteReview
 * @description This function is called by the populateTeaser function to simply create a ‘Write a Review’ link. The click event handler for this element is added in the ​populateTeaser​ function after the teaser has been rendered on the page.
 * @param {String} text value to be added to the element
 * @return {Object} DOM object
 */
function generateWriteReview(text) {
	return createTeaserElement('button', 'TTteaser__write-review', text); 
}

/**
 * @function
 * @name generateReadComments
 * @description This function is called by the populateTeaser function to generate the link that will move to the place in the page where the Checkout Comments are located by placing the name of the Checkout Comments div id in the href.
 * @param {Number} numComments
 * @return {Object} el DOM object
 */
// eslint-disable-next-line
function generateReadComments(numComments) {
	// Populate the 'x Buyer Comments' text with the number of comments and set
	var text = numComments + ' Buyer Comment' + (numComments > 1 ? 's' : '');
	var el = createTeaserElement('a', 'TTteaser__read-comments', text);
	el.setAttribute('href', '#tt-chatter-widget');
	return el; 
}

/**
 * @function
 * @name generateQuestions
 * @description This function is called by the populateTeaser function to generate the read questions
 * @param {Number} numQuestions
 * @return {Object} el DOM object
 */
function generateQuestions(num_questions, num_answers) {
	// Populate 'x Questions' text with the number of questions
	var text = num_questions + ' Question' + (num_questions > 1 ? 's' : '');
	
	// then populate the number of answers
	if (num_answers > 0) {
	text = text + ', ' + num_answers + ' Answer' + (num_answers > 1 ? 's' : '');
	}
	
	var el = createTeaserElement('a', 'TTteaser__read-qa', text);
	el.setAttribute('href', '#tt-instant-answers-widget');
	
	//For the Q&A list widget set to the following
	el.setAttribute('href', '#tt-qa-list');
	
	return el;
}

/**
 * @function
 * @name generateReadReviews
 * @description This function is called by the populateTeaser function to generate the link that will move to the place in the page where the reviews list is located by placing the name of the reviews list div id in the href.
 * @param {Number} numReviews
 * @return {Object} el DOM object
 */
function generateReadReviews(numReviews) {
	// Populate the 'Read x Reviews' text with the number of reviews and set
	if($('.pdpLite').length == 0){
		var text = 'Read ' + numReviews + ' Review' + (numReviews > 1 ? 's' : '');
	} else {
		var text = '(' + numReviews + ')';
	}
	var el = createTeaserElement('a', 'TTteaser__read-reviews', text);
	el.setAttribute('href', '#tt-reviews-list'); 
	if($('.pdpLite').length > 0){
		const fullPdpUrl = util.removeParamFromURL(document.location.href, 'pdplite');
		el.setAttribute('href', fullPdpUrl + '#tt-reviews-list'); 
	}
		return el;
}

/**
 * @function
 * @name generateTeaserStar
 * @description The generateTeaserStars function creates an SVG element that references one of three possible stars (full, half or empty). The SVGs must be defined at the top of the body of the page.
 * @param {String} starType
 * @return {Object} el DOM object
 */
function generateTeaserStar(starType) { 
	var svgEl = document.createElementNS('http://www.w3.org/2000/svg', 'svg'); 
	svgEl.setAttribute('class', 'TTteaser__icon--' + starType); 
	var useEl = document.createElementNS('http://www.w3.org/2000/svg','use'); 
	useEl.setAttributeNS('http://www.w3.org/1999/xlink', 'xlink:href', '#tt-teaser-star--' + starType);
	svgEl.appendChild(useEl);
	var el = createTeaserElement('span', 'TTteaser__star'); 
	el.appendChild(svgEl);
	return el;
}

//adjusts/rounds the rating to have decimal value of .0 or .5
function getAdjustedRating(rating) {
	var floorValue = Math.floor(rating);
	var rounded = Math.round(rating * 100) / 100;
	var decimalValue = parseFloat((rounded - floorValue).toFixed(2));
	if (decimalValue < 0.25) {
		return floorValue;
	} else if (decimalValue < 0.75) {
		return floorValue + 0.5;
	}
	return floorValue + 1;
}

/**
 * @function
 * @name generateTeaserStars
 * @description The generateTeaserStars function is called by the ​populateTeaser​ function to create the review stars.
 * @param {String} rating
 * @return {Object} el DOM object
 */
function generateTeaserStars(rating) {
	var el = createTeaserElement('div', 'TTteaser__rating');
	if($('.pdpLite').length > 0){
		const fullPdpUrl = util.removeParamFromURL(document.location.href, 'pdplite');

		var starsEl = createTeaserElement('a', 'TTteaser__stars-link');
		starsEl.setAttribute('href', fullPdpUrl + '#tt-reviews-summary'); 
		el.appendChild(starsEl);
	}
	var adjustedRating = getAdjustedRating(rating);
	for (var i = 1; i <= 5 ; i++) {
		if (i > adjustedRating && i - adjustedRating >= 1) {
			el.appendChild(generateTeaserStar('empty'));
		} else if (i <= adjustedRating) {
			el.appendChild(generateTeaserStar('full'));
		} else {
			el.appendChild(generateTeaserStar('half'));
		}
	}
	return el;
}

function addCountToExpanderTitle(selector, messaging, count) {
	if ($(selector).length < 1) {
		return;	
	}

	$(selector).text(`${messaging} (${count})`);
}

/**
 * @function
 * @name createTeaserElementProductTile
 * @description This is a helper function used by other functions to create an element with a specific tag name and class name. Optionally pass “text” to populate the element with the provided text.
 * @param {String} tag type of DOM element
 * @param {String} className name of the element class
 * @param {String} text value to be added to the element
 * @return {Object} el DOM object
 */
function createTeaserElementProductTile(tag, className, text) { 
    var el = document.createElement(tag);
    el.setAttribute('class', className);
    if (text) {
        el.innerText = text;
    }
    return el;
}

/**
 * @function
 * @name generateTeaserStarProductTile
 * @description The generateTeaserStarProductTile function creates an SVG element that references one of three possible stars (full, half or empty). The SVGs must be defined at the top of the body of the page.
 * @param {String} starType
 * @return {Object} el DOM object
 */
function generateTeaserStarProductTile(starType) { 
	var svgEl = document.createElementNS('http://www.w3.org/2000/svg', 'svg'); 
	svgEl.setAttribute('class', 'TTteaser__icon--' + starType); 
	var useEl = document.createElementNS('http://www.w3.org/2000/svg','use'); 
	useEl.setAttributeNS('http://www.w3.org/1999/xlink', 'xlink:href', '#tt-teaser-star--' + starType);
	svgEl.appendChild(useEl);
	var el = createTeaserElementProductTile('span', 'TTteaser__star'); 
	el.appendChild(svgEl);
	return el;
}

/**
 * @function
 * @name generateTeaserStarsProductTile
 * @description The generateTeaserStarsProductTile function is called by the ​populateTeaser​ function to create the review stars.
 * @param {Object} teaserDiv DOM object where to add the teaser stars
 * @param {String} rating
 * @return {Object} el DOM object
 */
function generateTeaserStarsProductTile(teaserDiv, rating) {
    var el = createTeaserElementProductTile('div', 'TTteaser__rating');
    var numFullStars = Math.floor(rating);
    for (var i = 0; i < numFullStars; i++) {
        el.appendChild(generateTeaserStarProductTile('full'));
    }
    var hasHalfStar = (rating - numFullStars) >= 0.5;
    if (hasHalfStar) {
        el.appendChild(generateTeaserStarProductTile('half'));
    }
    var emptyStarsStartIdx = numFullStars + (hasHalfStar ? 1 : 0);
    for (var i = emptyStarsStartIdx; i < 5; i++) {
        el.appendChild(generateTeaserStarProductTile('empty'));
    }
	teaserDiv.appendChild(el);
}

function renderTileStars() {
    // :not(*) will only select tiles that haven't had the stars rendered.
    var allTeaserDivs = $('.TTteaser-tile:not(:has(*))');

    for(var i=0; i <  allTeaserDivs.length; i++) {
        var teaserDiv = allTeaserDivs[i];
        if('starrating' in teaserDiv.dataset && teaserDiv.dataset.starrating > 0) {
            generateTeaserStarsProductTile(teaserDiv, teaserDiv.dataset.starrating);
        }
    }
}

function checkCCStatus() {
	// check if cookie consent functionality is available and turnto is enabled
	if (!(window.ccCheckV && isFunction(window.ccCheckV))) {
		return true;
	}
	return window.ccCheckV("turnto");
}

function hideTurnToPDP() {
	$('.pdp-aplus__reviews').hide();
}

function init() {
	// check if user has disabled turnto cookies via cookie privacy settings.
	if (checkCCStatus() && window.turnToConfig && window.turnToConfig.sku) {
		loadTeaserCounts(window.turnToConfig.sku);
	} else {
		hideTurnToPDP();
	}
	// stars can render even if the turnto is disabled via privacy settings.
    renderTileStars();
}


module.exports = {
    init: init,
    loadTeaserCounts: loadTeaserCounts,
    renderTileStars: renderTileStars
};